import { Component, OnInit, Input } from '@angular/core';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service'
import { Title } from '@angular/platform-browser';
 


@Component({
  selector: 'component-cp034',
  templateUrl: './cp034.component.html',
  styleUrls: ['./cp034.component.scss']
})
export class Cp034Component implements OnInit {

  @Input()
  context:any = []
  selectedLanguage:string = ""
  origSelectedLanguage:string = ""

  constructor(private _api:ApiService, public dialog: MatDialog, private _snackbarManager:SnackbarmanagerService, private title:Title) { }

  ngOnInit(): void {    
    this.title.setTitle(this.title.getTitle().replace("...", "Settings"));
    //console.log(this._api.getLanguage())
    for (let l of this.context.Row) {
      //console.log(l)
      if (l.Elemento2 == this._api.getLanguage()) {
        this.selectedLanguage = l.Elemento3;
        this.origSelectedLanguage = l.Elemento3;
        //console.log("FOUND")
        break;
      }
    }

    //console.log(this.selectedLanguage);
    //console.log(this.origSelectedLanguage);
  }

  save() {
    this._api.loading = true;

    var context_data = {
      "LanguageCode" : "en",
      "LanguageCodeIso" : "ENG"};
    for (let l of this.context.Row) {
      if (l.Elemento3 == this.selectedLanguage) {
        context_data = {
          "LanguageCode" : l.Elemento2,
          "LanguageCodeIso" : l.Elemento1
        }
        break;
      }
    }

    this._api.doButtonPostRequest(context_data, "/change-language").subscribe((response) => {
      if(response) {
        this._api.printResponse(response);
        this._api.setLanguage(context_data["LanguageCode"])
        //this._snackbarManager.open(this.context.MessageSuccess, 'ok');
        //this._api.constructionComplete = false;
        sessionStorage.removeItem("page")        
        location.reload()
        //this._api.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);
        this._snackbarManager.open(this.context.MessageError, 'ko');
        this._api.loading = false;
      }
    });
  }

  sync() {
    this._api.loading = true;

    var context_data = {
      "MessageGroupId" : this.context.QueueMessageGroupId,
      "TimeDelta" : new Date().getTimezoneOffset()
    }

    this._api.doButtonPostRequest(context_data, this.context.ButtonCezanneSyncAction, this.context.FAGUID != undefined ? this.context.FAGUID : undefined).subscribe((response) => {
      if(response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response))
        //JSON.parse(JSON.stringify(response)).successMessage
        if (responseObj.isSuccess != undefined && responseObj.isSuccess) this._snackbarManager.open("Success!", 'ok');
        else {
          if (responseObj.message != undefined && responseObj.message != "") this._snackbarManager.open(responseObj.message, 'ko');
          else this._snackbarManager.open("Error!", 'ko');
        }
        this._api.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);
        this._snackbarManager.open("Error", 'ko');
        this._api.loading = false;
      }
    });

  }

}

<mat-card>
    <mat-card-content>

        <mat-form-field class="formfield" appearance="fill">
            <mat-label>{{ context.TitleStatus }}</mat-label>
            <mat-select [(ngModel)]="context.StatusSelected" [(value)]="context.StatusSelected" [placeholder]="context.PlacheolderStatus">
                <mat-option *ngFor="let e of context.Status" [value]="e.IdStatus">
                    {{e.Description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <textarea ngIf="context.NoteActive != null && context.NoteActive != ''" class="txtarea" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [placeholder]="context.PlaceholderNote">{{ context.ValueNote }}</textarea>            

    </mat-card-content>
</mat-card>
import { Component, Input, ViewChild } from '@angular/core';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { IHttpPostMessageResponse } from 'http-post-message';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';


export interface ConfigResponse {
  Id: string;
  EmbedUrl: string;
  EmbedToken: {
    Token: string;
  };
}


@Component({
  selector: 'component-cp061',
  templateUrl: './cp061.component.html',
  styleUrls: ['./cp061.component.scss'],
})
export class Cp061Component {

  @Input()
  context:any
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

  isEmbedded = false;
  loading = true;

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    embedUrl: undefined,
    id: undefined,
    viewMode: models.ViewMode.View,
    permissions: models.Permissions.Read,
    settings: {
      panes: {
        filters: {
            expanded: false,
            visible: false
        }
      },
      bars: {
        statusBar: {
          visible: true
        }
      },
      zoomLevel: 1
    }
  };
  eventHandlersMap = new Map ([
    ['loaded', () => console.log('Report has loaded')],
    ['rendered', () => console.log('Report has rendered')],
    ['error', (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
          this.errorThrower.throw(event.detail)
        }
      },
    ],
    ['pageChanged', (event) => console.log(event)],
  ])


  constructor(private errorThrower:ErrorthrowerService, private _api:ApiService, private _snackbarManager:SnackbarmanagerService) {}

  ngOnInit() {    
    var contextReport = this.parseContext();
    this.getEmbedToken(contextReport);
  }

  parseContext() {
    if (this.context.url != undefined) {
      if (this.context.url.includes("https://app.powerbi.com/groups/")) {
        var workspaceId = this.context.url.split("groups/")[1].split("/")[0];
        var reportStr = this.context.url.split("reports/")[1];
        if (reportStr.includes('/')) {
          var reportId = reportStr.split("/")[0];
        } else {
          var reportId = reportStr;
        }
      } else if (this.context.embedUrl.includes("https://app.powerbi.com/reportEmbed")){
        var workspaceId = this.context.embedUrl.split("groupId=")[1].split("&")[0];
        var reportStr = this.context.embedUrl.split("reportId=")[1].split("&")[0];
      } else {
        this.errorThrower.throw("Can't understand report url string!");
      }
      this.context.workspaceId = workspaceId
      this.context.reportId = reportId
      return { 
        workspaceId: this.context.workspaceId,
        reportId: this.context.reportId
      }

    } else if (this.context.reportId != undefined && this.context.datasetId != undefined && this.context.embedUrl != undefined) {
      this.reportConfig.embedUrl = this.context.embedUrl
      return { 
        reportId: this.context.reportId,
        datasetId: this.context.datasetId 
      }

    } else {
      this.errorThrower.throw("Can't parse context!");
      return undefined;
    }
  }

  getNewEmbedToken() {
    var contextReport:any = { 
      reportId: this.context.reportId
     }
    if (this.context.workspaceId != undefined) {
      contextReport.workspaceId = this.context.workspaceId
    } else if (this.context.datasetId != undefined) {
      contextReport.datasetId = this.context.datasetId
    }
    this.getEmbedToken(contextReport);
  }

  getEmbedToken(contextReport:any) {
    this._api.doButtonPostRequest(contextReport, "/powerbi").subscribe((response) => {      
      if (response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response));  
        this.reportConfig.accessToken = responseObj.body.embedToken;
        if (this.reportConfig.embedUrl == undefined && responseObj.body.embedUrl != undefined) {
          this.reportConfig.embedUrl = responseObj.body.embedUrl;
        } else if (this.reportConfig.embedUrl == undefined && responseObj.body.embedUrl == undefined) {
          this.errorThrower.throw("Expecting response with 'embedUrl' key!");
        }
        this.reportConfig.accessToken = responseObj.body.embedToken;
        this.isEmbedded = true;
        setTimeout(() => {
          console.log("Token refresh");
          this.isEmbedded = false;
          this.getNewEmbedToken();
        }, responseObj.body.expirationIn * 60000);
        this.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);    
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
        else this._snackbarManager.open(error.error, 'ko');
        this.loading = false; 
        if (error.status != undefined && error.status == 401) {
          location.reload();
        }
      }
    });  
  }

}

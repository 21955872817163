import { Component, Input, ViewChild } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { DialogComponent } from '../dialog/dialog.component';
import { MatSelect } from '@angular/material/select';
import { MatLegacySelect } from '@angular/material/legacy-select';

@Component({
  selector: 'component-cp090',
  templateUrl: './cp090.component.html',
  styleUrls: ['./cp090.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class Cp090Component {

  @Input() context:any
  @Input() stagingApproverMode:boolean = false
  loading:boolean = true;
  dataStruct:any = {"Data":{}};
  environmentChosen:string = "";
  tableChosen:string = "";
  fileChosen:string = "";
  tableColumns:string[] = [];
  rowsSelection:any = [];
  @ViewChild("tableSelect") tableSelect:MatLegacySelect | undefined;
  @ViewChild("fileSelect") fileSelect:MatLegacySelect | undefined;

  constructor(private _snackbarManager:SnackbarmanagerService, public dialog: MatDialog, private _api:ApiService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.retrieveTables("Produzione")
  }

  retrieveTables(env:string) {
    this.tableSelect!.value = undefined
    this.fileSelect!.value = undefined
    this.tableChosen = ""
    this.fileChosen = ""
    this.environmentChosen = ""
    this.dataStruct = {"Data":{}}
    this._api.doButtonPostRequest(this.dataStruct, "/dbbackup").subscribe((response) => {      
      if (response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response));  
        this.dataStruct = responseObj.body
        this.environmentChosen = env
        this.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);    
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
        else this._snackbarManager.open(error.error, 'ko');
        this.loading = false; 
      }
    });       
  }

  retrieveFiles(tableName:any) {    
    this.loading = true;
    this.fileSelect!.value = undefined
    this.tableChosen = "";
    this.fileChosen = "";
    if ("RowChoice" in this.dataStruct) delete this.dataStruct["RowChoice"];
    if ("FileChoice" in this.dataStruct) delete this.dataStruct["FileChoice"];
    var today = new Date();
    var date = today.getFullYear() + '/' + String(today.getMonth() + 1).padStart(2, '0') + '/' + String(today.getDate()).padStart(2, '0');
    this.dataStruct["TableChoice"] = {
      "Environment": this.environmentChosen,
      "Table": tableName,
      "DataStart": "2024/01/01",
      "DataEnd": date
    }
    this._api.doButtonPostRequest(this.dataStruct, "/dbbackup").subscribe((response) => {      
      if (response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response));  
        this.dataStruct = responseObj.body;
        this.tableChosen = this.dataStruct["TableChoice"]["Table"]
        if (this.dataStruct['Data'][this.environmentChosen][this.tableChosen] == undefined) {
          this._snackbarManager.open("No backup file found!", "warning")
        }
        this.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);    
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
        else this._snackbarManager.open(error.error, 'ko');
        this.loading = false; 
      }
    });  
  }

  retrieveRows(fileName:any) {
    this.loading = true;
    this.fileChosen = "";
    this.rowsSelection = [];
    if ("RowChoice" in this.dataStruct) delete this.dataStruct["RowChoice"];
    this.dataStruct["FileChoice"] = {
      "File": fileName
    }
    this._api.doButtonPostRequest(this.dataStruct, "/dbbackup").subscribe((response) => {      
      if (response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response));  
        this.dataStruct = responseObj.body;
        this.tableColumns = [];
        this.fileChosen = fileName;
        if (this.dataStruct['Data'][this.environmentChosen][this.tableChosen][this.fileChosen].length > 0) {
          var keys = Object.keys(this.dataStruct['Data'][this.environmentChosen][this.tableChosen][this.fileChosen][0]);
          keys.forEach((key:string) => {
            this.tableColumns.push(key);
          })
        }
        this.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);    
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
        else this._snackbarManager.open(error.error, 'ko');
        this.loading = false; 
      }
    });
  }

  backupRestoreTable(operation=1) {
    console.log(this.rowsSelection)
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: this.context.PopupLabels[0],
        content: this.context.PopupLabels[1],
        accept: this.context.PopupLabels[2],
        reject: this.context.PopupLabels[3]
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {              
        this.loading = true
        this._api.loading = true
        if (operation == 0) this.dataStruct["FileChoice"]["Operation"] = "Backup"
        else if (operation == 1) this.dataStruct["FileChoice"]["Operation"] = "Restore"
        else if (operation == 2) this.dataStruct["FileChoice"]["Operation"] = "Approve"
        this.dataStruct["RowChoice"] = []
        var i = 0;
        this.rowsSelection.forEach((row:any) => {
          this.dataStruct['Data'][this.environmentChosen][this.tableChosen][this.fileChosen].every((element:any) => {
            if (element == row) {
              this.dataStruct["RowChoice"].push(i);
              return false;
            }
            i += 1;
            return true;
          })
          i = 0;
        })
        console.log(this.dataStruct["RowChoice"])

        this._api.doButtonPostRequest(this.dataStruct, "/dbbackup").subscribe((response) => {      
          if (response) {
            this._api.printResponse(response);
            var responseObj = JSON.parse(JSON.stringify(response));  
            if ("isSuccess" in responseObj && responseObj.isSuccess) {
              this._snackbarManager.open("Success!", "ok")
            } else {
              this._snackbarManager.open("An error has occurred, check the logs on AWS!", "ko")
            }
            this.loading = false;
            this._api.loading = false
          }
        }, (error) => {
          if(error) {
            console.log(error);    
            if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
            else this._snackbarManager.open(error.error, 'ko');
            this.loading = false;
            this._api.loading = false
          }
        });
      }
    })
  }

}
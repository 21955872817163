<div class="starbuttons-div"><!-- && context.ButtonStarYourTeamMemberActive != 'None'-->
    <button *ngIf="context.ButtonStarYourTeamMemberActive != undefined && context.ButtonStarYourTeamMemberActive != ''" mat-raised-button color="accent" [matTooltip]="context.ButtonStarYourTeamMemberTooltip" (click)="starMember()"
        class="star-button">
        {{context.ButtonStarYourTeamMemberLabel}}
    </button>          
    <button *ngIf="context.ButtonStarYourTeamMemberActive != undefined && context.ButtonStarYourTeamMemberActive != '' && context.ButtonStarReportLabel != undefined && context.ButtonStarReportLabel != ''" mat-raised-button color="accent" [matTooltip]="context.ButtonStarReportTooltip" (click)="starReport()"
        class="star-button">
        {{context.ButtonStarReportLabel}}
    </button>
    <button *ngIf="context.ButtonRelevanceReportLabel != undefined && context.ButtonRelevanceReportLabel != ''" mat-raised-button color="accent" [matTooltip]="context.ButtonRelevanceReportTooltip" (click)="relevanceReport()" 
            class="star-button">
            {{context.ButtonRelevanceReportLabel}}
    </button>
</div>
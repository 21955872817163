<component-starteammemberbuttons *ngIf="context.ButtonStarYourTeamMemberActive != 'None'" [context]="context" [selectYear]="selectYear" [selectRegion]="selectRegion" 
                    [selectJob]="selectJob" [selectManager]="selectManager" [selectArea]="selectArea" 
                    [selectLevel]="selectLevel" componentType="CP043">
</component-starteammemberbuttons>


<mat-card>
    <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card"> 
        {{context.Title}}
    </mat-card-title>
    <mat-card-content class="mat-card-content">
        <div class="form-table">
            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelAnno}}</mat-label>
                <input [(ngModel)]="selectYear" matInput [matDatepicker]="picker"/>
                <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                <mat-datepicker #picker [startAt]="selectYear" startView="multi-year" (yearSelected)="chosenYearHandler($event, picker)"></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelRegion}}</mat-label>
                <mat-select  [(ngModel)]="selectRegion" [(value)]="context.Region">
                    <mat-option *ngFor="let e of context.RegionList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelJob}}</mat-label>
                <mat-select [(ngModel)]="selectJob" [(value)]="context.Job">
                    <mat-option *ngFor="let e of context.JobList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelArea}}</mat-label>
                <mat-select [(ngModel)]="selectArea" [(value)]="context.Area">
                    <mat-option *ngFor="let e of context.AreaList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-table" style="justify-content: flex-end;;">
            <div class="formfield"></div>
            <div class="formfield"></div>

            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelManager}}</mat-label>
                <mat-select [(ngModel)]="selectManager" [(value)]="context.Manager">
                    <mat-option *ngFor="let e of context.ManagerList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field class="formfield" appearance="fill">
                <mat-label>Level</mat-label>
                <mat-select [(ngModel)]="selectLevel" [(value)]="context.Level">
                    <mat-option *ngFor="let e of context.LevelList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="searchbutton-div">
            <button mat-raised-button class="genericbutton" color="accent" [matTooltip]="context.ButtonTooltip" (click)="search()">
                <mat-icon>search</mat-icon> {{context.ButtonLabel}}
            </button>
        </div>
        
        <div>
            <button *ngIf="context.table1.length > 0" mat-raised-button [style]="context.ButtonReportStyle" [matTooltip]="context.ButtonReportTooltip" (click)="report()">
                {{context.ButtonReportLabel}}
            </button>
        </div>

        <div *ngIf="context.DescriptionTop != ''"  class="descriptiontop-div" >
            {{ context.table1.length > 0 ? context.DescriptionTop : context.NoDataFound }}
        </div>

        <div *ngIf="context.table1.length > 0" class="descriptiontable1-div">
            {{context.DescriptionTable1}}
        </div>

        <div *ngIf="context.table1.length > 0" class="parent-container">
            <div class="child-container-1">
                <table>
                    <tr class="reallydark-row">
                        <th></th>
                        <th *ngFor="let number of [].constructor(context.table1[0].length - 1); let j = index;">{{j + 1}}</th>
                    </tr>
                    <ng-template ngFor let-item [ngForOf]="context.table1" let-i="index">
                        <tr class="light-row" *ngIf="i % 2 == 0">
                            <th style="width:20%">{{context.table1[i][0]}}</th>
                            <th *ngFor="let number of [].constructor(context.table1[i].length - 1); let j = index;">{{context.table1[i][j + 1] * 100 | number}}%</th>
                        </tr>
                        <tr class="dark-row" *ngIf="i % 2 != 0">
                            <th style="width:20%">{{context.table1[i][0]}}</th>
                            <th *ngFor="let number of [].constructor(context.table1[i].length - 1); let j = index;">{{context.table1[i][j + 1] * 100 | number}}%</th>
                        </tr>
                    </ng-template>                
                </table>
            </div>
            <div *ngIf="context.chart1 != undefined" class="child-container-2">
                <div class="pchart">
                    <p-chart type="line" width=240px height=240px [data]="basicData1" [options]="basicOptions1"></p-chart>
                </div>
            </div>
        </div>

        <div *ngIf="context.table2.length > 0" class="descriptiontable2-div">
            {{context.DescriptionTable2}}
        </div>

        <div *ngIf="context.table2.length > 0" class="parent-container">
            <div class="child-container-1">
                <table>
                    <tr class="reallydark-row">
                        <th></th>
                        <th *ngFor="let number of [].constructor(context.table2[0].length - 1); let j = index;">{{j + 1}}</th>
                    </tr>
                    <ng-template ngFor let-item [ngForOf]="context.table2" let-i="index">
                        <tr class="light-row" *ngIf="i % 2 == 0">
                            <th style="width:20%">{{context.table2[i][0]}}</th>
                            <th *ngFor="let number of [].constructor(context.table2[i].length - 1); let j = index;">{{context.table2[i][j + 1] * 100 | number}}%</th>
                        </tr>
                        <tr class="dark-row" *ngIf="i % 2 != 0">
                            <th style="width:20%">{{context.table2[i][0]}}</th>
                            <th *ngFor="let number of [].constructor(context.table2[i].length - 1); let j = index;">{{context.table2[i][j + 1] * 100 | number}}%</th>
                        </tr>
                    </ng-template>   
                </table>
            </div>
            <div *ngIf="context.chart2 != undefined" class="child-container-2">
                <div class="pchart">
                    <p-chart type="line" width=240px height=240px [data]="basicData2" [options]="basicOptions2"></p-chart>
                </div>
            </div>
        </div>

        <div *ngIf="context.DescriptionBottom != '' && context.table2.length > 0"  class="descriptiontop-div" >
            {{context.DescriptionBottom}}
        </div>
    </mat-card-content>
<mat-card>
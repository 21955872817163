import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from '../services/api.service';



@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss']
})
export class ComponentsComponent implements OnInit {

    @Input() component:any;
    @Input() title:any;
    @Input() username:any;
    @Input() response:any;

    constructor(private _api:ApiService) { }

    ngOnInit(): void {
    }

}

<button mat-raised-button *ngIf="displayBackBtn == true" class="button-back-absence" (click)="onClickBack()">Back</button>
<button mat-raised-button *ngIf="context.ButtonContentRun != '' && context.ButtonContentRun != null" color="accent"
        class="button-back-absence" (click)="onClickRun()">
        {{ context.ButtonContentRun }}
</button>
      <!-- Questo bottone deve avere un icona come 'testo', possibilmente
           una tipo una freccia indietro, visto che e' il bottone per tornare
           indietro. -->

<div class="card-absence">
    <mat-card class="card box-datepicker">
        <mat-card-content class="inner-card">
            <div *ngIf="context.TitleDatePicker != null && context.TitleDatePicker != ''" class="title-datepicker" [style.background-color]="_styler.currentAccent">
            {{ context.TitleDatePicker }}
            </div>
            <div class="box-sel-company">
                <mat-form-field appearance="fill" color="accent" style="width: 300px">
                    <mat-label>{{ context.PlaceholderMonth }} / {{ context.PlaceholderYear }}</mat-label>
                    <input matInput [(ngModel)]="monthAndYear" [matDatepicker]="picker" (dateChange)="emitDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="year" (monthSelected)="monthChanged($event, picker)">
                    </mat-datepicker>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>
</div>


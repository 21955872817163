import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'component-cp023',
  templateUrl: './cp023.component.html',
  styleUrls: ['./cp023.component.scss']
})
export class Cp023Component implements OnInit {

  @Input()
  context:any;
  context2:any;
  @Input()
  position:any;

  showTables:boolean = false;

  path: any;

  constructor(private _api:ApiService) { }

  ngOnInit(): void {
    let url = window.location.pathname.split("/");
    this.path = url.filter((e)=> e !== '');
  }

  changePath() {   
    this._api.loading = true;      
    this._api.changeOBGUID(this._api.getUrlParameters().obguid, "wf-route");      

    /*
    this._api.doButtonPostRequest(undefined, "/wf-route").subscribe((response) => {  
      if(response) { 
        //this.router.navigate([this.path[0],this.path[1],this.path[2],this.path[3],this.path[4],]);     
        this._api.printResponse(response);
        this.context2 = response;
        this.showTables = true;    
        this._api.changeOBGUID(this._api.getUrlParameters().obguid, "wf-route"); 
        this._api.loading = false;
      }      
    }, (error) => {
      if(error) {        
        console.log(error);
        this._api.loading = false;
      }
    }); */
  }

  closeWfSection() {    
    //this._api.changeOBGUID(this._api.getUrlParameters().obguid)
    history.back();
    this.context2 = [];
    //console.log(this.context);
    this.showTables = false;
  }
}

<mat-card>
    <mat-card-content class="mat-card-content">
        <p-table #dt [rowHover]="false" [rows]="10" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
            [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true"
            [columns]="context.Column" [value]="context.Row" sortMode="multiple" [multiSortMeta]="multiSortMeta"
            [globalFilterFields]="['Elemento1', 'Elemento2']">

            <ng-template pTemplate="caption" id="hideIcons">
                <div class="table_header" id="hideIcons">
                    <div style="width:160px">
                        <button class="generic-button" *ngIf="context.ButtonExcelExportContent != ''" 
                            (click)="exportTable()" [matTooltip]="context.ButtonExcelExportContent" mat-raised-button color="accent">
                        <mat-icon>table_chart</mat-icon>
                        <span class="excel_button_label"> {{ context.ButtonExcelExportContent }} </span>
                    </button>
                    </div>
                    <div class="search_div">                        
                        <span class="p-input-icon-right" id="hideIcons">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" placeholder="Search" />
                        </span>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th class="column_header" *ngFor="let column of context.Column; let i = index" [pSortableColumn]="columns[i].field" pResizableColumn>
                        <span class="column_title">
                            {{columns[i].header}}
                        </span>                        
                        <span class="sort_icons" id="hideIcons">
                            <p-columnFilter type="text" [field]="columns[i].field" display="menu" class="ml-auto"></p-columnFilter>
                            <p-sortIcon [field]="columns[i].field"></p-sortIcon>
                        </span>
                    </th>
                    <th>

                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr pSelectableRowDisabled="true">
                    <td *ngFor="let item of context.Row; let i = index">
                        <span class="table_data">{{ rowData['Elemento' + (i + 1)] }} </span>
                    </td> 
                    <td class="view_button_cell">
                        <button class="generic-button" mat-raised-button color="primary" (click)="open(rowData['Elemento2'])">Apri</button>
                    </td>     
                </tr>
            </ng-template>

            
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="context.Row.length == 0" >
                    <td [attr.colspan]="columns.length" class="nodata_cell">
                        No data found.
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </mat-card-content>
</mat-card>

import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'component-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input() context:any;
  detailsVisible:boolean = false;
  supportSent:boolean = false;
  errorType:number = 0;

  constructor(private _api:ApiService, private  _snackbarManager:SnackbarmanagerService, private title:Title) { }

  ngOnInit(): void {
    try {
      this.errorType = 0;
      if ((this.context.error.message != undefined && this.context.error.message.includes('PRFUCO')) ||
          (this.context.error != undefined && this.context.error.includes('PRFUCO'))) 
        this.errorType = 1;
    } catch(e) {}
    
    this.title.setTitle("Error");
  }

  goToHomePage() {
    /*
    this._api.loading = true;
    setTimeout(() => {    
      sessionStorage.removeItem("page");              
      location.reload();
    }, 300);*/
    if (this.context.status == -1) location.reload();
    else history.back()
  }
  
  sendSupport() {           
      this._api.loading = true;
      var context_data = {
        "Error" : this.context.error
      }    

      this._api.doButtonPostRequest(context_data, "/support").subscribe((response) => {      
        if(response) {        
          this._api.printResponse(response);             
          this._snackbarManager.open("Sent!", 'ok');
          this._api.loading = false; 
          this.supportSent = true;
        }
      }, (error) => {
        if(error) {
          console.log(error);    
          this._snackbarManager.open("Nooo another error!!! :''(", 'ko');
          //this._api.lastError = JSON.parse(JSON.stringify(error));
          //this._api.navigate("","","","","ErrorPage");
          this._api.loading = false;
        }
      });     
  }

}

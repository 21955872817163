import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'component-cp039',
  templateUrl: './cp039.component.html',
  styleUrls: ['./cp039.component.scss']
})
export class Cp039Component implements OnInit {

  @Input()
  context:any;

  constructor() { }

  ngOnInit(): void {
  }

  formatValue(format:any, value:any) : any{
    switch(format){
      case 'n0':
        return Number(value);
      default:
        return value;
    }
     
  }

}

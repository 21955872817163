import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface Condition {
  equals:boolean,
  value:string,
  and?:boolean
}

@Component({
  selector: 'component-cp063',
  templateUrl: './cp063.component.html',
  styleUrls: ['./cp063.component.scss']
})
export class Cp063Component {

  @Input() evalString:String = ""
  @Output() closeFormEvent = new EventEmitter<any>();
  evalStringParsed:any = []
  isEvalValid:boolean = false

  ngOnInit() {
    this.parseEval(this.evalString)
  }

  parseEval(evalString:String) {
    //evalString = "{0} == '1' && {0} != ''"
    var eval_splitted = evalString.replace('eval:', '').split(' ')
    this.isEvalValid = this.checkEvalValidity(eval_splitted)
    if (this.isEvalValid) {
      var condition:Condition = {
        equals: true,
        value: ""
      }
      var step = 0
      this.evalStringParsed = []
      //this.filterEvalStr = ""
      eval_splitted.forEach((e:string) => {
        switch (step) {
          case 1:
            if (e == '==') condition.equals = true
            else if (e == '!=') condition.equals = false
            break
          case 2:
            condition.value = e
            break
          case 3:
            if (e == '&&') {
              condition.and = true
              this.evalStringParsed.push(condition)
              step = -1
            } else if (e == '||') {
              condition.and = false
              this.evalStringParsed.push(condition)
              step = -1
            }
            else step = 0
        }
        step += 1
      })
    } else {
      //this.filterEvalStr = evalString
      this.evalStringParsed = []
    }
  }

  compileEval() {
    var evalString = "eval:"
    this.evalStringParsed.forEach((c:any) => {
      evalString += "{0} "
      if (c.equals) evalString += "== '" + c.value + "' "
      else evalString += "!= '" + c.value + "' "
      if (c.and != undefined) {
        if (c.and) evalString += "&& "
        else evalString += "|| "
      }
    })
    console.log(evalString)
    return evalString
  }

  checkEvalValidity(evalSplitted:string[]) {    
    var i = 0
    var firstRange:number[] = []
    var secondRange:number[] = []
    var thirdRange:number[] = []
    var fourthRange:number[] = []
    for(i = 0; i < 32; i+=4) {
      firstRange.push(i)
      secondRange.push(i+1)
      thirdRange.push(i+2)
      fourthRange.push(i+3)
    }
    var isValid = true
    i = 0
    console.log(evalSplitted)
    evalSplitted.every((s:any) => {
      console.log(s)
      if ((firstRange.includes(i) && s.length != 3) ||
        (secondRange.includes(i) || fourthRange.includes(i)) && s.length != 2) {
        isValid = false
        console.log("Invalid filter eval, character [" + s + "] at position " + i + " of string '" + evalSplitted + "'")
        return 0
      } 
      i++
      return 1
    })
    return isValid
  }

  onToggleValueChange(event:any, index:number) {
    console.log(event)
    this.evalStringParsed[index].and = event.value
  }

  addCondition() {
    if (this.evalStringParsed.length == 0) {
      this.evalStringParsed.push(
        {
          equals:true,
          value:"",
        }
      )
    } else {      
      this.evalStringParsed[this.evalStringParsed.length - 1].and = true
      this.evalStringParsed.push(
        {
          equals:true,
          value:"",
        }
      )
    }
  }

  removeCondition(index:number) {
    if (this.evalStringParsed[index].and != undefined) {
      delete this.evalStringParsed[index].and
    } else {
      delete this.evalStringParsed[index - 1].and
    }
    this.evalStringParsed.splice(index, 1)
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ApiService } from '../../services/api.service';
import { DatePipe } from '@angular/common'


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};
@Component({
  selector: 'component-cp038',
  templateUrl: './cp038.component.html',
  styleUrls: ['./cp038.component.scss'],
  providers: [DatePipe, {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS 
    },
    
  ]
})
export class Cp038Component implements OnInit {

  @Input()
  context:any;  
  selectedDate:any;

  constructor(private _api: ApiService, public dialog: MatDialog, public datepipe: DatePipe) { }
  

  ngOnInit(): void {   
    this.selectedDate = new Date();
    //console.log(this.selectedDate)
    this.changeDataValue();
  }

  chosenDateHandler(ev:MatDatepickerInputEvent<Date>, picker:any){;
    //console.log(ev.value)
    this.selectedDate = ev.value;
    this.changeDataValue();
  }

  changeDataValue() {    
    this.context.DateValue = this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd');       
    //console.log(this.context.DateValue); 
  }

}

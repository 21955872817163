<mat-card class="form-card">
    <mat-card-title class="title-card">
        <mat-icon>vpn_key</mat-icon>{{labels.tokenstatusLabel}}
    </mat-card-title>
    <mat-card-content style="display: flex; flex-direction: column;gap:8px; align-items: center;">
        <div *ngIf="context == undefined" class="spinner-div">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="context != undefined">
            <table>
                <tr>
                    <th style="padding-right:8px; padding-bottom: 8px;">
                        {{labels.tokenupdatedataLabel}}
                    </th>
                    <td style="padding-bottom: 8px;">
                        <span style="padding:4px">{{context.lastUpdateDate}}</span>
                    </td>
                </tr>
                <tr>                
                    <th style="padding-right:8px; padding-bottom: 8px;">
                        {{labels.tokenexpirydataLabel}}
                    </th>
                    <td style="padding-bottom: 8px;">
                        <span style="padding:4px" [class]="nowDate >= nowMinusWeekDate && nowDate < context.expiryDate ? 'dangerDate-span' : (nowDate >= context.expiryDate ? 'alarmDate-span' : '')">{{context.expiryDate}}</span>
                    </td>
                </tr>
                <tr>                
                    <th style="padding-right:8px; padding-bottom: 8px;">
                    </th>
                    <td style="padding-bottom: 8px;">
                        <button mat-raised-button color="accent" (click)="navigate()" style="width:100%">{{labels.updatetokenLabel}}</button>
                    </td>
                </tr>
            </table>            
        </div>
    </mat-card-content>
</mat-card>

import { Component, Input, ViewChild } from '@angular/core';
import { Cp081Component } from '../cp081/cp081.component';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';

export interface Client {
  CLGUID: string;
  CLDESC: string;
  TEGUID: string;  
  CLLOGO: string | null;
}


@Component({
  selector: 'component-cp080',
  templateUrl: './cp080.component.html',
  styleUrls: ['./cp080.component.scss']
})
export class Cp080Component {

  @Input() context:any
  @Input() teguid:string = ""
  @ViewChild(Cp081Component) cp081:Cp081Component | undefined
  sortType = 0

  clientFilter = ''
  filteredClients:any[] = []

  constructor(private _api:ApiService) {}

  ngOnInit() {
    //console.log(this.context)
    if (this.context.clients != undefined && this.context.clients.length > 0 && this.context.clients[0].TEGUID == "TE0000") {
      this.context.clients.shift()
    }
    this.filteredClients = this.context.clients.slice()
    //console.log(this.context.clients)
  }

  filterClients() {
    if (!this.context.clients) {
      return;
    }
    // get the search keyword
    let search = this.clientFilter;
    //console.log(search)
    if (search == '') {
      this.filteredClients = this.context.clients.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredClients = this.context.clients.filter((client:any) => client.CLDESC.toLowerCase().indexOf(search) > -1)
    //console.log(this.filteredClients )
  }

  sortByName() {
    this.context.clients.sort(function(a:any, b:any) {
      var textA = a.CLDESC.toUpperCase();
      var textB = b.CLDESC.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    this.sortType = 0
  }

  sortByTenant() {
    this.context.clients.sort(function(a:any, b:any) {
      var textA = a.TEGUID.toUpperCase();
      var textB = b.TEGUID.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    this.sortType = 1
  }

  selectClient(teguid:string) {    
    if (!this.cp081?.loading) {
      this.teguid = teguid
    } 
  }

  switchSortType() {
    if (this.sortType == 1) {
      this.sortType = 0
      this.sortByName()
    } else {
      this.sortType = 1
      this.sortByTenant()
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service'
import { StylerService } from 'src/app/services/styler.service';


interface companyFields{
  idCompany: string;
  description: string;
}

export class MonthpickerDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }

  override parse(value: string): Date | null {
    const monthAndYearRegex = /(10|11|12|0\d|\d)\/[\d]{4}/;
    if (value?.match(monthAndYearRegex)) {
      const parts = value.split('/');
      const month = Number(parts[0]);
      const year = Number(parts[1]);
      if (month > 0 && month <= 12) {
        return new Date(year, month - 1);
      }
    }
    return null;
  }

  override format(date: Date, displayFormat: any): string {
    const month = date.getMonth() + 1;
    const monthAsString = ('0' + month).slice(-2);
    const year = date.getFullYear();
    return monthAsString + '/' + year;
  }
}


@Component({
  selector: 'component-cp007',
  templateUrl: './cp007.component.html',
  styleUrls: ['./cp007.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MonthpickerDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class Cp007Component implements OnInit {

  @Input() context: any;
  contextData: any;

  monthAndYear: Date | null = new Date();

  selectedYear: any;
  selectedMonth: any;

  displayBackBtn: boolean = false;
  selectCompany: any;

  companies: Array<companyFields> = [];
  constructor(private _api: ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService, public _styler:StylerService) { }

  ngOnInit(): void {
    this.initializeInputMyData();
    let data: Date = new Date();
    this.selectedYear = String(data.getFullYear());
    this.selectedMonth = String(data.getMonth() + 1);
    //console.log(this.context);
    this.contextData = {
      "OUCODA" : this._api.lastOUCODA,
      "Sections" : this._api.lastSections
    }
  }

  initializeInputMyData(){
    /*
     * Questa funzione prende i dati dal json per creare l'array necessario al componente
     * (prende i dati dall'array Company per metterli in un altro).
     * */
  }

  emitDateChange(event: MatDatepickerInputEvent<Date | null, unknown>): void{
    this.monthAndYear = event.value;
  }

  monthChanged(value: any, picker: any): void{
    this.monthAndYear = value;
    this.monthAndYear?.setHours(this.monthAndYear.getHours() + 24); //altrimenti prende il 31 dell'ultimo mese
    //console.log(JSON.stringify(this.monthAndYear));
    let date = JSON.stringify(this.monthAndYear).split("-");
    //console.log(date[0].replace('"', ''));
    //console.log(date[1]);
    this.selectedYear = date[0].replace('"', '');
    // Aggiungo 1 al mese visto che parte da 0.
    let numberDate = +date[1];
    this.selectedMonth = String(numberDate);

    picker.close();
  }

  onClickBack(){
    // do something
  }

  onClickRun(){
    // fare cio che fa getSessionData(...)
    // fare la richista tramite quella che e' "callApiGateway(...)"
    // Successivamente si fa comparire una notifica che dice se la richiesta
    // e' andata a buon fine o meno.

    this._api.loading = true;
    var context = "/o-action-save";
     this._api.doButtonPostRequest(this.contextData, context).subscribe((response) => {
      if(response) {
        this._api.printResponse(response)
        this._snackbarManager.open("Success!", 'ok');
        this._api.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);
        // Stringa hardcodata, non so dove prendere quella giusta, visto che nel json non viene passata.
        this._snackbarManager.open("Error while saving, please try again", 'ko');
        this._api.loading = false;
      }
    });

  }

}

<mat-card *ngIf="context.StarInfo.Commento != undefined" class="star-card">
  <mat-card-content>
    <div class="textStar">{{context.StarDisclaimer}}</div>
    <div class="rowStar">
      <div class="starimg-container">
        <div class="star-img fullstar-img"></div>
      </div>

      <div class="boxInfo">
        <div class="boxComMan">
            <div class="commentStar">{{translateButtonToggle ? commentStarOriginal : commentStar}}</div>
            <div class="manangerStar">{{context.StarInfo.Manager}}</div>
        </div>
        <mat-button-toggle *ngIf="context.TranslateAWS != '' && commentStarOriginal != commentStar && commentStarOriginal != '' && commentStar != ''" (click)="translateButtonToggle = !translateButtonToggle"
              class="translate-button">{{translateButtonToggle ? context.OriginalText : context.TranslateAWS }}</mat-button-toggle>       
      </div>
    </div>
  </mat-card-content>
</mat-card>

<p-table #dt [rowHover]="false" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" styleClass="p-datatable-sm"
                [loading]="loading" responsiveLayout="scroll" [paginator]="true" [scrollable]="true" [filterDelay]="0"
                [reorderableColumns]="false" [resizableColumns]="true" [columns]="columns" [value]="context.Row"
                sortMode="multiple" [globalFilterFields]="context.Column > 0 ? getRowProperties() : []" 
                [globalFilterFields]="['Elemento1', 'Elemento2', 'Elemento3', 'Elemento5']">

    <ng-template pTemplate="caption" id="hideIcons">
      <div class="search-div">
        <span class="p-input-icon-right" id="hideIcons">
            <i class="pi pi-search"></i>
            <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" [placeholder]="context.Search" />
        </span>     
      </div>   
    </ng-template>
  
    
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="column-header" *ngFor="let item of columns; let i = index" [pSortableColumn]="columns[i].field" pResizableColumn>
          <span class="column-title">
            {{columns[i].header}}
            <span class="sort-icons" id="hideIcons">
              <p-columnFilter type="text" [field]="columns[i].field" display="menu" class="ml-auto"></p-columnFilter>
              <p-sortIcon [field]="columns[i].field"></p-sortIcon>
            </span>
          </span>
        </th>
        <th>
            <!-- empty -->
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex>
      <tr class="p-selectable-row" [pSelectableRowDisabled]="true" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td *ngFor="let item of [].constructor(3); let i = index">
            <span class="table-data">{{ rowData['Elemento' + (i + 1)] }} </span>
        </td>
        <td>
          <span class="table-data">{{ rowData['Elemento5'] }} </span>
        </td>
        <td class="view-button-cell">
          <button class="table-button" mat-raised-button color="primary" 
                  (click)="view(context.function, rowData['Elemento4'])" (contextmenu)="onRightClick($event, context.function, rowData['Elemento4'])">
                  {{ context.ButtonView }}
          </button>
      </td> 
      </tr>            
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="columns.length" class="nodata-cell">
          {{context.NoDataFound}}
        </td>
      </tr>
    </ng-template>

</p-table>

<!--right click menu-->
<div style="visibility: hidden; position: fixed;" 
[style.left]="rightClickMenuPosition.x" 
[style.top]="rightClickMenuPosition.y" 
[matMenuTriggerFor]="rightMenu"></div> 

<mat-menu #rightMenu="matMenu"> 
  <ng-template matMenuContent let-fmguid="fmguid" let-obguid="obguid"> 
    <button mat-menu-item (click)="viewNewTab(fmguid, obguid)">View in new tab</button> 
  </ng-template> 
</mat-menu> 
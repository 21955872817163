<div *ngIf="context.Sliders.length == 0" class="nodata-div">
  No data available.
</div>
<ng-container *ngIf="context.Display != ''">
  <ng-container *ngFor="let slider of sliders; index as i; last as isLast">
    <mat-card class="p-3 matCard slider-card" [style.margin-bottom]="sliders.length > 1 && !isLast ? '16px' : ''">
        <div class="title-div" *ngIf="slider.Title != ''">{{slider.Title}}</div>
        <textarea class="subtitle" *ngIf="context.Subtitle" matInput cdkTextareaAutosize [disabled]="context.SubtitleReadOnly || translateButtonToggles[i]">{{translateButtonToggles[i] ? slider.Elemento1 : slider.Elemento4}}</textarea>
        <mat-button-toggle *ngIf="context.TranslateAWS != '' && (context.Sliders['Elemento1'] != context.Sliders['Elemento4'] && context.Sliders['Elemento1'] != '')" (click)="toggleTranslateButton(0)"
                class="translate-button">{{translateButtonToggles[0] ? context.OriginalText : context.TranslateAWS}}</mat-button-toggle>
        <mat-slider style="width:100%;" [value]="slider.Elemento2 * (100 / (context.Labels.length - 1))" (change)="updateSliderValue($event, i)" [step]="100 / (context.Labels.length - 1)" [disabled]="context.Readonly != ''"></mat-slider>
        <table class="labelsTable">
          <tr>
            <td *ngFor="let l of context.Labels; index as j; first as isFirst; last as isLast" [style.text-align]="isFirst? 'left' : (isLast ? 'right' : 'center')" 
                [width]="isFirst || isLast ?  labelWidth1 : labelWidth2" class="sliderLabelCell">
              <span [class]="j == slider.Elemento2 ? 'sliderLabelSelected' : 'sliderLabel'" [matTooltip]="enableScaleTooltip ? l : ''">{{l}}</span>
            </td>
          </tr>
        </table>
        <div class="commentarea-div" [style.gap]="commentTextareas[i] ? '16px' : '0px'">
            <div class="buttons-div">
              <button mat-raised-button class="comment-button" color="accent" *ngIf="context.ButtonAddComment" [disabled]="commentTextareas[i] == true"
                      [matTooltip]="context.ButtonAddCommentTooltip" (click)="toggleCommentArea(i, true)">
                      <mat-icon>message</mat-icon>
                      {{context.ButtonAddCommentContent}}
              </button>
              <button mat-mini-fab class="close-button" color="accent" *ngIf="commentTextareas[i]" (click)="toggleCommentArea(i, false)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div class="comment-td">
              <textarea class="comment" matInput cdkTextareaAutosize *ngIf="commentTextareas[i]" [disabled]="context.Readonly != '' || translateButtonTogglesComment[i]" [placeholder]="context.Placeholder" 
                        [(ngModel)]="translateButtonTogglesComment[i] ? slider.Elemento3 : slider.Elemento5"></textarea>
              <mat-button-toggle *ngIf="commentTextareas[i] && context.TranslateAWS != '' && (slider.Elemento3 != slider.Elemento5 && slider.Elemento3 != '')" (click)="toggleCommentTranslateButton(i)"
                                class="translate-button">{{translateButtonTogglesComment[i] ? context.OriginalText : context.TranslateAWS}}
              </mat-button-toggle>
            </div>
        </div>
    </mat-card>
  </ng-container>
</ng-container>

        <div *ngIf="loadingDetail" style="width:548px; height:500px">
            <div style="width:100%;">
                <mat-spinner style="position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 0%);"></mat-spinner>
            </div>
        </div>
        <div *ngIf="scheduleSelected && !loadingDetail" class="selectedschedule-div">
            <!--
            <div class="list-div">
                <div class="buttonslist-div">
                    <button mat-raised-button color="accent" (click)="addSchedule()" [disabled]="newSchedule || context.schedulersList == undefined">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button mat-raised-button color="accent" (click)="removeSchedule()" [disabled]="selectedScheduleIndex == undefined || context.schedulersList == undefined">
                        <mat-icon>remove</mat-icon>
                    </button>
                    <button mat-raised-button color="accent" (click)="save()" [disabled]="isSavingDisabled()" style="margin-right:4px">
                        <mat-icon style="margin-top:-4px">save</mat-icon>
                    </button>
                </div>
                <mat-list class="button-list">
                    <ng-container *ngFor="let schedule of context.schedulersList, index as i">
                            <button [@inOutAnimation] mat-button [class]="selectedScheduleIndex != undefined && selectedScheduleIndex == i ? 'selected-client-button' : 'client-button'" (click)="getScheduleDetails(i)">
                                <div class="clientbutton-innerdiv">
                                    <span class="clientname-span" [style.font-style]="schedule.isNew != undefined ? 'bold' : 'regular'"> {{schedule.isNew == undefined ? schedule.description.split("- ")[1] : "New Scheduler"}} </span>
                                </div>
                            </button>
                        <mat-divider></mat-divider>
                    </ng-container>          
                    <div *ngIf="loadingList" class="loader-innerdiv">
                        <mat-spinner style="transform:scale(.3)"></mat-spinner>
                    </div>
                    <div *ngIf="context.schedulersList != undefined && context.schedulersList.length == 0" class="empty-innerdiv">
                        <span class="empty-span"> No schedulers </span>
                    </div>
                    <div *ngIf="context.isSuccess == false && context.errMessage != undefined" class="empty-innerdiv">
                        <span class="empty-span"> {{context.errMessage}} </span>
                    </div>
                </mat-list>
            </div>
            <div *ngIf="selectedSchedule == undefined && !loadingDetail" style="width:100%;">
                <mat-icon style="position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: rgb(231, 231, 231);
                    transform: scale(8);">
                    timer
                </mat-icon>                
            </div>

            !-->
            <!--
            <div *ngIf="selectedSchedule.isNew != undefined" class="newschedule-div">
                <div class="target-div">
                    <b>Target:</b>
                    <div class="target-innerdiv">
                        <mat-form-field appearance="fill" hideRequiredMarker="true">
                            <mat-label>Client</mat-label>
                            <mat-select required (selectionChange)="getFunctionsList($event)">
                                <ng-container *ngFor="let tenant of context['clientList']; index as t;">
                                    <mat-option [value]="tenant.TEGUID">{{tenant.CLDESC}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" hideRequiredMarker="true">
                            <mat-spinner *ngIf="gettingFunctions" class="loader-mini"></mat-spinner>
                            <mat-label>Function </mat-label>
                            <mat-select [disabled]="context['integrationList'] == undefined || context['integrationList'].length == 0" (selectionChange)="setFAGUID($event)" required>
                                <ng-container *ngFor="let function of context['integrationList']; index as f;">
                                    <mat-option [value]="function.FAGUID">{{function.FADESC}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            -->
            
            <div [@inOutAnimation] class="schedule-div">
                <!--<div style="display:flex; flex-direction: column;">
                    <mat-form-field appearance="fill" hideRequiredMarker="true">
                        <mat-label>Client</mat-label>
                        <input matInput [disabled]="true" [value]="selectedSchedule.name.split('-')[0]">
                    </mat-form-field>
                    <mat-form-field appearance="fill" hideRequiredMarker="true">
                        <mat-label>Function</mat-label>
                        <input matInput [disabled]="true" [value]="selectedSchedule.name.split('-')[1]">
                    </mat-form-field>
                </div>!-->
                
                <div class="statustime-div">
                    <div class="status-div">
                        <b style="margin-bottom:16px">{{labels.statusLabel}}:</b>
                        <mat-checkbox [(ngModel)]="this.selectedSchedule.enabled">{{labels.enabledLabel}}</mat-checkbox>
                    </div>
                    <div class="time-div">
                        <div style="margin-top:20px">
                        <mat-form-field appearance="fill" style="width:130px;">
                            <mat-label>{{labels.timeLabel}} (UTC+0)</mat-label>
                            <input matInput [ngxMatTimepicker]="picker" [format]="24" [value]="this.convertedHour" readonly>
                            <ngx-mat-timepicker #picker color="accent" [hoursOnly]="true" (timeChanged)="updateHour($event)"></ngx-mat-timepicker>
                            <mat-icon matSuffix (click)="picker.open()">watch_later</mat-icon>                        
                        </mat-form-field> 
                    </div>
                </div>
            </div>
        </div>
        <div class="daysselection-div">
            <b>{{labels.daysselectionLabel}}:</b>
            <mat-radio-group [(ngModel)]="daysMode" (change)="changeDays($event)">
                <mat-radio-button value="week" style="margin-right:8px">{{labels.weekLabel}}<br></mat-radio-button>
                <div class="weekdays-div">
                    <mat-checkbox *ngIf="labels.sunLabel != 'DOM'" [checked]="daysWeekArray[0].checked" (change)="daysWeekArray[0].checked = !daysWeekArray[0].checked; updateDaysWeek()" style="margin-right:16px" [disabled]="daysMode == 'month'">{{daysWeekArray[0].name}}</mat-checkbox>
                    <mat-checkbox [checked]="daysWeekArray[1].checked" (change)="daysWeekArray[1].checked = !daysWeekArray[1].checked; updateDaysWeek()" style="margin-right:16px" [disabled]="daysMode == 'month'">{{daysWeekArray[1].name}}</mat-checkbox>
                    <mat-checkbox [checked]="daysWeekArray[2].checked" (change)="daysWeekArray[2].checked = !daysWeekArray[2].checked; updateDaysWeek()" style="margin-right:16px" [disabled]="daysMode == 'month'">{{daysWeekArray[2].name}}</mat-checkbox>
                    <mat-checkbox [checked]="daysWeekArray[3].checked" (change)="daysWeekArray[3].checked = !daysWeekArray[3].checked; updateDaysWeek()" style="margin-right:16px" [disabled]="daysMode == 'month'">{{daysWeekArray[3].name}}</mat-checkbox>
                    <mat-checkbox [checked]="daysWeekArray[4].checked" (change)="daysWeekArray[4].checked = !daysWeekArray[4].checked; updateDaysWeek()" style="margin-right:16px" [disabled]="daysMode == 'month'">{{daysWeekArray[4].name}}</mat-checkbox>
                    <mat-checkbox [checked]="daysWeekArray[5].checked" (change)="daysWeekArray[5].checked = !daysWeekArray[5].checked; updateDaysWeek()" style="margin-right:16px" [disabled]="daysMode == 'month'">{{daysWeekArray[5].name}}</mat-checkbox>
                    <mat-checkbox [checked]="daysWeekArray[6].checked" (change)="daysWeekArray[6].checked = !daysWeekArray[6].checked; updateDaysWeek()" style="margin-right:16px" [disabled]="daysMode == 'month'">{{daysWeekArray[6].name}}</mat-checkbox>
                    <mat-checkbox *ngIf="labels.sunLabel == 'DOM'" [checked]="daysWeekArray[0].checked" (change)="daysWeekArray[0].checked = !daysWeekArray[0].checked; updateDaysWeek()" style="margin-right:16px" [disabled]="daysMode == 'month'">{{daysWeekArray[0].name}}</mat-checkbox>
                </div>
                <mat-radio-button value="month">{{labels.monthLabel}}</mat-radio-button>
                <div class="monthdays-div">
                    <button mat-mini-fab *ngFor="let d of [].constructor(31); index as i" style="margin-right:4px; margin-bottom:4px" [class]="daysMonthArray[i] || daysMode == 'week' ? 'mat-elevation-z0': 'mat-elevation-z1'" [color]="daysMonthArray[i] ? 'accent' : 'basic'" [value]="daysMonthArray[i]" [disabled]="lastMonthDayToggle || daysMode == 'week'" (click)="daysMonthArray[i] = !daysMonthArray[i]; selectDaysMonth()">{{i < 9 ? '0' + (i+1) : i+1}}</button>
                    <button mat-raised-button [class]="lastMonthDayToggle ? 'mat-flat-button' : 'mat-raised-button'" [color]="lastMonthDayToggle ? 'accent' : 'basic'" style="margin-top:8px" (click)="selectLastMonthDay()" [disabled]="daysMode == 'week'">{{labels.lastdayLabel}}</button>
                    <br>
                    <button mat-raised-button color="accent" style="margin-top:8px; margin-right:8px" (click)="selectAllDaysMonth()" [disabled]="daysMode == 'week'">{{labels.selectallLabel}}</button>
                    <button mat-raised-button color="accent" style="margin-top:8px; margin-right:8px" (click)="selectNoneDaysMonth()" [disabled]="daysMode == 'week'">{{labels.deselectallLabel}}</button>

                    <!--
                    <mat-form-field appearance="fill" style="width:220px">
                        <mat-label>Insert days numbers</mat-label>
                        <input matInput type="text" placeholder="Es. 1,3,7,24" [formControl]="daysMonthForm" (change)="updatedaysMonth($event)" [disabled]="daysMode == 'week'">
                        <mat-error *ngIf="baddaysMonth">Name already used</mat-error>
                    </mat-form-field> 
                    -->

                </div>
            </mat-radio-group>
        </div>  
        <div style="display: flex; flex-direction: row; justify-content: flex-end;">
            <button mat-raised-button color="basic" (click)="close()" style="margin-right:8px">
                <mat-icon style="margin-top:-4px">clear</mat-icon> {{labels.closeLabel}}
            </button>    
            <button mat-raised-button color="accent" (click)="save()" [disabled]="isSavingDisabled()">
                <mat-icon style="margin-top:-4px">save</mat-icon> {{labels.saveLabel}}
            </button>    
        </div>
    </div>
<div *ngIf="!formVisible" style="display: flex; justify-content: center;">
    <mat-spinner class="loader loader-in"></mat-spinner>
</div>

<div *ngIf="formVisible">
    <table>
        <tr *ngIf="context.isFormChartable && this.ordersIndex != -1 && Orders['Elemento4'] != ''"> <!--ordersIndex != -1-->
            <td>
                <div class="card-graph-alt">
                    <span class="graph-title">{{ context.charts[Orders['Elemento4'].toLowerCase().replace(' ','')].title }}</span>
                    <p-chart type="line" [data]="basicData" [options]="basicOptions"></p-chart>
                </div>
            </td>
        </tr>
        <tr>
            <td [style.width]="!context.isFormChartable ? '1%' : ''">              
                <ng-container *ngFor="let col of context.columns" [formGroup]="profileForm">
                    <ng-container *ngIf="col.isFormVisible">

                        <mat-form-field *ngIf="col.formComponent == 'TextField'" appearance="fill" color="accent">
                            <mat-label>{{col.title}}</mat-label>
                            <input [ngStyle]="parseStyle(col.formStyle)" [id]="col.elemento" [formControlName]="col.elemento"
                                    matInput type="text" [placeholder]="col.placeholder" [style.width]="col.width" 
                                    (change)="updateTextfield($event, col)">
                            <mat-error>Error!</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="col.formComponent == 'TextArea'" appearance="fill" color="accent">
                            <mat-label>{{col.title}}</mat-label>
                            <textarea [ngStyle]="parseStyle(col.formStyle)" [id]="col.elemento" [formControlName]="col.elemento" [style.width]="col.width"
                                        matInput cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6" [placeholder]="col.placeholder" 
                                        (change)="updateTextfield($event, col)"></textarea> 
                            <mat-error>Error!</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="col.formComponent == 'NumberField'" appearance="fill" color="accent">
                            <mat-label>{{col.title}}</mat-label>
                            <input [ngStyle]="parseStyle(col.formStyle)" [id]="col.elemento" matInput type="number" 
                                    [placeholder]="col.placeholder" [style.width]="col.width" 
                                    (change)="updateNumberfield($event, col)" [formControlName]="col.elemento">
                            <span *ngIf="col.format.includes('p')" matSuffix>%</span>
                            <span *ngIf="col.format.includes('##,#')" matSuffix>{{col.format.replaceAll("##,#", "")}}</span>
                            <mat-error>Error!</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="col.formComponent == 'ComboBox'" appearance="fill" color="accent">
                            <mat-label>{{col.title}}</mat-label>
                            <mat-select [ngStyle]="parseStyle(col.formStyle)" type="text" (selectionChange)="updateCombobox($event, col)" 
                                        [placeholder]="col.placeholder" [style.width]="col.width" [formControlName]="col.elemento" 
                                        [value]="getComboboxKey(col)">
                                <mat-option *ngFor="let e of col.ComboBoxList" [ngStyle]="parseStyle(col.formStyle)" [value]="e.Kkey">{{e.Vvalue}}</mat-option>
                            </mat-select>
                            <mat-error>Error!</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="col.formComponent == 'DateField'" appDefaultformat appearance="fill" color="accent">
                            <mat-label>{{col.title}}</mat-label>
                            <input matInput [matDatepicker]="picker" [min]="col.minValue" [max]="col.maxValue"
                                    [id]="col.elemento" [placeholder]="col.placeholder" [style.width]="col.width" 
                                    [formControlName]="col.elemento" (dateChange)="updateDatefield($event, col)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker>
                            </mat-datepicker>
                            <mat-error>Error!</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="col.formComponent == 'DateMonthField'" appYearmonthformat appearance="fill" color="accent">
                            <mat-label>{{col.title}}</mat-label>
                            <input matInput [matDatepicker]="picker" [min]="col.minValue" [max]="col.maxValue"
                                    [id]="col.elemento" [placeholder]="col.placeholder" [style.width]="col.width" 
                                    [formControlName]="col.elemento" (change)="updateDatemonthfield($event, col)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker [startView]="'year'" (monthSelected)="updateDatemonthfield($event, col, picker)">
                            </mat-datepicker>
                            <mat-error>Error!</mat-error>
                        </mat-form-field>    
                        
                        <mat-form-field *ngIf="col.formComponent == 'Void'" style="visibility: hidden;">
                            <input matInput>
                        </mat-form-field>

                        <button *ngIf="col.formComponent == 'ShortcutButton'" mat-raised-button color="accent" (click)="openShortcut(col.defaultValue)">
                            {{ col.title }}
                        </button>

                        <mat-button-toggle *ngIf="col.formComponent == 'GridButton' && context.variants != undefined && context.variants.length > 0 && context.variantsHeader != undefined && context.variantsHeader.length > 0" 
                            color="accent" (click)="showVariantsTable = !this.showVariantsTable" style="margin:6px">
                            {{ col.title == '' ? col.placeholder : col.title }}
                        </mat-button-toggle>

                    </ng-container>
                    
                </ng-container>
            </td>
            <td *ngIf="context.isFormChartable && this.ordersIndex != -1 && Orders['Elemento4'] != ''" class="graph-lat"> <!--due grafici uguali per essere reactive-->
                <div class="card-graph">
                    <span class="graph-title">{{ context.charts[Orders['Elemento4'].toLowerCase().replace(' ','')].title }}</span>
                    <p-chart type="line" [data]="basicData" [options]="basicOptions"></p-chart>
                </div>
            </td>
        </tr>
    </table>

    <mat-card *ngIf="showVariantsTable" style="margin:8px; margin-bottom:16px">
        <mat-card-content>
            <p-table #littleDt [rowHover]="context.EnableHover" [rows]="10" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10]" responsiveLayout="scroll" [paginator]="true" [scrollable]="true"
            [filterDelay]="0" [reorderableColumns]="context.AllowReordering" [resizableColumns]="context.AllowResizing"
            [columns]="context.variantsHeader" [value]="context.variants" (onRowSelect)="selectVariant($event)"
            selectionMode="single" styleClass="p-datatable-sm">

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let element of context.variantsHeader" [pSelectableRow]="element" [pSortableColumn]="element" 
                            pResizableColumn pReorderableColumn class="column-header">
                            <div class="text-align:left">
                                {{element}}
                            </div>
                            <!--
                            <span class="sort-icons" style="display: flex; justify-content: center;">
                                <td><p-columnFilter type="text" [field]="element" display="menu" class="ml-auto"></p-columnFilter></td>
                                <td><p-sortIcon [field]="element"></p-sortIcon></td>
                            </span>
                            -->
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr class="p-selectable-row" [pSelectableRow]="rowData"  [pSelectableRowIndex]="rowIndex">
                        <td style="white-space: break-spaces;" *ngFor="let col of columns; let i = index">          
                            <span *ngIf="getColumnFormat(col) == 'p0'">{{ (rowData[i] * 100).toFixed(0) }}%</span>
                            <span *ngIf="getColumnFormat(col) == 'p1'">{{ (rowData[i] * 100).toFixed(1) }}%</span>
                            <span *ngIf="getColumnFormat(col) == 'n0'">{{ (rowData[i]).toFixed(0) }}</span>
                            <span *ngIf="getColumnFormat(col) == 'n1'">{{ (rowData[i]).toFixed(1) }}</span>
                            <span *ngIf="getColumnFormat(col).includes('##,#')">{{getColumnFormat(col).replace("##,#", "")}} {{ rowData[i] }}</span>
                            <span *ngIf="getColumnFormat(col) == ''">{{rowData[i]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </mat-card-content>
    </mat-card>
    

    <div *ngIf="context.buttonSaveTitle != undefined && context.buttonSaveTitle != ''" class="form-buttons-div">
        <button mat-raised-button color="basic" (click)="closeForm()">{{context.buttonCancelTitle}}</button>
        <button mat-raised-button color="accent" [disabled]="!profileForm.valid" 
            (click)="saveRow()">{{context.buttonSaveTitle}}</button>
    </div>
</div>
import { Component, OnInit, Input } from '@angular/core';
 

@Component({
  selector: 'component-cp002',
  templateUrl: './cp002.component.html',
  styleUrls: ['./cp002.component.scss']
})
export class CP002Component implements OnInit {

  @Input() context: any;
  @Input() position: any;  

  translateButtonToggles:boolean[] = [];

  constructor() { }

  ngOnInit(): void {
    this.context.forEach((e:any) => {          
      if (e.Original == e.Value) e.Value = "";
      this.translateButtonToggles.push(false);  
    })
  }

  update(i:number, event:any) {
    //console.log(event.target.value);
    this.context[i].Original = event.target.value;
  }

}

<component-starteammemberbuttons [context]="context" [selectYear]="selectYear" [selectRegion]="selectRegion" 
                        [selectJob]="selectJob" [selectManager]="selectManager" [selectArea]="selectArea" 
                        [selectLevel]="selectLevel" componentType="CP031">
</component-starteammemberbuttons>

<mat-tab-group class="tab-group" color="accent" (selectedTabChange)="$event.tab.origin == -1 ? createPolarChart() : 'radarChart.destroy()'">
    <mat-tab *ngIf="context.Dashboard1Active != ''" [label]="context.Dashboard1Title" class="tab">

      <mat-card>
        <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
            {{context.Title}}
        </mat-card-title>
        <mat-card-content class="mat-card-content">

            <div class="forms-table">
              <!-- Scelta anno -->
                <mat-form-field appearance="fill" color="accent">
                  <mat-label>{{context.LabelAnno}}</mat-label>
                  <input [(ngModel)]="selectYear" matInput [matDatepicker]="picker" (focusout)="updateYearContext()"/>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker [startAt]="selectYear" startView="multi-year"
                  (yearSelected)="chosenYearHandler($event, picker)"></mat-datepicker>
                </mat-form-field>

              <!-- Scelta Business Unit / Regione -->

                <mat-form-field appearance="fill" color="accent">
                    <mat-label>{{context.LabelRegion}}</mat-label>
                    <mat-select [(ngModel)]="selectRegion" [(value)]="context.Region">
                        <mat-option *ngFor="let e of context.RegionList" [value]="e.Elemento1">
                            {{e.Elemento2}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Scelta Job / Ruolo -->

                  <mat-form-field appearance="fill" color="accent">
                      <mat-label>{{context.LabelJob}}</mat-label>
                      <mat-select [(ngModel)]="selectJob" [(value)]="context.Job">
                          <mat-option *ngFor="let e of context.JobList" [value]="e.Elemento1">
                              {{e.Elemento2}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>

                <!-- Scelta Area -->

                  <mat-form-field appearance="fill" color="accent">
                      <mat-label>{{context.LabelArea}}</mat-label>
                      <mat-select [(ngModel)]="selectArea" [(value)]="context.Area">
                          <mat-option *ngFor="let e of context.AreaList" [value]="e.Elemento1">
                              {{e.Elemento2}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>

            </div>

            <!-- Bottone di ricerca -->
            <div class="searchbutton-div">
                <button mat-raised-button class="generic-button" color="accent" [matTooltip]="context.ButtonTooltip"
                    (click)="search()">
                    <mat-icon>search</mat-icon> {{context.ButtonLabel}}
                </button>
            </div>

            <div *ngIf="noDataFound" class="tableprocess-div" style="text-align:center">
                {{ context.NoDataFound }}
            </div>

            <div *ngIf="!noDataFound" class="tableprocess-div">
              <table class="tableProcessGeneralRoadMap">
                <tr>
                  <th rowspan="2" class="firstRowHeaderTableRoadMap">{{context.TableColumns1[0]}}</th>
                  <th rowspan="2" class="firstRowHeaderTableRoadMap">{{context.TableColumns1[1]}}</th>
                  <th rowspan="2" class="firstRowHeaderTableRoadMap">{{context.TableColumns1[2]}}</th>
                  <th colspan="4" class="firstRowHeaderTableRoadMap">{{context.TableColumns1[3]}}</th>
                </tr>
                <tr>
                  <ng-container *ngFor="let val of [].constructor(context.TableColumns1.length - 4); let i = index">
                    <td class="secondRowHeaderTableRoadMap">{{context.TableColumns1[i + 4]}}</td>
                  </ng-container>
                </tr>
                <tr class="rowsTableRoadMap" *ngFor="let value of rowsArray; let i = index">
                  <ng-container *ngFor="let val of value">                    
                    <td *ngIf="i % 2 == 0" class="light-row rowTableRoadMap">{{val}}</td>
                    <td *ngIf="i % 2 != 0" class="dark-row rowTableRoadMap">{{val}}</td>
                  </ng-container>
                </tr>
              </table>
            </div>
          </mat-card-content>
        </mat-card>


        <mat-card *ngIf="(context.NoAverageRows1 == undefined || !context.NoAverageRows1) && context.AverageTitle1 != ''">
          <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
            {{context.AverageTitle1}}
          </mat-card-title>
          <mat-card-content>
            
            <div class="row" [style.display]="!noChartDataFound ? 'flex' : 'none'">
              <!-- Colonna tabella con i valori di Familiality, Positivity, Respect, Quality e Success-->
              <div class="column">
                <table *ngIf="!noChartDataFound" class="chart-table">
                  <tr *ngFor="let item of context.AverageRows1">
                    <td class="parameter-td">
                      <b>{{item.Elemento1}}</b>
                    </td>
                    <td>                  
                      {{item.Elemento2 | number:'1.2-2'}}
                    </td>
                  </tr>
                </table>
              </div>
              <!-- Colonna grafico a pentagono -->
              <div class="column">
                  <canvas id="RadarChart" class="chart-div"></canvas>
              </div>
            </div>
            
            <div *ngIf="context.NoDataFound != '' && noChartDataFound">
              <p style="text-align:center;padding-bottom:0">{{context.NoDataFound}}</p>
            </div>
          </mat-card-content>
        </mat-card>


      <mat-card *ngIf="context.AverageTitle2 != ''">
        <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
          {{context.AverageTitle2}}
        </mat-card-title>
        <mat-card-content>
            <p *ngIf="context.AverageRows2 != null && context.AverageRows2[0] != ''" style="text-align: center;">{{context.AverageRows2[0] | number:'1.2-2'}} {{context.AverageRows2[1]}} {{context.AverageRows2[2] | number:'1.2-2'}}</p>
            <p *ngIf="context.AverageRows2 == null || context.AverageRows2[0] == ''" style="text-align: center;">{{context.NoDataFound}}</p>
        </mat-card-content>
      </mat-card>

    </mat-tab>


////////////////////////////////////////


    <mat-tab *ngIf="context.Dashboard2Active != ''" [label]="context.Dashboard2Title">

      <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
        {{context.Title}}
      </mat-card-title>
      <mat-card-content class="mat-card-content">

          <div class="forms-table">
            <!-- Scelta anno -->
              <mat-form-field appearance="fill" color="accent">
                <mat-label>{{context.LabelAnno}}</mat-label>
                <input [(ngModel)]="selectYear" matInput [matDatepicker]="picker2" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 [startAt]="selectYear" startView="multi-year"
                (yearSelected)="chosenYearHandler($event, picker2)"></mat-datepicker>
              </mat-form-field>

            <!-- Scelta Business Unit / Regione -->

              <mat-form-field appearance="fill" color="accent">
                  <mat-label>{{context.LabelRegion}}</mat-label>
                  <mat-select [(ngModel)]="selectRegion" [(value)]="context.Region">
                      <mat-option *ngFor="let e of context.RegionList" [value]="e.Elemento1">
                          {{e.Elemento2}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <!-- Scelta Job / Ruolo -->

                <mat-form-field appearance="fill" color="accent">
                    <mat-label>{{context.LabelJob}}</mat-label>
                    <mat-select [(ngModel)]="selectJob" [(value)]="context.Job">
                        <mat-option *ngFor="let e of context.JobList" [value]="e.Elemento1">
                            {{e.Elemento2}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

              <!-- Scelta Area -->

                <mat-form-field appearance="fill" color="accent">
                    <mat-label>{{context.LabelArea}}</mat-label>
                    <mat-select [(ngModel)]="selectArea" [(value)]="context.Area">
                        <mat-option *ngFor="let e of context.AreaList" [value]="e.Elemento1">
                            {{e.Elemento2}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

          </div>

          <!-- Bottone di ricerca -->
          <div class="searchbutton-div">
              <button mat-raised-button class="genericbutton" color="accent" [matTooltip]="context.ButtonTooltip"
                  (click)="search()">
                  <mat-icon>search</mat-icon> {{context.ButtonLabel}}
              </button>
          </div>

          <div *ngIf="noDataFound" class="tableprocess-div" style="text-align:center">
              {{ context.NoDataFound }}
          </div>

          <div *ngIf="!noDataFound" class="tableprocess-div">
            <table class="tableProcessGeneral">
              <tr>
                <th rowspan="2" class="firstRowHeaderTableRoadMap">{{context.TableColumns2[0]}}</th>
                <th rowspan="2" class="firstRowHeaderTableRoadMap">{{context.TableColumns2[1]}}</th>
                <th rowspan="2" class="firstRowHeaderTableRoadMap">{{context.TableColumns2[2]}}</th>
                <th colspan="4" class="firstRowHeaderTableRoadMap">{{context.TableColumns2[3]}}</th>
              </tr>
              <tr>
                <ng-container *ngFor="let val of [].constructor(context.TableColumns2.length - 4); let i = index">
                  <td class="secondRowHeaderTableRoadMap">{{context.TableColumns2[i + 4]}}</td>
                </ng-container>
              </tr>
              <tr class="rowsTableRoadMap" *ngFor="let value of rowsArray2; let i = index">
                <ng-container *ngFor="let val of value">                    
                  <td *ngIf="i % 2 == 0" class="light-row rowTableRoadMap">{{val}}</td>
                  <td *ngIf="i % 2 != 0" class="dark-row rowTableRoadMap">{{val}}</td>
                </ng-container>
              </tr>
            </table>
          </div>
      </mat-card-content>

      <mat-card *ngIf="context.AverageTitle3 != ''">
        <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center title-card">
          {{context.AverageTitle3}}
        </mat-card-title>
        <mat-card-content style="display:flex; flex-direction: row; gap:32px;">

          <mat-card style="width:50%">
            <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center title-card">
              {{context.AverageTitle4}}
            </mat-card-title>
            <mat-card-content style="text-align: center; background-color: white;">              
              {{context.AverageTitle5}}
              <div *ngIf="context.AverageRows3[0] != '' && context.AverageRows3[2] != ''">
                {{context.AverageRows3[0] | number:'1.2-2'}} {{context.AverageRows3[1]}} {{context.AverageRows3[2] | number:'1.2-2'}}
              </div>
              <div *ngIf="context.AverageRows3[0] == '' || context.AverageRows3[2] == ''">
                <p>{{context.NoDataFound}}</p>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card style="width:50%">
            <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center title-card">
              {{context.AverageTitle6}}
            </mat-card-title>
            <mat-card-content style="text-align: center; background-color: white;">              
              {{context.AverageTitle7}}
              <div *ngIf="context.AverageRows4[0] != '' && context.AverageRows4[2] != ''">
                {{context.AverageRows4[0] | number:'1.2-2'}} {{context.AverageRows4[1]}} {{context.AverageRows4[2] | number:'1.2-2'}}
              </div>
              <div *ngIf="context.AverageRows4[0] == '' || context.AverageRows4[2] == ''">
                <p>{{context.NoDataFound}}</p>
              </div>
            </mat-card-content>
          </mat-card>

          <div></div><!--just for looks-->
        </mat-card-content>
      </mat-card>

    </mat-tab>
</mat-tab-group>
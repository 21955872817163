import { Component, OnInit, Input, ViewChild } from '@angular/core';
import FileSaver from 'file-saver';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { trigger, style, animate, transition } from '@angular/animations';
import { splitNsName } from '@angular/compiler';
import { Title } from '@angular/platform-browser';

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

@Component({
  selector: 'component-cp100',
  templateUrl: './cp100.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp100.component.scss']
})
export class Cp100Component implements OnInit {
  
  @Input()
  context:any;

  constructor(private _snackbarManager:SnackbarmanagerService, private title:Title, private _api:ApiService) { }

  ngOnInit(): void {
    if (this.context.link != undefined) {
      if (this.context.newPage != undefined && this.context.newPage) {
        window.open(this.context.link, "_blank", "location=yes,width=" + ((screen.height/2)-(screen.height/2))/2 + ",height=" + ((screen.width/2)-(screen.width/2)) + ",scrollbars=yes,status=yes")
      } else window.open(this.context.link, "_blank")
    }
    if (this.context.followup != undefined) {
      var urlParams = this.context.followup.split('/')
      try {
        this._api.navigate(urlParams[1], urlParams[2], urlParams[3], urlParams[4])
      } catch (e) {
        history.back()
      }
    } else history.back()
  }
}
import { Component, Input, OnInit, ViewEncapsulation, HostListener} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
 

@Component({
  selector: 'component-cp001',
  templateUrl: './cp001.component.html',
  styleUrls: ['./cp001.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Cp001Component implements OnInit {

  @Input() context: any;
  @Input() position: any;
  sliders:any[] = [];

  multipleSliders:boolean = false;
  commentTextareas:boolean[] = [];
  translateButtonToggles:boolean[] = [];
  translateButtonTogglesComment:boolean[] = [];
  enableScaleTooltip:boolean = false;

  goldenImg: string ="url(../../../../../assets/icon/dot-slider.svg)";
  labelWidth1 = '17%'
  labelWidth2 = '33%'

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth < 700) this.enableScaleTooltip = true;
    else this.enableScaleTooltip = false;
  }

  constructor(private _api:ApiService, private _errorthrower:ErrorthrowerService) {}

  ngOnInit(): void {
    if (this.context.Display == '') {
      this._errorthrower.throw("Unauthorized.", 400, false, false);
    }
    let url = window.location.pathname;
    let path = url.split("/");
    if(path.find((e)=>e == "AP002")){
      this.goldenImg = "url(../../../../../assets/icon/iconGoldenGoose.png)"
    }
    document.documentElement.style.setProperty('--golden-img', this.goldenImg);

    this.labelWidth2 =  (100 / (this.context.Labels.length - 1)) + '%'
    this.labelWidth1 = (100 - ((100 / (this.context.Labels.length - 1)) * (this.context.Labels.length - 2))) / 2 + '%'

    this.multipleSliders = (this.context.Sliders instanceof Array);
    //console.log(this.context)
    //console.log(this.multipleSliders);
    if (this.multipleSliders) {
      this.context.Sliders.forEach((e:any) => {
        if (e.Elemento3 == e.Elemento5) e.Elemento3 = "";
        this.sliders.push(e);
        this.commentTextareas.push(e.Elemento5 != '' && e.Elemento5 != null ? true : false);
        this.translateButtonToggles.push(false);
        this.translateButtonTogglesComment.push(false);
      })
    } else {
      this.sliders.push(this.context.Sliders);
      this.commentTextareas.push(this.context.Sliders.Elemento5 != '' && this.context.Sliders.Elemento5 != null ? true : false);
      this.translateButtonToggles.push(false);
      this.translateButtonTogglesComment.push(false);
    }

    this.onWindowResize()
  }

  toggleCommentArea(i:number, status:boolean) {
    this.commentTextareas[i] = status;
    this.sliders[i].Elemento3 = "";
    this.sliders[i].Elemento5 = "";
  }

  updateSliderValue(event:any, i:number) {
    this.sliders[i].Elemento2 = event.value / (100 / (this.context.Labels.length - 1));
  }

  toggleTranslateButton(i:number) {
   this.translateButtonToggles[i] = !this.translateButtonToggles[i];
  }

  toggleCommentTranslateButton(i:number) {
    this.translateButtonTogglesComment[i] = !this.translateButtonTogglesComment[i];
  }
}

<div *ngIf="context.Sliders.length == 0" class="nodata-div">
  No data available.
</div>
<ng-container *ngIf="context.Display != ''">
  <ng-container *ngFor="let slider of context.Sliders; index as i; last as isLast">
    <mat-card class="p-3 matCard slider-card" [style.margin-bottom]="sliders.length > 1 && !isLast ? '16px' : ''">
        <div class="title-div" *ngIf="slider.Title != ''">{{slider.Title}}</div>
        <div class="labelsTable-div">
          <div class="labelandbutton-div">
            <textarea class="comment" matInput cdkTextareaAutosize [disabled]="context.ReadOnly || translateButtonToggles[i]" [placeholder]="context.PlaceholderActivities"
                      [(ngModel)]="translateButtonToggles[i] ? slider.Elemento1 : slider.Elemento4" ></textarea>          
            <mat-button-toggle *ngIf="context.TranslateAWS != '' && (slider['Elemento1'] != slider['Elemento4'] && slider['Elemento1'] != '')"
                  (click)="toggleTranslateButton(i)" class="translate-button" >
                  {{translateButtonToggles[i] ? context.OriginalText : context.TranslateAWS}}
            </mat-button-toggle>
          </div>
          <div class="clear-button-td">
              <button mat-mini-fab class="delete-button" color="accent" (click)="clearSlider(i)" [disabled]="slider.Elemento4 == null || slider.Elemento4 == ''">
                      <mat-icon>delete</mat-icon>
              </button>
            </div>
        </div>
        <mat-slider style="width:100%;" [value]="slider.Elemento2 * (100 / (context.Labels.length - 1))" (change)="updateSliderValue($event, i)" [step]="100 / (context.Labels.length - 1)"  [disabled]="context.Readonly != ''"></mat-slider>
        <table class="labelsTable">
          <tr>
            <td *ngFor="let l of context.Labels; index as j; first as isFirst; last as isLast" [style.text-align]="isFirst? 'left' : (isLast ? 'right' : 'center')"
              [width]="isFirst || isLast ?  labelWidth1 : labelWidth2">
              <span [class]="j == slider.Elemento2 ? 'sliderLabelSelected' : 'sliderLabel'">{{l}}</span>
            </td>
          </tr>
        </table>
    </mat-card>
  </ng-container>
</ng-container>
<ng-container *ngIf="context.Display != ''">
    <ng-container *ngFor="let slider of sliders; index as i; last as isLast">
        <mat-card class="p-3 matCard slider-card" [style.margin-bottom]="sliders.length > 1 && !isLast ? '16px' : ''">
            <div class="starandcomment-div">
                <div class="starimg-container">
                    <div [class]="slider.Status == 1 ? 'star-img fullstar-img' : 'star-img emptystar-img'" (click)="toggleStar(i)"></div>
                    <div class="title-div" *ngIf="slider.Title != ''">{{slider.Title}}</div>
                </div>
                <div class="textandbutton-div">
                    <textarea class="txtarea" matInput [disabled]="translateButtonToggles[i] || context.Readonly != ''" cdkTextareaAutosize 
                            [(ngModel)]="translateButtonToggles[i] ? slider.Value : slider.Original" #autosize="cdkTextareaAutosize" [placeholder]="context.Placeholder"></textarea>
                    <mat-button-toggle *ngIf="context.TranslateAWS != '' && (slider.Value != slider.Original && slider.Value != '')" (click)="toggleTranslateButton(i)"
                                        class="translate-button">{{translateButtonToggles[i] ? context.OriginalText : context.TranslateAWS}}</mat-button-toggle>
                </div>
            </div>        
        </mat-card>
    </ng-container>
</ng-container>
import { Component, Input, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StylerService } from '../services/styler.service';
import { ErrorthrowerService } from '../services/errorthrower.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {

  @Input()
  maxiLogo: any;
  @Input()
  username: any;
  @Output("showPopupMessagePage") showPopupMessagePage: EventEmitter<any> = new EventEmitter();

  response:any = {};
  sections:any[] = []; 
  sectionTemplate:any = {
    Section: {
      ID: "SZ015",
      Context: {
        Title: "",
        CssStyle: "",
        Position: "v"
      }
    },
    Components: [
      {
        Component: {
          Type: "ERROR",
          Context: {}
        }
      }        
    ]
  };

  refreshSubscription:Subscription;
  innerHeight:string = "0px";
  innerHeightDiv:string = "0px";
  debugEnabled:boolean = false;


  constructor(private _errorthrower:ErrorthrowerService, public _api:ApiService, private router: Router, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService, private _styler:StylerService) {
    _errorthrower.sectionComponent = this;
    router.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        if (this._api.getUrlParameters().apguid != '') {  
          this.getSections();
        }
        /* go to error page when obguid==errorpage, unused
        if ((this._api.getUrlParameters().obguid == 'ErrorPage')) {
          this.setErrorSection(this._api.lastError);
        }*/
      }
    })
    this.refreshSubscription = this._api.refreshSectionsSignal().subscribe((response:any) => {
      if (response) {
        this.getSections();
      }
    });  
  }

  ngOnInit() { 
    if (this._api.getUrlParameters().apguid != '') this.getSections();
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
}

  ngAfterViewInit() {
    this.onWindowResize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.innerHeight = (window.innerHeight/2) - 100 - 56 + "px";
    this.innerHeightDiv = (window.innerHeight/2) + 100 + "px";
  }

  getSections() {
    this._api.loading = true;
    this._api.gettingSections = true;
    this._api.gettingSectionTime = Date.now();

    if (this.debugEnabled) {//only when debug is enabled

      var urlParams = this._api.getUrlParameters();
      this._api.getCustomResponse("cp040").subscribe((response) => {
        if(response) {
          this.response = JSON.parse(JSON.stringify(response));
          this._api.printResponse(response);
          if (urlParams.obguid.includes("wf-") || urlParams.obguidParam.includes("wf-")) { //for wf-route navigation only, uses a different endpoint
            var params = this.getWfUrlParameters(urlParams)
            this.parseWFSections(params[0], params[1]);
          } else {                
            this.parseSections(response);   
          } 
          this._api.loading = false;
        }          
      }, (error) => {
        if(error) {
          console.log(error);
          this._api.loading = false;
        }
      });

    } else { //default path

      var urlParams = this._api.getUrlParameters();
      if (urlParams.obguid.includes("wf-") || urlParams.obguidParam.includes("wf-")) { //for wf-route navigation only, uses a different endpoint
        
        var params = this.getWfUrlParameters(urlParams)
        this.parseWFSections(params[0], params[1]);

      } else { 

        const gettingSectionTimeout = setTimeout(() => {
          if (this._api.gettingSectionTime > 0 && this._api.gettingSections) this._api.stillGettingSections = true
        }, 5000)

        this._api.getPageSections().subscribe((response) => {
          if(response) {

            clearTimeout(gettingSectionTimeout)
            this.response = JSON.parse(JSON.stringify(response));
            this._api.printResponse(response);

            var timeDiff = Date.now() - this._api.gettingSectionTime;
            //console.log(timeDiff < 500 && timeDiff > 0 ? 500 - timeDiff : 0)
            setTimeout(() => { //timeout only for looks 
              if (urlParams.obguid.includes("wf-")) { //for other wf-xxx pages
                this.parseWFSections(undefined, urlParams.obguid, response);
              } else {
                this.parseSections(response);   
              } 
            }, timeDiff < 500 && timeDiff > 0 ? 500 - timeDiff : 0);          

          }
        }, (error) => {
          if(error) {
            if (error.status != undefined && error.status > 400 && error.status < 500) {
              //this._snackbarManager.open("Session timeout, redirecting...", "warning")
              location.reload()
            } else {
              console.log(error);
              clearTimeout(gettingSectionTimeout)
              if (this._api.constructionComplete) {
                //this._snackbarManager.open("FATAL ERROR: Can't get sections list.", "KO");
                this._api.loading = false;
                this._api.gettingSections = false;
              }
              if (error.error != null && error.error.message != undefined && error.error.message == "Unauthorized") {
                this._api.navigateToLogin();
              }
              this.setErrorSection(JSON.parse(JSON.stringify(error)));
              this._api.loading = false;
              this._api.gettingSections = false;
            }
          }
        });
          
      }
    
    }
  }

  getWfUrlParameters(urlParams:any) {
    if (urlParams.obguid.includes("wf-")) {
      return [urlParams.obguid, urlParams.obguidParam];
    } else if (urlParams.obguidParam.includes("wf-")) {
      return [null, urlParams.obguidParam];
    }
    return ["", ""];
  }

  setErrorSection(context:any) {
    this.sections = [];
    this.sectionTemplate.Components[0].Component.Type = "ERROR";
    this.sectionTemplate.Components[0].Component.Context = context;
    this.sectionTemplate.Section.ID = "SZ015",
    this.sectionTemplate.Section.Context.Title = "";
    this.sectionTemplate.Section.Context.CssStyle = "";
    this.sectionTemplate.Section.Context.Position = "v";
    this.sections.push(this.sectionTemplate);
    this.stopLoadingVars();
    //console.log(this.sections)
  }

  parseSections(response:any) {
    this._api.lastSections = response.Sections;   
    this._api.lastOUCODA = response.OUCODA; 

    this.sections = [];
    response.Sections.forEach((section:any) => {
      this.sections.push(section);
    })
    this.stopLoadingVars(); 
    //console.log(response.Sections);
  }

  parseWFSections(obguid:any, param:string, response?:any) {   
    switch (param) {
      case "wf-route":
        this.sectionTemplate.Components[0].Component.Type = "WFROUTE";
        this._api.doButtonPostRequest(undefined, "/wf-route").subscribe((response) => {  
          if(response) { 
            this._api.printResponse(response);    
                     
            var responseObj = JSON.parse(JSON.stringify(response));

            this.sectionTemplate.Components[0].Component.Context = JSON.parse(JSON.stringify(response))
            this.sectionTemplate.Section.Context.Title = "";
            this.sectionTemplate.Section.Context.CssStyle = "";
            this.sectionTemplate.Section.Context.Position = "v";
            this.sections = [];
            this.sections.push(this.sectionTemplate);
    
            this.stopLoadingVars(); 
          }      
        }, (error) => {
          if(error) {        
            console.log(error);
            this.setErrorSection(error);
            this.stopLoadingVars(); 
          }
        }); 
        break;
    };
    switch (obguid) {
      case "wf-star-report": 
        this.sectionTemplate.Components[0].Component.Type = "WFSTARTEAMMEMBER";
        this._api.doButtonPostRequest({ Year: param }, "/star-report").subscribe((response) => {  
          if(response) { 
            this._api.printResponse(response); 
            var responseObj = JSON.parse(JSON.stringify(response));
    
            this.sectionTemplate.Components[0].Component.Context = responseObj;
            this.sectionTemplate.Section.Context.Title = responseObj.StarReportTitle;
            this.sectionTemplate.Section.Context.CssStyle = "titleBoxBlack";
            this.sectionTemplate.Section.Context.Position = "c";
            this.sections = [];
            this.sections.push(this.sectionTemplate);    
    
            this.stopLoadingVars(); 
          }      
        }, (error) => {
          if(error) {        
            console.log(error);
            this.setErrorSection(error);
            this.stopLoadingVars(); 
          }
        }); 
        break;
      case "wf-star-team-member":  
        this.sectionTemplate.Components[0].Component.Type = "WFSTARTEAMMEMBER";
        this._api.doButtonPostRequest({ Year: param }, "/star-your-team-member-report").subscribe((response) => {  
          if(response) { 
            this._api.printResponse(response);             
            var responseObj = JSON.parse(JSON.stringify(response));
    
            this.sectionTemplate.Components[0].Component.Context = responseObj;
            this.sectionTemplate.Section.Context.Title = responseObj.StarYourTeamMemberTitle + " - " + param;
            this.sectionTemplate.Section.Context.CssStyle = "titleBoxBlack";
            this.sectionTemplate.Section.Context.Position = "c";
            this.sections = [];
            this.sections.push(this.sectionTemplate);    
    
            this.stopLoadingVars(); 
          }      
        }, (error) => {
          if(error) {        
            console.log(error);
            this.setErrorSection(error);
            this.stopLoadingVars(); 
          }
        }); 
        break;
      /*
        var responseObj = JSON.parse(JSON.stringify(response));
        console.log(responseObj.Sections)
        if (responseObj.Sections == undefined) {
          this.sectionTemplate.Components[0].Component.Type = "WFSTARTEAMMEMBER";
          this.sectionTemplate.Components[0].Component.Context = responseObj;
          this.sectionTemplate.Section.Context.Title = responseObj.StarYourTeamMemberTitle;

          this.sections.push(this.sectionTemplate);   
        } else {
          var context = {
            status: 501,
            error: "Star Your Team Member and Star Report functionalities are disabled."
          }
          this.setErrorSection(context);
        }
        break;
        */
    }    
  }

  stopLoadingVars() {
    this._api.firstBoot = false;
    this._api.loading = false;
    this._api.gettingSections = false;
    this._api.gettingSectionTime = 0;
    this._api.stillGettingSections = false;
  }

  goBack() {
    history.back();
  }

  searchComponent(type:string) {
    for(var i = 0; i < this.sections.length; i++) {
      for (var j = 0; j < this.sections[i].Components.length; j++) {
        if (this.sections[i].Components[j].Component.Type == type) return this.sections[i].Components[j].Component.Context;
      }
    }
    return undefined;
  }


}

<div *ngIf="starMemberOpened" class="card-background">
    <mat-card class="starmember-card">    
        <mat-card-title class="title-card memberwindow">
            <div>
                <span class="membername-span">{{ selectedRow.Elemento4 }} / {{ selectedRow.Elemento5 }}</span>
            </div>
            <div class="back-button-div">
                <button class="back-button" mat-mini-fab color="accent" (click)="closeMemberCard()">
                        <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-card-title>   
        <mat-card-content *ngIf="starMemberOpened"  class="confirm-card-content">    
            <div class="starandcomment-div">
                <div class="starimg-container">
                    <div [class]="selectedRow.Elemento6 ? 'star-img fullstar-img' : 'star-img emptystar-img'" (click)="toggleStar()"></div>
                </div>
                <textarea [matTooltip]="selectedRow.Elemento2 != '' && selectedRow.Elemento2 != selectedRow.Elemento7 ? selectedRow.Elemento2 : ''" 
                        class="txtarea" matInput [disabled]="selectedRow.Elemento6 == false || translateEnabled" cdkTextareaAutosize [(ngModel)]="translateEnabled ? selectedRow.Elemento2 : selectedRow.Elemento7" 
                        #autosize="cdkTextareaAutosize" [placeholder]="context.Placeholder"></textarea>
            </div>
            <div class="card-buttons">
                <!--<mat-button-toggle *ngIf="(selectedRow.Elemento6 != selectedRow.Elemento2 && selectedRow.Elemento2 != null)" (click)="toggleTranslation()">Translate with AWS</mat-button-toggle>!-->
                <button mat-raised-button class="table-button" color="primary"
                        [disabled]="(selectedRow.Elemento6 == selectedRowBak.Elemento6 && selectedRow.Elemento7 == selectedRowBak.Elemento7 ) || (selectedRow.Elemento6 && selectedRow.Elemento7 == '')" (click)="confirmStar()">
                    {{ context.ButtonConfirm }}
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<p-table #dt [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm"
    [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true" 
    [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" [scrollable]="true"
    [columns]="context.Column" [value]="context.Data" sortMode="multiple" [multiSortMeta]="multiSortMeta"
    [globalFilterFields]="['Elemento4', 'Elemento5', 'Elemento6', 'Elemento7']">

    <ng-template pTemplate="caption" id="hideIcons">
        <div class="table-header" id="hideIcons">
            <div style="width:160px">
                <button class="generic-button" *ngIf="context.ExcelExportButton != ''" 
                    (click)="exportTable()" [matTooltip]="context.ExcelExportTooltip" mat-raised-button color="accent">
                    <mat-icon>table_chart</mat-icon>
                    <span class="excel-button-label"> {{context.ExcelExportButton}}</span>
                </button>
            </div>
            <div class="search-div">
                <span class="p-input-icon-right" id="hideIcons">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" placeholder="Search" />
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th class="column-header" *ngFor="let item of columns; let i = index" [pSortableColumn]="columns[i].field" pResizableColumn>
                <span class="column-title">
                    {{columns[i].header}}
                    <span class="sort-icons" id="hideIcons">
                        <p-columnFilter type="text" [field]="columns[i].field" display="menu" class="ml-auto"></p-columnFilter>
                        <p-sortIcon [field]="columns[i].field"></p-sortIcon>
                    </span>
                </span>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData *ngIf="!isStarReport">
        <tr (click)="openMemberCard(rowData)">                      
            <td>                  
                <span class="table-data"> {{ rowData['Elemento4'] }}</span>
            </td> 
            <td>                  
                <span class="table-data"> {{ rowData['Elemento5'] }}</span>
            </td> 
            <td style="width:100px">                  
                <span class="table-data"> 
                    <div [class]="rowData['Elemento6'] ? 'star-img-table fullstar-img-table' : 'star-img-table emptystar-img-table'"></div>
                </span>
            </td> 
            <td style="width:30%">                  
                <span class="table-data"> {{ rowData['Elemento7'] }}</span>
            </td>                
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData *ngIf="isStarReport">
        <tr>               
            <ng-container *ngFor="let item of [].constructor(6); let i = index">               
                <td>                  
                    <span class="table-data">
                        <div *ngIf="i == 3" [class]="rowData['Elemento6'] ? 'star-img-table fullstar-img-table' : 'star-img-table emptystar-img-table'"></div>
                        <div *ngIf="i != 3" [matTooltip]="i == 4 && rowData['Elemento2'] != rowData['Elemento7'] ? rowData['Elemento2'] : null"> {{ rowData['Elemento' + (i + 3)] }} </div>
                    </span>
                </td>   
            </ng-container>                 
        </tr>
    </ng-template>


    
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="columns.length" class="nodata-cell">
                No data found.
            </td>
        </tr>
    </ng-template>

</p-table>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'component-cp999',
  templateUrl: './cp999.component.html',
  styleUrls: ['./cp999.component.scss']
})
export class Cp999Component implements OnInit {

  @Input() context:any

  constructor() { }

  ngOnInit(): void {
  }

}

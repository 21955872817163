import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { Cp062Component } from '../cp062/cp062.component';
import { Cp063Component } from '../cp063/cp063.component';


@Component({
  selector: 'component-cp071',
  templateUrl: './cp071.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp071.component.scss']
})
export class Cp071Component implements OnInit {
  
  @Input() inguid = ''
  @Input() labels:any = {}
  @Output() closeFormEvent = new EventEmitter<any>();
  /*
  modelsCheckboxes:any = {}
  modelsCheckboxesBak:any = {}
  decodingsCheckboxes:any = {}
  sourcesList:any = {}
  sourcesRadios:any = {}
  hasSources:boolean = false
  matches:any = []*/

  loading:boolean = true
  /*
  detailsCard:boolean = false
  detailsReceived:any = {}
  Orders:any = []
  */

  
  @ViewChild('apiNavigator') apiNavigator: Cp062Component | undefined;
  showApinavigator:boolean = false
  configElementPaths:any = {}

  selectedInguid = ""
  labelsTable:any = undefined
  dateKeys:any = {}

  context:any = {}


  constructor(private _snackbarManager:SnackbarmanagerService, private _api:ApiService, private _errorThrower:ErrorthrowerService) { }

  ngOnInit(): void {
    console.log(this.labels)
    this._api.doButtonPostRequest({"action": "get", "configuration": this.inguid}, "/integration-configuration").subscribe((response) => {
      if (response) {
        this._api.printResponse(response);
        this.context = JSON.parse(JSON.stringify(response))
        this.loadConfig()
      }
    }, (error) => {
      if(error) {
        console.log(error);
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
         else this._snackbarManager.open(error.error, 'ko');
        this.loading = false;
      }
    });
  }

  loadConfig() {
    try {
      /*
      var customGroups:any[] = []
      var bg = 0
      this.context.baseConfig["Groups"].forEach((group:any) => {
        var found = false
        this.context.config["Groups"].every((baseGroup:any) => {
          if (group["Id"] == baseGroup["Id"]) {
            found = true
            return false
          }
          return true  
        })            
        if (!found) {
            group["Enabled"] = false
            customGroups.push({
              obj: group,
              index: bg
            })
        }
        bg++
      })
      
      customGroups.forEach((customGroup:any) => {
        this.context.config["Groups"].splice(customGroup["index"], 0, customGroup["obj"]); 
        //console.log(customGroup["obj"].Id)
      })
          
      this.context.baseConfig.Groups.forEach((baseGroup:any) => { console.log(baseGroup.Id) })
      console.log('------------------')
      this.context.config.Groups.forEach((group:any) => { console.log(group.Id) })


      var bg = 0
      this.context.baseConfig.Groups.forEach((baseGroup:any) => {
        console.log(baseGroup.Id)        
        this.context.config.Groups[bg]["Enabled"] = false
        this.context.config.Groups.every((group:any) => {
          if (group.Id == baseGroup.Id) {
            this.context.config.Groups[bg]["Enabled"] = true
            var bf = 0
            this.context.baseConfig.Groups[bg].Fields.forEach((baseField:any) => {
              console.log("  " + baseField.Id)             
              console.log("  " + bf)
              if (bf > this.context.config.Groups[bg].Fields.length - 1) {
                this.context.config.Groups[bg].Fields.splice(bf, 0, baseField); 
                this.context.config.Groups[bg].Fields[bf]["Enabled"] = false
              }
              this.context.config.Groups[bg].Fields.every((field:any) => {
                if (field.DateKey != undefined && group.Essential == undefined) {
                  this.dateKeys[group.Id] = {}
                  this.dateKeys[group.Id][field.Id] = bf
                }
                if (baseField.Id != undefined && field.Id == baseField.Id) {
                  this.context.config.Groups[bg].Fields[bf]["Enabled"] = true
                  return false
                }
                return true
              })
              bf++
            })
            return false
          }
          return true
        })
        bg++
      })*/
      
      console.log(this.context.config)
      this.loading = false
      return true

    } catch (e) { 
      console.log(e)
      this._snackbarManager.open("An error occurred!", 'ko')
      this.close(false)
      return false 
    }
  }

  buildConfig() {
    console.log(this.context.config.Groups)
    console.log(this.context.config.Settings)
  }

  getFieldLabel(fieldObj:any) {
    var label = ""
    Object.keys(this.context.apiLabels).every((entity:any) => {
      if (fieldObj["Entity"] == entity) {
        Object.keys(this.context.apiLabels[entity]).every((field:any) => {
          if (fieldObj["Id"] == field) {
            console.log(this.context.apiLabels[entity][field])
            label = this.context.apiLabels[entity][field]
            return false
          }
          return true
        })
      }
      return true
    });
    return label
  }

  hasField(group:any, field:any) {
    var ret = false
    group.Fields.every((f:any) => {
      if (f == field) {
        ret = true
        return false
      }
      return true
    })
    return ret
  }

  toggleFieldDecoding(field:any) {
    if (field.Decoding == undefined) {
      field.Decoding = ''
    } else {
      delete field.Decoding
    }
  }

  toggleFieldDecodingDefault(field:any) {
    if (field.Default == undefined) {
      field.Default = ''
    } else {
      delete field.Default
    }
  }

  isDatekey(field:any, entity:any) {
    if (field.Id == "LastModifiedDate") return true
    if (entity.DateKeys == undefined) return false
    var isDateKey = false
    this.context.dateKeys[entity.DateKeys.Key].every((e:any) => {
      if (field.Id == e) {
        isDateKey = true
        return false
      }
      return true
    })    
    return isDateKey
  }

  entityHasDatekeys(entity:any) {
    if (entity.DateKeys != undefined) {
      return true
    }
    return false
  }

  switchDateKeys(g:any, f:any) {   
    console.log(this.context.config.Groups[g].Fields[f].Id)
    if (this.context.config.Groups[g].Fields[f].Id == "LastModifiedDate") {
      var datekey = "EffectiveFrom"
      if (this.context.config.Groups[g].Entities[0].DateKeys != undefined) {
        datekey = this.context.config.Groups[g].Entities[0].DateKeys.Value[0]
      }
      this.context.config.Groups[g].Fields[f].Id = datekey
    } else {
      this.context.config.Groups[g].Fields[f].Id = "LastModifiedDate"
    }
    console.log(this.context.config.Groups[g].Fields[f].Id)
  }

  addCompanyDecoding() {
    this.context.config.Settings.CompaniesDecodings.push({"Input":"input", "Output": "output"})
  }

  removeCompanyDecoding(i:number) {
    this.context.config.Settings.CompaniesDecodings.splice(i, 1)
  }

  getEntityLabel(group:any, id:string) {
    var label = id
    group.Entities.every((entity:any) => {
      if (entity.Id == id) {
        label = entity.Label
        return false
      }
      return true
    })
    return label
  }

  updateFieldStatus(config:any, groupIndex:number, fieldIndex:number) {
    var datekeyIndex = 0
    var allDisabled = true
    var i = 0
    config.Groups[groupIndex].Fields.every((field:any) => {
      if (i == fieldIndex) {
        field.Enabled = !field.Enabled
      }
      if (field.DateKey == undefined && field.Enabled) {
        allDisabled = false
      }
      if (field.DateKey != undefined) {
        datekeyIndex = i
      }
      i++
      return true
    })
    if (allDisabled) {
      config.Groups[groupIndex].Fields[datekeyIndex].Enabled = false
      config.Groups[groupIndex].Enabled = false
    } else {
      config.Groups[groupIndex].Fields[datekeyIndex].Enabled = true
      config.Groups[groupIndex].Enabled = true
    }
  }

  removeInputVariable(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  addInputVariable(groupIndex:number, fieldIndex:number) {
    this.context.config.Groups[groupIndex].Fields[fieldIndex].Calculation.Input.push({'Label': '', 'Element': ''});
    var l = this.context.config.Groups[groupIndex].Fields[fieldIndex].Calculation.Input.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Groups[' + groupIndex + '].Fields[' + fieldIndex + '].Calculation.Input[' + l + '].Element', 
      name: 'this.context.config.Groups[' + groupIndex + '].Fields[' + fieldIndex + '].Calculation.Input[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }

  addFilterField() {
    this.context.config.Settings.ExtractionFilter.Input.push({'Label': '', 'Element': ''});
    var l = this.context.config.Settings.ExtractionFilter.Input.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Settings.ExtractionFilter.Input[' + l + '].Element', 
      name: 'this.context.config.Settings.ExtractionFilter.Input[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }

  removeFilterField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  addSortField() {
    this.context.config.Settings.ExtractionSort.push({'Label': '', 'Element': ''});
    var l = this.context.config.Settings.ExtractionSort.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Settings.ExtractionSort[' + l + '].Element', 
      name: 'this.context.config.Settings.ExtractionSort[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }

  /**
   * 
   * {
      'Eval': '', 
      'Fields': [],
      'Range':[]
    });
   */

  addRangeField(r:number) {
    this.context.config.Settings.PayrollcodeRanges[r].Fields.push({'Label': '', 'Element': ''})
    var l = this.context.config.Settings.PayrollcodeRanges[r].Fields.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Settings.PayrollcodeRanges[r].Fields[' + l + '].Element', 
      name: 'this.context.config.Settings.PayrollcodeRanges[r].Fields[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }


  removeRangeField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }


  removeSortField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  openApiNavigator(selectionType:number, entity:any) {
    let newApiList:any = []
    if (entity != undefined) {
      this.context.apiList.every((a:any) => {
        if (a.id == entity) {
          newApiList.push(a)
          return false
        }
        return true
      }) 
    } else {
      newApiList = this.context.apiList
    }
    console.log(newApiList)
    this.showApinavigator = true
    this.apiNavigator?.open(newApiList, selectionType)
  }

  getJsonPath(key:any, value:string) {
    var path = ""
    path = value + "=event." + key
    if (!value.includes("this.")) {
      value = "this." + value
    }
    return path
  }

  changeEntityConfig(groupIndex:number, entityIndex:number, element:string, selectionType:number, entity:any) {
    var path = "this.context.config.Groups[" + groupIndex + "].Entities[" + entityIndex + "]." + element + "=event.id"
    this.configElementPaths = []
    this.configElementPaths.push(path)
    this.openApiNavigator(selectionType, entity)
  }

  evalInContext(js:string, context:any, event:any) {
    return function() { return eval(js); }.call(context);
  }

  closeApiNavigator(event:any) {
    console.log(event)
    this.showApinavigator = false
    console.log(this.configElementPaths)  
    this.configElementPaths.forEach((path:any) => {
      this.evalInContext(path, this, event)
    })
    this.configElementPaths = []
  }

  close(success?:boolean) {
    this.closeFormEvent.emit({
      isSuccess: success
    });
  }

  saveConfig() {
    console.log(this.context.config)
    this._api.doButtonPostRequest({"action": "set", "configuration": { "key": this.inguid, "value": this.context.config }}, "/integration-configuration").subscribe((response) => {
      if (response) {
        this._api.printResponse(response)
      }
    }, (error) => {
      if(error) {
        console.log(error);
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
         else this._snackbarManager.open(error.error, 'ko');
        this.loading = false;
      }
    });
  }

/*
  loadDecodings(firstLoad?:boolean) {
    this.modelsCheckboxesBak = JSON.parse(JSON.stringify(this.modelsCheckboxes))
    
    var decodingsCheckboxesBak = JSON.parse(JSON.stringify(this.decodingsCheckboxes)) 
    //console.log(decodingsCheckboxesBak)
    this.decodingsCheckboxes = {}
    
    console.log(this.baseConfig.myExtract.FIELD)
    
    this.baseConfig.myExtract.FIELD.forEach((f:any) => {
      if (f.Decode != undefined && !f.Entita.includes('$')) {
        if (this.searchDecode(f.Decode)) this.decodingsCheckboxes[f.Decode] = true
        else this.decodingsCheckboxes[f.Decode] = false
      }
    })
    
    this.baseConfig.Model.forEach((m:any) => {
      if (m.Name in this.modelsCheckboxes && this.modelsCheckboxes[m.Name]) {
        this.baseConfig.myExtract.FIELD.every((f:any) => {
          if (f.Label == m.Field && f.Decode != undefined && !f.Entita.includes("$")) {
            //generazione delle caselle al primo avvio e agli aggiornamenti successivi
            if (firstLoad) { 
              if (this.cycleThrough(this.config.myExtract.FIELD, m.Field, "Label") && this.hasDecode(m.Field)) this.decodingsCheckboxes[m.Field] = true
              else if (this.cycleThrough(this.config.myExtract.FIELD, m.Field, "Entita.Field") && this.hasDecode(m.Field)) this.decodingsCheckboxes[m.Field] = true
              else this.decodingsCheckboxes[m.Field] = false
            } else {
              if (m.Field in decodingsCheckboxesBak) this.decodingsCheckboxes[m.Field] = decodingsCheckboxesBak[m.Field]
              else this.decodingsCheckboxes[m.Field] = true
            }
            return false
          } else if (m.Field.includes('.') && f.Entita == m.Field.split('.')[0] && f.Field == m.Field.split('.')[1] && f.Decode != undefined && !f.Entita.includes("$")) {
            if (firstLoad) {
              if (this.cycleThrough(this.config.myExtract.FIELD, m.Field, "Entita.Field") && this.hasDecode(m.Field)) this.decodingsCheckboxes[m.Field] = true
              else this.decodingsCheckboxes[m.Field] = false
            } else {
              if (m.Field in decodingsCheckboxesBak) this.decodingsCheckboxes[m.Field] = decodingsCheckboxesBak[m.Field]
              else this.decodingsCheckboxes[m.Field] = true
            }
            return false
          }
          return true
        })
        
      }
    })
    console.log("Decodings:")
    console.log(this.decodingsCheckboxes)
  }

  loadSources() {
    //this.modelsCheckboxesBak = JSON.parse(JSON.stringify(this.modelsCheckboxes))
    var calcFields = this.baseConfig.myExtract.FIELD.filter((obj:any) => {
      return obj.Entita === '$Calculator'
    })

    const groupBy = (array:any, key:any) => {
      return array.reduce((result:any, currentValue:any) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
      }, []);
    };
    var calcGroups = groupBy(calcFields, 'Label')
    

    this.sourcesList = {}
    this.sourcesRadios = {}
    Object.keys(calcGroups).forEach((k:any) => {
      if (calcGroups[k].length > 1) {
        this.baseConfig.Model.every((m:any) => {
          if (this.modelsCheckboxes[m.Name] && m.Field == k) {
            this.sourcesList[k] = calcGroups[k]
            this.sourcesRadios[calcGroups[k][0].Label] = 0
            for (var i = 0; i < calcGroups[k].length; i++) {
              if (this.cycleThrough(this.config.myExtract.FIELD, calcGroups[k][i].Field, "Field")) {
                this.sourcesRadios[calcGroups[k][i].Label] = i
              }              
            }
            return false
          }
          return true
        })
      }
    })
    console.log("Sources:")
    console.log(this.sourcesList)
    console.log(this.sourcesRadios)    
  }  

  loadMatches() {
    if (this.config.decode != undefined) {
      this.matches = []
      var decodeStrings = this.config.decode.split(';')
      delete decodeStrings[decodeStrings.length - 1]
      decodeStrings.forEach((d:any) => {
        var dec = d.split(',')
        this.matches.push({
          input: dec[0],
          output: dec[1]
        })
      })
      console.log("Matches:")
      console.log(this.matches)
    }
  }
  
  buildConfig() {
    var newConfig = JSON.parse(JSON.stringify(this.config))
    
    //Models
    newConfig.Model = []
    this.baseConfig.Model.forEach((m:any) => {
      if (this.modelsCheckboxes[m.Name]) newConfig.Model.push(m)
    })

    //Fields
    newConfig.myExtract.FIELD = []
    newConfig.Model.forEach((m:any) => { 
      var fields = this.getFields(this.baseConfig.myExtract.FIELD, m.Field)
      
      if (fields.length > 0) {

        var group:any = undefined
        if (m.Condition != undefined && m.Condition.includes('==')) group = m.Condition.split('==')[1].replaceAll("'", '').replace(' ', '')
        else if (m.Condition != undefined && m.Condition.includes('!=')) group = m.Condition.split('!=')[1].replaceAll("'", '').replace(' ', '')
        if (group != undefined && this.baseConfig.Gruppi[group] != undefined) {
          if (Boolean(group.match(/[a-z]/))) { //if group is not in all caps look for field with same entity and label
            var fieldEntity = this.baseConfig.Gruppi[group][0].split('.')[0]
            var fieldToAdd = this.getFields(this.getFields(this.baseConfig.myExtract.FIELD, fieldEntity), m.Field)
            if (fieldToAdd.length > 0) this.addField(newConfig.myExtract.FIELD, fieldToAdd[0])
            else console.log("(WARN) Can't find field " + m.Field + " from group " + group)
          } else { //if group name is in all caps add every field from group
            this.baseConfig.Gruppi[group].forEach((e:any) => {
              var fields2 = this.getFields(this.baseConfig.myExtract.FIELD, e)
              if (fields2.length > 0) {
                if (this.sourcesList[fields2[0].Label] != undefined) 
                  this.addField(newConfig.myExtract.FIELD, this.sourcesList[fields2[0].Label][this.sourcesRadios[fields2[0].Label]])
                else this.addField(newConfig.myExtract.FIELD, fields2[0])
              }
              else console.log("(WARN) Can't find field " + e + " from group " + group)
            });
          }
          
        } else {
          this.addField(newConfig.myExtract.FIELD, fields[0])
          var fieldDecorrenza = this.getFields(this.getFields(this.baseConfig.myExtract.FIELD, "Decorrenza"), fields[0].Entita)
          if (fieldDecorrenza.length > 0) {
            this.addField(newConfig.myExtract.FIELD, fieldDecorrenza[0])            
          } else console.log("(WARN) Field " + m.Field + " has no 'Decorrenza'")        
        }
      } else console.log("(WARN) Can't find field " + m.Field)
    })

    this.baseConfig.myExtract.FIELD.forEach((f:any) => {
      if (f.Mandatory) this.addField(newConfig.myExtract.FIELD, f)
      else if (f.Entita == "$Calculator") {
        if (this.cycleThrough(newConfig.myExtract.FIELD, f.Field.split(':')[1], "Entita.Field") > 0) {
          this.addField(newConfig.myExtract.FIELD, f)
        }
      } else if (f.Entita == "$Filter") this.addField(newConfig.myExtract.FIELD, f)
    })
    
    this.baseConfig.myExtract.FIELD.forEach((f:any, i:number) => { 

      if (f.Mandatory) newConfig.myExtract.FIELD.push(f)
      else {

        //se esiste un model con Model.Field = Field.Field
        var name = this.getModelName(f.Label)
        if (name != undefined && this.modelsCheckboxes[name]) { 
          if (f.Entita == "$Calculator" && !this.cycleThrough(newConfig.myExtract.FIELD, f.Label, "Label")) { // se entita = $calculator
            //gestione sorgente
            if (this.sourcesList[f.Label] != undefined) newConfig.myExtract.FIELD.push(this.sourcesList[f.Label][this.sourcesRadios[f.Label]])
            else newConfig.myExtract.FIELD.push(JSON.parse(JSON.stringify(f)))


          } else {
            if (this.cycleThrough(newConfig.Model, name, "Field")) { //se indirizzo aggiunge anche cap, citta', etc
              this.baseConfig.myExtract.FIELD.forEach((ff:any) => {
                if (ff.Entita == f.Entita)  {
                  newConfig.myExtract.FIELD.push(JSON.parse(JSON.stringify(ff)))
                  this.setDecodeStatus(newConfig, ff.Decode)   
                }
              })
            } else {
              newConfig.myExtract.FIELD.push(JSON.parse(JSON.stringify(f))) 
              this.setDecodeStatus(newConfig, f.Decode)
            }
         
          }

        } else if (f.Entita == "$Calculator") {
          if (this.cycleThrough(newConfig.myExtract.FIELD, f.Field.split(':')[1], "Entita.Field"))
            newConfig.myExtract.FIELD.push(JSON.parse(JSON.stringify(f)))

        } else if (f.Entita == "$Filter") {
          newConfig.myExtract.FIELD.push(JSON.parse(JSON.stringify(f)))  

        } else if (f.Label == "Decorrenza" || f.Label == "NoPrint") {
          if (this.cycleThrough(newConfig.myExtract.FIELD, f.Entita, "Entita") && 
              !this.cycleThrough(newConfig.myExtract.FIELD, f.Entita + ',' + f.Label, "Entita,Label")) newConfig.myExtract.FIELD.push(f)
        } 

        // se esiste un model con Model.Field = Field.Entita + '.' + Field.Field
        name = this.getModelName(f.Entita + '.' + f.Field)
        if (name != undefined && this.modelsCheckboxes[name] && this.cycleThrough(this.baseConfig.Model, f.Entita + '.' + f.Field, "Field")) {
          newConfig.myExtract.FIELD.push(JSON.parse(JSON.stringify(f)))
          this.setDecodeStatus(newConfig, f.Decode)    
        } 

      }
    })
    

    //Corrispondences
    if (this.matches.length > 0) {
      var decodeString = "" 
      this.matches.forEach((c:any) => {
        decodeString += c.input + ',' + c.output + ';'
      })
      newConfig.decode = decodeString
    }
    
    //Entities
    newConfig.myExtract.Entita = {}
    Object.keys(this.baseConfig.myExtract.Entita).forEach((e:any) => {
      if (this.cycleThrough(newConfig.myExtract.FIELD, e, "Entita")) {
        newConfig.myExtract.Entita[e] = this.baseConfig.myExtract.Entita[e]
      }
    })

    
    //Groups
    newConfig.myExtract.Entita = {}
    Object.keys(this.baseConfig.myExtract.Entita).forEach((e:any) => {
      if (this.cycleThrough(newConfig.myExtract.FIELD, e, "Entita")) {
        newConfig.myExtract.Entita[e] = this.baseConfig.myExtract.Entita[e]
      }
    })

    
    console.log("---- OUTPUT FILE DATA ----")
    console.log(newConfig.Model)
    console.log(newConfig.myExtract.FIELD)
    console.log(newConfig.myExtract.Entita)
    if (newConfig.decode != undefined) console.log(newConfig.decode)

    var diffFields:any = []
    this.baseConfig.myExtract.FIELD.forEach((f:any) => {
      var b = false
      newConfig.myExtract.FIELD.every((ff:any) => {
        if (f.Entita == ff.Entita && f.Field == ff.Field && f.Label == ff.Label) {
          b = true
          return false
        }
        return true
      })
      if (!b) diffFields.push(f)
    })
    console.log("--------------------------")    
    console.log("Fields difference:")
    console.log(diffFields)

    return newConfig
  }

  onTabChanged(event:any) {
    var changedModels = JSON.stringify(this.modelsCheckboxesBak) == JSON.stringify(this.modelsCheckboxes) ? false : true
    switch(event.index) {
      case 0:
        break;
      case 1:
        if (changedModels) this.loadDecodings();
        break;
      case 2:
        if (changedModels) this.loadSources()
        break;
    }
  }

  changeRadio(event:any, id:string) {
    this.sourcesRadios[id] = event.value
    console.log(this.sourcesRadios)
  }

  changeCheckbox(event:any, id:any, obj:any) {
    obj[id] = event.checked
    console.log(obj)
  }

  changeAllCheckboxes(arr:any, event:any) {
    Object.keys(arr).forEach((k:any) => {
      arr[k] = event.checked
    })
  }

  checkedStatus(arr:any, returnCount?:boolean) {
    var count = 0
    Object.keys(arr).forEach((k:any) => {
      if (arr[k]) count++
    })
    if (returnCount != undefined && returnCount) return count
    if (count == Object.keys(arr).length) return "all"
    else if (count == 0) return "none"
    else return "some"
  }

  getSameNameModels(name:string, asTooltip?:boolean) {
    var arr:any = []
    this.config.Model.forEach((m:any) => {
      if (m.Name == name) arr.push(m)
    })
    if (asTooltip != undefined && asTooltip) {
      var tooltip = "This model includes:\n"
      arr.forEach((e:any) => {
        tooltip += '- ' + e.Field
        if (e.Condition != undefined && !e.Condition.toUpperCase().includes('GRUPPO')) tooltip += ' (' + e.Condition + ')'
        tooltip += '\n'
      })
      return tooltip
    } else return arr
  }

  addMatch() {
    this.matches.push({
      input:"000000", 
      output:"000000"
    })
  }

  removeMatch(i:number) {    
    this.matches.splice(i, 1)
  } 
  
  
  removeRecord() {
    this.selectedRows.forEach((row:any) => {
      var i = 0
      this.config.voci.VOCI.every((r:any) => {
        if (row.Voce == r.Voce && row.Tipo == r.Tipo && row.Cod == r.Cod) {
          this.config.voci.VOCI.splice(i, 1)
          this.selectedRows = []
          return false
        }
        i++
        return true
      })
    })
    this.loadRecords()
    this._snackbarManager.open("Record deleted!", "ok")
  }  
  

  isEmpty(obj:any) {
    return Object.keys(obj).length == 0
  }

  addField(arr:any, field:any) {
    var found = false
    console.log(arr)
    arr.every((f:any) => {
      if ((f.Entita == field.Entita && f.Field == field.Field && f.Label == field.Label) ||
          (field.Source != undefined && f.Label == field.Label)) {
        found = true
        return false
      }
      return true
    })
    if (!found) {
      var f = JSON.parse(JSON.stringify(field))      
      if (f.Decode in this.decodingsCheckboxes && !this.decodingsCheckboxes[f.Decode]) {
        console.log("DISABLING DECODE of " + f.Decode)
        delete f.Decode
      }  
      arr.push(f)
      
      console.log("(OK) Added field " + field.Label)
    } else console.log("(WARN) Field " + field.Label + " already exists")
  }  

  getFields(array:any, string:string) {
    var fields:any = []
    console.log(string)
    array.forEach((f:any) => {
      if (f.Label == string || f.Entita == string ||
        (f.Entita == string.split('.')[0] && f.Field == string.split('.')[1]) ||
        (f.Entita.includes("$") && f.Field.split(':')[0] == string)) {
        fields.push(f)
      }
    })
    return fields
  }

  getModelName(label:string) {
    var name = undefined
    this.config.Model.every((m:any) => {
      if (m.Field == label) {
        name = m.Name
        return false
      }
      return true
    })
    return name
  }

  getModelGroup(label:string) {
    var condition = ""
    this.config.Model.every((m:any) => {
      if (m.Field == label) {
        condition = m.Condition.split("==")[1].replace("'", '')
        return false
      }
      return true
    })
    return condition
  }

  searchDecode(decode:string) {
    var found = false
    this.config.myExtract.FIELD.every((f:any) => {
      if (f.Decode != undefined && f.Decode == decode) {
        found = true
        return false
      }
      return true
    })
    return found
  }

  hasDecode(field:string) {
    var dec = false
    if (field.includes('.')) {
      var fieldSplit = field.split('.');
      this.config.myExtract.FIELD.every((f:any) => {
        if (f.Entita == fieldSplit[0] && f.Field == fieldSplit[1] && f.Decode != undefined) {
          dec = true
          return false
        }
        return true
      })
    } else {
      this.config.myExtract.FIELD.every((f:any) => {
        if (f.Label == field && f.Decode != undefined) {
          dec = true
          return false
        }
        return true
      })
    }
    return dec
  }

  hasCalculator(arr:any, str:string) {
    var ret = false
    arr.forEach((e:any) => {
      if (str in e.Field) {
        ret = true
        return false
      }
      return true
    })
    return ret
  }

  cycleThrough(arr:any, str:string, value:string) {
    var occurrences = 0
    arr.forEach((e:any) => {
      if ((value == "Label" && e.Label == str) ||
          (value == "Entita" && e.Entita == str) ||
          (value == "Field" && e.Field == str) ||
          (value == "Name" && e.Name == str)) {
        occurrences++
      } else if (value == "Entita.Field") {
        try {
          if (e.Entita == str.split('.')[0] && e.Field == str.split('.')[1]) {
            occurrences++
          }
        } catch (e) {}
      } else if (value == "Entita,Label") {
        try {
          if (e.Entita == str.split(',')[0] && e.Label == str.split(',')[1]) {
            occurrences++
          }
        } catch (e) {}
      }
      return true
    })
    return occurrences
  }

  
  //unused
  details(id:any) {
    this.loadingDetail = id;
    var context_data = {          
      "Details": id
    }
    
    this._api.doButtonPostRequest(context_data, "/cez2zuc").subscribe((response) => {      
      if (response) {
        this._api.printResponse(response);             
        var responseObj = JSON.parse(JSON.stringify(response));  
        if (responseObj.isSuccess) {
          //console.log(responseObj.details);
          if (responseObj.details.length > 0) {
            this.detailsReceived = responseObj.details;
            this.detailsCard = true;
          } else this._snackbarManager.open("No details found.", 'warning');
        }
        else this._snackbarManager.open(responseObj.error, 'ko');
        this.loadingDetail = "";
      }
    }, (error) => {
      if(error) {
        console.log(error);    
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
         else this._snackbarManager.open(error.error, 'ko');
        this._api.loading = false; 
        this.loading = false;
      }
    });          
  }
*/
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as FileSaver from 'file-saver';
import * as exceljs from 'exceljs';
import { StylerService } from './styler.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ExcelexporterService {

  constructor(private _api:ApiService, private _freestyler:StylerService, private  _snackbarManager:SnackbarmanagerService) { }
  
  export(title:string, columns:any, rows:any) {    
    this._api.loading = true;

    if (title == "") title = "Table"
    let date = new Date();
    let y = date.getFullYear() + "-" + (date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0"+ date.getDate());
    
    //console.log(rows)
    //console.log(columns)
    try {          
      var workbook:exceljs.Workbook = this.generateExcel(title, y, columns, rows);
      this.writeFile(title, y, workbook);         
      this._api.loading = false;
    } catch (e) {
      this._snackbarManager.open("Excel file export error!", 'ko');
      this._api.loading = false;
    }
  }

  generateExcel(title:string, date:any, columns:any, rows:any) {
      const workbook = new exceljs.Workbook(); 
      const worksheet = workbook.addWorksheet(title.split(' ').join('') + '_' + date);         

      var tempCol:any[] = [];
      columns.forEach((c:any) => {
        tempCol.push({ header:c.header, key:c.field})
      })
      if (rows.length > 0) worksheet.columns = tempCol;
      
      //move table down
      worksheet.insertRow(1,[]) 
      worksheet.insertRow(1,["Export date:", date])
      worksheet.getCell('A1').font = { bold : true };
      worksheet.insertRow(1,[])
      worksheet.insertRow(1,[])

      //title
      var color = 'ff' + this._freestyler.currentAccent.replace('#', '')
      //console.log(color)
      var lastCell = String.fromCharCode(96 + columns.length).toUpperCase() + "1"; 
      worksheet.mergeCells("A1:" + lastCell);
      worksheet.getCell('A1').value = title;
      worksheet.getCell('A1').alignment = { horizontal : 'center' };
      worksheet.getCell('A1').font = { size : 20, color: { argb: 'ffffffff' } };        
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { 
          argb: color
        },
        bgColor: { 
          argb: color
        }
      };

      //fill table
      if (rows.length > 0) {
        worksheet.addRows(rows);           
        worksheet.getRow(5).eachCell((cell:any) => {
          cell.font = { bold: true };
        });  
      } else {
        worksheet.addRow(["There are no currently available data."]);  
      }  

      //cell width
      for (var i = 0; i < worksheet.columns.length; i += 1) { 
        var dataMax = 0;
        var column = worksheet.columns[i];
        var sampleSize = 50; //uses only the first x rows to determine the cell width

        for (var j = 5; j < (column.values!.length <= sampleSize ? column.values!.length : sampleSize); j += 1) {
          var columnVal = column.values![j];
          //console.log(columnVal)
          var columnLength = columnVal?.toString().length;
          if (columnLength! > dataMax) {
            dataMax = columnLength == undefined ? 12 : columnLength + 1;
          }
        }
        column.width = dataMax < 12 ? 12 : dataMax;
      }   
      return workbook;    
  }

  writeFile(title:string, date:any, workbook:exceljs.Workbook) {
    workbook.xlsx.writeBuffer().then(function (data:any) {
      var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      title = title.split(' ').join('');
      FileSaver.saveAs(blob, title + '_' + date + ".xlsx");
    });   
  }

  generateRows(rows:any, columns:any){
    var tempCol = columns;
    var excelRows:any = [];
    var tempRows = rows;

    tempRows.forEach((row:any) => {
      let r:any = {};
      for (const [k, v] of Object.entries(row)){
        for(let i = 0; i < tempCol.length; i++){
          if(tempCol[i].field == k){
            r[tempCol[i].header] = v;
            break;
          }
        }
      }
      excelRows.push(r);
    });
    return excelRows;
  }

}

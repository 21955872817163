import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { ApiService } from '../../services/api.service'
import { ExcelexporterService } from 'src/app/services/excelexporter.service';
 

@Component({
  selector: 'component-cp012',
  templateUrl: './cp012.component.html',
  styleUrls: ['./cp012.component.scss']
})
export class Cp012Component implements OnInit {
  
  @Input() context: any;
  @ViewChild('dt1') table1: Table | undefined;
  @ViewChild('dt2') table2: Table | undefined;
  columns:any = []

  loading: boolean = true
  multiSortMeta:SortMeta[] = [];

  excelRows: any;

  constructor(private _api:ApiService, private _excel:ExcelexporterService) { }

  ngOnInit(): void {

    //fare una struttura dati fatta bene per le colonne serve per il sort e il filter
    for (var i = 0; i < this.context.Column.length; i++) {
      this.columns.push({field: "Elemento" + i, header: this.context.Column[i]})
      if (i == 4) i++;
    }
    
    this.multiSortMeta.push({field: '', order: 1});
    this.loading = false;

  }

  view(obguid:any) {
    this._api.changeFunction(this.context.function, obguid);
  }

  exportTable(title:string, rows:any) {
    this._excel.export(title, this.columns, rows)
  }

}

import { Injectable } from '@angular/core';
import { SectionsComponent } from '../sections/sections.component';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorthrowerService {

  sectionComponent:SectionsComponent | undefined;
  context:any = {
    status: 1,
    error: {
      message: "Error!"
    },
    reloadable: true
  }

  //status: 0 = error, 1 = page doesnt exists

  constructor(private _api:ApiService) { 
  }

  throw(message:string, status?:number, reloadable?:boolean, reportable?:boolean) {
    this.context.error.message = message
    this.context.status = (status == undefined ? -1 : status);
    this.context.reloadable = (reloadable == undefined ? true : reloadable)
    this.context.reportable = (reportable == undefined ? true : reportable)
    setTimeout(() => {
      this.sectionComponent?.setErrorSection(this.context);
    }, 250)
  }
  
}

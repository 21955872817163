import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, Output, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';

@Component({
  selector: 'component-cp081',
  templateUrl: './cp081.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp081.component.scss']
})
export class Cp081Component {

  @Input() context:any | undefined
  detailsContext:any | undefined
  @Input() teguid:any
  @Input() labels:any
  loading:boolean = true

  cardVisible:boolean = false
  intChosen:number = -1
  expandedDetail:number = -1
  saving:boolean = false

  configurationCardVisible:boolean = false
  parametersCardVisible:boolean = false
  scheduleCardVisible:boolean = false
  logsCardVisible:boolean = false
  faguid = ""
  fapara = ""
  inguid = ""

  constructor(private _snackbarManager:SnackbarmanagerService, private _errorThrower:ErrorthrowerService, private _api:ApiService) { }
 
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["teguid"] != undefined && changes['teguid'].currentValue != undefined && changes['teguid'].currentValue != '') {
      this.context = undefined
      this.getIntegrations()
    }
  }

  getIntegrations() {
    this.loading = true
    this._api.doButtonPostRequest({"TEGUID":this.teguid}, "/client-integrations").subscribe((response) => {
      if (response) {
        this._api.printResponse(response);
        this.context = JSON.parse(JSON.stringify(response))
        this.saving = false
        this.loading = false
      }
    }, (error) => {
      if(error) {
        console.log(error);
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
        else this._snackbarManager.open(error.error, 'ko');
        this.context = { "isSuccess": false, "message": "Fatal error." }
        this.saving = false;        
        this.loading = false
      }
    });
    /*this.context = {
      "TEGUID": "TE0028",
      "integrations": [
          {
              "runList": [
                {
                  "isSuccess": true,
                  "logList": [
                    {
                        "name": "2023/12/18/[$LATEST]b3ce8ad0492f4e0397e8b61970efecf4",
                        "url": "https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logsV2:log-groups/log-group/$252Faws$252Flambda$252Fwpdp-prod-dispa-upRetri/log-events/2023$252F12$252F18$252F$255B$LATEST$255Db3ce8ad0492f4e0397e8b61970efecf4",
                        "errors": [],
                        "end": "2023-12-18 17:15:27",
                        "isSuccess": true,
                        "start": "2023-12-18 17:15:06",
                        "lambda": "wpdp-prod-dispa-upRetriA3"
                    },
                    {
                        "name": "2023/12/18/[$LATEST]3de2b29869e048bb8c3878871e2f9657",
                        "url": "https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logsV2:log-groups/log-group/$252Faws$252Flambda$252Fwpdp-prod-dispa-upRetri/log-events/2023$252F12$252F18$252F$255B$LATEST$255D3de2b29869e048bb8c3878871e2f9657",
                        "errors": [],
                        "end": "2023-12-18 16:36:11",
                        "isSuccess": true,
                        "start": "2023-12-18 16:35:55",
                        "lambda": "wpdp-prod-dispa-upRetri"
                    }
                  ]
                }
              ],
              "FACODE": "FA0003",
              "FADESC": "Import Retribuzioni",
              "lambdaList": [
                  "wpdp-prod-dispa-upRetriA3",
                  "wpdp-prod-dispa-upRetri"
              ]
          }
      ],
      "time": 2.088295842999969
    }*/
  }

  openLogs(i:number) {
    this.intChosen = i
    this.logsCardVisible = true
  }

  closeLogs() {
    this.logsCardVisible = false
  }
  
  details(i:number) {
    var showDetails = true
    if ((this.context.TEGUID == "TE0050" && i == 0) || (this.context.integrations[i].errorMessage != undefined) || (this.context.integrations[i].runList[0].isSuccess == undefined)) {
      showDetails = false
    }
    if (showDetails) {
      this.detailsContext = undefined
      this.cardVisible = true
      this.intChosen = i
      
      this._api.doButtonPostRequest({"TEGUID":this.teguid, "FACODE":this.context.integrations[i].FACODE}, "/client-integrations").subscribe((response:any) => {      
        if (response) {
          this._api.printResponse(response);
          if (response.isSuccess != undefined && !response.isSuccess) {
            this._snackbarManager.open(response.message, 'ko')
          } else {
            this.detailsContext = JSON.parse(JSON.stringify(response));
          }
        }
      }, (error) => {
        if(error) {
          console.log(error);    
          if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
          else this._snackbarManager.open(error.error, 'ko');
        }
      });
    }
  }

  closeDetails() {
    this.cardVisible = false
    this.detailsContext = undefined
    this.intChosen = -1
  }

  openSchedule(faguid:string, intChosen:number) {
    this.intChosen = intChosen
    this.faguid = faguid
    this.scheduleCardVisible = true
  }

  closeSchedule(event:any) {
    if (event.isSuccess) {
      if (this.context.integrations[this.intChosen].scheduleList.length == 0) {
        this.context.integrations[this.intChosen].scheduleList.push({
          name: event.name, 
          enabled: event.enabled
        })
      } else {
        this.context.integrations[this.intChosen].scheduleList.every((s:any) => {
          if (s.name == event.name) {
            s.enabled = event.enabled
            return false
          }
          return true
        })
      }
    }    
    this.scheduleCardVisible = false
  }

  openParameters(faguid:string, fapara:string, intChosen:number) {
    this.intChosen = intChosen
    this.faguid = faguid
    this.fapara = fapara
    this.parametersCardVisible = true
  }

  openConfiguration(inguid:string, intChosen:number) {
    this.intChosen = intChosen
    this.inguid = inguid
    this.configurationCardVisible = true
  }

  closeParameters(event:any) {
    if (event.isSuccess) {
      this.context.integrations[this.intChosen].FAPARA = event.fapara
    }
    this.parametersCardVisible = false
  }

  closeConfiguration(event:any) {
    if (event.isSuccess) {
      this.context.integrations[this.intChosen].FAPARA = event.fapara
    }
    this.configurationCardVisible = false
  }

  navigateToFunction(faguid:string) {
    console.log(faguid)
    
    this._api.navigate("AP001", this.context.TEGUID, "MO001", faguid);
  }

  save() {
    this.saving = true;    
    this._api.doButtonPostRequest(this.context, "/client-integrations").subscribe((response) => {      
      if (response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response));  
        if (responseObj.isSuccess) {
          //console.log(responseObj.clients)
          this._snackbarManager.open("Success!", 'ok');
        }
        else this._snackbarManager.open(responseObj.error, 'ko');
        this._api.loading = false;
        this.saving = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);    
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
         else this._snackbarManager.open(error.error, 'ko');
        this._api.loading = false; 
        this.saving = false;
      }
    });
  }

}

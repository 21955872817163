<mat-card>
    <mat-card-content class="mat-card-content">
        <p-table #dt2 [rowHover]="false" [rows]="10" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
            [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true"
            [columns]="context.Column" [value]="context.Row2" sortMode="multiple" [multiSortMeta]="multiSortMeta"
            [globalFilterFields]="['Elemento1', 'Elemento2', 'Elemento3', 'Elemento5', 'Elemento6']">

            <ng-template pTemplate="caption" id="hideIcons">
                <span class="p-input-icon-right" id="hideIcons">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText (input)="dt2.filterGlobal($any($event.target).value,'contains')" [placeholder]="context.Search" />
                </span>
                <div class="table-header" id="hideIcons">
                    <button class="table-button" *ngIf="context.ButtonExcelContent != ''" (click)="exportTable('table2', context.Row2)" [matTooltip]="context.ButtonContent" mat-raised-button color="accent">
                        <mat-icon>table_chart</mat-icon>
                        <span class="excel-button-label"> {{ context.ButtonExcelContent }} </span>
                    </button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th class="column-header" *ngFor="let item of [].constructor(6); let i = index" [pSortableColumn]="columns[i].field" pResizableColumn>
                        <span class="column-title">
                            {{columns[i].header}}
                        </span>
                        <span class="sort-icons" id="hideIcons">
                            <p-columnFilter type="text" [field]="columns[i].field" display="menu" class="ml-auto"></p-columnFilter>
                            <p-sortIcon [field]="columns[i].field"></p-sortIcon>
                        </span>
                    </th>
                    <th>
                        <!-- empty -->
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr pSelectableRowDisabled="true">
                    <td *ngFor="let item of [].constructor(4); let i = index">
                        <span class="table-data">{{ rowData['Elemento' + (i + 1)] }} </span>
                    </td>
                    <td *ngFor="let item of [].constructor(2); let i = index">
                        <span class="table-data">{{ rowData['Elemento' + (i + 6)]  }}</span>
                    </td>
                    <td class="view-button-cell">
                        <button class="table-button" mat-raised-button color="primary" (click)="view(rowData['Elemento5'])">{{ context.ButtonGoToLinkContent }}</button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="context.Row.length == 0" >
                    <td [attr.colspan]="columns.length" class="nodata-cell">
                        {{context.NoDataFound}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-content class="mat-card-content">
        <p-table #dt1 [rowHover]="false" [rows]="10" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,25,50,100]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
            [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true"
            [columns]="context.Column" [value]="context.Row" sortMode="multiple" [multiSortMeta]="multiSortMeta"
            [globalFilterFields]="['Elemento1', 'Elemento2', 'Elemento3', 'Elemento5', 'Elemento6']">

            <ng-template pTemplate="caption" id="hideIcons">
                <span class="p-input-icon-right" id="hideIcons">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText (input)="dt2.filterGlobal($any($event.target).value,'contains')" placeholder="Search" />
                </span>
                <div class="table-header" id="hideIcons">
                    <button class="table-button" *ngIf="context.ButtonExcelContent != ''" (click)="exportTable('table1', context.Row1)" [matTooltip]="context.ButtonContent" mat-raised-button color="accent">
                        <mat-icon>table_chart</mat-icon>
                        <span class="excel-button-label"> {{ context.ButtonExcelContent }} </span>
                    </button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th class="column-header" *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn>
                        <span class="column-title">
                            {{col.header}}
                        </span>
                        <span class="sort-icons" id="hideIcons">
                            <p-columnFilter type="text" [field]="col.field" display="menu" class="ml-auto"></p-columnFilter>
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </span>
                    </th>
                    <th>
                        <!-- empty -->
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr pSelectableRowDisabled="true">
                    <td *ngFor="let item of [].constructor(3); let i = index">
                        <span class="table-data">{{ rowData['Elemento' + (i + 1)] }} </span>
                    </td>
                    <td *ngFor="let item of [].constructor(2); let i = index">
                        <span class="table-data">{{ rowData['Elemento' + (i + 6)]  }}</span>
                    </td>
                    <td class="view-button-cell">
                        <button class="table-button" mat-raised-button color="primary" (click)="view(rowData['Elemento3'])">{{ context.ButtonGoToLinkContent }}</button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="context.Row2.length == 0" >
                    <td [attr.colspan]="columns.length" class="nodata-cell">
                        {{context.NoDataFound}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </mat-card-content>
<mat-card>
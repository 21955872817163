<!--<!DOCTYPE html>-->
<div [class]="(_api.loading && !_api.gettingSections) || _api.firstBoot ? (loggingOut ? 'loader-div loader-div-in-logout' : (_api.firstBoot ? 'loader-div loader-div-in-instant' : 'loader-div loader-div-in')) : 'loader-div loader-div-out'">
  <!--loader-->
  <mat-spinner [class]="(_api.loading && !_api.gettingSections && changingApp == -1) || _api.firstBoot ? 'loader loader-in' : 'loader loader-out'"></mat-spinner>
  <!--<div *ngIf="_api.loading && _api.gettingSections && _api.stillGettingSections" class="loader-text">Still waiting for a response...</div>-->

  <!--app loader-->
  <div *ngIf="appIndex >= 0 && tenantIndex >= 0 && changingApp != -1 && _api.loading" class="loading-logo-div">    
    <img [src]="app[changingApp].app.MaxiLogo" class="loading-logo">
  </div>
</div>

<div *ngIf="_api.sessionTimedout && !_api.loading" [style.display]="_api.sessionTimedout ? 'block' : 'none'" class="loader-div loader-div-in">
  <mat-card class="sessiontimeout-card">
    <div class="sessiontimeout-div">
      <div class="sessiontimeout-div-child">
        <mat-icon>timer</mat-icon>
        This session has expired, going back to the login page... (click the button below if the redirect doesn't work).
      </div>
      <div class="sessiontimeout-div-buttons">
        <button mat-raised-button color="accent" (click)="_api.navigateToLogin()">Back to login</button>
        <!--<button mat-raised-button color="basic" (click)="_api.sessionTimedout = false">Ignore</button>-->
      </div>
    </div>
  </mat-card>
</div>

<div *ngIf="fatalError != ''">
  <mat-card class="fatalerror_card">
    <div class="fatalerror_div">
        <h4 style="text-align:center; font-weight: bold;">A fatal error has occurred... :'(</h4>
        <span style="font-size: 14px;">Click the button below to go back to the login page, if the problem persists please ask for support.</span>     
        <div class="errormessage_div">> {{fatalError}}</div>
        <button mat-raised-button color="basic" (click)="logout()">Back to login</button>
    </div>
  </mat-card>
</div>

<div *ngIf="helpCardVisible" class="loader-div loader-div-in">
  <mat-card class="help-card">
    <div class="help-div">
      <div class="help-closebutton">
        <button mat-icon-button (click)="helpCardVisible = false"><mat-icon>close</mat-icon></button>
      </div>
      <div class="help-img-div"></div>
      <span>To begin using the portal, select an application from the <b>(1) Application menu</b>,
        then select a function from the <b>(3) Function menu</b>.<br> 
        If the application selected has different modules you can switch to a different one from the <b>(2) Module drawer</b>.<br><br>
      </span>
      <div class="help-img-div2"></div>
      <span>To access the module's settings page, click on the <b>(4) Settings button</b>, to logout click the <b>(5) Logout button.</b><br><br>
        For more informations you can visit the Wospee website by clicking <a href="https://wospee.com/" target="_blank" style="color:black">here</a>.
      </span>
    </div>
  </mat-card>
</div>

<mat-toolbar *ngIf="this.fatalError == ''" id="nav-menu" class="mat-toolbar" color="primary">

  <!--Sidebar button-->
  <button mat-icon-button class="side-button" (click)="!animatingDrawer ? toggleDrawer() : null"
      [disabled]="appIndex >= 0 && tenantIndex >= 0 && app[appIndex].tenant[tenantIndex].module.length == 1 ? true : false"
      [style.display]="appIndex >= 0 && tenantIndex >= 0 && app[appIndex].tenant[tenantIndex].module.length > 1 ? '' : 'none'">
      <mat-icon>menu</mat-icon>
  </button>

  <div class="toolbar-div" *ngIf="appIndex >= 0 && tenantIndex >= 0 && moduleIndex >= 0" >
    <!--[style.filter]="i != appIndex && app[appIndex].app.ID == 'AP002' ? 'invert(1) hue-rotate(180deg)' : ''"-->
    <!--App selector-->
    <div [class]="drawerEnabled ? 'appButtons' : 'appButtons-full'" *ngIf="appIndex >= 0 && tenantIndex >= 0">
      
      <!--[matTooltip]="app[appIndex].app.ShortDescription != null ? app[appIndex].app.Description + ' - ' + app[appIndex].app.ShortDescription : app[appIndex].app.Description"-->
      <div class="selected-app-button" color="primary" matTooltipPosition="above"
              [matTooltip]="app[appIndex].tenant[tenantIndex].tenant.ShortDescription != null ? app[appIndex].tenant[tenantIndex].tenant.Description + ' - ' + app[appIndex].tenant[tenantIndex].tenant.ShortDescription : app[appIndex].tenant[tenantIndex].tenant.Description">
              <!--(contextmenu)="appIndex == i ? onRightClick($event, {content: 'Item ' + i}, {app: i, tenant: tenantIndex, module: 0, function: 0}) : ''">-->
        <div [style.margin-left]="app[appIndex].tenant[tenantIndex].module.length > 1 ? '16px' : '8px' " class="selected-app-button-div">
          <!--<mat-icon *ngIf="app[appIndex].app.MaxiLogo == '' || !app[appIndex].app.MaxiLogo.includes('https://')" style="margin-right:4px;">dashboard</mat-icon>-->
          <img *ngIf="app[appIndex].app.MaxiLogo != '' && app[appIndex].app.MaxiLogo.includes('https://')" [class]="showAppName[appIndex] ? 'app-logo-in' : 'app-logo-out-selected'" 
                [src]="screenWidth < 600 ? app[appIndex].app.MiniLogo : app[appIndex].app.MaxiLogo"/>
          <!--screenWidth < 600 ? app[appIndex].app.MiniLogo : (app[1] == null && app[0].app.ID == 'AP001' && app[appIndex].app.MaxiLogo.includes('Wospee') ? './assets/icon/Wospee_Logo_New.png' : app[appIndex].app.MaxiLogo)-->
          <!-- [style.margin-bottom]="app[1] != null && app[appIndex].app.ID == 'AP001' && app[appIndex].app.MaxiLogo.includes('Wospee') ? '8px' : '0'"-->
          
          <span [class]="showAppName[appIndex] || (app[appIndex].app.MaxiLogo == '' || !app[appIndex].app.MaxiLogo.includes('https://')) ? 'app-name-in' : 'app-name-out'" [style.font-weight]="app[appIndex].app.MaxiLogo == '' || !app[appIndex].app.MaxiLogo.includes('https://') ? 'bold' : ''">
            {{app[appIndex].app.Description}}
          </span>
        </div>
      </div>
      
      <button mat-mini-fab *ngIf="app[appIndex].tenant.length > 1" (click)="tenantsFilter=''; filterTenants(appIndex); searchInput.focus()" [matMenuTriggerFor]="app[appIndex].tenant.length > 1 ? tenantsMenu : null" color="basic" style="transform: scale(.5); margin-left: -10px;" [style.margin-bottom]="app[appIndex].app.ID == 'AP001' ? '-6px': '0px'">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <mat-menu #tenantsMenu="matMenu" class="tenant-menu">
        <mat-form-field apparence="fill" subscriptSizing="dynamic" style="padding:8px; padding-bottom: 0px !important; max-width:150px; background-color:whitesmoke">
          <input #searchInput matInput (blur)="searchInput.focus()" type="text" placeholder="Search" [(ngModel)]="tenantsFilter" (keyup)="filterTenants(appIndex)" (click)="$event.stopPropagation()">
          <mat-icon matSuffix style="margin-bottom:-8px; transform: scale(.75)">search</mat-icon> 
        </mat-form-field>
        <ng-container *ngFor="let t of filteredTenants; index as j">
          <button mat-menu-item class="tenant-item" *ngIf="app[appIndex].tenant.length > 1 && t.tenant.ID != app[appIndex].tenant[tenantIndex].tenant.ID" (click)="changeApp(appIndex, getTenantIndex(appIndex, t.tenant.ID))">
            {{ t.tenant.Description }}
          </button>
        </ng-container>
      </mat-menu>

      <!--
      <button mat-icon-button *ngIf="app.length > 1" class="username-button" style="margin-left:-12px" (click)="showAppsList = !showAppsList">
        <mat-icon *ngIf="!showAppsList">arrow_right</mat-icon>
        <mat-icon *ngIf="showAppsList">arrow_left</mat-icon>
      </button>
      -->

      <div class="appslist-div" *ngIf="app.length > 1" class="appslist-div" [class]="showAppsList ? 'appslist-div-open' : 'appslist-div-close'">
        <ng-container *ngIf="showAppsList">
          <ng-template ngFor [ngForOf]="app" let-i="index">
            <ng-template [ngIf]="appIndex != i">
              <button mat-flat-button class="app-button" style="width:30px" matTooltipPosition="above" color="basic"
                        [matTooltip]="app[i].tenant.length == 1 ? appIndex != i ? (app[i].app.ShortDescription != null ? app[i].app.Description + ' - ' + app[i].app.ShortDescription : app[i].app.Description) : '' : ''"
                        (click)="app[i].tenant.length == 1 ? (appIndex != i ? changeApp(i, 0) : null) : tenantsFilter='';searchInput.focus(); filterTenants(i)" [matMenuTriggerFor]="app[i].tenant.length > 1 ? tenantsMenu : null"
                        (mouseenter)="showAppName[i] = true" (mouseleave)="showAppName[i] = false">
                        <!--(contextmenu)="appIndex == i ? onRightClick($event, {content: 'Item ' + i}, {app: i, tenant: tenantIndex, module: 0, function: 0}) : ''">-->
                <div class="selected-app-button-div">
                  <!--<mat-icon *ngIf="app[i].app.MaxiLogo == '' || !app[i].app.MaxiLogo.includes('https://')" style="margin-right:4px;">dashboard</mat-icon>-->
                  <img *ngIf="app[i].app.MaxiLogo != '' && app[i].app.MaxiLogo.includes('https://')" [class]="showAppName[i] && appIndex == i ? 'app-logo' : 'app-logo'" 
                        [src]="screenWidth < 600 ? app[i].app.MiniLogo : app[i].app.MiniLogo"/>
                  <!--screenWidth < 600 ? app[i].app.MiniLogo : (app[1] == null && app[0].app.ID == 'AP001' && app[i].app.MaxiLogo.includes('Wospee') ? './assets/icon/Wospee_Logo_New.png' : app[i].app.MaxiLogo)-->
                  <!-- [style.margin-bottom]="app[1] != null && app[i].app.ID == 'AP001' && app[i].app.MaxiLogo.includes('Wospee') ? '8px' : '0'"-->
                  <span [class]="(showAppName[i] && appIndex == i) || (app[i].app.MaxiLogo == '' || !app[i].app.MaxiLogo.includes('https://')) ? 'app-name-in' : 'app-name-out'" [style.font-weight]="app[i].app.MaxiLogo == '' || !app[i].app.MaxiLogo.includes('https://') ? 'bold' : ''">
                    {{app[i].app.Description}}
                  </span>
                </div>
              </button>
              
              <mat-menu #tenantsMenu="matMenu" class="tenant-menu">
                <mat-form-field apparence="fill" style="padding:8px; padding-bottom: 0px !important; max-width:150px; background-color:whitesmoke">
                  <input #searchInput matInput (blur)="searchInput.focus()" type="text" placeholder="Search" [(ngModel)]="tenantsFilter" (keyup)="filterTenants(i)" (click)="$event.stopPropagation()">
                  <mat-icon matSuffix style="margin-bottom:-8px; transform: scale(.75)">search</mat-icon> 
                </mat-form-field>
                <ng-container *ngFor="let t of filteredTenants; index as j"><!-- *ngIf="app[i].tenant.length > 1 && t.tenant.ID != app[appIndex].tenant[tenantIndex].tenant.ID" -->
                  <button mat-menu-item class="tenant-item" *ngIf="app[i].tenant.length > 1 && t.tenant.ID != app[appIndex].tenant[tenantIndex].tenant.ID" (click)="changeApp(i, getTenantIndex(i, t.tenant.ID))">
                    {{ t.tenant.Description }}
                  </button>
                </ng-container>
              </mat-menu>
            </ng-template>
          </ng-template>
        </ng-container>
      </div>
      <!--
      <button class="mat-button app-button" color="primary" aria-label="AppButton" matTooltipPosition="above">
        <div class="selected-app-button-div">            
          <span class="app-name-in">
            Example app
          </span>
        </div>
      </button>
      -->
      
    </div>

    <div class="rightmenu-div">
      <span [class]="app.length > 2 ? 'usernamealt' : ''" id="username">{{username}}</span>
      <button class="username-button" mat-icon-button [matMenuTriggerFor]="logoutMenu" aria-label="LogoutButton" [matTooltip]="chatBanner != '' ? chatBanner.split(':')[0] : ''"
        [matBadge]="chatBanner != '' ? chatBanner.split('#')[1] : ''" matBadgeColor="warn" matBadgeSize="small" matBadgePosition="after">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #logoutMenu="matMenu" xPosition="before">
        <span mat-menu-item [disabled]="true" class="username-item" color="accent">
          <b>{{username}}</b>
        </span>
        <button *ngIf="chatBanner != ''" mat-menu-item class="logout-menu-item conversation-menu-item" (click)="openConversations()">
          Conversation<mat-icon>message</mat-icon>
        </button>
        <mat-divider *ngIf="chatBanner != ''" class="menu-divider"></mat-divider>
        <button mat-menu-item class="logout-menu-item" (click)="openNewTab()">
          {{ language == 'ITA' ? 'Nuova scheda' : language == 'ESP' ? 'Nueva pestaña' : 'New tab' }}<mat-icon>open_in_new</mat-icon>
        </button> 
        <mat-divider class="menu-divider"></mat-divider>
        <button mat-menu-item class="logout-menu-item" (click)="help()">
          {{ language == 'ITA' ? 'Aiuto' : language == 'ESP' ? 'Ayuda'  : 'Help' }}<mat-icon>help</mat-icon>
        </button>
        <button mat-menu-item class="logout-menu-item" (click)="settings()">
          {{ language == 'ITA' ? 'Impostazioni' : language == 'ESP' ? 'Configuración' : 'Settings' }}<mat-icon>settings</mat-icon>
        </button>
        <button mat-menu-item class="logout-menu-item" (click)="logout()">
          {{ language == 'ITA' ? 'Esci' : language == 'ESP' ? 'Cerra' : 'Logout' }}<mat-icon>logout</mat-icon>
        </button>  
      </mat-menu>
    </div>

  </div>

</mat-toolbar>


<mat-drawer-container *ngIf="app != {}" class="drawer-container" hasBackdrop="false"> 
  
  <mat-drawer #drawer [mode]="screenWidth > 600 ? 'side' : 'over'" [opened]="appIndex >= 0 && tenantIndex >= 0 && app[appIndex].tenant[tenantIndex].module.length > 1 && !mobileView ? 'true' : false"
              class="mat-drawer-page" [style.width]="compactDrawer ? '64px' : '256px'" (transitionend)="desktopDrawerAnimationEnd()">
    <mat-list *ngIf="appIndex >= 0 && tenantIndex >= 0 && moduleIndex >= 0" class="module-list">
        <mat-list-item *ngFor="let module of app[appIndex].tenant[tenantIndex].module; index as i">

          <button *ngIf="mobileUseragent ? !isModuleDisabled(module.module.ID) : true" [ngClass]="moduleIndex == i ? 'mat-mini-fab' : 'mat-icon-button'" class="module-button" [class]="moduleIndex == i ? 'module-button-selected' : ''" (click)="changeModule(i)"
                  [matTooltip]="compactDrawer ? module.module.Description : ''" matTooltipPosition="right">
                  
            <mat-icon *ngIf="module.module.MiniLogo.includes('icon:/')" class="module-logo-maticon" [style.color]="i != moduleIndex ? 'black' : 'white'">{{module.module.MiniLogo.replace("icon:/", '')}}</mat-icon>
            <img class="module-logo" *ngIf="module.module.MiniLogo != '' && !module.module.MiniLogo.includes('icon:/')" [src]="module.module.MiniLogo"
                  [style.filter]="i != moduleIndex ? 'brightness(0) invert(0)' : 'brightness(0) invert(1)'" (error)="enableDefaultLogo($event)">
            
            <span class="modulename-span" [style.color]="i != moduleIndex ? 'black' : 'white'" [style.opacity]="compactDrawer ? '0' : '1'">{{ module.module.Description }}</span>
          </button>

        </mat-list-item>
    </mat-list>

    <img *ngIf="appIndex >= 0 && app[appIndex].app.MiniLogo" [src]="app[appIndex].app.MiniLogo" [class]="_api.gettingSections ? 'currentapp-icon pulse' : 'currentapp-icon'"/>
  </mat-drawer>

  <mat-drawer-content #drawerContent [class]="appIndex >= 0 && tenantIndex >= 0 && app[appIndex].tenant[tenantIndex].module.length > 1 && !mobileView ? 'drawer-content' : 'drawer-content-full'" 
                      [style.margin-left]="compactDrawer ? '64px' : '256px'">  
    <!--[class]="!drawerOpened ? (app[appIndex].tenant[tenantIndex].module.length > 0 ? 'magic-banner-size' : '') : ''"-->
    <div id="magic-banner" class="magic-banner" [style.background-color]="magicBanner.includes('[INFO]') ? '#c8c8c8' : '#ffd900'" *ngIf="magicBanner != ''">
      <mat-icon *ngIf="!magicBanner.includes('[INFO]')" style="margin-right:4px">warning</mat-icon>
      <mat-icon *ngIf="magicBanner.includes('[INFO]')" style="margin-right:4px">info</mat-icon>
      <marquee scrollAmount="10px" class="magic-banner-marquee">{{magicBanner.includes('[INFO]') ? magicBanner.replace("[INFO]", "") : magicBanner}}</marquee>
      <mat-icon (click)="magicBanner = ''" style="cursor:pointer">close</mat-icon>
    </div>

    <div id="function-nav" [class]="isSticky ? 'func-wrap sticky' : 'func-wrap'" [style.margin-top]="magicBanner != '' ? '48px' : '12px'"
      [style.height]="funcmenuVisible ? '44px' : (_api.isNakedSection() ? '44px' : '0px')">
      <app-func-menu *ngIf="appIndex >= 0 && tenantIndex >= 0 && moduleIndex >= 0" 
        [functions]="app[appIndex].tenant[tenantIndex].module[moduleIndex].function" 
        [functionIndex]="functionIndex" [language]="language" [mobileView]="mobileUseragent" [showMenu]="funcmenuVisible" [isSticky]="isSticky"
        (changeFunctionEvent)="changeFunction($event)">
      </app-func-menu>
    </div>

    <div class="sections-wrap">
      <div id="pdfContent" class="content">
        <app-sections *ngIf="_api.constructionComplete" [username]="username"></app-sections>
      </div>
    </div>

  </mat-drawer-content>
</mat-drawer-container>

<span *ngIf="_api.ENVIRONMENT < 5" class="currentenvironment-span">{{_api.ENVIRONMENT < 3 ? 'STAG' : 'TEST' }} - {{ version }}</span>

<!--right click menu-->
<div style="visibility: hidden; position: fixed;" 
[style.left]="rightClickMenuPosition.x" 
[style.top]="rightClickMenuPosition.y" 
[matMenuTriggerFor]="rightMenu"></div> 

<mat-menu #rightMenu="matMenu"> 
  <ng-template matMenuContent let-item="item" let-i="i"> 
    <button mat-menu-item (click)="openNewTab(i)">
      <mat-icon>open_in_new</mat-icon>
      Open new app instance
    </button> 
  </ng-template> 
</mat-menu> 

<!--debug only
<div style="position:absolute; bottom:0; right:0;background-color: rgba(255,0,0,.5); color:white;z-index:99">
  App:{{appIndex}} - Module:{{moduleIndex}} - Function:{{functionIndex}}
</div>-->
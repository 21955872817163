<mat-card>
    <mat-card-title class="title-card">Functions</mat-card-title>
    <mat-card-content>
        <div *ngIf="context == undefined" class="spinner-div">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="context != undefined && context.isSuccess == undefined" style="display: flex; flex-direction: column; gap:8px; overflow-x: hidden; text-overflow: ellipsis; overflow-y: scroll; height: 300px;">
            <ng-container *ngFor="let function of context.functions, index as i">
                <mat-checkbox [(ngModel)]="function.FTATTI" [disabled]="saving">{{function.FADESC}} ({{function.FACODE}}) </mat-checkbox>
            </ng-container>
        </div>
        <button mat-raised-button (click)="save()" [disabled]="checkChanges()">
            <mat-icon *ngIf="!saving">save</mat-icon>
            <mat-icon *ngIf="saving">settings</mat-icon>
            <span *ngIf="!saving">Save</span>
            <span *ngIf="saving">Saving...</span>
        </button>
    </mat-card-content>

</mat-card>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-actions',
  templateUrl: './page-actions.component.html',
  styleUrls: ['./page-actions.component.scss']
})
export class PageActionsComponent implements OnInit {

  @Input()
  position:any;
  @Input()
  component:any;
  @Input()
  sections:any;


  constructor() { }

  ngOnInit(): void {
    //console.log("page actions")
  }

}

import { NgModule } from '@angular/core';

import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageComponent } from './page/page.component';
import { ComponentsComponent } from './components/components.component';
import { FuncMenuComponent } from './components/func-menu/func-menu.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
//import { MatFormFieldModule } from '@angular/material/form-field';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
//import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
//import { MatSelectModule } from '@angular/material/select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SliderModule } from 'primeng/slider';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule } from 'primeng/chart';
import { ButtonModule } from 'primeng/button';
import { NgIdleModule } from  '@ng-idle/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxCsvParserModule } from 'ngx-csv-parser';

import { SectionsComponent } from './sections/sections.component';
//import { SectionHeaderComponent } from './sections/section-header/section-header.component';
import { PageActionsComponent } from './sections/page-actions/page-actions.component';
import { GenericformComponent } from './components/genericform/genericform.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { Cp001Component } from './components/cp001/cp001.component';
import { Cp017Component } from './components/cp017/cp017.component';
import { Cp018Component } from './components/cp018/cp018.component';
import { Cp019Component } from './components/cp019/cp019.component';
import { Cp020Component } from './components/cp020/cp020.component';
import { Cp021Component } from './components/cp021/cp021.component';
import { Cp022Component } from './components/cp022/cp022.component';
import { Cp023Component } from './components/cp023/cp023.component';
import { Cp024Component } from './components/cp024/cp024.component';
import { CP030Component } from './components/cp030/cp030.component';
import { Cp039Component } from './components/cp039/cp039.component';
import { CP040Component } from './components/cp040/cp040.component';
import { Cp032Component } from './components/cp032/cp032.component';
import { CP002Component } from './components/cp002/cp002.component';
import { CP003Component } from './components/cp003/cp003.component';
import { Cp043Component } from './components/cp043/cp043.component';
import { Cp004Component } from './components/cp004/cp004.component';
import { Cp044Component } from './components/cp044/cp044.component';
import { Cp028Component } from './components/cp028/cp028.component';
import { Cp014Component } from './components/cp014/cp014.component';
import { Cp009Component } from './components/cp009/cp009.component';
import { Cp029Component } from './components/cp029/cp029.component';
import { Cp010Component } from './components/cp010/cp010.component';
import { Cp031Component } from './components/cp031/cp031.component';
import { Cp999Component } from './components/cp999/cp999.component';
import { Cp037Component } from './components/cp037/cp037.component';
import { Cp015Component } from './components/cp015/cp015.component';
import { Cp041Component } from './components/cp041/cp041.component';
import { Cp034Component } from './components/cp034/cp034.component';
import { Cp050Component } from './components/cp050/cp050.component';
import { Cp038Component } from './components/cp038/cp038.component';
import { Cp005Component } from './components/cp005/cp005.component';
import { Cp006Component } from './components/cp006/cp006.component';
import { Cp045Component } from './components/cp045/cp045.component';
import { Cp025Component } from './components/cp025/cp025.component';
import { Cp007Component } from './components/cp007/cp007.component';
import { Cp035Component } from './components/cp035/cp035.component';
import { Cp013Component } from './components/cp013/cp013.component';
import { Cp016Component } from './components/cp016/cp016.component';
import { ErrorComponent } from './components/error/error.component';
import { Cp008Component } from './components/cp008/cp008.component';
import { Cp027Component } from './components/cp027/cp027.component';
import { Cp012Component } from './components/cp012/cp012.component';
import { Cp036Component } from './components/cp036/cp036.component';
import { Cp060Component } from './components/cp060/cp060.component';
import { YearmonthformatDirective } from './components/genericform/yearmonthformat.directive';
import { DefaultformatDirective } from './components/genericform/defaultformat.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WfrouteComponent } from './components/wfroute/wfroute.component';
import { WfstarteammemberComponent } from './components/wfstarteammember/wfstarteammember.component';
import { StarteammemberbuttonsComponent } from './components/starteammemberbuttons/starteammemberbuttons.component';
import { Cp011Component } from './components/cp011/cp011.component';
import { Cp100Component } from './components/cp100/cp100.component';
import { Cp070Component } from './components/cp070/cp070.component';
import { environment } from 'src/environments/environment';
import { Cp046Component } from './components/cp046/cp046.component';
import { Cp071Component } from './components/cp071/cp071.component';
import { Cp072Component } from './components/cp072/cp072.component';
import { Cp073Component } from './components/cp073/cp073.component';
import { Cp080Component } from './components/cp080/cp080.component';
import { Cp081Component } from './components/cp081/cp081.component';
import { Cp082Component } from './components/cp082/cp082.component';
import { Cp047Component } from './components/cp047/cp047.component';
import { Cp061Component } from './components/cp061/cp061.component';
import { Cp090Component } from './components/cp090/cp090.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { Cp083Component } from './components/cp083/cp083.component';
import { Cp084Component } from './components/cp084/cp084.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { Cp064Component } from './components/cp064/cp064.component';
import { Cp065Component } from './components/cp065/cp065.component';
import { Cp062Component } from './components/cp062/cp062.component';
import { Cp063Component } from './components/cp063/cp063.component';
import { Cp085Component } from './components/cp085/cp085.component';



@NgModule({
  declarations: [
    AppComponent,
    ComponentsComponent,
    PageComponent,
    FuncMenuComponent,
    SectionsComponent,
    CP040Component,
    CP030Component,
    //SectionHeaderComponent,
    PageActionsComponent,
    Cp022Component,
    Cp039Component,
    Cp017Component,
    DialogComponent,
    Cp020Component,
    Cp018Component,
    Cp021Component,
    GenericformComponent,
    Cp019Component,
    Cp023Component,
    Cp024Component,
    Cp001Component,
    Cp032Component,
    CP002Component,
    CP003Component,
    Cp004Component,
    Cp043Component,
    Cp044Component,
    Cp028Component,
    Cp014Component,
    Cp009Component,
    Cp029Component,
    Cp010Component,
    Cp031Component,
    Cp999Component,
    Cp037Component,
    Cp015Component,
    Cp041Component,
    Cp034Component,
    Cp050Component,
    Cp038Component,
    Cp005Component,
    Cp006Component,
    Cp045Component,
    Cp025Component,
    Cp007Component,
    Cp035Component,
    Cp013Component,
    Cp016Component,
    ErrorComponent,
    Cp008Component,
    Cp027Component,
    Cp012Component,
    Cp036Component,
    Cp060Component,
    YearmonthformatDirective,
    DefaultformatDirective,
    WfrouteComponent,
    WfstarteammemberComponent,
    StarteammemberbuttonsComponent,
    Cp011Component,
    Cp100Component,
    Cp070Component,
    Cp046Component,
    Cp071Component,
    Cp072Component,
    Cp073Component,
    Cp080Component,
    Cp081Component,
    Cp082Component,
    Cp047Component,
    Cp090Component,
    Cp061Component,
    Cp083Component,
    Cp084Component,
    Cp064Component,
    Cp065Component,
    Cp062Component,
    Cp063Component,
    Cp085Component
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    MatFormFieldModule,
    MatCardModule,
    AmplifyAuthenticatorModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTooltipModule,
    TableModule,
    ReactiveFormsModule,
    MultiSelectModule,
    ProgressBarModule,
    SliderModule,
    DropdownModule,
    ChartModule,
    ButtonModule,
    MatDialogModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatStepperModule,
    MatBadgeModule,
    MatChipsModule,
    MatExpansionModule,
    AngularEditorModule,
    MatButtonToggleModule,
    FontAwesomeModule,
    NgxCsvParserModule,
    PowerBIEmbedModule,
    NgxMatTimepickerModule,
    NgIdleModule.forRoot(),
    MatSlideToggleModule,  
    MatTreeModule,  
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, //if true it makes the app reload forever on live reloading
      registrationStrategy: 'registerImmediately' 
    })
  ],
  providers: [Title, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }

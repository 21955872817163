import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Directive } from '@angular/core';

export const DEFAULT_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}; 
@Directive({
  selector: '[appDefaultFormat]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_FORMAT }
  ],
})
export class DefaultformatDirective {
}
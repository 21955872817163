import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service'
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
 

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'component-cp044',
  templateUrl: './cp044.component.html',
  styleUrls: ['./cp044.component.scss'],
  providers: [{
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS 
    },
  ]
})
export class Cp044Component implements OnInit {

  @ViewChild('picker', {static: false}) private picker: MatDatepicker<Date> | undefined;
  @Input() context: any;
  @Input() response: any;
  tableData:any = [];
  selectedCell:any = {x:-1, y:-1};

  selectYear:any;
  selectRegion:any = "ALL";
  selectJob:any = "ALL";
  selectManager:any = "ALL";
  selectArea:any = "ALL";
  selectLevel:any = "0";


  constructor(private _api:ApiService, public dialog: MatDialog, private _snackbarManager: SnackbarmanagerService) { }

  ngOnInit(): void {    
    this.selectYear = new Date();
    this.selectYear.setFullYear(this.context.Anno);
    this.tableData = JSON.parse(JSON.stringify(this.context.table4));
    this.tableData.sort((a:any,b:any) => a[0] > b[0] ? 1 : -1);
  }

  countElements(table3:any, x:number, y:number) {
    var n = 0;
    table3.forEach((e:any) => {
      if (e[1] == y && e[2] == x) n++;
    });
    if (n == 0) return "";
    else return n;
  }

  getNameList(table3:any, x:number, y:number) {
    var names:any = [];
    table3.forEach((e:any) => {
      if (e[1] == y && e[2] == x) {
        if (names.length > 2) {
          names.push("\n...");
        } else {
          //var surname = e[0].split(',')[0];
          names.push(e[0]);
        }
      }
    });
    if (names.length > 3) return names.slice(0,4);
    else return names;
  }

  getWillingness(table3:any, name:string) {
    for(var i = 0; i < table3.length; i++) {
      if (table3[i][0].includes(name)) return table3[i][3];
    }
    return 1;
  }

  getColor(willingness:any) {
    for(var i = 0; i < this.context.Colors.length; i++) {
      if (this.context.Colors[i].svalue == willingness) return this.context.Colors[i].color;
    }
    return "black";
  }

  updateTable4(x?:number, y?:number) {
    if (x != undefined && y != undefined) {
      if (this.countElements(this.context.table3, x, y) != 0) {
        this.selectedCell = {x, y};
        //console.log(this.selectedCell)
        this.tableData = [];
        this.context.table3.forEach((e:any) => {
          if (e[1] == y && e[2] == x) {
            for(var i = 0; i < this.context.table4.length; i++) {
            if (e[0] == this.context.table4[i][0]) {
                this.tableData.push(this.context.table4[i]);
                break;
              }
            }
          }
        });
      }
    }
  }

  chosenYearHandler(ev:any, input:any){
    let { _d } = ev;
    this.selectYear = _d;
    this.context.Anno = this.selectYear.getFullYear()
    this.picker?.close();
  }

  report() {
    this._api.loading = true;
    this.response.Sections[0].Components[0].Component.Context = this.context;
    var context_data = this.response

    this._api.doButtonPostRequest(context_data, "/o-action-save", this.context.ButtonReportFunction).subscribe((response) => {      
      if(response) {        
        this._api.printResponse(response);    
        this._snackbarManager.open(this.context.ButtonReportMessageSuccess, 'ok');   
        this._api.loading = false;   
      }
    }, (error) => {
      if(error) {
        console.log(error);        
        this._snackbarManager.open(this.context.ButtonReportMessageError, 'ko');   
        this._api.loading = false;
      }      
    });  
  }  


  search() {
    this._api.loading = true;

    this._api.doButtonPostRequest(this.getContextDataSearch()).subscribe((response) => {      
      if(response) {        
        this._api.printResponse(response) 
        var jsonResponse = JSON.parse(JSON.stringify(response));
        this.context.table3 = jsonResponse.Sections[0].Components[0].Component.Context.table3;
        this.context.table4 = jsonResponse.Sections[0].Components[0].Component.Context.table4;
        this.context.Colors = jsonResponse.Sections[0].Components[0].Component.Context.Colors;
        this.context.RatingScaleLegend = jsonResponse.Sections[0].Components[0].Component.Context.RatingScaleLegend;
        this.updateTable4()
        this.selectedCell.x = -1;
        this.selectedCell.y = -1;
        this._api.loading = false;  
      }
    }, (error) => {
      if(error) {
        console.log(error);    
        this._api.loading = false;    
        this._snackbarManager.open("Error: " + error.error, 'ko');   
      }
    });      
  }

  getContextDataSearch() {
    let context_data = {
      "ANNO": "" + this.selectYear.getFullYear(),
      "JOB": this.selectJob,
      "GEGERE": this.selectManager,
      "AREA": this.selectArea,
      "WHERE": "", //query sql data dal livello
      "REGION": ""
    }

    if (context_data.JOB != "ALL")
      context_data.WHERE = " AND (TPRUOL = \'" + this.selectJob + "\' OR TPRUOL IS NULL)"
    if (context_data.GEGERE != "ALL")
      context_data.WHERE += " AND ((GEGERX LIKE \'" + this.selectManager + "%\' AND GEGERX <> \'" + this.selectManager +"\') OR GEGERX IS NULL)";
    if (context_data.AREA != "ALL")
      context_data.WHERE += " AND (TPAREC = \'" + this.selectArea + "\' OR TPAREC IS NULL)";
    if (this.selectLevel == "1")
      context_data.WHERE += " AND LENGTH(GEGERX) <> LENGTH(\'" + this.selectManager + "\') + 2";
    if (this.selectLevel == "2")
      context_data.WHERE += " AND LENGTH(GEGERX) = LENGTH(\'" + this.selectManager + "\') + 2";
    if (this.selectRegion == "ALL") {
      let regionList:any = [];
      this.context.RegionList.forEach((e:any) => {
        regionList.push(e.Elemento1);
      });
      context_data.REGION = regionList;
      //context_data.WHERE += " AND (TPREGI = '" + this.selectRegion + "' OR TPREGI IS NULL)";
    } else {
      context_data.REGION = this.selectRegion;
      context_data.WHERE += " AND (TPREGI = \'" + this.selectRegion + "\' OR TPREGI IS NULL)"
    }

    return context_data
  }

}


import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'component-cp073',
  templateUrl: './cp073.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp073.component.scss']
})
export class Cp073Component implements OnInit {
  
  //record table management
  selectedRows:any = []
  recordColumns:any = []
  records:any = []
  recordsLoading:boolean = false

  config:any = {}
  baseConfig:any = {}

  constructor(private _snackbarManager:SnackbarmanagerService, private _api:ApiService) { }

  ngOnInit(): void {
  }

  loadConfig(config:any, baseConfig:any) {
    this.config = config
    this.baseConfig = JSON.parse(baseConfig)
    
    this.loadMyConfig()
    return true
  }

  loadMyConfig() {
    
  }

  isEmpty(obj:any) {
    return Object.keys(obj).length == 0
  }

  buildConfig() {
    return {}
  }

}

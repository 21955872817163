import { Component, Input } from '@angular/core';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'component-cp047',
  templateUrl: './cp047.component.html',
  styleUrls: ['./cp047.component.scss']
})
export class Cp047Component {

  @Input()
  context:any
  loading:boolean = true
  url:any = undefined

  constructor(private errorThrower:ErrorthrowerService, private sanitizer: DomSanitizer, private _api:ApiService) {}

  ngOnInit() {
    if (this.context.url == undefined || this.context.url == '') {
      this.errorThrower.throw("Missing url in context.")
    }
    if (this.context.tokencookie != undefined && this.context.tokencookie == true) {
      this.addTokenCookie()
    }
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.url)
    this.loading = false
  }

  addTokenCookie() {
    var env = this._api.ENVIRONMENT
    var headers = this._api.getHttpHeader();
    var domain = "";
    var splitArr = this.context.url.split('.');
    var arrLen = splitArr.length;
    if (arrLen > 2) {
      domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
      if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
        domain = splitArr[arrLen - 3] + '.' + domain;
      }
    }
    domain = "." + domain.replace("/", "")
    document.cookie = "Token" + env + "=" + headers["Authorization"].replace("Bearer ", "") + "; Domain=" + domain + "; Path=/; Secure; Samesite=Lax;"
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router, Event, NavigationEnd } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class StylerService {

  isPwa:boolean = false;
  cognitoUser:String = ""

  //since i cant read themes.scss i need to copy the accents color here for some services (excelexporter)
  defaultAccent:string = "#2fac66";
  blueAccent:string = "#0099dd";
  defaultDarkAccent:string = "#2A2A2A";
  wospeeAccent:string = "#00a75d";
  goldengooseAccent:string = "#000000";
  currentAccent:string = "";

  constructor(private router:Router, private _api:ApiService) { 
    router.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        this.updateTheme();
      }
    })
  }

  ngInit() {
    this.updateTheme();
  }

  setIsPwa(isPwa:boolean) {
    this.isPwa = isPwa;
  }

  updateTheme() {
    this.cognitoUser = ""
    try {
      this.cognitoUser = JSON.parse(sessionStorage.getItem("page")!).other.cognitoUser
    } catch (e) {      
      this.cognitoUser = ""
    }
    
    if (this._api.getUrlParameters().teguid != undefined) {
      switch (this._api.getUrlParameters().teguid) {
        case "TE0000":
          this.setDefaultTheme();
          this.setDefaultPwaTitlebar();
          break;
        case "TE0006":
          this.setGoldengooseTheme();
          this.setGoldenGoosePwaTitlebar();
          break;
        default:
          this.setWospeeTheme();
          this.setWospeePwaTitlebar();
          break;
      }      
    } else {
      if (this.isPwa) {
        this.setGoldengooseTheme();
      } else {
        this.setWospeeTheme();
      }
    }
  }

  setDefaultDarkTheme() {
    var favicon = document.querySelector("link[id=favicon]");
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.length > 1) body.classList.remove(body.classList[1])
    body.classList.add("default-theme-dark");
    favicon?.setAttribute("href", "assets/icon/iconWospeeNewAlt.jpg");
    this.currentAccent = this.defaultDarkAccent;
  }
  
  setDefaultTheme() {
    var favicon = document.querySelector("link[id=favicon]");    
    favicon?.setAttribute("href", "assets/icon/iconWospeeNew.png");
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.length > 1) body.classList.remove(body.classList[1])
    var customTheme = localStorage.getItem("customTheme")
    if (customTheme != undefined && customTheme == "blue" && this.cognitoUser == "massimo.marino" && this._api.ENVIRONMENT < 5) {
      body.classList.add("blue-theme");
      this.currentAccent = this.blueAccent;
    } else {
      body.classList.add("default-theme");
      this.currentAccent = this.defaultAccent;
    }
  }

  setWospeeTheme() {
    var favicon = document.querySelector("link[id=favicon]");
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.length > 1) body.classList.remove(body.classList[1])
    body.classList.add("wospee-theme");
    favicon?.setAttribute("href", "assets/icon/iconWospeeNewAlt.jpg");
    this.currentAccent = this.wospeeAccent;
  }

  setGoldengooseTheme() {
    var favicon = document.querySelector("link[id=favicon]");
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.length > 1) body.classList.remove(body.classList[1])
    body.classList.add("goldengoose-theme");
    favicon?.setAttribute("href", "assets/icon/logoGoldenGooseAct.png");
    this.currentAccent = this.goldengooseAccent;
  }

  setWospeePwaTitlebar() {    
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    var appleThemeColor = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]");
    metaThemeColor?.setAttribute("content", "#00a75d");
    appleThemeColor?.setAttribute("content", "#00a75d");      
  }

  setDefaultPwaTitlebar() {    
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    var appleThemeColor = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]");
    metaThemeColor?.setAttribute("content", "#2a2a2a");
    appleThemeColor?.setAttribute("content", "#2a2a2a");      
  }

  setGoldenGoosePwaTitlebar() {    
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    var appleThemeColor = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]");
    metaThemeColor?.setAttribute("content", "#000000");
    appleThemeColor?.setAttribute("content", "#000000");
  }

}

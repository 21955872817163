import { Component, OnInit, Input } from '@angular/core';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'component-starteammemberbuttons',
  templateUrl: './starteammemberbuttons.component.html',
  styleUrls: ['./starteammemberbuttons.component.scss']
})
export class StarteammemberbuttonsComponent implements OnInit {
  
  @Input()
  context:any;
  @Input()
  selectYear:any;
  @Input()
  selectRegion:any;
  @Input()
  selectJob:any;
  @Input()
  selectManager:any;
  @Input()
  selectArea:any;
  @Input()
  selectLevel:any;
  @Input()
  componentType:any;

  constructor(private _api:ApiService, private  _snackbarManager:SnackbarmanagerService) { }

  ngOnInit(): void {
  }

  starMember() {
    this._api.changeOBGUID("wf-star-team-member", "" + this.selectYear.getFullYear());
  }
  
  starReport() {
    this._api.changeOBGUID("wf-star-report");
  }

  
  relevanceReport() {
    this._api.loading = true;
    var context = "/relevance-report";
    var context_data;
    if (this.componentType == "CP044" || this.componentType == "CP043") context_data = this.getContextDataRelevanceTalentMatrix();
    else context_data = this.getContextDataRelevanceDashboard();

    this._api.doButtonPostRequest(context_data, context).subscribe((response) => {      
      if(response) {        
        //this._api.printResponse(response);        
        //this._snackbarManager.open(JSON.parse(JSON.stringify(response)).DownloadReportMessage, 'ok');        
        //this._api.loading = false;   
      }
    }, (error) => {
      if(error) {
        //console.log(error);        
        //this._snackbarManager.open(error.error.message, 'ko');           
        //this._api.loading = false;
      }
      
    });  
    setTimeout(() => {
      this._api.loading = false;   
      this._snackbarManager.open("Your report procedure has been started.\nPlease see the output in the Reports function", 'ok');        
    }, 500);
  }

  
  getContextDataRelevanceTalentMatrix() {   
    let context_data = {
      "Year": this.selectYear.getFullYear(),
      "Area": "CT90999",
      "TPRUOL": this.selectJob,
      "GEGERE": this.selectManager,
      "TPAREC": this.selectArea,
      "Where": "", //query sql data dal livello
      "TPREGI": this.selectRegion,
      "TimeDelta" : new Date().getTimezoneOffset() //Intl.DateTimeFormat().resolvedOptions().timeZone
    }    

    if (context_data.TPRUOL != "ALL")
      context_data.Where = " AND (TPRUOL = \'" + this.selectJob + "\' OR TPRUOL IS NULL)"
    if (context_data.GEGERE != "ALL")
      context_data.Where += " AND ((GEGERX LIKE \'" + this.selectManager + "%\' AND GEGERX <> \'" + this.selectManager +"\') OR GEGERX IS NULL)";
    if (context_data.Area != "ALL")
      context_data.Where += " AND (TPAREC = \'" + this.selectArea + "\' OR TPAREC IS NULL)";
    if (this.selectLevel == "1")
      context_data.Where += " AND LENGTH(GEGERX) <> LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectLevel == "2")
      context_data.Where += " AND LENGTH(GEGERX) = LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectRegion == "ALL") {
      let regionList:any = [];
      this.context.RegionList.forEach((e:any) => {
        regionList.push(e.Elemento1);
      });
      context_data.TPREGI = regionList;
      //context_data.WHERE += " AND (TPREGI = '" + this.selectRegion + "' OR TPREGI IS NULL)";
    } else {
      context_data.TPREGI = this.selectRegion;
      context_data.Where += "and (TPREGI = \'" + this.selectRegion + "\' OR TPREGI IS NULL)"
    }
    
    return context_data;
  }

  getContextDataRelevanceDashboard() {
    let context_data = {
      "Year": "" + this.selectYear.getFullYear(),
      "Area": this._api.getUrlParameters().fmguid == "FA0009" ? "CT00010" : "CT00011",
      "TimeDelta" : new Date().getTimezoneOffset(), //Intl.DateTimeFormat().resolvedOptions().timeZone      
      "TPREGI": this.selectRegion,
      "TPRUOL": this.selectJob,
      "GEGERE": null,
      "TPAREC": this.selectArea
    }  
    return context_data
  }

}

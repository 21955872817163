<div class="main_div" *ngIf="!loggedIn && !pageAccess && init">

    <img class="homepage-logo" [@inOutAnimation] [src]="isPwa ? './assets/icon/logoGoldenGooseEsteso.png' : './assets/icon/Wospee_Logo_' + environmentCode + '.png'">

    <mat-card-content class="login-container" [@inOutAnimation] [style.max-width]="challengeRequired != '' || passwordReset == true || environmentCode < 6 ? '350px' : '700px'">

      <ng-container *ngIf="challengeRequired == '' && passwordReset == false">
        <div [class]="environmentCode >= 5 && (!auth.idpListDownloaded || (auth.idpListDownloaded && auth.idpList.length > 0)) ? 'login-wrap' : 'side-c'">
          <div class="side-sx" *ngIf="environmentCode >= 6">
            <p class="login-title">Log in with your<br>corporate ID</p>
            <mat-spinner *ngIf="!auth.idpListDownloaded" class="loader-mini-idp" diameter="28" color="accent"></mat-spinner>
            <mat-form-field *ngIf="auth.idpListDownloaded" appearance="fill" color="accent" [@inOutAnimation]>
              <mat-label>Choose...</mat-label>
              <mat-select (selectionChange)="federatedSignIn($event)" [disabled]="loggingIn">
                <mat-option *ngFor="let corporate of auth.idpList" [value]="corporate.id"> {{ corporate.id }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="latestuser-div" *ngIf="auth.lastIdpIndex != -1 && auth.idpListDownloaded && environmentCode >= 6" [@inOutAnimation]>
              <span>Latest user:</span> 
              <button mat-raised-button style="width:fit-content; margin-bottom: 24px;" (click)="federatedSignIn($event, auth.lastIdpIndex)" [disabled]="loggingIn" color="accent">{{auth.idpList[auth.lastIdpIndex].id}}</button> 
            </div>
          </div>

          <div class="divider" *ngIf="environmentCode >= 6"></div>

          <div [class]="environmentCode >= 6 ? 'side-dx' : ''">
            <form [formGroup]="loginForm" (ngSubmit)="submitLogin(loginForm.value)">
              <p class="login-title">Log In with your<br>username and password</p>
              <mat-form-field appearance="fill" color="accent" style="width:254px">
                <mat-label>Username</mat-label>
                <input autofocus cdkFocusInitial matInput #username name="username" formControlName="username">
              </mat-form-field>
              <mat-form-field appearance="fill" color="accent" style="width:254px">
                <mat-icon style="cursor:pointer" (mousedown)="hidePwd = false" (mouseup)="hidePwd = true" [color]="!hidePwd ? 'accent' : ''"  matSuffix>remove_red_eye</mat-icon>
                <mat-label>Password</mat-label>
                <input matInput #password [type]="hidePwd ? 'password' : 'text'" name="password" formControlName="password">
                <mat-hint *ngIf="environmentCode < 5" class="forgotpassword-span" (click)="forgotPassword()">Forgot password?</mat-hint>
              </mat-form-field>

              <button class="login_button" [disabled]="loggingIn" mat-raised-button color="accent" cdkFocusInitial>
                <mat-spinner *ngIf="loggingIn" class="loader-mini" diameter="28" color="accent"></mat-spinner>{{ !loggingIn ? 'Login' : '‎' }}
              </button>
            </form>
          </div>          
        </div>
    </ng-container>

    <ng-container *ngIf="challengeRequired == 'NEW_PASSWORD_REQUIRED'">
      <div class="side-c">
        <form [formGroup]="newPasswordForm" (ngSubmit)="submitPassword(newPasswordForm.value)">
          <p class="login-title">Please set a new password</p>
            <ul style="text-align:left">
              <li>At least 8 characters long</li>
              <li>Contains at least 1 number, 1 lowercase letter, 1 uppercase letter and 1 special character</li>
            </ul>
          <mat-form-field appearance="fill" color="accent">
            <mat-icon style="cursor:pointer" (mousedown)="hidePwd1 = false" (mouseup)="hidePwd1 = true" [color]="!hidePwd1 ? 'accent' : ''" matSuffix>remove_red_eye</mat-icon>
            <mat-label>New password</mat-label>
            <input matInput cdkFocusInitial #password1 [type]="hidePwd1 ? 'password' : 'text'" name="newPassword" formControlName="newPassword" required>
          </mat-form-field>
          <mat-form-field appearance="fill" color="accent">
            <mat-icon style="cursor:pointer" (mousedown)="hidePwd2 = false" (mouseup)="hidePwd2 = true" [color]="!hidePwd2 ? 'accent' : ''" matSuffix>remove_red_eye</mat-icon>
            <mat-label>Retype new password</mat-label>
            <input matInput #password2 [type]="hidePwd2 ? 'password' : 'text'" name="newPasswordTest" formControlName="newPasswordTest" required>
          </mat-form-field>

          <button class="login_button" [disabled]="loggingIn || newPasswordForm.value.newPassword != newPasswordForm.value.newPasswordTest || newPasswordForm.value.newPassword == challengeUser.password || newPasswordForm.value.newPasswordTest == challengeUser.password" mat-raised-button color="accent" cdkFocusInitial>
            <mat-spinner *ngIf="loggingIn" class="loader-mini" diameter="28" color="accent"></mat-spinner>{{ !loggingIn ? 'Submit' : '‎' }}
          </button>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="passwordReset && !codeSent">
      <button mat-mini-fab color="accent" class="back-button" (click)="passwordReset = false">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="side-c">
        <form [formGroup]="passwordResetForm" (ngSubmit)="submitUsername(passwordResetForm.value)">
          <p class="login-title">Please insert your username to<br>reset your password</p>
          <mat-form-field appearance="fill" color="accent">
            <mat-label>Username</mat-label>
            <input autofocus cdkFocusInitial matInput #username1 name="username" formControlName="username" required>
          </mat-form-field>

          <button class="login_button" [disabled]="loggingIn || passwordResetForm.value.username == ''" mat-raised-button color="accent" cdkFocusInitial>
            <mat-spinner *ngIf="loggingIn" class="loader-mini" diameter="28" color="accent"></mat-spinner>{{ !loggingIn ? 'Send' : '‎' }}
          </button>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="passwordReset && codeSent">
      <div class="side-c">
        <form [formGroup]="passwordResetForm2" (ngSubmit)="submitCodePassword(passwordResetForm2.value)">
          <p class="login-title">Please check your email for the verification code<br>and insert a new password</p>
          <mat-form-field appearance="fill" color="accent">
            <mat-label>Code</mat-label>
            <input autofocus matInput #code name="code" formControlName="code" required>
          </mat-form-field>
          <mat-form-field appearance="fill" color="accent">
            <mat-icon style="cursor:pointer" (mousedown)="hidePwd1 = false" (mouseup)="hidePwd1 = true" [color]="!hidePwd1 ? 'accent' : ''" matSuffix>remove_red_eye</mat-icon>
            <mat-label>New password</mat-label>
            <input matInput cdkFocusInitial #password1 [type]="hidePwd1 ? 'password' : 'text'" name="newPassword" formControlName="newPassword" required>
          </mat-form-field>
          <mat-form-field appearance="fill" color="accent">
            <mat-icon style="cursor:pointer" (mousedown)="hidePwd2 = false" (mouseup)="hidePwd2 = true" [color]="!hidePwd2 ? 'accent' : ''" matSuffix>remove_red_eye</mat-icon>
            <mat-label>Retype new password</mat-label>
            <input matInput #password2 [type]="hidePwd2 ? 'password' : 'text'" name="newPasswordTest" formControlName="newPasswordTest" required>
          </mat-form-field>

          <button class="login_button" [disabled]="loggingIn || passwordResetForm2.value.code == ''|| passwordResetForm.value.newPassword != passwordResetForm.value.newPasswordTest" mat-raised-button color="accent" cdkFocusInitial>
            <mat-spinner *ngIf="loggingIn" class="loader-mini" diameter="28" color="accent"></mat-spinner>{{ !loggingIn ? 'Submit' : '‎' }}
          </button>
        </form>
      </div>
    </ng-container>

    </mat-card-content>

    <span class="version_span">{{version}} - {{environmentCode == 1 ? 'Staging' : (environmentCode == 3 ? 'Showcase' : (environmentCode == 5 ? 'Production' : '???'))}} 
      <a *ngIf="environmentCode < 5" class="version_span" href="https://w3.dp-wospee.com">(Go to Prod)</a>
    </span>
    
</div>

<app-page *ngIf="loggedIn"></app-page>

import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import { ApiService } from '../../services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { ExcelexporterService } from 'src/app/services/excelexporter.service';
 

@Component({
  selector: 'component-wfstarteammember',
  templateUrl: './wfstarteammember.component.html',
  styleUrls: ['./wfstarteammember.component.scss']
})
export class WfstarteammemberComponent implements OnInit {

  @Input() context: any;
  selectedYear: any;

  @ViewChild('dt1') table1: Table | undefined;
  columns:any = []

  loading: boolean = true
  multiSortMeta:SortMeta[] = [];
  excelRows: any;

  selectedRow:any = {}
  selectedRowBak:any = {}
  starMemberOpened:boolean = false;
  starStatus:boolean = false;
  translateEnabled:boolean = false;

  isStarReport:boolean = false;

  constructor(private _api:ApiService, private _excel:ExcelexporterService, private  _snackbarManager:SnackbarmanagerService) { }

  ngOnInit(): void {    
    var urlParams = this._api.getUrlParameters();    
    if (urlParams.obguid == "wf-star-report") this.isStarReport = true;

    //fare una struttura dati fatta bene per le colonne serve per il sort e il filter
    var i = 0;
    var max = this.context.Columns.length;
    if (!this.isStarReport) {
      i = 1;
      max -= 1;
    }
    for (i; i < max; i++) {
      if (!this.isStarReport) {
        this.columns.push({field: "Elemento" + (i + 3), header: this.context.Columns[i]});
      } else {
        this.columns.push({field: "Elemento" + (i + 3), header: this.context.Columns[i]});
      }
    }
    //console.log(this.columns)

    this.multiSortMeta.push({field: '', order: 1});
    this.loading = false;    

    if (urlParams.obguidParam != "") {
      this.selectedYear = urlParams.obguidParam;
    } else {
      this.selectedYear = "";
    }
  }

  view(obguid:any) {
    this._api.changeFunction(this.context.function, obguid);
  }

  toggleStar() {
    this.selectedRow.Elemento6 = !this.selectedRow.Elemento6;
  }

  toggleTranslation() {
    this.translateEnabled = !this.translateEnabled;
  }

  openMemberCard(row:any) {
    if (!this.isStarReport) {
      this.selectedRowBak = JSON.parse(JSON.stringify(row));
      this.selectedRow = row;
      this.starMemberOpened = true;
      this.starStatus = row.Elemento6;
      this.toggleDrawerContentZindex(true);
    }
  }

  closeMemberCard() {
    this.starMemberOpened = false;  
    this.starStatus = false;
    if (this.selectedRowBak != this.selectedRow) {
      //console.log(this.selectedRow)
      //console.log(this.selectedRowBak)
      this.selectedRow.Elemento6 = this.selectedRowBak.Elemento6;  
      this.selectedRow.Elemento7 = this.selectedRowBak.Elemento7;  
      this.selectedRow = {};
      this.selectedRowBak = {};
    }
    if (!this.selectedRow.Elemento6 && this.selectedRow.Elemento7 != "") {
      this.selectedRow.Elemento7 = "";
    }
    this.toggleDrawerContentZindex(false);
  }

  toggleDrawerContentZindex(isAbove:boolean) {
    var myCss = ".mat-drawer-content { z-index: " + (isAbove ? "2" : "1") + "}";
    var style = document.createElement("STYLE");
    style.innerText = myCss;
    document.body.appendChild(style);
  }

  confirmStar() {
    this._api.loading = true;
    console.log(this.selectedRow.Elemento7)
    var context_data = {
      SRSTAR : this.selectedRow.Elemento6,
      SRGUID : this.selectedRow.Elemento1,
      PEGUID : this.selectedRow.Elemento8,
      SRNOTA : this.selectedRow.Elemento7,
      YEAR : this.selectedYear
    };

    this._api.doButtonPostRequest(context_data, this.context.StarYourTeamMemberLambda).subscribe((response) => {
      if(response) {
        this._api.printResponse(response);
        this.selectedRowBak = this.selectedRow;
        this._snackbarManager.open(this.context.StarYourTeamMemberSaveSuccess, 'ok');
        this._api.loading = false;
        this.closeMemberCard();
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this._api.loading = false;
        this._snackbarManager.open(this.context.StarYourTeamMemberSaveFailure, 'ko');
      }
    });

  }
  
  exportTable() {
    console.log(this.selectedYear)
    this._excel.export(this.selectedYear != "" ? this.context.StarYourTeamMemberTitle : this.context.StarReportTitle, this.columns, this.context.Data)
  }

}

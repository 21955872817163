import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthenticationGuard } from './authentication.guard';

import { PageComponent } from './page/page.component';


const routes: Routes = [
  { path: '', component: AppComponent },      
  { path: ':app', component: PageComponent, canActivate:[AuthenticationGuard] },
  { path: ':app/:ten', component: PageComponent, canActivate:[AuthenticationGuard] },
  { path: ':app/:ten/:mod', component: PageComponent, canActivate:[AuthenticationGuard] },
  { path: ':app/:ten/:mod/:fun', component: PageComponent, canActivate:[AuthenticationGuard] },
  { path: ':app/:ten/:mod/:fun/:obx', component: PageComponent, canActivate:[AuthenticationGuard] },
  { path: ':app/:ten/:mod/:fun/:obx/:wfx', component: PageComponent, canActivate:[AuthenticationGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div *ngIf="compareCard" class="card-background">
    <mat-card class="mat-card-popup">        
        <mat-card-content class="confirm-card-content">            
            {{context.MessageProcessSaveConfirm}}
            <br>
            <table class="changes-table">
                <tr>
                    <th>
                        {{context.Column[0]}}:
                    </th>
                    <td>
                        {{newProcess ? convertDateToMoment(startDate).format('YYYY-MM-DD') : context.Row[selectedRowIndex].Elemento2}}
                    </td>
                    <td *ngIf="!newProcess">
                        {{convertDateToMoment(startDate).format('YYYY-MM-DD') == context.Row[selectedRowIndex].Elemento2 ? '-' : convertDateToMoment(startDate).format('YYYY-MM-DD')}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{context.Column[1]}}:
                    </th>
                    <td>
                        {{newProcess ? convertDateToMoment(endDate).format('YYYY-MM-DD') : context.Row[selectedRowIndex].Elemento3}}
                    </td>
                    <td *ngIf="!newProcess">
                        {{convertDateToMoment(endDate).format('YYYY-MM-DD') == context.Row[selectedRowIndex].Elemento3 ? '-' : convertDateToMoment(endDate).format('YYYY-MM-DD')}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{context.Column[4]}}:
                    </th>
                    <td>
                        {{newProcess ? group : context.Row[selectedRowIndex].Elemento8}}
                    </td>
                    <td *ngIf="!newProcess">
                        {{group ==  context.Row[selectedRowIndex].Elemento8 ? '-' : group}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{context.Column[3]}}:
                    </th>
                    <td>
                        {{newProcess ? template : context.Row[selectedRowIndex].Elemento6}}
                    </td>
                    <td *ngIf="!newProcess">
                        {{template == context.Row[selectedRowIndex].Elemento6 ? '-' : template}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{context.Column[2]}}:
                    </th>
                    <td>
                        {{newProcess ? description : context.Row[selectedRowIndex].Elemento4}}
                    </td>
                    <td *ngIf="!newProcess">
                        {{description == context.Row[selectedRowIndex].Elemento4 ? '-' : description}}
                    </td>
                </tr>
            </table>

            <div class="card-buttons">
                <button mat-raised-button color="basic" (click)="closeCard()">{{context.MessageProcessAbortButton}}</button>
                <button mat-raised-button class="table-button" color="primary" (click)="save(0)">
                    {{context.MessageProcessConfirmButton}}</button>
            </div>

        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="confirmStartStopCard || confirmExitCard" class="card-background">
    <mat-card class="mat-card-popup">
        <mat-card-content *ngIf="confirmStartStopCard" class="confirm-card-content">            
            {{status == 0 ? context.MessageStartConfirm : context.MessageStopConfirm}}
            <br>
            <div class="card-buttons">
                <button mat-raised-button color="basic" (click)="closeCard()">{{context.MessageProcessAbortButton}}</button>
                <button mat-raised-button class="table-button" color="primary" (click)="save(1)">
                    {{context.MessageProcessConfirmButton}}</button>
            </div>
        </mat-card-content>
        <mat-card-content *ngIf="confirmExitCard" class="confirm-card-content">
            {{context.MessageAbortSaveProcess}}
            <br>
            <div class="card-buttons">
                <button mat-raised-button color="basic" (click)="closeCard()">{{context.MessageProcessAbortButton}}</button>
                <button mat-raised-button class="table-button" color="primary" (click)="toggleDisplaySection(false, false)">
                    {{context.MessageProcessConfirmButton}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="confirmStartStopDeleteCard" class="card-background">
    <mat-card class="mat-card-popup">        
        <mat-card-content *ngIf="confirmStartStopDeleteCard" class="confirm-card-content">            
            {{singleprocessPressedLambda == context.ButtonSingleStartLambda ? context.MessageStartConfirm : ''}}
            {{singleprocessPressedLambda == context.ButtonSingleStopLambda ? context.MessageStopConfirm : ''}}
            {{singleprocessPressedLambda == context.ButtonSingleDeleteLambda ? context.MessageDeleteConfirm : ''}}
            <br>
            <div class="card-buttons">
                <button mat-raised-button class="table-button abort-button" color="basic" (click)="closeCard()">{{context.MessageProcessAbortButton}}</button>
                <button *ngIf="singleprocessPressedLambda == context.ButtonSingleStartLambda" mat-raised-button class="table-button" color="primary"
                (click)="manageSingleuser(context.ButtonSingleStartLambda)">
                    {{context.MessageProcessConfirmButton}}
                </button>
                <button *ngIf="singleprocessPressedLambda == context.ButtonSingleStopLambda" mat-raised-button class="table-button" color="primary"
                (click)="manageSingleuser(context.ButtonSingleStopLambda)">
                    {{context.MessageProcessConfirmButton}}
                </button>
                <button *ngIf="singleprocessPressedLambda == context.ButtonSingleDeleteLambda" mat-raised-button class="table-button" color="primary"
                (click)="manageSingleuser(context.ButtonSingleDeleteLambda)">
                    {{context.MessageProcessConfirmButton}}
                </button>
            </div>
        </mat-card-content>
        <mat-card-content *ngIf="confirmExitCard" class="confirm-card-content">
            {{context.MessageAbortSaveProcess}}
            <br>
            <div class="card-buttons">
                <button mat-raised-button color="basic" (click)="closeCard()">{{context.MessageProcessAbortButton}}</button>
                <button mat-raised-button class="table-button" color="primary" (click)="toggleDisplaySection(false, false)">
                    {{context.MessageProcessConfirmButton}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="confirmPublishCard" class="card-background">
    <mat-card class="mat-card-popup">        
        <mat-card-content class="confirm-card-content"> 
            <div class="parent-container"> 
                <div *ngIf="contextPublish.tableDel != undefined" class="child-container-1">
                    
                    <div class="publish-table-title">{{contextPublish.tableDel.Title}}</div>

                    <p-table [rowHover]="false" [rows]="10" [showCurrentPageReport]="true" [loading]="loading" responsiveLayout="scroll" [paginator]="true" [scrollable]="false"
                        [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" styleClass="p-datatable-sm"
                        [columns]="contextPublish.tableDel.Column" [value]="contextPublish.tableDel.Rows" sortMode="multiple" [multiSortMeta]="multiSortMeta"
                        [globalFilterFields]="['Elemento3', 'Elemento4', 'Elemento5']">

                        <ng-template pTemplate="header">
                            <tr>
                                <th class="column-header" *ngFor="let col of columnsPublishDel" [pSortableColumn]="col.field"
                                    pResizableColumn class="medium-column">
                                    <span class="column-title">
                                        {{col.header}}                                        
                                        <span class="sort-icons" id="hideIcons">
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-rowData let-rowIndex>
                            <tr class="p-selectable-row" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                                <td>
                                    <span class="table-data"> {{ rowData['Elemento3'] }}</span>
                                </td>
                                <td>
                                    <span class="table-data"> {{ rowData['Elemento4'] }}</span>
                                </td>
                                <td>
                                    <span class="table-data"> {{ rowData['Elemento5'] }}</span>
                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                </div>

                <div *ngIf="contextPublish.tableIns != undefined"  class="child-container-2">

                    <div class="publish-table-title">{{contextPublish.tableIns.Title}}</div>

                    <p-table [rowHover]="false" [rows]="10" [showCurrentPageReport]="true" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
                        [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" styleClass="p-datatable-sm"
                        [columns]="contextPublish.tableIns.Column" [value]="contextPublish.tableIns.Rows" sortMode="multiple" [multiSortMeta]="multiSortMeta"
                        [globalFilterFields]="['Elemento3', 'Elemento4', 'Elemento5']">

                        <ng-template pTemplate="header">
                            <tr>
                                <th class="column-header" *ngFor="let col of columnsPublishIns" [pSortableColumn]="col.field"
                                    pResizableColumn class="medium-column">
                                    <span class="column-title">
                                        {{col.header}}
                                        <span class="sort-icons" id="hideIcons">
                                            <p-columnFilter type="text" [field]="col.field" display="menu" class="ml-auto"></p-columnFilter>
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-rowData let-rowIndex>
                            <tr class="p-selectable-row" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                                <td>
                                    <span class="table-data"> {{ rowData['Elemento2'] }}</span>
                                </td>
                                <td>
                                    <span class="table-data"> {{ rowData['Elemento3'] }}</span>
                                </td>
                                <td>
                                    <span class="table-data"> {{ rowData['Elemento4'] }}</span>
                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                </div>
            </div>

            <div class="card-buttons">
                <button mat-raised-button color="basic" (click)="closeCard()">{{context.MessageProcessAbortButton}}</button>
                <button mat-raised-button *ngIf="columnsPublishDel.length > 0" color="primary" (click)="save(3)">
                    {{contextPublish.ButtonOKwoDel}}
                </button>
                <button mat-raised-button *ngIf="columnsPublishIns.length > 0" color="primary" (click)="save(2)">
                    {{contextPublish.ButtonOK}}
                </button>
            </div>
            
        </mat-card-content>
    </mat-card>
</div>



<ng-container *ngIf="actorsSection">
    
    <mat-card>
        <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card"> 
            <div class="back-button-div">
                
            </div>
            ... > {{contextEmployees.Title}} > {{contextActors.tablePhaseList.Title}}
        </mat-card-title>
        <mat-card-content class="mat-card-content">

            <p-table [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
                [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" styleClass="p-datatable-sm"
                [columns]="contextActors.tablePhaseList.Column" [value]="contextActors.tablePhaseList.Rows" sortMode="multiple" [multiSortMeta]="multiSortMeta"
                [globalFilterFields]="['Elemento4', 'Elemento5']"
                selectionMode="single" (onRowSelect)="selectRow($event, 2)" (onRowUnselect)="unselectRow($event, 2)">

                <ng-template pTemplate="caption">
                    <div class="table-header">
                        <div class="table-buttons">
                            <button class="back-button" (click)="toggleActorSection()" mat-mini-fab color="accent">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                            <button class="table-button" [matTooltip]="contextActors.tableChangeActor.ButtonChangeActor"
                                    mat-raised-button (click)="toggleChangeActor()"
                                    [disabled]="selectedActor == undefined ? true : false"
                                    color="accent"> <!--[disabled]="selectedEmployee == undefined ? true : (selectedEmployee.Elemento5 == '' ? false : true)-->
                                <mat-icon>create</mat-icon>
                                <span class="button-label"> {{contextActors.tableChangeActor.ButtonChangeActor}} </span>
                            </button>
                        </div>
                        <div class="changeactor-formfield" [style.display]="changeActor ? (selectedActor != undefined ? 'flex' : 'none') : 'none'">
                            <mat-form-field appearance="fill" color="accent">
                                <mat-label>Actor</mat-label>
                                <mat-select [(value)]="currentActorName">
                                <mat-option *ngFor="let a of contextActors.tableChangeActor.Rows" [value]="a.Elemento2">
                                    {{a.Elemento2}}
                                </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button class="table-button" [matTooltip]="contextActors.tableChangeActor.ButtonSave"
                                    mat-raised-button (click)="manageSingleuser(contextActors.tableChangeActor.ButtonSaveLambda)"
                                    [disabled]="selectedActor == undefined ? true : false"
                                    color="accent"> <!--[disabled]="selectedEmployee == undefined ? true : (selectedEmployee.Elemento5 == '' ? false : true)-->
                                <mat-icon>save</mat-icon>
                                <span class="button-label" style="height:48px"> {{contextActors.tableChangeActor.ButtonSave}} </span>
                            </button>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th class="column-header" *ngFor="let col of columnsActors" [pSortableColumn]="col.field"
                            pResizableColumn class="medium-column">
                            <span class="column-title">
                                {{col.header}}                                
                                <span class="sort-icons" id="hideIcons">
                                    <p-columnFilter type="text" [field]="col.field" display="menu" class="ml-auto"></p-columnFilter>
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </span>
                            </span>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-rowIndex>
                    <tr class="p-selectable-row" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                        <td class="medium-cell">
                            <span class="table-data"> {{ rowData['Elemento4'] }}</span>
                        </td>
                        <td class="medium-cell">
                            <span class="table-data"> {{ rowData['Elemento5'] }}</span>
                        </td>

                        <td *ngIf="contextActors.tableChangeActor.Rows.length == 0">
                            {{contextActors.tableChangeActor.Placeholder}}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </mat-card-content>
    </mat-card>
</ng-container>


<ng-container *ngIf="employeesSection && !actorsSection">
   
    <mat-card>
        <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card"> 
            <div class="back-button-div">
                
            </div>
            ... > {{context.ButtonViewProcessContent}} > {{contextEmployees.Title}}
        </mat-card-title>
        <mat-card-content class="mat-card-content">

            <div class="disclaimer">{{context.DisclaimerResults}}</div>

            <p-table #dtEmployees [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
                [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" styleClass="p-datatable-sm"
                [columns]="contextEmployees.Column" [value]="contextEmployees.Rows" sortMode="multiple" [multiSortMeta]="multiSortMeta"
                [globalFilterFields]="['Elemento9', 'Elemento10', 'Elemento11', 'Elemento12']"
                selectionMode="single" (onRowSelect)="selectRow($event, 1)" (onRowUnselect)="unselectRow($event, 1)">

                <ng-template pTemplate="caption">
                    <div class="table-header">
                        <div class="table-buttons">
                            <button class="back-button" (click)="toggleEmployeeSection()" mat-mini-fab color="accent">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                            <button class="table-button" [matTooltip]="context.TooltopStartProcessContent"
                                    mat-raised-button (click)="openCard(4, context.ButtonSingleStartLambda)"
                                    [disabled]="selectedEmployee == undefined ? true : false"

                                    color="accent"> <!--[disabled]="selectedEmployee == undefined ? true : (selectedEmployee.Elemento5 == '' ? false : true)-->
                                <mat-icon>play_arrow</mat-icon>
                                <span class="button-label"> {{context.ButtonStartProcessContent}} </span>
                            </button>
                            <button class="table-button" [matTooltip]="context.TooltipStopProcessContent"
                                    mat-raised-button (click)="openCard(4, context.ButtonSingleStopLambda)"
                                    [disabled]="selectedEmployee == undefined ? true : false"
                                    color="accent">
                                <mat-icon>stop</mat-icon>
                                <span class="button-label"> {{context.ButtonStopProcessContent}} </span>
                            </button>
                            <button class="table-button" [matTooltip]="context.TooltipDeleteProcessContent"
                                    mat-raised-button (click)="openCard(4, context.ButtonSingleDeleteLambda)"
                                    [disabled]="selectedEmployee == undefined ? true : false"
                                    color="accent">
                                <mat-icon>delete</mat-icon>
                                <span class="button-label"> {{context.ButtonDeleteProcessContent}} </span>
                            </button>
                            <button class="table-button" [matTooltip]="context.TooltipActorProcessContent"
                                    mat-raised-button (click)="manageSingleuser(context.ButtonSingleActorLambda)"
                                    [disabled]="selectedEmployee == undefined ? true : false"
                                    color="accent">
                                <mat-icon>person</mat-icon>
                                <span class="button-label"> {{ context.ButtonActorProcessContent }} </span>
                            </button>
                        </div>

                        <div class="search-div">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText (input)="dtEmployees.filterGlobal($any($event.target).value,'contains')" placeholder="Search" />
                            </span>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th class="column-header" *ngFor="let col of columnsEmployees" [pSortableColumn]="col.field"
                            pResizableColumn [class]="col.header == 'Note' ? 'long-column' : 'medium-column'">
                            <span class="column-title">
                                {{col.header}}
                                <span class="sort-icons" id="hideIcons">
                                    <p-columnFilter type="text" [field]="col.field" display="menu" class="ml-auto"></p-columnFilter>
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </span>
                            </span>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-rowIndex>
                    <tr class="p-selectable-row" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                        <td class="medium-cell">
                            <span class="table-data"> {{ rowData['Elemento9'] }}</span>
                        </td>
                        <td class="medium-cell">
                            <span class="table-data"> {{ rowData['Elemento10'] }}</span>
                        </td>
                        <td class="medium-cell">
                            <span class="table-data"> {{ rowData['Elemento11'] }}</span>
                        </td>
                        <td class="long-cell" style="width:50%">
                            <span class="table-data"> {{ rowData['Elemento12'] }}</span>
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </mat-card-content>
    </mat-card>
</ng-container>




<ng-container *ngIf="displaySection && !employeesSection">
    <mat-card>
        <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
            <div class="back-button-div">
                
            </div>
            {{context.Title}} > {{ newProcess ? context.ButtonNewProcessContent : (editMode ? context.ButtonChangeProcessContent : context.ButtonViewProcessContent)}}
        </mat-card-title>
        <mat-card-content class="mat-card-content">
            <div class="display-section-button-div">
                <div class="table-buttons">
                    <button class="back-button" *ngIf="context.ButtonUserListProcessContent != ''" mat-mini-fab color="accent"
                            (click)="!newProcess ? (checkChanges() ? openCard(3) : toggleDisplaySection(false, false)) : toggleDisplaySection(false, false)">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <button class="table-button" *ngIf="editMode && !newProcess && context.ButtonDisplayProcessContent != ''" matTooltip="Back"
                            mat-raised-button (click)="toggleEditMode()" color="accent">
                        <mat-icon>undo</mat-icon>
                        <span class="button-label"> Undo </span>
                    </button>

                    <button class="table-button" *ngIf="newProcess && context.ButtonNewProcessContent != ''" [matTooltip]="context.TooltipChangeProcessContent"
                            mat-raised-button (click)="openCard(0)" color="accent">
                        <mat-icon>save</mat-icon>
                        <span *ngIf="newProcess" class="button-label"> {{context.ButtonNewProcessContent}}  </span>
                    </button>

                    <button class="table-button" *ngIf="!newProcess && context.ButtonChangeProcessContent != ''" [matTooltip]="context.TooltipChangeProcessContent"
                            mat-raised-button (click)="editMode ? openCard(0) : toggleEditMode()" [disabled]="selectedRows.length == 1 ? false : true"
                            color="accent">
                        <mat-icon *ngIf="!editMode">create</mat-icon>
                        <mat-icon *ngIf="editMode">save</mat-icon>
                        <span *ngIf="!editMode" class="button-label"> {{context.ButtonChangeProcessContent}} </span>
                        <span *ngIf="editMode" class="button-label"> {{context.ButtonSaveProcessContent}}  </span>
                    </button>

                    <button class="table-button" *ngIf="editMode && !newProcess && context.ButtonPublishProcessContent != ''" [matTooltip]="context.TooltipPublishProcessContent"
                            mat-raised-button (click)="publish()" color="accent">
                        <mat-icon>publish</mat-icon>
                        <span class="button-label"> {{context.ButtonPublishProcessContent}} </span>
                    </button>

                    <button class="table-button" *ngIf="editMode && !newProcess && context.ButtonChangeProcessContent != ''" [matTooltip]="status == 0 ? context.TooltipStartProcessContent : context.TooltipStopProcessContent"
                            mat-raised-button (click)="openCard(1)" color="accent">
                        <mat-icon *ngIf="selectedRows[0].Elemento9 == 0">play_arrow</mat-icon>
                        <mat-icon *ngIf="selectedRows[0].Elemento9 == 1">stop</mat-icon>
                        <span *ngIf="selectedRows[0].Elemento9 == 0" class="button-label"> {{context.ButtonStartProcessContent}} </span>
                        <span *ngIf="selectedRows[0].Elemento9 == 1" class="button-label"> {{context.ButtonStopProcessContent}} </span>
                    </button>

                    <button class="table-button" *ngIf="!newProcess && context.ButtonUserListProcessContent != ''" (click)="listEmployees()" [matTooltip]="context.TooltipUserListProcessContent"
                            mat-raised-button color="accent">
                        <mat-icon>people</mat-icon>
                        <span class="button-label"> {{ context.ButtonUserListProcessContent }} </span>
                    </button>

                </div>

                <div class="display-form-div">
                    <mat-form-field class="formfield" appearance="fill">
                        <mat-label>{{context.Column[0]}}</mat-label>
                        <input class="formfield-text" [disabled]="!editMode" matInput [(ngModel)]="startDate" [matDatepicker]="picker1"/>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="formfield" appearance="fill">
                        <mat-label>{{context.Column[1]}}</mat-label>
                        <input class="formfield-text" [disabled]="!editMode" matInput [(ngModel)]="endDate" [matDatepicker]="picker2"/>
                        <mat-datepicker-toggle matSuffix [for]="picker2" ></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="formfield" appearance="fill">
                        <mat-label>{{context.Column[3]}}</mat-label>
                        <mat-select class="formfield-text" [disabled]="!editMode" [(ngModel)]="template" [(value)]="template">
                            <mat-option *ngFor="let e of context.Template" [value]="e.Text">
                                {{e.Text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="formfield" appearance="fill">
                        <mat-label>{{context.Column[4]}}</mat-label>
                        <mat-select class="formfield-text" [disabled]="!editMode" [(ngModel)]="group" [(value)]="group">
                            <mat-option *ngFor="let e of context.Gruppo" [value]="e.Text">
                                {{e.Text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <table>
                        <tr>
                            <td class="description-cell">
                                <mat-form-field class="formfield" style="width:100%" appearance="fill">
                                    <mat-label>{{context.Column[2]}}</mat-label>
                                    <input class="formfield-text" [disabled]="!editMode || translatedDescription" [(ngModel)]="description" matInput
                                            [value]="newProcess ? description : (translatedDescription ? selectedRows[0].Elemento10 : description)">
                                </mat-form-field>
                                <button *ngIf="!newProcess && selectedRows[0].Elemento10 != description && selectedRows[0].Elemento10 != '' && !editMode" mat-raised-button color="accent"
                                        [class]="translatedDescription ? 'translate-button' : ''" (click)="translatedDescription = !translatedDescription">
                                    {{context.TranslateAWS}}
                                </button>
                            </td>
                            <td class="status-cell">
                                <span class="status-span" >{{context.Column[5]}}</span>
                                <mat-icon *ngIf="status == 1" style="color:#00aa00;">check_circle</mat-icon>
                                <mat-icon *ngIf="status == 0" style="color:#555555">indeterminate_check_box</mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>

        </mat-card-content>
    </mat-card>
</ng-container>




<ng-container *ngIf="!displaySection">
    <mat-card>
        <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
           {{context.Title}}
        </mat-card-title>
        <mat-card-content class="mat-card-content">

            <div class="disclaimer">{{context.DisclaimerResults}}</div>

            <p-table #dt [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" (onFilter)="onFilter($event)"
                [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
                [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" styleClass="p-datatable-sm"
                [columns]="context.Column" [value]="context.Row" sortMode="multiple" [multiSortMeta]="multiSortMeta"
                [globalFilterFields]="['Elemento2', 'Elemento3', 'Elemento4', 'Elemento6', 'Elemento8', 'Elemento9']"
                selectionMode="single" (onRowSelect)="selectRow($event, 0)" (onRowUnselect)="unselectRow($event, 0)">

                <ng-template pTemplate="caption">
                    <div class="table-header">
                        <div class="table-buttons">                        
                            <button class="table-button" *ngIf="context.ButtonNewProcessContent != ''" [matTooltip]="context.TooltipNewProcessContent"
                                    mat-raised-button (click)="toggleDisplaySection(true, true)" [disabled]="selectedRows.length == 0 ? false : true"
                                    color="accent">
                                <mat-icon>add_circle</mat-icon>
                                <span class="button-label"> {{context.ButtonNewProcessContent}} </span>
                            </button>
                            <button class="table-button" *ngIf="context.ButtonViewProcessContent != ''" [matTooltip]="context.TooltipViewProcessContent"
                                    mat-raised-button (click)="toggleDisplaySection(false, false)" [disabled]="selectedRows.length == 1 ? false : true"
                                    color="accent">
                                <mat-icon>remove_red_eye</mat-icon>
                                <span class="button-label"> {{context.ButtonViewProcessContent}} </span>
                            </button>
                            <button class="table-button" *ngIf="context.ButtonChangeProcessContent != ''" [matTooltip]="context.TooltipChangeProcessContent"
                                    mat-raised-button (click)="toggleDisplaySection(true, false)" [disabled]="selectedRows.length == 1 ? false : true"
                                    color="accent">
                                <mat-icon>create</mat-icon>
                                <span class="button-label"> {{context.ButtonChangeProcessContent}} </span>
                            </button>
                            <button class="table-button" *ngIf="context.ButtonExcelExportContent != ''" (click)="exportTable(filteredRows.length == 0 ? context.Row : filteredRows)" [matTooltip]="context.TooltipExcelExportContent"
                                    mat-raised-button color="accent">
                                <mat-icon>table_chart</mat-icon>
                                <span class="button-label"> {{ context.ButtonExcelExportContent }} </span>
                            </button>
                        </div>

                        <div class="search-div">
                            <span class="p-input-icon-right searchbox-span">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" placeholder="Search" />
                            </span>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th class="column-header" *ngFor="let col of columns" [pSortableColumn]="col.field"
                            pResizableColumn [class]="col.header.includes('Date') ? 'shortalt-column' :
                                                    col.header == 'Status' ? 'short-column' :
                                                    col.header == 'Description' ? 'long-column' : 'medium-column'">
                            <span class="column-title">
                                {{col.header}}                                
                                <span class="sort-icons" id="hideIcons">
                                    <p-columnFilter type="text" [field]="col.field" display="menu" class="ml-auto"></p-columnFilter>
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </span>
                            </span>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-rowIndex>
                    <tr class="p-selectable-row" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                        <td class="shortalt-cell">
                            <span class="table-data"> {{ rowData['Elemento2'] }}</span>
                        </td>
                        <td class="shortalt-cell">
                            <span class="table-data"> {{ rowData['Elemento3'] }}</span>
                        </td>
                        <td class="long-cell" style="width:50%">
                            <span class="table-data"> {{ rowData['Elemento4'] }}</span>
                        </td>
                        <td class="medium-cell">
                            <span class="table-data"> {{ rowData['Elemento6'] }}</span>
                        </td>
                        <td class="medium-cell">
                            <span class="table-data"> {{ rowData['Elemento8'] }}</span>
                        </td>
                        <td class="short-cell">
                            <span *ngIf="rowData['Elemento9'] == 1" style="color:#00aa00;">
                                <mat-icon>check_circle</mat-icon>
                            </span>
                            <span *ngIf="rowData['Elemento9'] == 0" style="color:#555555">
                                <mat-icon>indeterminate_check_box</mat-icon>
                            </span>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr *ngIf="context.Row.length == 0" >
                        <td class="nodata-cell">
                            {{context.NoDataFound}}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </mat-card-content>
    </mat-card>
</ng-container>

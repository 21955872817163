import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'component-cp024',
  templateUrl: './cp024.component.html',
  styleUrls: ['./cp024.component.scss']
})
export class Cp024Component implements OnInit {

  @Input()
  context:any;
  @Input()
  position:any;

  path: any;

  constructor(private router:Router) { }

  ngOnInit(): void {
    let url = window.location.pathname.split("/");
    this.path = url.filter((e)=> e !== '');
  }

  changePath(){
    this.router.navigate([this.path[0],this.path[1],this.path[2],this.context.Navigation])
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
 

@Component({
  selector: 'component-cp003',
  templateUrl: './cp003.component.html',
  styleUrls: ['./cp003.component.scss']
})
export class CP003Component implements OnInit {

  @Input() context: any;
  @Input() position: any;
  sliders:any[] = [];

  multipleSliders:boolean = false;
  commentTextareas:boolean[] = [];
  translateButtonToggles:boolean[] = [];
  translateButtonTogglesComment:boolean[] = [];

  goldenImg:string="url(../../../../../assets/icon/dot-slider.svg)";
  labelWidth1 = '17%'
  labelWidth2 = '33%'

  constructor(private _api:ApiService, private _errorthrower:ErrorthrowerService) { 
  }

  ngOnInit(): void {
    if (this.context.Display == '') {
      this._errorthrower.throw("Unauthorized.", 400, false, false);
    }
    let url = window.location.pathname;
    let path = url.split("/");
    if(path.find((e)=>e == "AP002")){
      this.goldenImg = "url(../../../../../assets/icon/iconGoldenGoose.png)"
    }

    document.documentElement.style.setProperty('--golden-img', this.goldenImg);
    this.labelWidth2 =  (100 / (this.context.Labels.length - 1)) + '%'
    this.labelWidth1 = (100 - ((100 / (this.context.Labels.length - 1)) * (this.context.Labels.length - 2))) / 2 + '%'    

    this.multipleSliders = (this.context.Sliders instanceof Array);
    //console.log(this.context)
    //console.log(this.multipleSliders);

    this.context.Subtitles.forEach((e:any) => {      
      this.translateButtonToggles.push(false);
    });
    if (this.multipleSliders) {
      this.context.Sliders.forEach((e:any) => {        
        if (e.Elemento3 == e.Elemento5) e.Elemento3 = "";
        this.sliders.push(e);
      })
    } else {      
      if (this.context.Sliders.Elemento3 == this.context.Sliders.Elemento5) this.context.Sliders.Elemento3 = "";
      this.sliders.push(this.context.Sliders);
    }
  }

  updateSliderValue(event:any, i:number) {
    this.sliders[i].Elemento2 = event.value / (100 / (this.context.Labels.length - 1))
  }

  toggleTranslateButton(i:number) {
    this.translateButtonToggles[i] = !this.translateButtonToggles[i];
  }

  toggleCommentArea(i:number, status:boolean) {
    this.commentTextareas[i] = status;
  }

  toggleCommentTranslateButton(i:number) {
    this.translateButtonTogglesComment[i] = !this.translateButtonTogglesComment[i];
  }
}


<button mat-raised-button *ngIf="context.ButtonContentRun != '' && context.ButtonContentRun != null"
        class="button-back-absence" color="accent" (click)="onClickRun()">
        <mat-icon class="button-icon">play_arrow</mat-icon>
        {{ context.TooltipButtontRun != "" ? context.TooltipButtontRun : "Run" }}
</button>
<mat-slide-toggle *ngIf="context.UpdateActive != null" color="accent" [checked]="!context.UpdateValue" (change)="context.UpdateValue = !context.UpdateValue;" style="margin-left:16px">&nbsp;{{context.UpdateLabel}}</mat-slide-toggle>

<div *ngIf="context.DoNotShowData != 'ok' && (context.MonthActive != null && context.MonthActive != '') || (context.StartEndDateActive != null && context.StartEndDateActive != '')" class="card-absence">
  <mat-card *ngIf="context.CompanyActive != null && context.CompanyActive != '' && ((context.MonthActive != null && context.MonthActive != '') || (context.StartEndDateActive != null && context.StartEndDateActive != ''))" class="card box-sel-company">
    <mat-card-content class="inner-card">
      <div *ngIf="context.TitleCompany != null && context.TitleCompany != ''" class="title-datepicker"  [style.background-color]="_styler.currentAccent">
        {{ context.TitleCompany }}
      </div>
      <div>
        <mat-form-field appearance="fill" color="accent" style="max-width: 200px;">
          <mat-label>{{ context.PlaceholderCompany }}</mat-label>
          <mat-select [(ngModel)]="selectedCompany" [disabled]="emptyCompany">
              <mat-option *ngFor="let c of context.Company" [value]="c.Id">
                  {{c.Text}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="card box-datepicker" [style.margin-left]="context.CompanyActive != null && context.CompanyActive != '' ? '8px' : '0px'">
    <mat-card-content *ngIf="(context.MonthActive != null && context.MonthActive != '' && context.YearActive != null && context.YearActive != '') || (context.StartEndDateActive != null && context.StartEndDateActive != '')" class="inner-card">
      <div *ngIf="context.Description != null && context.Description != ''" class="title-datepicker" [style.background-color]="_styler.currentAccent">
        {{ context.StartEndDateActive == undefined || context.StartEndDateActive == '' ? context.TitleDatePicker : context.TitlePeriodPicker }}
      </div>
      <div style="display:flex; flex-direction: row; gap:8px; align-items:center; flex-wrap: wrap;">
        <mat-form-field appearance="fill" color="accent" [style.width]="context.StartEndDateActive != undefined && context.StartEndDateActive != '' ? '130px': '200px'">
          <mat-label>{{ context.StartEndDateActive == undefined || context.StartEndDateActive == '' ? context.Description : context.PlaceholderStartMonth }} </mat-label>
          <input matInput #monthAndYearInput [(ngModel)]="monthAndYear" [matDatepicker]="picker" (dateChange)="emitDateChange($event, 1)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker [startView]="'year'" class="monthyear_datapicker"
            (monthSelected)="monthChanged($event, picker, 1)">
          </mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="context.StartEndDateActive != undefined && context.StartEndDateActive != ''" appearance="fill" color="accent" style="max-width: 130px;">
          <mat-label>{{ context.PlaceholderEndMonth }} </mat-label>
          <input matInput #monthAndYear2Input [(ngModel)]="monthAndYear2" [matDatepicker]="picker2" (dateChange)="emitDateChange($event, 2)">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 [startView]="'year'" class="monthyear_datapicker"
            (monthSelected)="monthChanged($event, picker2, 2)">
          </mat-datepicker>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>

</div>
  
<div class="file-uploader" *ngIf="context.DoNotShowData != 'ok' && context.Upload != null && context.Upload.length > 0">
  <component-cp006 [context]="context"></component-cp006>
</div>

<!--
<div *ngIf="context.DoNotShowData == 'ok'" class="card-absence">
  
  <mat-card class="card box-datepicker" *ngIf="(context.CompanyActive == null || context.CompanyActive == '') && 
    (context.MonthActive == null || context.MonthActive == '') && (context.YearActive == null || context.YearActive == '')">
    <mat-card-content class="inner-card">
      Premi il tasto Run per far partire l'operazione.
    </mat-card-content>
  </mat-card>

</div>
-->




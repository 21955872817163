<div *ngIf="newConversation" class="card-background">
    <mat-card>      
        <mat-card-title class="mat-card-title">
            {{ context.PowerConversationUserList }}
        </mat-card-title>
        <mat-card-content class="card-content">
            <mat-form-field appearance="fill" color="accent" style="width:100%">
                <mat-label>{{ context.PowerConversationSearch }}</mat-label>
                <input matInput type="text" [(ngModel)]="searchWordRecipient" (input)="searchRecipient()">
            </mat-form-field>

            <div class="recipientlist-div">
                <mat-selection-list [multiple]="false">
                    <mat-list-option class="recipient" *ngFor="let d of filteredRecipientList; index as i" (click)="selectRecipient(d)">
                        {{ d.name }}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <mat-form-field appearance="fill" color="accent" >
                <mat-label>{{context.PowerConversationSubject}}</mat-label>
                <input matInput type="text" [(ngModel)]="conversationSubject" required>
            </mat-form-field>

            <br>
            <div class="card-buttons">
                <button mat-raised-button color="basic" (click)="closeCard(false)">{{context.PowerConversationCancel}}</button>
                <button mat-raised-button [disabled]="selectedRecipient == undefined || conversationSubject == ''" class="table-button" color="accent" (click)="closeCard(true)">
                    {{context.PowerConversationOk}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>


<component-cp041 *ngIf="context.genericButtonContext.Active != 0" class="generic-button-mobile" [context]="context.genericButtonContext"></component-cp041>
<mat-toolbar class="title" color="accent">
    <button mat-mini-fab class="drawer-button" [color]="!openedDrawer ? 'accent' : 'primary'" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <span class="powerconversation-title">{{ context.PowerConversationTitle }}</span>
    <span class="example-spacer"></span>
    <div *ngIf="context.genericButtonContext.Active != 0" class="generic-button-div">
        <component-cp041 [context]="context.genericButtonContext"></component-cp041>
    </div>
</mat-toolbar>
<mat-drawer-container class="drawer-container">
    <mat-drawer #drawer class="drawer" [mode]="screenWidth < 800 ? 'over' : 'side'" [(opened)]="openedDrawer" (opened)="events.push('open!')"
                (closed)="events.push('close!')">
        <mat-form-field class="searchbar" appearance="fill" style="width:100%">
            <mat-label>{{ context.PowerConversationSearch }}</mat-label>
            <input matInput type="text" [(ngModel)]="searchWordConversation" (input)="searchConversation()">
        </mat-form-field>
        <div style="display:flex; flex-direction: column; justify-content: center;">
            <button class="new-conversation-button" mat-raised-button color="accent" (click)="createConversation()">{{ context.PowerConversationNewConversation }}</button>
            <mat-checkbox color="accent" style="margin-left:16px;" (click)="toggleUnreadOnly()">{{ context.PowerConversationNotReadOnly }}</mat-checkbox>
        </div>

        <mat-selection-list #chatList [multiple]="false">
            <mat-list-option class="chatlist-div" *ngFor="let chat of filteredChatList; index as i" (click)="selectChat(i)">
                <div class="chat-div">
                    <div class="avatar-div">
                        {{ chat.intestatario.substring(0, 1) }}{{ chat.intestatario.split(' ')[1].substring(0, 1) }}
                    </div>
                    <div class="chatinfo-div">
                        <span class="chatrecipient-span" [style.font-weight]="chat.letto == 0 ? 'bold' : ''">{{ chat.intestatario }}</span>
                        <span class="chatsubject-span">{{ chat.oggetto }}</span>
                    </div>

                </div>
            </mat-list-option>
        </mat-selection-list>

    </mat-drawer>
    <mat-drawer-content class="drawer-content">

        <mat-icon *ngIf="currentChat == undefined" class="drawer-content-empty">markunread</mat-icon>

        <div class="subtitle-div" *ngIf="currentChat != undefined">
            <span>{{ context.PowerConversationChatWith }} {{ currentChat.intestatario }}</span>
            <span class="example-spacer"></span>
            <span>{{ context.PowerConversationSubject }} {{ currentChat.oggetto }}</span>
        </div>
        <div class="subtitle-div" *ngIf="currentChat != undefined">
            <mat-checkbox color="accent" (click)="toggleTranslation()">{{ context.PowerConversationTranslate }}</mat-checkbox>
        </div>

        <div #messageListContainer class="messagelist-container-div" [scrollTop]="scrollTop">
            <div class="messagelist-div">
                <ng-container *ngIf="currentChat != undefined">
                    <ng-container *ngFor="let message of chatMessages">
                        <div [class]="message.mittente_id == message.sonoio_id ? 'message-div-wrapper message-div-wrapper-sent' : 'message-div-wrapper message-div-wrapper-received'"
                                *ngIf="message.bozza == 0">
                            <div [class]="message.mittente_id == message.sonoio_id ? 'message-div message-div-sent' : 'message-div message-div-received'">
                                    <div class="message-div-innerhtml" [innerHTML]="translationEnabled ? message.translated_message : message.messaggio"></div>
                                    <span class="messagetimestamp-span">{{ message.timestamp.substring(5,16) }}</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div class="texteditor-div" *ngIf="currentChat != undefined">
            <angular-editor [placeholder]="context.PowerConversationTypeSomething" [(ngModel)]="messageText" [config]="editorConfig"></angular-editor>
        </div>

        <div class="buttons-div" *ngIf="currentChat != undefined">
            <button mat-raised-button color="accent" [disabled]="messageText == ''" (click)="clear()"> {{ context.PowerConversationCancel }} </button>
            <button mat-raised-button color="accent" [disabled]="messageText == ''" (click)="send(true)"> {{ context.PowerConversationDraft }} </button>
            <button mat-raised-button color="accent" [disabled]="messageText == ''" (click)="send(false)"> {{ context.PowerConversationSend }} </button>
        </div>


    </mat-drawer-content>
</mat-drawer-container>


<mat-card style="margin-bottom:16px; width:100%;">
    <mat-card-content>
        <div style="display:flex; flex-direction: row; justify-content: flex-start; margin-bottom: -12px; gap:24px">
            <mat-form-field #select1 appearance="fill" class="env-select" [style.display]="stagingApproverMode ? 'none':''">
                <mat-label>Choose Env</mat-label>
                <mat-select [value]="environmentChosen" (selectionChange)="retrieveTables($event.value)" [disabled]="loading">
                    <mat-option value="Produzione">PROD</mat-option>
                    <mat-option value="Sviluppo">DEV</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="table-select">
                <mat-label>
                    <mat-spinner *ngIf="environmentChosen == '' && loading" class="loader-mini"></mat-spinner>
                    <div @inOutAnimation *ngIf="!(environmentChosen == '' && loading)">Choose Table</div>
                </mat-label>
                <mat-select #tableSelect [class]="environmentChosen == '' && loading ? 'hiddenArrow' : ''" (selectionChange)="retrieveFiles($event.value)"  [disabled]="loading || dataStruct['Data'] == {}">
                    <mat-option *ngFor="let table of dataStruct['Data'][environmentChosen] | keyvalue" [value]="table.key">
                        {{table.key}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field #select3 appearance="fill" class="backup-select" [style.display]="stagingApproverMode ? 'none':''">
                <mat-label>
                    <mat-spinner *ngIf="tableChosen == '' && loading" class="loader-mini"></mat-spinner>
                    <div @inOutAnimation *ngIf="!(tableChosen == '' && loading)">Choose File</div>
                </mat-label>
                <mat-select #fileSelect [class]="tableChosen == '' && loading ? 'hiddenArrow' : ''" (selectionChange)="retrieveRows($event.value)" [disabled]="loading || tableChosen == '' || dataStruct['Data'] == {} || dataStruct['TableChoice'] == undefined || dataStruct['Data'][environmentChosen][dataStruct['TableChoice']['Table']] == undefined">
                    <ng-container *ngIf="tableChosen != ''">
                        <mat-option *ngFor="let backup of dataStruct['Data'][environmentChosen][tableChosen] | keyvalue" [value]="backup.key">
                            {{backup.key}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="environmentChosen != '' && tableChosen != '' && fileChosen == '' && loading" class="loader-div">
            <mat-spinner class="loader"></mat-spinner>
        </div>
        <div @inOutAnimation *ngIf="environmentChosen != '' && tableChosen != '' && fileChosen != ''" style="margin-bottom:16px; margin-top:8px; width:100%;">
        
                <p-table @inOutAnimation #dt *ngIf="tableChosen != '' && !(fileChosen == '' && loading) && tableColumns.length > 0" [rowHover]="false" [rows]="5" [showCurrentPageReport]="true" styleClass="p-datatable-lg"
                    [rowsPerPageOptions]="[5,10,25,50,100]" responsiveLayout="scroll" [paginator]="true" [scrollable]="false" [filterDelay]="0" [reorderableColumns]="false"
                    [columns]="tableColumns" [value]="dataStruct['Data'][environmentChosen][tableChosen][fileChosen]" sortMode="multiple" [(selection)]="rowsSelection">
        
                    <ng-template pTemplate="caption" id="hideIcons">
                        <div class="table-header" id="hideIcons">
                            <div class="search-div">
                                <span class="p-input-icon-right searchbox-span" id="hideIcons">
                                    <i class="pi pi-search"></i>
                                    <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" placeholder="Search" class="search-input"/>
                                </span>
                            </div>
                        </div>
                    </ng-template>
        
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3rem">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th class="column-header" *ngFor="let item of [].constructor(tableColumns.length); let i = index" [pSortableColumn]="tableColumns[i]" pResizableColumn>
                                <span class="column-title">
                                    {{tableColumns[i]}}
                                </span>
                            </th>
                        </tr>
                    </ng-template>
        
                    <ng-template pTemplate="body" let-rowData>
                        <tr [pSelectableRow]="rowData">
                            <td>
                                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <ng-container *ngFor="let item of [].constructor(tableColumns.length); let i = index">
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" [(ngModel)]="rowData[tableColumns[i]]">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData[tableColumns[i]] }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-template>
        
                    <ng-template pTemplate="emptymessage">
                        <tr *ngIf="tableColumns.length == 0" >
                            <td [attr.colspan]="tableColumns.length" class="nodata-cell">
                                No data found.
                            </td>
                        </tr>
                    </ng-template>
        
                </p-table>
        
                <div style="display: flex; flex-direction: row; gap:16px; justify-content: flex-end; margin-top:16px">
                    <button mat-raised-button *ngIf="false" [disabled]="rowsSelection.length == 0" color="accent" (click)="backupRestoreTable(0)">
                        <mat-icon>backup</mat-icon>
                        Backup
                    </button>
                    <button mat-raised-button *ngIf="tableColumns.length > 0" [disabled]="rowsSelection.length == 0" color="accent" (click)="backupRestoreTable(stagingApproverMode ? 2 : 1)">
                        <ng-container *ngIf="!stagingApproverMode">
                            <mat-icon>restore</mat-icon>
                            Restore
                        </ng-container>
                        <ng-container *ngIf="stagingApproverMode">
                            <mat-icon>check_circle</mat-icon>
                            Approve
                        </ng-container>
                    </button>
                </div>
        </div>
    </mat-card-content>
</mat-card>

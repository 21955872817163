<div @inOutAnimation *ngIf="cardVisible" class="card-background">
    <mat-card class="form-card">
        <mat-card-title class="title-card">
            <div>
                <mat-icon>list_alt</mat-icon>
                <span class="title-span">{{context.integrations[intChosen].FADESC}}</span>
            </div>
            <button mat-mini-fab (click)="closeDetails()" class="close-button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-title>
        <mat-card-content class="form-card-content">
            <div *ngIf="detailsContext == undefined" class="spinner-div-detail">
                <mat-spinner></mat-spinner>
                Retrieving detailed info, this may take a while.
            </div>
            <ng-container *ngIf="detailsContext != undefined">
                <!--
                <mat-stepper [linear]="false" #stepper>
                    <mat-step *ngFor="let lambda of detailsContext.integrations[intChosen].lambdaList, index as l" [completed]="false" class="run-div">
                        <ng-template matStepLabel>
                            {{lambda}}
                            <mat-icon *ngIf="!detailsContext.integrations[intChosen].runList[0].logList[l].isSuccess" color="warn">warning</mat-icon>
                        </ng-template>
                    </mat-step>
                </mat-stepper>
                --> 
                <div style="display:flex; flex-direction:row; justify-content: center;">
                    <ng-container *ngFor="let lambda of detailsContext.integrations[0].lambdaList, index as l">
                        <mat-icon style="margin-left:16px; margin-right:16px">{{l > 0 ? 'arrow_right' : ''}}</mat-icon>
                        <mat-icon style="margin-right:8px;">filter_{{l+1}}</mat-icon>
                        <span>{{lambda}}</span>
                    </ng-container>
                </div>
                <mat-card style="margin-top:16px;">
                    <mat-tab-group>
                        <mat-tab *ngFor="let run of detailsContext.integrations[0].runList, index as r" [label]="r == 0 ? 'Latest run' : 'Run -' + r">
                            <div style="display:flex; flex-direction:row; flex-wrap:wrap; width:100%; justify-content: center;">
                                <mat-card *ngFor="let lambda of detailsContext.integrations[0].lambdaList, index as l" style="margin:8px; width:400px" [style.margin-left]="l > 0 ? '16px' : ''">
                                    <mat-card-content class="expansion-innerdiv" [style.background-color]="run.logList[l] != undefined ? run.logList[l].isSuccess ? '#50bb50 !important' : '#bb5050 !important' : 'gray !important'">
                                        <div style="display:flex; flex-direction:row; align-items: flex-start; justify-content: space-between; width:100%">
                                            <div style="display:flex; flex-direction:column; width:100%">
                                                <span style="display: flex; flex-direction:row; justify-content: space-between; align-items: center; font-weight: bold;margin-bottom: 16px; width:100%">
                                                    <div style="display: flex; flex-direction:row; justify-content: space-between; align-items: center;">
                                                        <mat-icon style="margin-right:8px;">filter_{{l+1}}</mat-icon>
                                                        {{lambda}}
                                                    </div>
                                                    {{run.logList[l] != undefined ? run.logList[l].isSuccess ? 'OK' : 'FAIL' : 'SKIP'}}
                                                </span>
                                                <div style="display: flex; flex-direction:row; justify-content: space-between; align-items: center;">
                                                    <div *ngIf="run.logList[l] != null">
                                                        <span><b>Log ID:</b><a [href]="run.logList[l].url" target="_blank" matTooltip="View on Cloudwatch"> {{run.logList[l].name.split(']')[1]}}</a></span><br>
                                                        <span><b>Start:</b> {{run.logList[l].start}}</span><br>
                                                        <span><b>End:</b> {{run.logList[l].end}}</span><br>
                                                    </div>
                                                    <mat-icon style="margin-right:-44px">{{l < detailsContext.integrations[0].lambdaList.length - 1 ? 'arrow_right' : ''}}</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-expansion-panel style="margin-top:8px" *ngIf="run.logList[l] != null && !run.logList[l].isSuccess && run.logList[l].errors.length > 0">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title class="expansion-title">
                                                    Error list:
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="terminal-div">
                                                <span *ngFor="let error of run.logList[l].errors">
                                                    {{error.message}}<br><br>
                                                </span>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </ng-container>
        </mat-card-content>
    </mat-card>
</div>

<div @inOutAnimation *ngIf="logsCardVisible" class="card-background">
    <mat-card class="form-card form-card-scrollable" style="max-width: 90%; width:600px">
        <mat-card-title class="title-card-popup">
            <mat-icon>list_alt</mat-icon>{{context.integrations[intChosen].FADESC}}
        </mat-card-title>
        <mat-card-content class="form-card-content logs-cardcontent">
            <textarea matInput disabled cdkTextareaAutosize width="400px" style="resize:none; color:black;">{{context.integrations[intChosen].runList[0].logList[0].errors[0]}}</textarea>
            <div class="buttons-div">
                <button mat-raised-button color="basic" (click)="closeLogs()">
                    <mat-icon style="margin-bottom:-4px">clear</mat-icon> {{labels.closeLabel}}
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div @inOutAnimation *ngIf="scheduleCardVisible" class="card-background">
    <mat-card class="form-card form-card-scrollable">
        <mat-card-title class="title-card-popup">
            <mat-icon>timer</mat-icon>{{context.integrations[intChosen].FADESC}}
        </mat-card-title>
        <mat-card-content class="form-card-content">
            <component-cp084 [teguid]="teguid" [faguid]="faguid" [labels]="labels" (closeFormEvent)="closeSchedule($event)"></component-cp084>
        </mat-card-content>
    </mat-card>
</div>

<div @inOutAnimation *ngIf="parametersCardVisible" class="card-background">
    <mat-card class="form-card form-card-scrollable">
        <mat-card-title class="title-card title-card-popup">
            <mat-icon>build</mat-icon>{{context.integrations[intChosen].FADESC}}
        </mat-card-title>
        <mat-card-content class="form-card-content">
            <component-cp083 [fapara]="fapara" [faguid]="faguid" [labels]="labels" (closeFormEvent)="closeParameters($event)"></component-cp083>
        </mat-card-content>
    </mat-card>
</div>

<div @inOutAnimation *ngIf="configurationCardVisible" class="card-background">
    <mat-card class="form-card" style="height:95%; min-width:800px">
        <mat-card-title class="title-card title-card-popup">
            <mat-icon>settings</mat-icon>{{context.integrations[intChosen].FADESC}}
        </mat-card-title>
        <mat-card-content class="form-card-content">
            <component-cp071 *ngIf="context.integrations[intChosen].FACODE == 'FA0002'" [inguid]="inguid" [labels]="labels" (closeFormEvent)="closeConfiguration($event)"></component-cp071>
            <component-cp072 *ngIf="context.integrations[intChosen].FACODE == 'FA0001'" [inguid]="inguid" [labels]="labels" (closeFormEvent)="closeConfiguration($event)"></component-cp072>
        </mat-card-content>
    </mat-card>
</div>

<mat-card @inOutAnimation>
    <mat-card-title class="title-card">
        <mat-icon>list</mat-icon> {{labels.integrationsTitle}}
    </mat-card-title>
    <mat-card-content>
        <div *ngIf="context == undefined" class="spinner-div">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="context != undefined && context.isSuccess == undefined" class="integration-div">
            <table border=1 frame=void rules=rows>
                <tr style="height:36px">
                    <th></th>
                    <th>{{labels.latestrunLabel}}</th>                    
                    <th></th>   
                    <th>{{labels.actionsLabel}}</th>
                </tr>
                <ng-container *ngFor="let integration of context.integrations, index as i">
                    <tr class="integration-tr">
                        <td>
                            <div class="fadesc-div">{{integration.FADESC}}</div>
                        </td> 
                        <td style="width:150px; min-width:80px">
                            {{integration.runList.length == 0 ? labels.monthsagoLabel : integration.runList[0].logList[integration.runList[0].logList.length-1].end}}
                        </td>      
                        <td style="width:50px">
                            <!--<button mat-mini-fab style="font-weight:bold; transform:scale(.8)" class="mat-elevation-z0" [style.background-color]="integration.runList.length == 0 ? 'gray' : (integration.runList[0].isSuccess == undefined ? '#ffb100' : (integration.runList[0].isSuccess ? '#00aa00' : '#aa0000'))" (click)="this.details(i)">!-->
                            <mat-icon *ngIf="integration.runList.length > 0 && integration.runList[0].isSuccess" style="color:#00aa00">check_circle</mat-icon>
                            <mat-icon *ngIf="integration.runList.length > 0 && integration.runList[0].isSuccess == false" color="warn">error</mat-icon>
                            <mat-icon *ngIf="integration.runList.length == 0" style="color:black">help</mat-icon><!--#ffb100!-->
                            <mat-icon *ngIf="integration.runList.length > 0 && integration.runList[0].isSuccess == undefined" style="color:gray">watch_later</mat-icon>
                        </td>  
                        <td style="min-width:85px; display: flex;flex-wrap:wrap">
                            <button mat-mini-fab color="accent" style="transform:scale(.8)" [matTooltip]="labels.scheduleLabel" (click)="openSchedule(integration.FAGUID, i)" [matBadge]="integration.scheduleList != undefined && integration.scheduleList.length > 0 && integration.scheduleList[0].enabled ? 'on' : ''">
                                <mat-icon>timer</mat-icon>
                            </button>
                            <button mat-mini-fab style="transform:scale(.8)" [matTooltip]="labels.configureLabel" (click)="openConfiguration(integration.INGUID, i)" [disabled]="integration.INGUID == undefined || (integration.FACODE != 'FA0002' && integration.FACODE != 'FA0001')">
                                <mat-icon>settings</mat-icon>
                            </button>
                            <button mat-mini-fab style="transform:scale(.8)" [matTooltip]="labels.parametersLabel" (click)="openParameters(integration.FAGUID, integration.FAPARA, i)" [disabled]="integration.FAPARA == undefined || !integration.FAPARA.includes('configuratorSpecs')">
                                <mat-icon>build</mat-icon>
                            </button>
                            <!--
                            <button mat-mini-fab style="transform:scale(.8)" [matTooltip]="labels.launchLabel" (click)="navigateToFunction(integration.FAGUID)">
                                <mat-icon>play_arrow</mat-icon>
                            </button>
                            !-->
                            <button mat-mini-fab style="transform:scale(.8)" [matTooltip]="labels.logsLabel" [disabled]="integration.runList.length == 0 || integration.runList.length == 0 || integration.runList[0].isSuccess || integration.runList[0].logList.length == 0 || integration.runList[0].logList[0].errors == undefined || integration.runList[0].logList[0].errors.length == 0" (click)="openLogs(i)">
                                <mat-icon>list_alt</mat-icon>
                            </button>
                        </td>     
                    </tr>   
                </ng-container>
            </table>
                <!--
                <div class="integrationsummary-div">
                    <ng-container *ngIf="integration.runList.length > 0">
                        {{integration.runList[0].logList[integration.runList[0].logList.length-1].end}}
                        <b>{{integration.runList[0].isSuccess == undefined ? '...' : integration.runList[0].isSuccess ? 'OK' : 'FAIL'}}</b>
                    </ng-container>
                    <ng-container *ngIf="integration.runList.length == 0">
                        {{integration.errorMessage}}
                    </ng-container>
                </div>
                <button @inAnimation mat-button class="integration-button" [style.background-color]="integration.runList.length == 0 ? 'gray' : (integration.runList[0].isSuccess == undefined ? '#ab6951' : (integration.runList[0].isSuccess ? '#50bb50' : '#bb5050'))" (click)="this.details(i)">
                    <span style="overflow: hidden; text-overflow: ellipsis; font-weight:bold">{{integration.FADESC}}</span>
                    <div class="integrationsummary-div">
                        <ng-container *ngIf="integration.runList.length > 0">
                            {{integration.runList[0].logList[integration.runList[0].logList.length-1].end}}
                            <b>{{integration.runList[0].isSuccess == undefined ? '...' : integration.runList[0].isSuccess ? 'OK' : 'FAIL'}}</b>
                        </ng-container>
                        <ng-container *ngIf="integration.runList.length == 0">
                            {{integration.errorMessage}}
                        </ng-container>
                    </div>
                </button>-->
            <!--<i style="font-size:smaller; color:gray; text-align: center;">Response time: {{context.time}}</i>!-->
        </div>
        <ng-container *ngIf="context != undefined && context.isSuccess != undefined && context.isSuccess == false">
            <b>An error has been occurred.</b><br>
            <div class="terminal-div">
                > {{context.error != undefined && context.error.message != undefined ? context.error.message : context.message}} <span class="blinkingcharacter-span">_</span>
            </div>
        </ng-container>

    </mat-card-content>
</mat-card>
<mat-card @inOutAnimation *ngIf="isEvalValid" class="filter-card">
    <ng-container *ngFor="let condition of evalStringParsed; index as i">
        <mat-card @inOutAnimation class="condition-card">
            <mat-form-field style="max-width: 110px;">
                <mat-label>Operator</mat-label>
                <mat-select [(ngModel)]="condition.equals" required>
                <mat-option [value]="true">Equals</mat-option>
                <mat-option [value]="false">Not Equals</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Value</mat-label>
                <input matInput required [(ngModel)]="condition.value">
            </mat-form-field>
            <button mat-mini-fab (click)="removeCondition(i)" class="remove-button">
                <mat-icon>remove</mat-icon>
            </button>
        </mat-card>
        <div style="display:flex; justify-content: center;">
        <mat-button-toggle-group *ngIf="condition.and != undefined" [(ngModel)]="condition.and" (change)="onToggleValueChange($event, i)">
            <mat-button-toggle [value]="true">And</mat-button-toggle>
            <mat-button-toggle [value]="false">Or</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    </ng-container>
    <div style="display:flex; justify-content:center;">
    <button mat-mini-fab (click)="addCondition()">
        <mat-icon>add</mat-icon>
    </button>
</div>
</mat-card>
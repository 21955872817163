<mat-card *ngIf="cardVisible" class="form-card">
    <mat-card-title class="title-card">{{selectionType == 0 ? 'Select entity' : 'Select field'}}</mat-card-title>
    <mat-card-content>
        <mat-tree *ngIf="dataSource != undefined" [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node; n as index" matTreeNodePadding style="width:100%">
                <div style="display:flex; justify-content: space-between; align-items:center; width:100%" [style.margin-left]="selectionType == 0 ? '8px' : '0px'">
                    {{node.name}}                    
                    <button mat-raised-button color="accent" style="margin-right:8px;" (click)="selectElement(node)">
                        <mat-icon>forward</mat-icon>
                    </button>
                </div>
            </mat-tree-node>
            <ng-container *ngIf="selectionType > 0">
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                    <button mat-icon-button matTreeNodeToggle>
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <div class="treerow-div">
                        {{node.name}}
                        <button *ngIf="selectionType == 0" mat-raised-button color="accent" style="margin-right: 8px;" (click)="selectElement(node)">
                            <mat-icon>forward</mat-icon>
                        </button>
                    </div>
                </mat-tree-node>
            </ng-container>
        </mat-tree>
    </mat-card-content>
</mat-card>

<div class="page-div">
    <mat-card class="clientlist-card">
        <mat-card-title class="title-card">
            <mat-icon>people</mat-icon> {{context.labels.clientsTitle}}
        </mat-card-title>
        <mat-card-content>
            <!--
            <div style="display:flex; flex-direction: row; gap:8px; justify-content: center; align-items: center;">
                Sort by:
                <button mat-raised-button (click)="sortByName()" [disabled]="sortType == 0">Name</button>
                <button mat-raised-button (click)="sortByTenant()" [disabled]="sortType == 1">Tenant</button>
            </div>
            -->
            <mat-form-field style="width:100%; max-width:225px">
                <input matInput type="search" [placeholder]="context.labels.searchLabel" [(ngModel)]="clientFilter" (keyup)="filterClients()">
                <button matSuffix mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
            
            <mat-list class="button-list">
                <ng-container *ngFor="let client of filteredClients, index as i">
                    <button mat-button [class]="teguid == client.TEGUID ? 'mat-raised-button' : 'mat-button'" color="accent" class="client-button" (click)="selectClient(client.TEGUID)">
                        <!--<div class="clientbutton-innerdiv">!-->
                            <!--<img *ngIf="client.CLLOGO != undefined && client.CLLOGO != ''" [src]="client.CLLOGO" class="clientlogo-img">!-->
                            <!--<mat-icon *ngIf="client.CLLOGO == undefined" class="clientlogo-icon">account_circle</mat-icon>!-->
                            <!--<span class="clientname-span"> {{sortType == 1 ? client.TEGUID + ' - ' : ''}}!-->
                            <div style="overflow:hidden;">
                                <span style="text-overflow: ellipsis;">{{client.CLDESC}}</span>
                            </div>   
                    </button>
                    <!--<mat-divider></mat-divider>!-->
                </ng-container>
            </mat-list>
        </mat-card-content>
    </mat-card>
    <div *ngIf="teguid" style="display:flex; flex-direction:column; flex-wrap:wrap; align-items: flex-start; gap:16px; width:100%">
            <component-cp081 [teguid]="teguid" [labels]="context.labels" style="width:100%"></component-cp081>

            <!--<component-cp082 [teguid]="teguid"></component-cp082>!-->
            
    </div>
</div>

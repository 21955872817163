import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { StylerService } from './services/styler.service';
import packageJson from '../../package.json'; 
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarmanagerService } from './services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  version: string = packageJson.version;

  loginForm: FormGroup;
  newPasswordForm: FormGroup;
  passwordResetForm: FormGroup;
  passwordResetForm2: FormGroup;

  loggedIn: boolean = false;
  pageAccess: boolean = false;
  authAmplify: any = null;

  hidePwd: boolean = true;
  hidePwd1: boolean = true;
  hidePwd2: boolean = true;

  challengeRequired: string = "";
  challengeUser = { username : "", password : ""}

  passwordReset: boolean = false;
  codeSent: boolean = false;
  passwordResetUser: string = "";

  loggingIn: boolean = false;
  isPwa: boolean = false;
  init:boolean = false;
  baseUrl = window.location.href;  
  
  environmentCode:number = environment.code

  @ViewChild('username', {read: ElementRef}) username: ElementRef<HTMLElement> | undefined;
  @ViewChild('password', {read: ElementRef}) password: ElementRef<HTMLElement> | undefined;
  usernameAutofilled: boolean = false;
  passwordAutofilled: boolean = false;

  constructor(private fb:FormBuilder, public auth:AuthService, private _snackBarService:SnackbarmanagerService,
              private _styler:StylerService, private router:Router, private route: ActivatedRoute) {
      this.loginForm = this.fb.group({
        'username': [''],
        'password': ['']
      });
      this.newPasswordForm = this.fb.group({
        'newPassword': ['', Validators.required],
        'newPasswordTest': ['', Validators.required],
      });
      this.passwordResetForm = this.fb.group({
        'username': ['', Validators.required]
      });
      this.passwordResetForm2 = this.fb.group({
        'code': ['', Validators.required],
        'newPassword': ['', Validators.required],
        'newPasswordTest': ['', Validators.required]
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event:any): void {
    if (sessionStorage.getItem("page") !== null) localStorage.setItem("session", "open");
  }

  ngOnInit() {
    this.init = false;

    //forced logout if still logged in after page closed
    if (sessionStorage.getItem("page") === null && localStorage.getItem("session") !== null)
      this.auth.deleteAmplifyCookies();
    localStorage.removeItem("session");

    //redirect for gg
    if (window.location.href == "https://mobile.dp-wospee.com/" || window.location.href == "https://ios-mobile.dp-wospee.com/")
      //this.federatedSignIn(null, undefined, "https://idp-wospee.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=GoldenGoose&redirect_uri=https://w3.dp-wospee.com/&response_type=CODE&client_id=5nn5hbdg0op0vo54h9fov980dh&scope=openid aws.cognito.signin.user.admin http://www.cezannehr.com/auth-scope/token-info")
      this.federatedSignIn(null, undefined, "https://idp-wospee.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=GoldenGoose&redirect_uri=https://w3.dp-wospee.com/&response_type=CODE&client_id=2flpa9l7efdndk3urv7gi376vo&scope=openid aws.cognito.signin.user.admin http://www.cezannehr.com/auth-scope/token-info")
    else {
      this.loggedIn = false;
      this.loggingIn = false;

      this.auth.isAuthenticated().subscribe(loggedIn => {
        if (loggedIn) {
          this.auth.getUserAttributes().subscribe(result => {
            if (result != undefined && Object.keys(result).length) {
              if (result.attributes != undefined && Object.keys(result.attributes).length > 0 && result.attributes['custom:language'] != undefined) 
                sessionStorage.setItem("LANGU", result.attributes['custom:language']);  
              else if (sessionStorage.getItem("LANGU") == undefined || sessionStorage.getItem("LANGU") == "") {
                sessionStorage.setItem("LANGU", "en");  
              }
            }
            localStorage.removeItem("ng2Idle.main.timeout");

            this.route.queryParams.subscribe(params => {
              if (Object.keys(params).length > 0 && Object.keys(params)[0] == "code") this.router.navigate(['']); //rimuovo i parametri dell'auth dall'url
              this.loggedIn = true;
              this.pageAccess = true;
              this.init = true;
            });
          })
        
        } else {
          this.route.queryParams.subscribe(params => {
            if (Object.keys(params)[0] == "code") this.federatedSignIn(null, -1);
            else this.init = true;
          });
        }
      });
    }

    //if (!this.loggedIn && !this.pageAccess && this.environmentCode >= 5) this.auth.downloadIdpList()
  }  

  ngAfterViewChecked() {
    if (window.matchMedia('(display-mode: standalone)').matches) this.isPwa = true;
    else this.isPwa = false;
    
    this._styler.setIsPwa(this.isPwa);
    this._styler.updateTheme();
  }

  federatedSignIn(event: any, index?:any, url?:any) {
    this.loggingIn = true
    var state = this.auth.getRandomString();
    sessionStorage.setItem("pkce_state", state);
    var code_verifier = this.auth.getRandomString();
    sessionStorage.setItem("ouath_pkce_key", code_verifier);

    this.auth.encryptStringWithSHA256(code_verifier).subscribe(result => {
        var code_challenge = this.auth.hashToBase64url(result);
        sessionStorage.setItem("code_challenge", code_challenge);
        
        if (index == undefined) {
          if (url != undefined) window.location.assign(url + "&code_challenge_method=S256&code_challenge=" + code_challenge);
          else {
            let OIDC: string = event.value;
            for (let i = 0; i < this.auth.idpList.length; ++i) {
              if (this.auth.idpList[i].id === OIDC) {
                window.location.assign(this.auth.idpList[i].url + "&code_challenge_method=S256&code_challenge=" + code_challenge);
              }
            }
          }
        } else {
          //if (index == -1) window.location.assign("https://idp-wospee-prd.auth.eu-west-1.amazoncognito.com/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + environment.cognitoData.client_id + "&redirect_uri=" + environment.cognitoData.oauth.redirect_sign_in + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge);
          if (index == -1) window.location.assign("https://idp-wospee.auth.eu-west-1.amazoncognito.com/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + environment.cognitoData.client_id + "&redirect_uri=" + environment.cognitoData.oauth.redirect_sign_in + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge);
          else window.location.assign(this.auth.idpList[index].url + "&code_challenge_method=S256&code_challenge=" + code_challenge);
        }
      }
    )
  }


  forgotPassword() {
    this.passwordReset = true;
  }

  submitLogin(value:any) {
    if (value.username == '' || value.password == '') this._snackBarService.open("Missing credentials!", 'ko')
    else {
      this.loggingIn = true;
      this.auth.signIn(value.username, value.password).subscribe(
        result => {
          if (result.attributes != undefined) {
            sessionStorage.setItem("LANGU", result.attributes['custom:language']);  
            localStorage.removeItem("ng2Idle.main.timeout");
            this.loggingIn = false;
            this.pageAccess = true;
            location.reload();
          } else if (result.challengeName != undefined) {
            this.challengeUser.username = value.username;
            this.challengeUser.password = value.password;
            this.challengeRequired = result.challengeName;
            this.loggingIn = false;
          } else {
            this._snackBarService.open("No user attributes!", 'ko')
            this.loggingIn = false;
          }
        },
        error => {
          console.log(error);
          var errorString = String(error).split(/:(.+)/)[1];
          if (errorString != undefined && errorString.includes("Password reset")) this.passwordReset = true;
          else if (errorString == undefined) this._snackBarService.open(error, 'ko')
          else this._snackBarService.open(errorString, 'ko')
          this.loggingIn = false;
        }
      );
    }
  }

  submitPassword(value:any) {
    this.loggingIn = true;
    this.auth.sendPassword(this.challengeUser.username, this.challengeUser.password, value.newPassword).subscribe(
      result => {
          console.log(result);
          this.loggingIn = false;
          this.challengeRequired = "";
          this.challengeUser.username = "";
          this.challengeUser.password = "";
          this.newPasswordForm.reset();
          this._snackBarService.open("Password changed!", 'ok');
      },
      error => {
        console.log(error);
        this._snackBarService.open(String(error).split(/:(.+)/)[1], 'ko')
        this.loggingIn = false;
      }
    );
  }

  submitUsername(value:any) {
    this.loggingIn = true;
    this.auth.sendUsername(value.username).subscribe(
        result => {
            console.log(result);
            this.passwordResetUser = value.username;
            this.codeSent = true;
            this.loggingIn = false;
        },
        error => {
          console.log(error);
          this._snackBarService.open(String(error).split(/:(.+)/)[1], 'ko')
          this.loggingIn = false;
        }
      );
  }

  submitCodePassword(value:any) {
    this.loggingIn = true;
    this.auth.sendCodePassword(this.passwordResetUser, value.code, value.newPassword).subscribe(
      result => {
          console.log(result);
          this.passwordReset = false;
          this.codeSent = false;
          this.passwordResetUser = "";
          this.loggingIn = false;
          this._snackBarService.open("Password changed!", 'ok')
      },
      error => {
        console.log(error);
        this._snackBarService.open(String(error).split(/:(.+)/)[1], 'ko')
        this.loggingIn = false;
      }
    );
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'component-cp036',
  templateUrl: './cp036.component.html',
  styleUrls: ['./cp036.component.scss']
})
export class Cp036Component implements OnInit {

  @Input() context:any;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { DialogComponent } from '../dialog/dialog.component';
import {v4 as uuidv4} from 'uuid';
import { Cp062Component } from '../cp062/cp062.component';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { Table } from 'primeng/table';


@Component({
  selector: 'component-cp072',
  templateUrl: './cp072.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp072.component.scss']
})
export class Cp072Component implements OnInit {

  @Input() inguid = ''
  @Input() labels:any = {}
  @Output() closeFormEvent = new EventEmitter<any>();
 
  @ViewChild('apiNavigator') apiNavigator: Cp062Component | undefined;
  showApinavigator:boolean = false
  configElementPaths:any = {}

  selectedInguid = ""
  labelsTable:any = undefined
  dateKeys:any = {}
  recordsColumns = ["Input", "Output", "Type"]
  recordsColumns2 = ["Input", "Output", "Past month Output", "Policy", "Type"]
  selectedRows = []
  selectedRows2 = []
  selectedRows3 = []
  @ViewChild('dt') table: Table | undefined;
  @ViewChild('dt2') table2: Table | undefined;
  @ViewChild('dt3') table3: Table | undefined;
  firstRow:any = 0
  firstRow2:any = 0
  firstRow3:any = 0
  allowedFileExtension:string = "csv"
  filterFieldValue:string = ""
  filterType:number = -1
  asItemTypes:any = []
  ntItemTypes:any = []
  paItemTypes:any = []


  context:any = {}

  loading:boolean = true


  constructor(private _snackbarManager:SnackbarmanagerService, private _api:ApiService, public dialog: MatDialog, private ngxCsvParser: NgxCsvParser, private _errorThrower:ErrorthrowerService) { }

  ngOnInit(): void {
    this._api.doButtonPostRequest({"action": "get", "configuration": { "id": this.inguid }}, "/configure-integration").subscribe((response) => {
      if (response) {
        this._api.printResponse(response)
        this.context = JSON.parse(JSON.stringify(response))
        this.context.itemTypes.forEach((item:any) => {
          if (item.Key.charAt(0) == "N") {
            this.ntItemTypes.push(item)
          } else if (item.Key.charAt(0) == "V" && this.context.config.Items.Proposals != undefined) {
            this.paItemTypes.push(item)
          } else {
            this.asItemTypes.push(item)
          }
        })
        var f = 0
        this.filterType = -1
        this.context.config.Settings.ExtractionFilters.forEach((item:any) => {
          console.log(item)
          console.log(this.context.config.Extraction.Filter)
          if (JSON.stringify(item) === JSON.stringify(this.context.config.Extraction.Filter)) {
            this.filterType = f
            if (f == 1) {
              this.filterFieldValue = this.context.config.Extraction.Filter[0].Value
            } else if (f == 2) {
              this.filterFieldValue = this.context.config.Extraction.Filter[2].Value
            }
          }
          f += 1
        })
        console.log(this.filterType)
        this.loadConfig()
      }
    }, (error) => {
      if(error) {
        console.log(error);
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko')
        else this._snackbarManager.open(error.error, 'ko')
        this.loading = false
      }
    });
  }

  loadConfig() {
    try {
      console.log(this.context.config)
      this.loading = false
      return true
    } catch (e) { 
      console.log(e)
      this._snackbarManager.open("An error occurred!", 'ko')
      this.close(false)
      return false 
    }
  }

  close(success?:boolean) {
    this.closeFormEvent.emit({
      isSuccess: success
    });
  }

  changePayrollcodeSource(event:any) {   
    var source = event.target.textContent.replace(" ", "")
    if (event.target.textContent.includes("PersonCode")) {
      source = this.getPersoncode()
    }
    source = source.replace(" ", "")
    this.context.config.Settings.PayrollcodeSource = source
  }

  changeExtractionFilter(event:any) { 
    if (event.value != undefined) {
      this.filterType = event.value
      this.context.config.Extraction.Filter = this.context.config.Settings.ExtractionFilters[this.filterType]
    }
    if (this.context.config.Extraction.Filter.length > 0) {
      if (this.filterType == 1) {
        this.context.config.Extraction.Filter[0].Value = this.filterFieldValue
      } else if (this.filterType == 2) {
        this.context.config.Extraction.Filter[2].Value = this.filterFieldValue
      }
    }
    console.log(this.context.config.Extraction.Filter)
  }

    
  addRecord(tableIndex:number = 0) {
    var newItem = {}
    if (tableIndex == 0 || tableIndex == 2) {
      newItem = {
        "Id": uuidv4(),
        "Input":"New item",
        "Type":"AA",
        "Output":"Output",
        "Amount": 0,
        "Hours": 0
      }
      if (tableIndex == 0) {
        this.context.config.Items.Absences.push(newItem)
        if (this.context.config.Items.Absences.length > 10) this.firstRow = (this.context.config.Items.Absences.length - 1) - (this.context.config.Items.Absences.length % 10)  
      } else if (tableIndex == 2) {
        this.context.config.Items.Proposals.push(newItem)
        if (this.context.config.Items.Proposals.length > 10) this.firstRow3 = (this.context.config.Items.Proposals.length - 1) - (this.context.config.Items.Proposals.length % 10)  
      }
    } else {  
      newItem = {        
        "Id": uuidv4(),
        "Input":"New item",
        "Type":"AA",
        "Output":"Output",
        "Amount": 0,
        "Hours": 0,
        "PastMonthOutput":"",
        "Policy":"",
      }
      this.context.config.Items.ExpensesReports.push(newItem)
      if (this.context.config.Items.ExpensesReports.length > 10) this.firstRow2 = (this.context.config.Items.ExpensesReports.length - 1) - (this.context.config.Items.ExpensesReports.length % 10)
    }
    this._snackbarManager.open("New record added!", "ok")
  }
  
  removeRecords(tableIndex:number = 0) {
    if (tableIndex == 0) {
      this.selectedRows.forEach((r:any) => {
        this.context.config.Items.Absences = this.context.config.Items.Absences.filter( (item:any) => item !== r );
      })
      if (this.selectedRows.length == this.context.config.Items.Absences) this.firstRow = 0
      this.selectedRows = []
      if (this.selectedRows.length > 1) this._snackbarManager.open("The selected rows have been removed.", "ok")
      else this._snackbarManager.open("The selected row has been removed.", "ok")
    } else if (tableIndex == 2) {
      this.selectedRows3.forEach((r:any) => {
        this.context.config.Items.Proposals = this.context.config.Items.Proposals.filter( (item:any) => item !== r );
      })
      if (this.selectedRows3.length == this.context.config.Items.Proposals) this.firstRow3 = 0
      this.selectedRows3 = []
      if (this.selectedRows3.length > 1) this._snackbarManager.open("The selected rows have been removed.", "ok")
      else this._snackbarManager.open("The selected row has been removed.", "ok")
    } else {      
      this.selectedRows2.forEach((r:any) => {
        this.context.config.Items.ExpensesReports = this.context.config.Items.ExpensesReports.filter( (item:any) => item !== r );
      })
      if (this.selectedRows2.length == this.context.config.Items.ExpensesReports) this.firstRow2 = 0
      this.selectedRows2 = []
      if (this.selectedRows2.length > 1) this._snackbarManager.open("The selected rows have been removed.", "ok")
      else this._snackbarManager.open("The selected row has been removed.", "ok")
    }
  }

  onFileSelected(event:any){
    let filesArray = event.target.files;
    if (filesArray.length > 0) {
      this.ngxCsvParser.parse(filesArray[0], { header: false, delimiter: ',', encoding: 'utf8' })
      .pipe().subscribe({
        next: (result:any): void => {
          result.forEach((v:any) => {
            console.log(v)
            if (v[1].charAt(0) == "N") {              
              this.context.config.Items.ExpensesReports.push({
                "Input":v[0],
                "Type":v[1],
                "Output":v[2],
                "PastMonthOutput":v[3],
                "Policy":v[4],
                "Amount": 0,
                "Hours": 0
              })
            } else {
              this.context.config.Items.Absences.push({
                "Input":v[0],
                "Type":v[1],
                "Output":v[2],
                "Amount": 0,
                "Hours": 0
              })
            }
          });
          this.firstRow = 0
          this._snackbarManager.open("New records have been imported.", "ok")
        },
        error: (error: NgxCSVParserError): void => {
          console.log('Error', error);
        }
      });
    }
  }

  addCompanyDecoding() {
    this.context.config.Settings.CompaniesDecodings.push({"Input":"input", "Output": "output"})
  }

  removeCompanyDecoding(i:number) {
    this.context.config.Settings.CompaniesDecodings.splice(i, 1)
  }

  getPersoncode() {
    var entityFound = false
    var entityName = ""
    var entityIndex = 0
    this.context.config.Extraction.Entities.every((entity:any) => {
      if (entity.Id == "AllPeopleSearch") {
        entityFound = true
        entityName = entity.Id
        return false
      }
      else if (entity.Id == "People") {
        entityFound = true
        entityName = entity.Id
        return false
      }
      entityIndex += 1
      return true
    })
    if (entityName == "AllPeopleSearch") {
      return "AllPeopleSearch.PersonCode_Search"
    } else {
      return "People.PersonCode"
    }
  }
  
  closeApiNavigator(event:any) {
    this.showApinavigator = false
    console.log(this.configElementPaths)  
    this.configElementPaths.forEach((path:any) => {
      this.evalInContext(path, this, event)
    })
    this.configElementPaths = []
  }

  evalInContext(js:string, context:any, event:any) {
    return function() { return eval(js); }.call(context);
  }

  saveConfig() {
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: this.labels.titleLabel,
        content: this.labels.contentLabel,
        accept: this.labels.okLabel,
        reject: this.labels.koLabel
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {  
        console.log(this.context.config)
        this._api.loading = true
        this._api.doButtonPostRequest({"action": "set", "configuration": { "id": this.inguid, "body": this.context.config }}, "/configure-integration").subscribe((response) => {
          if (response) {
            this._api.printResponse(response)
            var responseObj = JSON.parse(JSON.stringify(response));  
            if (responseObj.isSuccess != undefined) {
              this.close(responseObj.isSuccess)
            }            
            this._snackbarManager.open("Success!", 'ok');
            this._api.loading = false
          }
        }, (error) => {
          if(error) {
            console.log(error);
            if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
            else this._snackbarManager.open(error.error, 'ko');            
            this._api.loading = false
          }
        });
      }
    })
  }

  removeFilterField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  addFilterField() {
    this.context.config.Extraction.Filter.Input.push({'Label': '', 'Element': ''});
    var l = this.context.config.Extraction.Filter.Input.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Extraction.Filter.Input[' + l + '].Element', 
      name: 'this.context.config.Extraction.Filter.Input[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }

  openApiNavigator(selectionType:number, entity:any) {
    let newApiList:any = []
    if (entity != undefined) {
      this.context.apiList.every((a:any) => {
        if (a.id == entity) {
          newApiList.push(a)
          return false
        }
        return true
      }) 
    } else {
      newApiList = this.context.apiList
    }
    console.log(newApiList)
    this.showApinavigator = true
    this.apiNavigator?.open(newApiList, selectionType)
  }

  addSortField() {
    this.context.config.Extraction.Sorting.push({'Label': '', 'Element': ''});
    var l = this.context.config.Extraction.Sorting.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Extraction.Sorting[' + l + '].Element', 
      name: 'this.context.config.Extraction.Sorting[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path)
    });
    this.openApiNavigator(1, undefined)
  }

  removeSortField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }


  isArray(element:any): boolean { return Array.isArray(element) }

  /*
  loadPreferences() {
    //company decodings
    if (this.config.decode != undefined) {
      var decodings = this.config.decode.split(';')
      this.companyDecodingsTable = []
      decodings.forEach((d:string) => {
        var splittedDecoding = d.split(',')
        this.companyDecodingsTable.push({input:splittedDecoding[0], output:splittedDecoding[1]})
      })
      this.companyDecodingsTableData = new MatTableDataSource(this.companyDecodingsTable)

      this.companyDecodings = true
      this.companyDecodingsEnabled = true
    } else {
      this.companyDecodings = false
      console.log("No decodings found!")
    }

    //payroll source
    var i = this.getPayrollSourceIndex(this.config)
    if (i < 0) this.payrollSource = "employmentid"
    else {
      if (this.config.myExtract.FIELD[i].Field.includes("EmploymentID")) this.payrollSource = "employmentid"
      else if (this.config.myExtract.FIELD[i].Entita.includes("Visa")) this.payrollSource = "visadetails"
      else if (this.config.myExtract.FIELD[i].Field.includes("PayrollCode")) this.payrollSource = "payrollcode"
    }

    //Filter
    i = this.getFilterIndex(this.config)
    if (i < 0) this.filterOn = "level"
    else {
      if (this.config.myExtract.FIELD[i].Field.includes("CCNL")) this.filterOn = "ccnl"
      else if (this.config.myExtract.FIELD[i].Field.includes("Level"))  this.filterOn = "level"
      this.parseEval(this.config.myExtract.FIELD[i].Decode)
    }
  }


  parseEval(evalString:string) {
    evalString = "{0} == '1' && {0} != ''"
    var eval_splitted = evalString.replace('eval:', '').split(' ')
    this.isEvalValid = this.checkEvalValidity(eval_splitted)
    if (this.isEvalValid) {
      var condition:Condition = {
        equals: true,
        value: ""
      }
      var step = 0
      this.filterEval = []
      this.filterEvalStr = ""
      eval_splitted.forEach((e:string) => {
        switch (step) {
          case 1:
            if (e == '==') condition.equals = true
            else if (e == '!=') condition.equals = false
            break
          case 2:
            condition.value = e
            break
          case 3:
            if (e == '&&') {
              condition.and = true
              this.filterEval.push(condition)
              step = -1
            } else if (e == '||') {
              condition.and = false
              this.filterEval.push(condition)
              step = -1
            }
            else step = 0
        }
        step += 1
      })
    } else {
      this.filterEvalStr = evalString
      this.filterEval = []
    }
  }

  checkEvalValidity(evalSplitted:string[]) {    
    var i = 0
    var firstRange:number[] = []
    var secondRange:number[] = []
    var thirdRange:number[] = []
    var fourthRange:number[] = []
    for(i = 0; i < 32; i+=4) {
      firstRange.push(i)
      secondRange.push(i+1)
      thirdRange.push(i+2)
      fourthRange.push(i+3)
    }
    var isValid = true
    i = 0
    console.log(evalSplitted)
    evalSplitted.every((s:any) => {
      console.log(s)
      if ((firstRange.includes(i) && s.length != 3) ||
        (secondRange.includes(i) || fourthRange.includes(i)) && s.length != 2) {
        isValid = false
        console.log("Invalid filter eval, character [" + s + "] at position " + i + " of string '" + evalSplitted + "'")
        return 0
      } 
      i++
      return 1
    })
    return isValid
  }

  compileEval() {
    var evalString = "eval:"
    this.filterEval.forEach((c:any) => {
      evalString += "{0} "
      if (c.equals) evalString += "== '" + c.value + "' "
      else evalString += "!= '" + c.value + "' "
      if (c.and != undefined) {
        if (c.and) evalString += "&& "
        else evalString += "|| "
      }
    })
    console.log(evalString)
    return evalString
  }

  onToggleValueChange(event:any, index:number) {
    console.log(event)
    this.filterEval[index].and = event.value
  }

  addCondition() {
    if (this.filterEval.length == 0) {
      this.filterEval.push(
        {
          equals:true,
          value:"",
        }
      )
    } else {      
      this.filterEval[this.filterEval.length - 1].and = true
      this.filterEval.push(
        {
          equals:true,
          value:"",
        }
      )
    }
  }

  removeCondition(index:number) {
    if (this.filterEval[index].and != undefined) delete this.filterEval[index].and
    else delete this.filterEval[index - 1].and
    this.filterEval.splice(index, 1)
  }

  loadRecords() {
    this.recordSourcesString = JSON.stringify(this.config.voci.FONTI, null, 2)
    this.recordsSourcesBak = JSON.parse(JSON.stringify(this.config.voci.FONTI))
    if (this.config.voci.VOCI != undefined) {
      this.recordsTable = []
      this.config.voci.VOCI.forEach((v:any) => {
        this.recordsTable.push({Record:v.Voce, Type:v.Tipo, Code:v.Cod})
      })
      this.recordsTableData = new MatTableDataSource(this.recordsTable)
    }
  }

  addDecoding() {
    this.companyDecodingsTable.push({input:"", output:""})
    this.companyDecodingsTableData = new MatTableDataSource(this.companyDecodingsTable)
    console.log("a")
  }

  removeDecoding(index:number) {
    this.companyDecodingsTable.splice(index, 1)
    this.companyDecodingsTableData = new MatTableDataSource(this.companyDecodingsTable)
    console.log(this.companyDecodingsTable)
  }

  isRecordsSource(s:any) {
    if (Object.keys(this.config.voci.FONTI).includes(s)) return true
    else return false
  }

  modifyRecordsSource(source:string) {
    if (Object.keys(this.config.voci.FONTI).includes(source)) {
      delete this.config.voci.FONTI[source]
    } else {
      this.config.voci.FONTI[source] = {}
    }
    this.recordSourcesString = JSON.stringify(this.config.voci.FONTI, null, 2)
  }

  compareRecordsSources() {
    return this.recordSourcesString === JSON.stringify(this.recordsSourcesBak, null, 2)
  }

  setRecordsSources(event:any) {
    try {
      this.config.voci.FONTI = JSON.parse(event.target.value)
      this.recordSourcesString = JSON.stringify(this.config.voci.FONTI, null, 2)
      this.isRecordsSourcesStringGood = true
    } catch {
      this.recordSourcesString = event.target.value
      this.isRecordsSourcesStringGood = false
    }
  }

  restoreRecordsSources() {
    this.config.voci.FONTI = JSON.parse(JSON.stringify(this.recordsSourcesBak))
    this.recordSourcesString = JSON.stringify(this.config.voci.FONTI, null, 2)
    this.isRecordsSourcesStringGood = true
    this._snackbarManager.open("Records sources restored!", "ok")
  }


  isEmpty(obj:any) {
    return Object.keys(obj).length == 0
  }

  getFilterIndex(config:any) {
    var i = 0
    config.myExtract.FIELD.every((f:any) => {
      if (f.Entita == "$Filter" && (f.Field.includes("ContractualSalaryLevelCode") || f.Field.includes("CCNLCode"))) return false
      i += 1
      return true
    })
    if (i == config.myExtract.FIELD.length) i = -1
    return i
  }

  getPayrollSourceIndex(config:any) {
    var i = 0
    config.myExtract.FIELD.every((f:any) => {
      if (f.Entita == "$Calculator" && f.Field.includes("payrollCode:")) return false
      i += 1
      return true
    })
    if (i == config.myExtract.FIELD.length) i = -1
    console.log(config.myExtract.FIELD[i]) 
    return i
  }

  buildConfig() {
    //company decoding
    var companyDecodingsString = ""
    var i = 0
    this.companyDecodingsTable.forEach((d:any) => {
      if (this.companyDecodingsTable.length > 1 && i > 0) companyDecodingsString += ';'
      companyDecodingsString += d.input + ',' + d.output
      i += 1
    })
    this.config.decode = companyDecodingsString


    //payroll source
    var payrollField = "payrollCode:"
    if (this.payrollSource == "employmentid") payrollField += "Employments.EmploymentID"
    else if (this.payrollSource == "visadetails") payrollField += "Documents.VisaDetails"
    else if (this.payrollSource == "payrollcode") payrollField += "Employments.PayrollCode"
    var payrollSource = {
      "Entita": "$Calculator",
      "Field": payrollField,
      "Decode": "eval:{0}",
      "Type": "varchar",
      "Label": "Payroll ID"
    }
    i = this.getPayrollSourceIndex(this.config)
    if (i < 0) this.config.myExtract.FIELD.push(payrollSource)
    else this.config.myExtract.FIELD[i] = payrollSource


    //filter
    var fieldString = "noEmploy:NationalContractSalaryLevels."
    if (this.filterOn == "level") fieldString += "ContractualSalaryLevelCode" 
    else if (this.filterOn == "ccnl") fieldString += "CCNLCode" 
    var evalString = this.compileEval()
    var filter = {
				"Entita": "$Filter",
				"Field": fieldString,
				"Decode": evalString,
				"Type": "varchar",
				"Label": "NoPrint"
		}    
    i = this.getFilterIndex(this.config)
    if (i < 0) this.config.myExtract.FIELD.push(filter)
    else this.config.myExtract.FIELD[i] = filter

    console.log(this.configBak)
    console.log(this.config)
    this.config = JSON.parse(JSON.stringify(this.configBak))
    return {}
  }*/

}

import { Component, Input, SimpleChanges } from "@angular/core";
import { MatExpansionModule } from '@angular/material/expansion';
//import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { MatDialog } from "@angular/material/dialog";

import { ApiService } from "src/app/services/api.service";
import { ErrorthrowerService } from "src/app/services/errorthrower.service";
import { SnackbarmanagerService } from "src/app/services/snackbarmanager.service";
import { DialogComponent } from "../dialog/dialog.component";

import { of } from "rxjs"; // Importa 'of' per creare un Observable statico

interface Role {
  RUGUID: string;
  // Aggiungi altre proprietà se necessarie
}

@Component({
  selector: "component-cp082",
  templateUrl: "./cp082.component.html",
  styleUrls: ["./cp082.component.scss"],
})


export class Cp082Component {
  @Input() context: any;
  contextBak: any;
  @Input() teguid: any = "TE0000";
  @Input() labels: any

  saving: boolean = false;

  selectedRole: any = null; // Ruolo selezionato
  roleFunctions: any = null; // Funzione selezionata
  moduleVisibility: { [key: string]: boolean } = {}; // Stato dei moduli aperti/chiusi
  moduleFunctionsVisibility: { [key: string]: boolean } = {}; // Stato dei moduli aperti/chiusi
  groupedFunctions: any[] = [];
  groupedFunctionsForRole: any[] = [];
  allExpanded: boolean = false; // Stato generale (tutto espanso o collassato)
  allFunctionsExpanded: boolean = false; // Stato generale (tutto espanso o collassato)
  selectedFunction: any = null; // Memorizza la funzione selezionata
  selectedFunctionForSettings: any = null;
  selectedFunctionForLayout: any = null;
  moduleRolesState: { [key: string]: boolean } = {};
  moduleFunctionsState: { [key: string]: boolean } = {};
  layoutOptionsMap: { [coguid: string]: Array<{ COGUID: string, COKEY: string, CONAME: string }> } = {};

  constructor(
    private _snackbarManager: SnackbarmanagerService,
    public dialog: MatDialog,
    private _errorThrower: ErrorthrowerService,
    private _api: ApiService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["teguid"] != undefined &&
      changes["teguid"].currentValue != undefined &&
      changes["teguid"].currentValue != ""
    ) {
      this.context = undefined;
      this.selectedRole = null;
      this.getTenantSetup();
    }
  }

  getRoles_Mock() {
    console.log("getRoles: inizio (Mock Mode)");

    // Creiamo una risposta statica senza chiamare l'endpoint
    const response = {};

    // Simuliamo il comportamento dell'API restituendo un Observable statico
    of(response).subscribe((response) => {
      if (response) {
        this._api.printResponse(response);
        this.context = JSON.parse(JSON.stringify(response));
        this.contextBak = JSON.parse(JSON.stringify(response));
        console.log(this.context.roles);
        console.log(this.contextBak.roles);
        this.saving = false;
      }
    });
  }

  getTenantSetup() {
    console.log("getRoles: inizio");

    const payload = {
      TEGUID: this.teguid,
      action: "getTenantSetup", // Indica che vogliamo recuperare i dati
    };

    this._api.doButtonPostRequest(payload, "/client-tenant-setup").subscribe(
      (response) => {
        if (response) {
          this._api.printResponse(response);
          this.context = JSON.parse(JSON.stringify(response));
          this.contextBak = JSON.parse(JSON.stringify(response));
          this.groupedFunctions = this.getGroupedFunctionsFromFunctions(this.context.functions);
          console.log("getRoles: this.context.roles ", this.context.roles);
          console.log("getRoles: this.context.functions ", this.context.functions);
          console.log("getRoles: this.context.layout_options ", this.context.layout_options);
          this.buildLayoutOptionsMap();
          this.saving = false;
        }
      },
      (error) => {
        if (error) {
          console.log(error);
          if (error.error.message != undefined)
            this._snackbarManager.open(error.error.message, "ko");
          else this._snackbarManager.open(error.error, "ko");
          this._api.loading = false;
          this.saving = false;
        }
      }
    );
  }

  getFunctions_old() {
    this._api
      .doButtonPostRequest({ TEGUID: this.teguid }, "/client-functions")
      .subscribe(
        (response) => {
          if (response) {
            this._api.printResponse(response);
            this.context = JSON.parse(JSON.stringify(response));
            this.contextBak = JSON.parse(JSON.stringify(response));
            console.log(this.context.functions);
            console.log(this.contextBak.functions);
            this.saving = false;
          }
        },
        (error) => {
          if (error) {
            console.log(error);
            if (error.error.message != undefined)
              this._snackbarManager.open(error.error.message, "ko");
            else this._snackbarManager.open(error.error, "ko");
            this._api.loading = false;
            this.saving = false;
          }
        }
      );
  }

  getGroupedFunctions_old(functions: any[]): any[] {
    const grouped = functions.reduce((acc, func) => {
      const moduleName = func.MODESC; // Nome del modulo
      if (!acc[moduleName]) {
        acc[moduleName] = { moduleName, functions: [] };
      }
      acc[moduleName].functions.push(func);
      return acc;
    }, {});

    return Object.values(grouped);
  }

  getGroupedFunctionsFromFunctions(functions: any[]): any[] {
    const groups = functions.reduce((acc, func) => {
      const moduleOrd = func.MOORDI; // Nome del modulo
      const moduleName = func.MODESC; // Nome del modulo
      const moduleCode = func.MOCODE; // Codice del modulo
      const moduleKey = moduleOrd + " - " + moduleName + " (" + moduleCode + ")";

      /*if (!acc[moduleCode]) {
        acc[moduleCode] = {
          moduleCode: moduleCode,
          moduleName: func.MODESC || '',
          functions: []
        };
      }*/
      if (!acc[moduleKey]) {
        acc[moduleKey] = { moduleOrd, moduleName, moduleCode, functions: [] };
      }

      //acc[moduleCode].functions.push(func);
      acc[moduleKey].functions.push(func);

      // Inizializza lo stato del modulo solo se non è già definito
      if (this.moduleFunctionsVisibility[moduleCode] === undefined) {
        this.moduleFunctionsVisibility[moduleCode] = false; // Moduli chiusi di default
      }
      return acc;
    }, {});
    return Object.values(groups);
  }

  getGroupedFunctions(functions: any[]): any[] {
    const grouped = functions.reduce((acc, func) => {
      const moduleOrd = func.MOORDI; // Nome del modulo
      const moduleName = func.MODESC; // Nome del modulo
      const moduleCode = func.MOCODE; // Codice del modulo
      // const moduleKey = `${moduleName} (${moduleCode})`; // Identificativo univoco
      const moduleKey =
        moduleOrd + " - " + moduleName + " (" + moduleCode + ")";

      if (!acc[moduleKey]) {
        acc[moduleKey] = { moduleOrd, moduleName, moduleCode, functions: [] };
      }
      acc[moduleKey].functions.push(func);

      // Inizializza lo stato del modulo solo se non è già definito
      if (this.moduleVisibility[moduleCode] === undefined) {
        this.moduleVisibility[moduleCode] = false; // Moduli chiusi di default
      }

      return acc;
    }, {});

    return Object.values(grouped);
  }

  selectFunction(func: any) {
    this.roleFunctions = func;
  }

  selectRole(role: any) {
    this.selectedRole = role;
    this.roleFunctions = role.functions; // Reset della funzione selezionata quando cambia il ruolo
    // Se il ruolo ha funzioni, raggruppale e memorizza il risultato
    if (role && role.functions) {
      this.groupedFunctionsForRole = this.getGroupedFunctions(role.functions);
    } else {
      this.groupedFunctionsForRole = [];
    }
  }
  /*
  normalizeFTATTI() {
    this.context.functions.forEach((f: any) => {
      if (f.FTATTI == true) f.FTATTI = 1;
      else f.FTATTI = 0;
    });
  }*/

  checkChanges() {
    // this.normalizeFTATTI();
    return (
      JSON.stringify(this.context.functions) ===
      JSON.stringify(this.contextBak.functions) ||
      JSON.stringify(this.context.roles) ===
      JSON.stringify(this.contextBak.roles)
    );
  }

  save() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Change active functions",
        content: "Would you like to apply these changes?",
        accept: "Yes",
        reject: "No",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.saving = true;
        //this.normalizeFTATTI();

        this._api
          .doButtonPostRequest(this.context, "/client-functions")
          .subscribe(
            (response) => {
              if (response) {
                this._api.printResponse(response);
                var responseObj = JSON.parse(JSON.stringify(response));
                this.contextBak = JSON.parse(JSON.stringify(response));
                if (responseObj.isSuccess) {
                  //console.log(responseObj.clients)
                  this._snackbarManager.open("Success!", "ok");
                } else this._snackbarManager.open(responseObj.error, "ko");
                this._api.loading = false;
                this.saving = false;
              }
            },
            (error) => {
              if (error) {
                console.log(error);
                if (error.error.message != undefined)
                  this._snackbarManager.open(error.error.message, "ko");
                else if (error.error != undefined)
                  this._snackbarManager.open(error.error, "ko");
                else if (error.message != undefined)
                  this._snackbarManager.open(error.message, "ko");
                else this._snackbarManager.open("Failed!", "ko");
                this._api.loading = false;
                this.saving = false;
              }
            }
          );
      }
    });
  }

  toggleModule(moduleCode: string): void {
    this.moduleVisibility[moduleCode] = !this.moduleVisibility[moduleCode];
  }

  toggleAllModules(): void {
    this.allExpanded = !this.allExpanded;
    Object.keys(this.moduleVisibility).forEach((key) => {
      this.moduleVisibility[key] = this.allExpanded;
    });
  }

  toggleFunctionsModule(moduleCode: string): void {
    console.log("toggleFunctionsModule: inizio")
    this.moduleFunctionsVisibility[moduleCode] = !this.moduleFunctionsVisibility[moduleCode];
  }

  toggleAllFunctionsModules(): void {
    this.allFunctionsExpanded = !this.allFunctionsExpanded;
    Object.keys(this.moduleFunctionsVisibility).forEach((key) => {
      this.moduleFunctionsVisibility[key] = this.allFunctionsExpanded;
    });
  }

  // Metodo per aprire la finestra modale con i dettagli della funzione
  openFunctionConfig(func: any): void {
    this.selectedFunction = { ...func }; // Clona l'oggetto per evitare modifiche dirette
  }

  // Metodo per chiudere la finestra modale
  closeModal(): void {
    this.selectedFunction = null;
  }

  findUpdatedFunction(IN_FAGUID: string) {
    for (let role of this.context.roles) {
      for (let func of role.functions) {
        if (func.FAGUID === IN_FAGUID) {
          return { ...func }; // Crea una nuova istanza per forzare il binding
        }
      }
    }
    return null;
  }

  findCurrentRole(ruguid: string) {
    return this.context.roles.find((role: any) => role.RUGUID === ruguid);
  }


  saveFunction() {
    if (!this.selectedFunction) {
      console.log("saveFunction: Nessuna funzione selezionata.");
      return;
    }

    const originalFunction = this.selectedRole?.functions.find(
      (f: any) => f.FTGUID === this.selectedFunction.FTGUID
    );

    if (!originalFunction) {
      console.log(
        "saveFunction: Funzione originale non trovata, non possiamo inviare il vecchio stato."
      );
      return;
    }

    const payload = {
      TEGUID: this.teguid,
      RUGUID: this.selectedRole.RUGUID,
      FACODE: this.selectedFunction.FACODE,
      FTGUID: this.selectedFunction.FTGUID,
      FAENABLED: this.selectedFunction.FAENABLED,
      IS_STANDARD: this.selectedFunction.IS_STANDARD,
      action: "saveFunction", // Indica che vogliamo salvare la funzione
      originalData: {
        // Dati originali per confronto
        FAENABLED: originalFunction.FAENABLED,
        IS_STANDARD: originalFunction.IS_STANDARD,
      },
    };

    console.log("saveFunction: Salvataggio funzione con payload:", payload);

    this._api.doButtonPostRequest(payload, "/client-tenant-setup").subscribe(
      (response) => {
        console.log("saveFunction: Risposta dal server:", response);
        this._snackbarManager.open("Salvataggio completato", "ok");

        this.updateLocalFunctionState(
          this.selectedFunction.FTGUID,
          this.selectedFunction.FAENABLED,
          this.selectedFunction.IS_STANDARD
        );

        const previousRoleGUID = this.selectedRole ? this.selectedRole.RUGUID : null; // Salva il GUID del ruolo selezionato

        this.context = undefined;
        this.selectedRole = null;
        this.getTenantSetup();

        setTimeout(() => {
          if (this.context && this.context.roles && this.context.roles.length > 0) {
            const restoredRole = this.context.roles.find((role: any) => role.RUGUID === previousRoleGUID);
            if (restoredRole) {
              this.selectRole(restoredRole);
            } else {
              this.selectRole(this.context.roles[0]);
            }
            this.closeModal();
            this.loadRolesPanelState();
          } else {
            console.log("saveFunction: Nessun ruolo disponibile dopo il refresh.");
            this.closeModal();
            this.loadRolesPanelState();
          }
        }, 1000);

      },
      (error) => {
        console.error("saveFunction: Errore durante il salvataggio:", error);
        this._snackbarManager.open("Errore durante il salvataggio", "ko");
      }
    );
  }

  private loadRolesPanelState() {
    const savedState = localStorage.getItem('myPanelState');
    if (savedState) {
      this.moduleRolesState = JSON.parse(savedState);
    }
  }

  updateLocalFunctionState(
    IN_FTGUID: string,
    IN_FAENABLED: boolean,
    IN_IS_STANDARD: boolean
  ) {
    if (!this.selectedRole || !this.selectedRole.functions) {
      return;
    }

    // const functionToUpdate = this.selectedRole.functions.find(f => f.FTGUID === IN_FTGUID);
    const functionToUpdate = this.selectedRole.functions.find(
      (f: any) => f.FTGUID === IN_FTGUID
    );

    if (functionToUpdate) {
      functionToUpdate.FAENABLED = IN_FAENABLED;
      functionToUpdate.IS_STANDARD = IN_IS_STANDARD;
    }
  }

  // Metodo per aprire la nuova finestra modale
  openFunctionSettings(func: any): void {
    console.log("openFunctionSettings: start");
    this.selectedFunctionForSettings = { ...func }; // Cloniamo per evitare modifiche dirette

    // Se FAPARA è una stringa JSON, prova a parsarla per l'editing leggibile
    try {
      this.selectedFunctionForSettings.FAPARA = JSON.stringify(JSON.parse(func.FAPARA || '{}'), null, 2);
    } catch (error) {
      console.error('Errore nel parsing del JSON di FAPARA', error);
    }

  }

  // Metodo per chiudere la finestra modale
  closeFunctionSettings(): void {
    this.selectedFunctionForSettings = null;
  }

  // Metodo per salvare le configurazioni avanzate (da implementare)
  saveFunctionSettings() {
    // Trovi la funzione originale in base all'FTGUID
    const originalFunction = this.context.functions.find(
      (f: any) => f.FAGUID === this.selectedFunctionForSettings.FAGUID
    );

    if (!originalFunction) {
      console.log("Funzione originale non trovata, impossibile salvare il vecchio stato.");
      return;
    }

    // Crea una copia dell'oggetto originale per avere un backup dello stato precedente
    const backupOriginalFunction = JSON.parse(JSON.stringify(originalFunction));


    try {
      // Controlla se il JSON è valido
      const jsonparsed = JSON.parse(this.selectedFunctionForSettings.FAPARA);
      // 2. Re-stringify senza spazi né a capo
      const jsoncompressed = JSON.stringify(jsonparsed);

      // Aggiorna la funzione selezionata con la nuova configurazione
      const index = this.context.roles.findIndex((role: any) =>
        role.functions.some((f: any) => f.FAGUID === this.selectedFunctionForSettings.FAGUID)
      );

      if (index !== -1) {
        let role = this.context.roles[index];
        let functionIndex = role.functions.findIndex((f: any) => f.FAGUID === this.selectedFunctionForSettings.FAGUID);
        if (functionIndex !== -1) {
          role.functions[functionIndex].FAPARA = this.selectedFunctionForSettings.FAPARA;
        }
      }

      // Costruisci il payload da inviare al backend
      const payload = {
        action: "saveFunctionConfiguration",
        TEGUID: this.teguid,                   // se lo usi
        FACODE: this.selectedFunctionForSettings.FACODE,
        FAGUID: this.selectedFunctionForSettings.FAGUID,
        FADESC: this.selectedFunctionForSettings.FADESC,
        IS_STANDARD: this.selectedFunctionForSettings.IS_STANDARD ? 1 : 0,
        FAPARA: jsoncompressed,
        originalData: {
          // Dati originali per confronto
          FADESC: originalFunction.FADESC,
          IS_STANDARD: originalFunction.IS_STANDARD,
          ORIGINAL_CONFIGURATION: backupOriginalFunction
        },
      };

      console.log("saveFunction: Salvataggio configurazione della funzione con payload:", payload);

      this._api.doButtonPostRequest(payload, "/client-tenant-setup").subscribe(
        (response) => {
          console.log("saveFunction: Risposta dal server:", response);
          this._snackbarManager.open("Salvataggio completato", "ok");

          const previousRoleGUID = this.selectedRole ? this.selectedRole.RUGUID : null; // Salva il GUID del ruolo selezionato

          this.context = undefined;
          this.selectedRole = null;
          this.getTenantSetup();
          this.closeModal(); // Chiude il pop-up dopo il salvataggio

        },
        (error) => {
          console.error("saveFunction: Errore durante il salvataggio:", error);
          this._snackbarManager.open("Errore durante il salvataggio", "ko");
          this.selectedFunctionForSettings = null;
          this.closeModal(); // Chiude il pop-up dopo il salvataggio
        }
      );

      this.selectedFunctionForSettings = null;
      console.log('Configurazione aggiornata con successo!');
    } catch (error) {
      alert('Errore: il JSON inserito non è valido.');
    }
  }

  // Metodo per aprire la finestra di configurazione del layout
  openLayoutConfig(func: any): void {
    this.selectedFunctionForLayout = { ...func }; // Clona l'oggetto per evitare modifiche dirette
  }

  // Metodo per chiudere la finestra modale del layout
  closeLayoutModal(): void {
    this.selectedFunctionForLayout = null;
  }

  // Metodo per salvare il layout

  saveLayout() {
    // 1. Verifica che esista una funzione selezionata
    if (!this.selectedFunctionForLayout) {
      console.warn("saveLayout: Nessuna funzione selezionata!");
      return;
    }

    // 2. Recupera la lista di componenti. 
    //    (Se la finestra mostra "selectedFunctionForLayout.components", 
    //     allora questo è l'array da inviare.)
    const components = this.selectedFunctionForLayout.components;

    // Se preferisci assicurarti che non sia vuoto:
    if (!components || components.length === 0) {
      console.warn("saveLayout: Nessun componente da salvare.");
      return;
    }

    // 3. Costruisci il payload con tutte le info necessarie
    //    Includi i campi che il tuo backend si aspetta (TEGUID, FTGUID, etc.)
    const payload = {
      action: "saveFunctionLayout",
      TEGUID: this.teguid,                   // se lo usi
      RUGUID: this.selectedRole?.RUGUID,     // se serve il ruolo
      FTGUID: this.selectedFunctionForLayout.FTGUID,
      FACODE: this.selectedFunctionForLayout.FACODE,
      FAGUID: this.selectedFunctionForLayout.FAGUID,
      components: components // L'array completo di righe
    };

    console.log("saveLayout: Sto inviando un payload con tutti i componenti:", payload);

    // 4. Esegui la chiamata HTTP verso il tuo endpoint
    this._api.doButtonPostRequest(payload, "/client-tenant-setup").subscribe(
      (response) => {
        console.log("saveLayout: Risposta dal server:", response);
        this._snackbarManager.open("Salvataggio completato", "ok");
        this.context = undefined;
        this.selectedRole = null;
        this.getTenantSetup();
        this.closeLayoutModal();
      },
      (error) => {
        console.error("saveLayout: Errore durante il salvataggio:", error);
        this._snackbarManager.open("Errore durante il salvataggio", "ko");
        this.closeModal(); // Chiude il pop-up dopo il salvataggio
      }
    );
  }


  /**
   * Ritorna le opzioni COKEY disponibili per uno specifico COGUID
   */
  getCokeyOptions(coguid: string): Array<{ COGUID: string, COKEY: string, CONAME: string }> {
    // Se non ci fosse nulla in mappa, restituisci array vuoto

    return this.layoutOptionsMap[coguid] || [];
  }

  buildLayoutOptionsMap(): void {
    console.log("buildLayoutOptionsMap: start : ", this.context.layout_options);

    // Se `this.context.layout_options` è assente, non faccio nulla
    if (!this.context || !this.context.layout_options) {
      return;
    }

    // 1) Re-inizializzo la mappa ogni volta
    this.layoutOptionsMap = {};

    // 2) Ricostruisco la mappa in base ai dati correnti
    for (const opt of this.context.layout_options) {
      if (!this.layoutOptionsMap[opt.COGUID]) {
        this.layoutOptionsMap[opt.COGUID] = [];
      }
      this.layoutOptionsMap[opt.COGUID].push(opt);
    }

    console.log("buildLayoutOptionsMap: this.layoutOptionsMap ", this.layoutOptionsMap);
  }


  onPanelRoleOpened(code: string): void {
    this.moduleRolesState[code] = true;
    this.savePanelRoleState();
  }

  onPanelRoleClosed(code: string): void {
    this.moduleRolesState[code] = false;
    this.savePanelRoleState();
  }

  private savePanelRoleState(): void {
    localStorage.setItem('myPanelRolesState', JSON.stringify(this.moduleRolesState));
  }


  onPanelFunctionOpened(code: string): void {
    this.moduleFunctionsState[code] = true;
    this.savePanelFunctionState();
  }

  onPanelFunctionClosed(code: string): void {
    this.moduleFunctionsState[code] = false;
    this.savePanelFunctionState();
  }

  private savePanelFunctionState(): void {
    localStorage.setItem('myPanelFunctionsState', JSON.stringify(this.moduleFunctionsState));
  }

  onTogglePersonalization(event: any): void {
    if (event.checked) {
      // Se l'utente attiva la personalizzazione, segnaliamo la funzione come non standard.
      this.selectedFunctionForSettings.IS_STANDARD = false;
    }
    // Non gestiamo il caso di deselezione, perché una volta personalizzata (IS_STANDARD false)
    // il checkbox è disabilitato e l'utente non può tornare indietro.
  }

  getSelectedOptionConame(component: any): string {
    const selected = this.getCokeyOptions(component.COGUID)
      .find(opt => opt.COKEY === component.COKEY);
    return selected ? selected.CONAME : '';
  }
}


import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'
import { ExcelexporterService } from 'src/app/services/excelexporter.service';
 

@Component({
  selector: 'component-cp008',
  templateUrl: './cp008.component.html',
  styleUrls: ['./cp008.component.scss']
})
export class Cp008Component implements OnInit {

  @Input() context: any;

  @ViewChild('dt') table1: Table | undefined;
  columns:any = []

  loading: boolean = true
  multiSortMeta:SortMeta[] = [];
  excelRows: any;
  

  constructor(private _api:ApiService, private router:Router, private _excel:ExcelexporterService) { }

  ngOnInit(): void {

    //fare una struttura dati fatta bene per le colonne serve per il sort e il filter
    for (var i = 0; i < this.context.Column.length; i++) {
      this.columns.push({field: "Elemento" + (i + 1), header: this.context.Column[i]})
      if (i == 4) i++;
    }
    //console.log(this.columns);

    this.multiSortMeta.push({field: '', order: 1});
    this.loading = false;

  }

  open(link:string) {
    //console.log(link);
    this.router.navigate([link]);
  }

  exportTable() {
    this._excel.export("Results", this.columns, this.context.Row)
  }

}

<div *ngIf="context.clients.length == 0 || context.base.length == 0">
    <mat-card>
        <mat-card-content style="text-align: center;">{{context.labels.nointegrationText}}</mat-card-content>
    </mat-card>
</div>

<div style="display:flex;flex-direction: row;justify-content: space-between;align-items: center;">
    <mat-card *ngIf="context.clients.length > 0 && context.base.length > 0" style="margin-bottom:16px; width:100%;">
        <mat-card-content>
            <div style="display:flex; flex-direction: row; justify-content: center; margin-bottom: -12px;">
                <mat-form-field appearance="fill" style="margin-top:4px; width:225px">
                    <mat-label>{{isEmpty(config) ? context.labels.chooseintegrationText : context.labels.integrationBox}}</mat-label>
                    <mat-select #select (selectionChange)="this.loading = true; runLoadConfig($event.value)" [disabled]="loading">
                        <mat-option *ngFor="let client of context.clients" [value]="client.INGUID">
                            {{client.INDESC}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--!isEmpty(config)-->
                <button *ngIf="saveButtonEnabled" mat-fab color="accent" (click)="buildConfig()" [@inOutAnimation] class="print-button" [disabled]="loading">
                    <div class="print-button-div">
                        <mat-icon *ngIf="!loading || !saving" style="transform:scale(.9)">save</mat-icon>
                        <mat-icon *ngIf="loading || saving" class="loading-icon">settings</mat-icon>
                        <span *ngIf="!saving && !loading" style="font-size:10px; margin-top:-6px">Save</span>
                        <span *ngIf="saving && !loading" style="font-size:8px; margin-top:-6px">Saving</span>
                        <span *ngIf="!saving && loading" style="font-size:8px; margin-top:-6px">Loading</span>
                    </div>
                </button>
                <button *ngIf="!saveButtonEnabled" mat-fab color="accent" class="fake-button" [disabled]="true">
                    <mat-icon style="transform:scale(.9)">save</mat-icon>
                </button>
                
            </div>
            <ng-container *ngIf="this.emptyConfig">
                <mat-icon>warning</mat-icon>Configuration is empty, using BASE template.
            </ng-container>
        </mat-card-content>

    </mat-card>
    <img *ngIf="false" height=90px src="../../../../../assets/icon/logoBackgroundRect.png" class="logo" [@inOutAnimation]>
</div>

<component-cp071 [style.display]="saveButtonEnabled && type == 'ToPaghe' ? 'block' : 'none'"></component-cp071>
<component-cp072 [style.display]="saveButtonEnabled && type == 'Paghe' ? 'block' : 'none'"></component-cp072>
<component-cp073 [style.display]="saveButtonEnabled && type == 'Retribuzioni' ? 'block' : 'none'"></component-cp073>

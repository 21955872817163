import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ApiService } from '../../services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StylerService } from 'src/app/services/styler.service';


@Component({
  selector: 'component-cp006',
  templateUrl: './cp006.component.html',
  styleUrls: ['./cp006.component.scss']
})
export class Cp006Component implements OnInit {

  @Input() context: any;
  @ViewChild('fileUpload') input:  ElementRef | undefined;

  GENERATE_UPLOAD_URL_ENDPOINT = "/o-generate-upload-url";
  REMOVE_UPLOAD_ENDPOINT = "/o-attachment-delete";

  fileName: string = "";
  oucoda: string = "";
  inputElement: any;
  formData: FormData = new FormData();
  selectedFiles: Array<File> = [];
  selectedFilesUploadStatus:boolean[] = [];
  allowedFileExtension: string = "";
  disabledUpload:boolean = false;
  disabledDelete:boolean = false;
  uploadCooldown:number = 5000;

  constructor(private _api: ApiService, private  _snackbarManager:SnackbarmanagerService, private dialog: MatDialog, public _styler:StylerService) { }

  ngOnInit(): void {
    this.oucoda = this._api.lastOUCODA;
    //console.log(this.oucoda);
    //console.log(this.context);
    if (this.context.Upload != undefined && this.context.Upload.length > 0)
      this.allowedFileExtension = this.context.Upload[0].ExtensionsFile;
    //console.log(this.allowedFileExtension);
  }

  onFileSelected(event: any){
    let filesArray = event.target.files;

    if (filesArray.length > 0) {
      let something: boolean = false;
      for (let i = 0; i < filesArray.length; ++i) {
        if (this.selectedFiles.length > 0) {
          something = this.isInArray(this.selectedFiles, filesArray[i].name);
          //console.log(something);
        } else{
          something = false;
        }
        if(something == false){
          this.selectedFiles.push(filesArray[i]);
          this.selectedFilesUploadStatus.push(false);
        }
      }
      this.onClickUploadNew();
    }
    this.input!.nativeElement.value = '';
  }

  isInArray(container: any[], value: any): boolean{
    for(let i = 0; i < container.length; ++i){
      if(value === container[i].name){
        return true;
      }
    }
    return false;
  }

  onClickUploadNew(){
    //this.disabledUpload = true;
    for(let i = 0; i < this.selectedFiles.length; ++i){

      let contextData = {
        'filename': this.selectedFiles[i].name,
        'OUCODA': this.oucoda
      }

      this._api.doButtonPostRequest(contextData, this.GENERATE_UPLOAD_URL_ENDPOINT).subscribe(

        (response: any) => {
          this.formData = new FormData();

          this.formData.append('key', response.fields.key);
          this.formData.append('AWSAccessKeyId', response.fields.AWSAccessKeyId);
          this.formData.append('x-amz-security-token', response.fields["x-amz-security-token"]);
          this.formData.append('policy', response.fields.policy);
          this.formData.append('signature', response.fields.signature);
          this.formData.append('file', this.selectedFiles[i]);

          //console.log(this.selectedFiles[i])
          this._api.doUploadFile(response.url, this.formData).subscribe(
            (response: any) => {
              this.selectedFilesUploadStatus[i] = true;     
              //setTimeout(() => this.disabledUpload = false, this.uploadCooldown);        
              this._snackbarManager.open('Upload completato con successo!', 'ok');        
            }, (error: any) => {
              console.log(error);
              if (error.status === 0){
                // fai partire notifica di successo.
                // si controlla lo status a 0 perche' non viene messo a 204
                // quando il response non ha body.
                this.disabledUpload = false;
                this.selectedFiles.pop();
                this._snackbarManager.open('Upload error!', 'ko');   

                //setTimeout(() => this.disabledUpload = false, this.uploadCooldown);  
              } else {
                this.disabledUpload = false;
                this.selectedFiles.pop();
                this._snackbarManager.open('Upload error!', 'ko');
              }
            });
            this._api.loading = false;
        }, (error: any) => {
          console.log(error);
          this._api.loading = false;
          this.disabledUpload = false;
        }
      );
    }
  }

  onClickDeleteUpload(filename:string, i:number){
    let contextData = {
      'filename': filename,
      'OUCODA': this.oucoda
    };
    this.selectedFilesUploadStatus[i] = false;
    this.disabledDelete = true

    this._api.doButtonPostRequest(contextData, this.REMOVE_UPLOAD_ENDPOINT).subscribe(
      (response: any) => {
        console.log(response);
        if(response.isSuccess){
          this.disabledDelete = false;
          this.selectedFiles.splice(i, 1);
          this.selectedFilesUploadStatus.splice(i,1);
        }
      }, (error: any) => {
        console.log(error);
        this.selectedFilesUploadStatus[i] = true;
        this._snackbarManager.open("File deletion error!", 'ko');
      }
    );
  }

}

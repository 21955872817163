<div [style.display]="showApinavigator ? 'block' : 'none'" class="card-background">
    <component-cp062 #apiNavigator (selectEvent)="closeApiNavigator($event)"></component-cp062>
</div>

<div class="filter-card">
    <ng-container *ngIf="!isString(evalObj)">
        <ng-container *ngFor="let element of evalObj; index as e">
            <mat-card *ngIf="!isString(element)" class="filter-innercard">
                <mat-form-field style="width:100%">
                    <mat-label>Parameter</mat-label>
                    <input matInput required readonly (click)="this.openApiNavigator(1, e)" [(ngModel)]="element.Parameter['Label']">
                </mat-form-field>
                <mat-form-field style="min-width:200px">
                    <mat-label>Operator</mat-label>
                    <mat-select [(ngModel)]="element.Operator" required>
                        <mat-option value="==">Equals to</mat-option>
                        <mat-option value="!=">Not equals to</mat-option>
                        <mat-option value=">">Greater than</mat-option>
                        <mat-option value=">=">Greater than equal to</mat-option>
                        <mat-option value="<">Smaller than</mat-option>
                        <mat-option value="<=">Smaller than equal to</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="width:100%">
                    <mat-label>Value</mat-label>
                    <input matInput [(ngModel)]="element.Value">
                </mat-form-field>
                
                <button mat-mini-fab (click)="removeElement(e)" class="remove-button">
                    <mat-icon>remove</mat-icon>
                </button>
            </mat-card>
            <div style="display:flex; justify-content: center;">
                <mat-button-toggle-group *ngIf="isString(element)" [value]="element" (change)="changeAndOr($event, e)">
                    <mat-button-toggle value="AND">And</mat-button-toggle>
                    <mat-button-toggle value="OR">Or</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </ng-container>
        <div style="display:flex; justify-content:center;">
            <button mat-mini-fab (click)="addCondition()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="isString(evalObj.Eval)">
        <mat-form-field style="width:100%">
            <mat-label>Eval</mat-label>
            <input matInput required readonly [(ngModel)]="evalObj.Eval">
        </mat-form-field>
    </ng-container>
</div>
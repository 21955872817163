<mat-card class="main_card">
    <mat-card-content>
        <div *ngIf="context.Description != null && context.Description != ''" class="description_div">
            {{ context.Description }}
        </div>
        <div>
            <mat-form-field appearance="fill" color="accent" style="width: 100%">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker" [disabled]="context.ReadOnly == '1'" 
                    [(ngModel)]="selectedDate" (dateChange)="chosenDateHandler($event, picker)">              
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-hint>DD/MM/YYYY</mat-hint>
            </mat-form-field>
        </div>
    </mat-card-content>
</mat-card>

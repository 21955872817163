import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import { ApiService } from '../../services/api.service'
import { ExcelexporterService } from 'src/app/services/excelexporter.service';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';

@Component({
  selector: 'component-cp028',
  templateUrl: './cp028.component.html',
  styleUrls: ['./cp028.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class Cp028Component implements OnInit {

  @Input() context: any;
  @ViewChild('dt1') table1: Table | undefined;
  @ViewChild('dt2') table2: Table | undefined;
  columns:any = [];
  filteredRows:any = [];
  filteredRows2:any = [];
  row2ElementsCount = 9;
  rowElementsCount = 9;

  loading: boolean = true
  multiSortMeta:SortMeta[] = [];

  @ViewChild(MatMenuTrigger, {static: true}) rightClickMenuTrigger: MatMenuTrigger | undefined; 
  rightClickMenuPosition =  {x: '0', y: '0'} 

  constructor(private _api:ApiService, private _excel:ExcelexporterService) { }

  ngOnInit(): void {

    //fare una struttura dati fatta bene per le colonne serve per il sort e il filter
    var i = 1;
    this.context.Column.forEach((c:any) => {
      this.columns.push({field: "Elemento" + i, header: c})
      i++;
      if (i == 5) i++;
    })

    if (this.context.Row2 != undefined && this.context.Row2.length > 0) this.row2ElementsCount = Object.keys(this.context.Row2[0]).length;
    if (this.context.Row != undefined && this.context.Row.length > 0) this.rowElementsCount = Object.keys(this.context.Row[0]).length

    this.multiSortMeta.push({field: '', order: 1});
    this.loading = false;
  }

  onFilter(event:any, tableIndex:any) { 
    if (tableIndex == 1) this.filteredRows = event.filteredValue; 
    if (tableIndex == 2) this.filteredRows2 = event.filteredValue; 
  }

  viewNewTab(obguid:any) {
    var urlParams = this._api.getUrlParameters();
    var tabUrl = urlParams.apguid + '/' + urlParams.teguid + '/' + 
      urlParams.moguid + '/' + this.context.function + '/' + obguid; 
    window.open(tabUrl, "_blank");
  }

  view(obguid:any) {
    this._api.changeFunction(this.context.function, obguid);
  }

  exportTable(title:string, rows:any) {
    this._excel.export(title, this.columns, rows)
  }

  onRightClick(event: MouseEvent, obguid:any) { 
    event.preventDefault(); 
    this.rightClickMenuPosition.x = event.clientX + 'px'; 
    this.rightClickMenuPosition.y = event.clientY + 'px'; 
    this.rightClickMenuTrigger!.menuData = {obguid: obguid};
    this.rightClickMenuTrigger!.openMenu(); 
  } 

}

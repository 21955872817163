import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';

@Component({
  selector: 'component-cp022',
  templateUrl: './cp022.component.html',
  styleUrls: ['./cp022.component.scss']
})
export class Cp022Component implements OnInit {

  @Input()
  context:any;
  @Input()
  position:any;

  @ViewChild('pdfContent')
  pdfContent!: ElementRef;

  constructor(private _api:ApiService, private _snackbarManager:SnackbarmanagerService) { }

  ngOnInit(): void {
  }

  print(){
    let height = document.querySelector('mat-drawer-content')?.scrollHeight;
    document.documentElement.style.setProperty('--print-height', height + 'px');
    window.print();
  }
  
  download() {
   this.doButtonPost(this.context.ActionDownload);
  }

  save() {
    //this.doButtonPost(this.context.ActionAPI);
    this.doButtonPost("/pdf");
  }

  doButtonPost(context:any) {    
    this._api.loading = true;
    
    var context_data = {
      "OUCODA" : this._api.lastOUCODA,
      "Sections" : this._api.lastSections
    };
    
    this._api.doButtonPostRequest(context_data, context).subscribe((response:any) => {
      if(response) {
        this._api.printResponse(response)
        this._api.loading = false; 
        if (response.temp_url != undefined) window.open(response.temp_url);
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this._snackbarManager.open(JSON.parse(JSON.stringify(error)).error, 'ko');
        //this._api.lastError = JSON.parse(JSON.stringify(error));
        //this._api.navigate("","","","","ErrorPage");
        this._api.loading = false; 
      }
    });
  }

}

<div [style.display]="showApinavigator ? 'block' : 'none'" style="position:absolute" class="card-background">
    <component-cp062 #apiNavigator (selectEvent)="closeApiNavigator($event)"></component-cp062>
</div>

<div style="display:flex; justify-content: center;">
    <div *ngIf="context == undefined || loading" class="spinner-div">
        <mat-spinner></mat-spinner>
    </div>
</div>

<mat-tab-group *ngIf="!loading" class="tab-group" color="accent">
    <mat-tab [label]="labels.groupsLabel" class="tab" style="display:flex; flex-direction:column; gap:8px; padding:12px">
        <mat-expansion-panel *ngFor="let group of context.config.Groups; index as g" style="background-color: whitesmoke">
            <mat-expansion-panel-header style="height:64px; padding-left:0">
                <mat-panel-title style="display:flex; justify-content:space-between">
                    <div>
                        <mat-checkbox color="accent" style="margin-left:24px" (click)="$event.stopPropagation()"
                            [(ngModel)]="context.config.Groups[g].Enabled"
                            [disabled]="group.Essential != undefined"></mat-checkbox>
                            <span style="margin-left:8px;">{{context.config.Groups[g].DefaultLabel}}</span>
                    </div>
                    <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Label != undefined" (click)="$event.stopPropagation()"
                        style="margin-top:15px; transform:scale(.8)">
                        <mat-label>{{labels.labelLabel}}</mat-label>
                        <input matInput [(ngModel)]="context.config.Groups[g].Label">
                    </mat-form-field>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-left:16px">
                <div style="display:flex; justify-content:space-between; flex-direction:column; gap:8px">
                    <ng-container *ngFor="let field of context.config.Groups[g].Fields; index as f">
                        <mat-expansion-panel *ngIf="field.DateKey == undefined">
                            <mat-expansion-panel-header style="height:64px; padding-left:0">
                                <mat-panel-title style="display:flex; justify-content:space-between">
                                    <div>
                                        <mat-checkbox color="accent" style="margin-left:24px" (click)="$event.stopPropagation()" (change)="updateFieldStatus(context.config, g, f)"
                                        [checked]="context.config.Groups[g].Fields[f].Enabled" [disabled]="!context.config.Groups[g].Enabled">
                                            {{context.config.Groups[g].Entities.length > 1 && context.config.Groups[g].Fields[f].Entity != undefined ? getEntityLabel(context.config.Groups[g], context.config.Groups[g].Fields[f].Entity) + ' - ' : ''}}{{context.config.Groups[g].Fields[f].DefaultLabel}}
                                        </mat-checkbox>
                                    </div>
                                    <mat-form-field class="mini-form" appearance="fill" (click)="$event.stopPropagation()"
                                        style="margin-top:15px; transform:scale(.8)" *ngIf="context.config.Groups[g].Fields[f].Label != undefined">
                                        <mat-label>{{labels.labelLabel}}</mat-label>
                                        <input matInput [(ngModel)]="context.config.Groups[g].Fields[f].Label">
                                    </mat-form-field>                                
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-card *ngIf="context.config.Groups[g].Fields[f].Calculation != undefined" style="margin-bottom:12px; ">
                                <mat-card-title class="title-card-mini">
                                    <mat-icon class="title-icon">settings_applications</mat-icon>
                                    {{labels.calculationLabel}}
                                </mat-card-title>
                                <mat-card-content style="display:flex; flex-direction:column; gap:8px; background-color:whitesmoke; padding:12px; align-items: flex-start;">
                                    <div style="display:flex; flex-direction:column;background-color:rgb(235, 235, 235); padding:6px; padding-left:10px">
                                        <span style="font-size:12px; color:gray">Input</span>
                                        <mat-chip-set>
                                            <ng-container *ngFor="let input of context.config.Groups[g].Fields[f].Calculation.Input; index as i">
                                                <mat-chip (removed)="removeInputVariable(context.config.Groups[g].Fields[f].Calculation.Input, i)">
                                                    <b>{{i}})</b> {{input.Label}}
                                                    <button matChipRemove>
                                                    <mat-icon>cancel</mat-icon>
                                                    </button>
                                                </mat-chip> 
                                            </ng-container>                                      
                                            <button mat-mini-fab style="transform:scale(.5)" color="accent" (click)="addInputVariable(g, f)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </mat-chip-set>
                                    </div>
                                    <div style="display:flex; flex-direction:row; gap:8px; width:100%; margin-top:8px">
                                        <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Fields[f].Calculation.Eval != undefined" style="width:75%">
                                            <mat-label>Eval</mat-label>
                                            <input matInput [(ngModel)]="context.config.Groups[g].Fields[f].Calculation.Eval">
                                        </mat-form-field>
                                        <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Fields[f].Calculation.Format != undefined" style="width:75%">
                                            <mat-label>Format</mat-label>
                                            <input matInput [(ngModel)]="context.config.Groups[g].Fields[f].Calculation.Format">
                                        </mat-form-field>
                                        <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Fields[f].Calculation.Decoding != undefined" style="width:75%">
                                            <mat-label>{{labels.mappingsLabel}}</mat-label>
                                            <input matInput [(ngModel)]="context.config.Groups[g].Fields[f].Calculation.Decoding">
                                        </mat-form-field>
                                        <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Fields[f].Calculation.Output != undefined" style="width:25%">
                                            <mat-label>Output</mat-label>
                                            <input matInput [(ngModel)]="context.config.Groups[g].Fields[f].Calculation.Output">
                                        </mat-form-field>     
                                    </div>                   
                                </mat-card-content>
                            </mat-card>
                            <mat-card>
                                <mat-card-title class="title-card-mini">
                                    <mat-icon class="title-icon">translate</mat-icon>
                                    {{labels.mappingsLabel}}
                                </mat-card-title>
                                <mat-card-content style="display: flex; flex-direction:row; padding:4px; gap:8px">
                                    <mat-checkbox color="accent" style="margin-left:9px; margin-right:8px; margin-top:14px" (click)="$event.stopPropagation()" (change)=" toggleFieldDecoding(context.config.Groups[g].Fields[f])" [checked]="context.config.Groups[g].Fields[f].Decoding == undefined ? 'false' : 'true'"></mat-checkbox> 
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{labels.mappingstableLabel}}</mat-label>
                                        <mat-select required [(ngModel)]="context.config.Groups[g].Fields[f].Decoding" [disabled]="context.config.Groups[g].Fields[f].Decoding == undefined">
                                            <mat-option *ngFor="let table of context.decodingTables" [value]="table.DECODE" (click)="context.config.Groups[g].Fields[f].Decoding = table.DECODE">{{table.DECODE}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{labels.defaultvalueLabel}}</mat-label>
                                        <input matInput [disabled]="context.config.Groups[g].Fields[f].Decoding == undefined" [(ngModel)]="context.config.Groups[g].Fields[f].Default">
                                    </mat-form-field>
                                </mat-card-content>
                            </mat-card>
                        </mat-expansion-panel>
                        <mat-checkbox *ngIf="field.DateKey != undefined && group.Essential == undefined" color="accent" style="margin-left:9px;margin-right:8px;margin-top:14px" (click)="$event.stopPropagation(); switchDateKeys(g, f)" [value]="field.Id == 'LastModifiedDate' ? 'true' : 'false'" [disabled]="!context.config.Groups[g].Enabled">{{labels.uselastmodifieddateasdatekeyLabel}}</mat-checkbox> 
                    </ng-container>
                </div>
            </div>
            <!--
            <div style="margin-top:8px; display:flex;justify-content: flex-end">
                <button mat-raised-button color="accent">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            !-->
        </mat-expansion-panel>
    </mat-tab>
    <mat-tab [label]="labels.entitiesLabel" class="tab">
        <ng-container *ngFor="let group of context.config.Groups; index as g">
            <ng-container *ngIf="group.Essential == undefined">
                <mat-expansion-panel *ngFor="let entity of group.Entities; index as e" style="background-color: whitesmoke">
                    <mat-expansion-panel-header style="height:64px; padding-left:0">
                        <mat-panel-title style="display:flex; justify-content:space-between; margin-left:16px">
                            {{context.config.Groups[g].Entities[e].Label}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div style="display:flex; flex-direction: column; gap:8px">
                        <mat-card *ngIf="context.config.Groups[g].Entities[e].EntityApi != undefined || context.config.Groups[g].Entities[e].DataFilter != undefined">
                            <mat-card-title class="title-card-mini" style="background-color:white">
                                <mat-icon class="title-icon">input</mat-icon>
                                {{labels.sourceLabel}}
                            </mat-card-title>
                            <mat-card-content style="display: flex; flex-direction:row; padding:12px; gap:8px; background-color:white">
                                <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Entities[e].EntityApi != undefined">
                                    <mat-label>{{labels.entityapiLabel}}</mat-label>
                                    <input matInput readonly (click)="changeEntityConfig(g, e, 'EntityApi', 0, undefined)" [(ngModel)]="context.config.Groups[g].Entities[e].EntityApi" [disabled]="context.config.Groups[g].Entities[e].EntityApi == context.config.Groups[g].Entities[e].Id">
                                </mat-form-field>   
                                <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Entities[e].DataFilter != undefined"  style="width: 410px;">
                                    <mat-label>{{labels.datafilterLabel}}</mat-label>
                                    <input matInput [(ngModel)]="context.config.Groups[g].Entities[e].DataFilter" width="300px">
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>
                        <mat-card>
                            <mat-card-title class="title-card-mini" style="background-color:white">
                                <mat-icon class="title-icon">settings_applications</mat-icon>
                                {{labels.propertiesLabel}}
                            </mat-card-title>
                            <mat-card-content style="display:flex; flex-direction: row; padding:12px; gap:8px; background-color:white">
                                <mat-form-field appearance="fill">
                                    <mat-label>Id</mat-label>
                                    <input matInput [(ngModel)]="context.config.Groups[g].Entities[e].Id" [disabled]="context.config.Groups[g].Entities[e].EntityApi == undefined">
                                </mat-form-field>  
                                <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Entities[e].JoinIn != undefined">
                                    <mat-label>Join In</mat-label>
                                    <input matInput readonly (click)="changeEntityConfig(g, e, 'JoinIn', 1, 'Employments');" [(ngModel)]="context.config.Groups[g].Entities[e].JoinIn" [disabled]="context.config.Groups[g].Entities[e].EntityApi == undefined">
                                </mat-form-field>    
                                <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Entities[e].JoinOut != undefined">
                                    <mat-label>Join Out</mat-label>                                
                                    <input matInput readonly (click)="changeEntityConfig(g, e, 'JoinOut', 2, (context.config.Groups[g].Entities[e].EntityApi != undefined ? context.config.Groups[g].Entities[e].EntityApi : context.config.Groups[g].Entities[e].Id))" [(ngModel)]="context.config.Groups[g].Entities[e].JoinOut" [disabled]="context.config.Groups[g].Entities[e].EntityApi == undefined">
                                </mat-form-field>   
                            </mat-card-content>
                        </mat-card>
                        <mat-card *ngIf="context.config.Groups[g].Entities[e].DateFilter != undefined">
                            <mat-card-title class="title-card-mini" style="background-color:white">
                                <mat-icon class="title-icon">calendar_today</mat-icon>
                                {{labels.periodLabel}}
                            </mat-card-title>
                            <mat-card-content style="display: flex; flex-direction:row; padding:12px; gap:8px; background-color:white">
                                <mat-form-field appearance="fill" *ngIf="context.config.Groups[g].Entities[e].DateFilter != undefined" style="width: 410px;">
                                    <mat-label>{{labels.datefilterLabel}}</mat-label>
                                    <mat-select required [(ngModel)]="context.config.Groups[g].Entities[e].DateFilter" [disabled]="context.config.Groups[g].Entities[e].DateKeys == undefined">
                                        <mat-option *ngFor="let filter of context.dateFilters" [value]="filter.Key" (click)="context.config.Groups[g].Entities[e].DateFilter = filter.Key">{{filter.Value}}</mat-option>
                                    </mat-select>                                
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </ng-container>
    </mat-tab>
    <mat-tab [label]="labels.settingsLabel">
        <ng-container *ngIf="context.config.Settings.ExtractionFilter != undefined">
            <mat-card>
                <mat-card-title class="title-card">Extraction filter</mat-card-title>
                <mat-card-content style="display:flex; flex-direction:column; background-color:whitesmoke; padding:12px; align-items: flex-start; margin-bottom:12px">                    
                    <div style="display:flex; flex-direction:column; background-color:rgb(235, 235, 235); padding:6px; padding-left:10px">
                        <span style="font-size:12px; color:gray">Input</span>
                        <mat-chip-set>
                            <ng-container *ngFor="let input of context.config.Settings.ExtractionFilter.Input, index as i">
                                <mat-chip (removed)="removeFilterField(context.config.Settings.ExtractionFilter.Input, i)">
                                    <b>{{i}})</b> {{input.Label}}
                                    <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip> 
                            </ng-container>                                      
                            <button mat-mini-fab style="transform:scale(.5)" color="accent" (click)="addFilterField()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-chip-set>
                    </div>
                    <br>
                    <mat-form-field appearance="fill" *ngIf="context.config.Settings.ExtractionFilter.Eval != undefined" style="width:100%">
                        <mat-label>Eval</mat-label>
                        <input matInput [(ngModel)]="context.config.Settings.ExtractionFilter.Eval">
                    </mat-form-field>
                </mat-card-content>                
            </mat-card>

            <div style="display: flex; flex-direction: row; align-items: flex-start; gap:16px">
                <mat-card @inOutAnimation *ngIf="context.config.Settings.ExtractionSort != undefined" style="margin-top:8px; width:40%">
                    <mat-card-title class="title-card">Extraction sort</mat-card-title>
                    <mat-card-content>
                        <div style="display:flex; flex-direction:column;background-color:rgb(235, 235, 235); padding:6px; padding-left:10px">
                            <span style="font-size:12px; color:gray">Elements</span>
                            <mat-chip-set>
                                <ng-container *ngFor="let element of context.config.Settings.ExtractionSort; index as i">
                                    <mat-chip (removed)="removeSortField(context.config.Settings.ExtractionSort, i)">
                                        <b>{{i}})</b> {{element.Label}}
                                        <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip> 
                                </ng-container>                                      
                                <button mat-mini-fab style="transform:scale(.5)" color="accent" (click)="addSortField()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-chip-set>
                        </div>                        
                    </mat-card-content>
                </mat-card>

                <mat-card @inOutAnimation *ngIf="context.config.Settings.CompaniesDecodings != undefined" style="margin-top:8px; width:60%">
                    <mat-card-title class="title-card">{{labels.companiesmappingLabel}}</mat-card-title>
                    <mat-card-content>
                        <table *ngIf="context.config.Settings.CompaniesDecodings.length > 0" style="width:100%">
                            <tr>
                                <th style="width:70%">Input (Cezanne)</th>
                                <th>Output</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let element of context.config.Settings.CompaniesDecodings, index as i">
                                <td>                                     
                                    <ng-container *ngIf="context.companiesList.length > 0">    
                                        <mat-form-field style="margin-right:16px" appearance="fill">                                           
                                            <mat-label>Company</mat-label>
                                            <mat-select required [(ngModel)]="element.Input">
                                                <mat-option *ngFor="let table of context.companiesList" style="width: 100%;" [value]="table.Code" (click)="element.Input = table.Code">{{table.Code}} - {{table.Name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="context.companiesList.length == 0">                                            
                                        <mat-form-field style="margin-right:16px" appearance="fill">   
                                            <mat-label>Company code</mat-label>
                                            <input matInput [(ngModel)]="element.Input">
                                        </mat-form-field>
                                    </ng-container>
                                </td>
                                <td>
                                    <mat-form-field style="margin-right:16px" appearance="fill">
                                        <mat-label>Code</mat-label>
                                        <input matInput [(ngModel)]="element.Output">
                                    </mat-form-field>
                                </td>
                                <td style="max-width: 50px">
                                    <button mat-mini-fab (click)="removeCompanyDecoding(i)" style="transform:scale(.75)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </table>
                        
                        <div style="display:flex; justify-content:center; margin-top:8px; padding-bottom:8px">
                            <button *ngIf="context.config.Settings.CompaniesDecodings.length < context.companiesList.length" mat-mini-fab (click)="addCompanyDecoding()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                        
                    </mat-card-content>
                </mat-card>
            </div>
            <mat-card @inOutAnimation *ngIf="context.config.Settings.PayrollcodeRanges != undefined" style="margin-top:8px; max-width:100%">
                <mat-card-title class="title-card">Payroll code Ranges</mat-card-title>
                <mat-card-content style="display:flex; flex-direction:row; flex-wrap:wrap; gap:8px">
                    <ng-container *ngFor="let rangeElement of context.config.Settings.PayrollcodeRanges, index as r">
                        <div style="width:208px">
                            <mat-form-field appearance="fill" *ngIf="rangeElement.Range[0] != undefined" style="width:100px; margin-right:8px">
                                <mat-label>From</mat-label>
                                <input matInput [(ngModel)]="rangeElement.Range[0]">
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="rangeElement.Range[1] != undefined" style="width:100px">
                                <mat-label>To</mat-label>
                                <input matInput [(ngModel)]="rangeElement.Range[1]">
                            </mat-form-field>

                            <div style="display:flex; flex-direction:column; background-color:rgb(235, 235, 235); padding:6px; padding-left:10px">
                                <span style="font-size:12px; color:gray">Elements</span>
                                <mat-chip-set>
                                    <ng-container *ngFor="let field of rangeElement.Fields; index as i">
                                        <mat-chip (removed)="removeRangeField(rangeElement.Fields, i)">
                                            <b>{{i}})</b> {{field.Label}}
                                            <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip> 
                                    </ng-container>                                      
                                    <button mat-mini-fab style="transform:scale(.5)" color="accent" (click)="addRangeField(r)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </mat-chip-set>
                            </div> 
                            <mat-form-field appearance="fill" *ngIf="rangeElement.Eval != undefined"  style="width:208px; margin-top:8px">
                                <mat-label>Eval</mat-label>
                                <input matInput [(ngModel)]="rangeElement.Eval">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    
                </mat-card-content>
            </mat-card>
        </ng-container>
    </mat-tab>
</mat-tab-group>
<div *ngIf="!loading" style="display: flex; flex-direction: row; justify-content: flex-end; margin-top:12px">
    <button mat-raised-button color="basic" (click)="close()" style="margin-right:8px">
        <mat-icon style="margin-top:-4px">clear</mat-icon> {{labels.closeLabel}}
    </button>    
    <button mat-raised-button color="accent" (click)="saveConfig()">
        <mat-icon style="margin-top:-4px">save</mat-icon> {{labels.saveLabel}}
    </button>    
</div>
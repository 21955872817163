<button mat-raised-button *ngIf="context.ButtonContentRun != '' && context.ButtonContentRun != null"
        class="button-back-absence" (click)="onClickRun()">
        {{ context.ButtonContentRun }}
</button>
<!-- Questo bottone deve avere un icona come 'testo', possibilmente
    una tipo una freccia indietro, visto che e' il bottone per tornare
    indietro. -->

<div class="card-absence">    
    <mat-card class="card box-datepicker main_card">
        <mat-card-content>
            <span *ngIf="context.Description != null && context.Description != ''">
                {{ context.Description }}
            </span>

            <div *ngIf="context.TitleDatePicker != null && context.TitleDatePicker != ''" class="title-datepicker">
                {{ context.TitleDatePicker }}
            </div>

            <div class="box-sel-company">
                <mat-form-field appearance="fill" color="accent" style="width: 300px">
                    <input matInput [(ngModel)]="monthAndYear" [matDatepicker]="picker" (dateChange)="emitDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="year" (monthSelected)="monthChanged($event, picker)">
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <div class="file-uploader" *ngIf="context.Upload != null && context.Upload.length > 0">
                <component-cp006 [context]="context"></component-cp006>
              </div>
        </mat-card-content>
    </mat-card>
</div>


import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'component-wfroute',
  templateUrl: './wfroute.component.html',
  styleUrls: ['./wfroute.component.scss']
})
export class WfrouteComponent implements OnInit {

  @Input()
  context:any;

  constructor(private title:Title) { }

  ngOnInit(): void {    
    this.title.setTitle(this.title.getTitle().replace("...", "Personal Roadmap KPI"));
  }

  closeWfSection() {    
    //this._api.changeOBGUID(this._api.getUrlParameters().obguid)
    history.back();
  }

}

import { Component, OnInit, Input, ViewChild, ElementRef, inject, signal } from '@angular/core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { Platform } from '@angular/cdk/platform';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service'
import { NativeDateAdapter } from '@angular/material/core';
import { StylerService } from 'src/app/services/styler.service';
import { MatInput } from '@angular/material/input';
import { FormBuilder, FormGroup } from '@angular/forms';


export class MonthpickerDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }

  override parse(value: string): Date | null {
    const monthAndYearRegex = /(10|11|12|0\d|\d)\/[\d]{4}/;
    if (value?.match(monthAndYearRegex)) {
      const parts = value.split('/');
      const month = Number(parts[0]);
      const year = Number(parts[1]);
      if (month > 0 && month <= 12) {
        return new Date(year, month - 1);
      }
    }
    return null;
  }

  override format(date: Date, displayFormat: any): string {
    const month = date.getMonth() + 1;
    const monthAsString = ('0' + month).slice(-2);
    const year = date.getFullYear();
    return monthAsString + '/' + year;
  }
}

@Component({
  selector: 'component-cp005',
  templateUrl: './cp005.component.html',
  styleUrls: ['./cp005.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MonthpickerDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class Cp005Component implements OnInit {

  private readonly _adapter = inject<DateAdapter<unknown, unknown>>(DateAdapter);
  private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));
  @Input() context: any;
  contextData:any;

  monthAndYear: Date | null = new Date();
  monthAndYear2: Date | null = new Date();
  @ViewChild("monthAndYearInput") monthAndYearInput:ElementRef | undefined;
  @ViewChild("monthAndYear2Input") monthAndYear2Input:ElementRef | undefined;
  daysCount:number = 31;
  periodRange:string = "";

  selectedYear: any;
  selectedMonth: any;

  displayBackBtn: boolean = false;
  selectedCompany: any = "";
  emptyCompany:boolean = false;

  constructor(private fb: FormBuilder, private elementRef: ElementRef, private _api: ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService, public _styler:StylerService) { }

  ngOnInit(): void {
    this._locale.set(this._api.getLanguage());
    this._adapter.setLocale(this._locale());
    if (this.context.OnPreviousMonth != undefined && this.context.OnPreviousMonth == true) {
      this.monthAndYear?.setMonth(this.monthAndYear.getMonth() - 1);
    } else {
      this.monthAndYear?.setMonth(this.monthAndYear.getMonth());
    }
    this.monthAndYear?.setDate(1);
    this.monthAndYear2?.setMonth(this.monthAndYear2!.getMonth() + 1)
    this.monthAndYear2?.setDate(new Date(this.monthAndYear2!.getFullYear(), this.monthAndYear2!.getMonth() + 1, 0).getDate())
    this.daysCount = new Date(this.monthAndYear2!.getFullYear(), this.monthAndYear2!.getMonth() + 1, 0).getDate()
    if (this.context.YearActive != null && this.context.YearActive != '' && this.context.MonthActive != null && this.context.MonthActive != '') {
      this.selectedYear = String(this.monthAndYear?.getFullYear());
      this.selectedMonth = String(this.monthAndYear!.getMonth() + 1);
    }
    this.updateContext();
    //console.log(typeof this.context.Company === 'string')
    if (this.context.Company != undefined) {
      if (typeof this.context.Company === 'string' || this.context.Company.length == 0) this.emptyCompany = true
      else if (this.context.Company.length > 0) this.selectedCompany = this.context.Company[0].Id; //first value as default
    }
    
    this.contextData = {
      "OUCODA" : this._api.lastOUCODA,
      "Sections" : this._api.lastSections
    }
  }

  updateContext() {
    if (this.context.YearActive != null && this.context.YearActive != '')
      this.context.YearValue = this.selectedYear;
    if (this.context.MonthActive != null && this.context.MonthActive != '')
      this.context.MonthValue = this.selectedMonth;
    if (this.context.YearActive != null && this.context.YearActive != '')
      this.context.CompanyValue = this.selectedCompany;
    /*
    if (this.context.DaysBeforeValue != undefined && this.context.DaysAfterValue != undefined) {
      if (this.monthAndYear?.getMonth() == new Date().getMonth()) {
        this.context.DaysBeforeValue = 0
        this.context.DaysAfterValue = this.daysCount;
      } else {
        console.log(this.monthAndYear2!.getDate())
        this.context.DaysBeforeValue = this.daysCount - this.monthAndYear2!.getDate();
        this.context.DaysAfterValue = this.monthAndYear2!.getDate()
      }
    }*/
    if (this.context.StartEndDateActive != undefined && this.context.StartEndDateActive != '' && this.context.StartDateValue != undefined && this.context.EndDateValue != undefined) {
      //console.log(this.monthAndYear);
      //console.log(this.monthAndYear2);
      var month = this.monthAndYear!.getMonth() + 1;
      var day = this.monthAndYear?.getDate();
      this.context.StartDateValue = String(this.monthAndYear?.getFullYear()) + (month < 10 ? '0' + String(month) : String(month)) + (day! < 10 ? '0' + String(day) : String(day)) + "000000"
      var month2 = this.monthAndYear2!.getMonth() + 1;
      var day2 = this.monthAndYear2?.getDate();
      this.context.EndDateValue = String(this.monthAndYear2?.getFullYear()) + (month2 < 10 ? '0' + String(month2) : String(month2)) + (day2! < 10 ? '0' + String(day2) : String(day2)) + "000000"
      //console.log(this.context.StartDateValue)
      //console.log(this.context.EndDateValue)
    }
  }

  emitDateChange(event: MatDatepickerInputEvent<Date | null, unknown>, inputNumber:number): void{
    console.log(event.value)
    if (event.value != null) {
      if (inputNumber == 1) this.monthAndYear = event.value;
      else if (inputNumber == 2) this.monthAndYear2 = event.value;
    } else {      
      this.checkInput(inputNumber == 1 ? this.monthAndYearInput!.nativeElement.value : this.monthAndYear2Input!.nativeElement.value, inputNumber)
    }
  }

  checkInput(value:any, inputNumber:number) {
    console.log(value)
    if (value.length != 7 || !value.includes('/')) {
      if (inputNumber == 1) {
        this.monthAndYear = new Date();
        this.monthAndYear.setDate(1);
        this.monthAndYearInput!.nativeElement.value = (this.monthAndYear!.getMonth() + 1 < 10 ? '0' : '') + (this.monthAndYear!.getMonth() + 1) + "/" + this.monthAndYear!.getFullYear()
      } else if (inputNumber == 2) {
        this.monthAndYear2 = new Date(); 
        this.monthAndYear2.setDate(new Date(this.monthAndYear2.getFullYear(), this.monthAndYear2.getMonth() + 1, 0).getDate()); 
        this.monthAndYear2Input!.nativeElement.value = (this.monthAndYear2!.getMonth() + 1 < 10 ? '0' : '') + (this.monthAndYear2!.getMonth() + 1) + "/" + this.monthAndYear2!.getFullYear()
      }
    }
  }

  monthChanged(value: any, picker: any, inputNumber:number|undefined) {
    if (inputNumber == undefined || inputNumber == 1) {
      this.monthAndYear = value;
      this.selectedYear = String(this.monthAndYear?.getFullYear());
      this.selectedMonth = String(this.monthAndYear!.getMonth() + 1);
      if ((this.monthAndYear!.getMonth() > this.monthAndYear2!.getMonth()) || this.monthAndYear!.getFullYear() > this.monthAndYear2!.getFullYear()) {
        this.monthAndYear2!.setFullYear(this.monthAndYear!.getFullYear())          
        this.monthAndYear2!.setMonth(this.monthAndYear!.getMonth())
        this.monthAndYear2Input!.nativeElement.value = (this.monthAndYear!.getMonth() + 1 < 10 ? '0' : '') + (this.monthAndYear!.getMonth() + 1) + "/" + this.monthAndYear!.getFullYear()
      }
    } else if (inputNumber == 2) {
      this.monthAndYear2 = value;      
      this.monthAndYear2?.setDate(new Date(this.monthAndYear2!.getFullYear(), this.monthAndYear2!.getMonth() + 1, 0).getDate())
      if ((this.monthAndYear!.getMonth() > this.monthAndYear2!.getMonth()) || this.monthAndYear!.getFullYear() > this.monthAndYear2!.getFullYear()) {
        this.monthAndYear!.setFullYear(this.monthAndYear2!.getFullYear())          
        this.monthAndYear!.setMonth(this.monthAndYear2!.getMonth())
        this.monthAndYearInput!.nativeElement.value = (this.monthAndYear2!.getMonth() + 1 < 10 ? '0' : '') + (this.monthAndYear2!.getMonth() + 1) + "/" + this.monthAndYear2!.getFullYear()
      }
    }
    //this.daysCount = Math.floor((this.monthAndYear2!.getTime() - this.monthAndYear!.getTime())  / (1000 * 60 * 60 * 24)) + this.monthAndYear!.getDate()
    //this.periodRange = this.monthAndYear!.toLocaleString().split(',')[0] + ' - ' + this.monthAndYear2!.toLocaleString().split(',')[0] 
    this.updateContext();
    picker.close();
  }

  onClickRun(){
    this.updateContext();
    this._api.loading = true;
    var context = "/o-action-save";
    
     this._api.doButtonPostRequest(this.contextData, context).subscribe((response) => {
      if(response) {
        this._api.printResponse(response)

        //refresh oucoda
        this._api.getPageSections().subscribe((response) => {
          if(response) {
            try {
              this.contextData.OUCODA = JSON.parse(JSON.stringify(response)).OUCODA;              
              this._snackbarManager.open("Dati salvati correttamente!", 'ok');
              this._api.loading = false;
            } catch (e) {
              location.reload()
            }
          }
        }, (error) => {
          if(error) {
            location.reload()
          }
        });

      }
    }, (error) => {
      if(error) {
        console.log(error);
        // Stringa hardcodata, non so dove prendere quella giusta, visto che nel json non viene passata.
        this._snackbarManager.open("Error while saving, please try again", 'ko');
        this._api.loading = false;
      }
    });

  }

}

import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { Cp062Component } from '../cp062/cp062.component';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';

@Component({
  selector: 'component-cp073',
  templateUrl: './cp073.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp073.component.scss']
})
export class Cp073Component implements OnInit {

  @Input() inguid = ''
  @Input() labels:any = {}
  @Output() closeFormEvent = new EventEmitter<any>();
 
  @ViewChild('apiNavigator') apiNavigator: Cp062Component | undefined
  @ViewChild('itemColumns') a: MultiSelect | undefined
  @ViewChild('dt2') dt2: Table | undefined
  showApinavigator:boolean = false
  configElementPaths:any = {}
  
  //record table management
  selectedRows:any = []
  selectedRows2:any = []
  recordsColumns:any = ["Input", "Output", "Type", "Decimals", "Label"]
  recordsColumns2:any = ["Name", "Type", "Format", "Separator"]
  records:any = []
  recordsLoading:boolean = false
  visibleRows:number = 8
  firstRow:number = 0
  itemKeyColumnHeaders:any = []
  showMappingDiv:boolean = false
  selectedFileIndex:number = -1
  showFiletypesDescriptionDiv:boolean = false
  dateDefinitionFromHeader:boolean = false
  filterFieldValue:string = ""
  filterType:number = -1

  config:any = {}
  baseConfig:any = {}

  context:any = {}
  loading:boolean = true
  
  @HostListener('window:keydown.esc', ['$event'])
  closeShortcut(event: KeyboardEvent) {
    event.preventDefault();
    if (this.showMappingDiv) {
      this.showMappingDiv = false
    } else {
      this.close()
    }
  }

  constructor(private _snackbarManager:SnackbarmanagerService, private _api:ApiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this._api.doButtonPostRequest({"action": "get", "configuration": { "id": this.inguid }}, "/configure-integration").subscribe((response) => {
      if (response) {
        this._api.printResponse(response)
        this.context = JSON.parse(JSON.stringify(response))
        if (!this.context.isSuccess) {
            this._snackbarManager.open(this.context.message, 'ko')
            this.loading = false
            this.close(false)
        } else {
          this.loadConfig()
        }
      }
    }, (error) => {
      if(error) {
        console.log(error);
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko')
        else this._snackbarManager.open(error.error, 'ko')
        this.loading = false
        this.close(false)
      }
    });
  }

  loadConfig() {
    try {
      console.log(this.context.config)
      var i = 0
      var j = 0
      this.itemKeyColumnHeaders = []
      this.context.config.Files.forEach((file:any) => {
        if (file.DataMapping.ItemKeysColumnValues != undefined) {
          file.DataMapping.ItemKeysColumnValues.forEach((item:any) => {
            this.itemKeyColumnHeaders.push(item)
            j+=1
          })
          i+=1
        }
      })
      console.log(this.itemKeyColumnHeaders)
      this.loading = false    
      return true
    } catch (e) { 
      console.log(e)
      this._snackbarManager.open(this.labels.errorLabel, 'ko')
      this.close(false)
      return false 
    }
  }

  addColumnalteration(fileIndex:number) {
    this.selectedRows2[0].DataMapping.ColumnsAlteration.push({"Input":"input", "Output": "output"})
  }

  removeColumnalteration(fileIndex:number) {
    this.selectedRows2[0].DataMapping.ColumnsAlteration.splice(fileIndex, 1)
  }

  addColumnsinsertion() {
    this.context.config.Settings.ColumnsInsertion.push({"Label":"label", "Type": "type"})
  }

  removeColumnsinsertion(index:number) {
    this.context.config.Settings.ColumnsInsertion.splice(index, 1)
  }

  addFieldsinsertion() {
    this.context.config.Settings.FieldsInsertion.push({"Label":"label", "Type": "type"})
  }

  removeFieldsinsertion(index:number) {
    this.context.config.Settings.FieldsInsertion.splice(index, 1)
  }
  
  addRecord(tableIndex:number) {
    if (tableIndex == 0) {
      var newItem:any = {
        "Input": "Input",
        "Output": "Output",
        "Type": 0,
        "Decimals": 0,
        "Label": "Label"
      }
      if (this.itemKeyColumnHeaders.length > 0) {
        newItem["Input"] = []
      }
      this.context.config.Items.push(newItem)
    } else if (tableIndex == 1) {
      var newFile = {
        "Name": "New",
        "Type": "Z03",
        "Format": "csv",
        "Separator": ";",
        "DataMapping": {
          "FileJoinField":{
            "Input": ["EmployeeKey"], 
            "Eval": '{0}.zfill(7)'
          },
          "CezanneJoinField": "payrollKey",
          "EmployeeColumns": {
            "Key": "", 
            "Company": "", 
            "FirstName": "", 
            "FamilyName": ""          
          },
          "ItemDateColumnHeader": "",
          "DateFormat": "",
          "ColumnSuffix": "",
          "ColumnsAlteration": []
        }
      }
      this.context.config.Files.push(newFile)
    }
    console.log(this.context.config.Files)
    this._snackbarManager.open(this.labels.newitemaddedLabel, "ok")
  }
  
  removeRecords(tableIndex:number) {
    if (tableIndex == 0) {      
      if (this.selectedRows.length > 1) this._snackbarManager.open(this.labels.itemsremovedLabel, "ok")
      else this._snackbarManager.open(this.labels.itemremovedLabel, "ok")
      this.selectedRows.forEach((r:any) => {
        this.context.config.Items = this.context.config.Items.filter( (item:any) => item !== r );
      })
      this.selectedRows = []
    } else {
      if (this.selectedRows2.length > 1) this._snackbarManager.open(this.labels.itemsremovedLabel, "ok")
      else this._snackbarManager.open(this.labels.itemremovedLabel, "ok")
      this.selectedRows2.forEach((r:any) => {
        this.context.config.Files = this.context.config.Files.filter( (item:any) => item !== r );
      })
      this.selectedRows2 = []
    }    
  }

  changeFileType(index:number) {
      if (this.context.config.Files[index].Type == "Z01") {        
        if (this.context.config.Files[index].DataMapping.ItemValuesColumnHeaders != undefined) {
          delete this.context.config.Files[index].DataMapping.ItemValuesColumnHeaders
        }     
        this.context.config.Files[index].DataMapping.ItemKeysColumnHeader = ""
        this.context.config.Files[index].DataMapping.ItemValuesColumnOffset = 1
        this.context.config.Files[index].DataMapping.FieldKeyColumnHeader = ""
        this.context.config.Files[index].DataMapping.FieldKeyColumnOffset = ""
        this.context.config.Files[index].DataMapping.FieldValuesColumnOffset = 1
      } else if (this.context.config.Files[index].Type == "Z02" || this.context.config.Files[index].Type == "Z04" || this.context.config.Files[index].Type == "Z05") {   
        if (this.context.config.Files[index].DataMapping.FieldKeyColumnHeader != undefined) {
          delete this.context.config.Files[index].DataMapping.FieldKeyColumnHeader
        }
        if (this.context.config.Files[index].DataMapping.FieldValuesColumnOffset != undefined) {
          delete this.context.config.Files[index].DataMapping.FieldValuesColumnOffset
        }
        if (this.context.config.Files[index].DataMapping.ItemValuesColumnOffset != undefined) {
          delete this.context.config.Files[index].DataMapping.ItemValuesColumnOffset
        }   
        if (this.context.config.Files[index].Type == "Z02" || this.context.config.Files[index].Type == "Z05") {  
          this.context.config.Files[index].DataMapping.ItemKeysColumnHeader = ""
        }
        this.context.config.Files[index].DataMapping.ItemValuesColumnHeaders = ""
        
      } else if (this.context.config.Files[index].Type == "Z03") {
        if (this.context.config.Files[index].DataMapping.ItemKeysColumnHeader != undefined) {
          delete this.context.config.Files[index].DataMapping.ItemKeysColumnHeader
        }
        if (this.context.config.Files[index].DataMapping.ItemValuesColumnHeaders != undefined) {
          delete this.context.config.Files[index].DataMapping.ItemValuesColumnHeaders
        } 
        if (this.context.config.Files[index].DataMapping.ItemValuesColumnOffset != undefined) {
          delete this.context.config.Files[index].DataMapping.ItemValuesColumnOffset
        }       
        if (this.context.config.Files[index].DataMapping.FieldValuesColumnOffset != undefined) {
          delete this.context.config.Files[index].DataMapping.FieldValuesColumnOffset
        }        
        if (this.context.config.Files[index].DataMapping.FieldKeyColumnHeader != undefined) {
          delete this.context.config.Files[index].DataMapping.FieldKeyColumnHeader
        }
    }    
    if (this.context.config.Files[index].DataMapping.ItemDateColumnHeader == undefined) {
      this.context.config.Files[index].DataMapping.ItemDateColumnHeader = ""
    }
    if (this.context.config.Files[index].DataMapping.DateFormat == undefined) {
      this.context.config.Files[index].DataMapping.DateFormat = ""
    }
    console.log(this.context.config.Files[index].DataMapping)
  }

  changeTablePage(table:any, itemsType:string, first:number) {
    var newFirstRow = 0
    var items = this.context.config.Items[itemsType]
    //console.log("length " + this.context.config.Items.ExpenseReport.length)
    var resto = ~~(items.length / this.visibleRows)      
    //console.log("resto " + resto)
    newFirstRow = (this.visibleRows * resto)
    //console.log("prima riga " + newFirstRow)
    if (items.length > this.visibleRows && items.length > newFirstRow) {      
      //console.log("first row NOW!!!!") 
      first = newFirstRow
    }
    if (items.length == 1 + (this.visibleRows * resto)) {        
      //console.log("reset NOW!!!!")
      table?.reset()
    }
  }

  isEmpty(obj:any) {
    return Object.keys(obj).length == 0
  }

  buildConfig() {
    return {}
  }

  onFileSelect(event:any) {
    this.dt2!._selection = event.data
    this.dt2!.selectionChange.emit(null);
    this.selectedRows2 = [event.data]
    console.log(this.selectedRows2)
  } 
  onFileUnselect(event:any) {
    //this.selectedRows2 = []
    console.log(this.selectedRows2)
  } 
  
  saveConfig() {
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: this.labels.titleLabel,
        content: this.labels.contentLabel,
        accept: this.labels.okLabel,
        reject: this.labels.koLabel
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {  
        console.log(this.context.config)
        this._api.loading = true
        this._api.doButtonPostRequest({"action": "set", "configuration": { "id": this.inguid, "body": this.context.config }}, "/configure-integration").subscribe((response) => {
          if (response) {
            this._api.printResponse(response)
            var responseObj = JSON.parse(JSON.stringify(response));  
            if (responseObj.isSuccess != undefined) {
              this.close(responseObj.isSuccess)
            }            
            this._snackbarManager.open(this.labels.successLabel, 'ok');
            this._api.loading = false
          }
        }, (error) => {
          if(error) {
            console.log(error);
            if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
            else this._snackbarManager.open(error.error, 'ko');            
            this._api.loading = false
          }
        });
      }
    })
  }

  close(success?:boolean) {
    this.closeFormEvent.emit({
      isSuccess: success
    });
  }

  openMappingCard() {
    if (this.selectedRows2[0].DataMapping.ItemDateColumnHeader != undefined) {
      this.dateDefinitionFromHeader = true
    } else {
      this.dateDefinitionFromHeader = false
    }
    this.showMappingDiv = true;
  }

  changeDate(event:any) {
    if (!event.source.value) {
      this.selectedRows2[0].DataMapping.ItemDateValue = {
        "Eval": "{0}[-11:-4]",
        "Input":"CompleteName"
      }
      delete this.selectedRows2[0].DataMapping.ItemDateColumnHeader
    } else {
      this.selectedRows2[0].DataMapping.ItemDateColumnHeader = ""
      delete this.selectedRows2[0].DataMapping.ItemDateValue
    }
    this.dateDefinitionFromHeader = event.source.value
    console.log(this.dateDefinitionFromHeader)
  }
  
  addCompanyDecoding() {
    this.context.config.Settings.CompaniesDecodings.push({"Input":"input", "Output": "output"})
  }

  removeCompanyDecoding(i:number) {
    this.context.config.Settings.CompaniesDecodings.splice(i, 1)
  }
  
  getPersoncode() {
    var entityFound = false
    var entityName = ""
    var entityIndex = 0
    this.context.config.Extraction.Entities.every((entity:any) => {
      if (entity.Id == "AllPeopleSearch") {
        entityFound = true
        entityName = entity.Id
        return false
      }
      else if (entity.Id == "People") {
        entityFound = true
        entityName = entity.Id
        return false
      }
      entityIndex += 1
      return true
    })
    if (entityName == "AllPeopleSearch") {
      return "AllPeopleSearch.PersonCode_Search"
    } else {
      return "People.PersonCode"
    }
  }
  
  changePayrollcodeSource(event:any) {   
    var source = event.target.textContent.replace(" ", "")
    if (event.target.textContent.includes("PersonCode")) {
      source = this.getPersoncode()
    }
    source = source.replace(" ", "")
    this.context.config.Settings.PayrollcodeSource = source
  }

  changeExtractionFilter(event:any) { 
    if (event.value != undefined) {
      this.filterType = event.value
      this.context.config.Extraction.Filter = this.context.config.Settings.ExtractionFilters[this.filterType]
    }
    if (this.context.config.Extraction.Filter.length > 0) {
      if (this.filterType == 1) {
        this.context.config.Extraction.Filter[0].Value = this.filterFieldValue
      } else if (this.filterType == 2) {
        this.context.config.Extraction.Filter[2].Value = this.filterFieldValue
      }
    }
    console.log(this.context.config.Extraction.Filter)
  }

  addSortField() {
    this.context.config.Extraction.Sorting.push({'Label': '', 'Element': ''});
    var l = this.context.config.Extraction.Sorting.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Extraction.Sorting[' + l + '].Element', 
      name: 'this.context.config.Extraction.Sorting[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path)
    });
    this.openApiNavigator(1, undefined)
  }

  removeSortField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }
  
  addFilterField() {
    this.context.config.Extraction.Filter.Input.push({'Label': '', 'Element': ''});
    var l = this.context.config.Extraction.Filter.Input.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Extraction.Filter.Input[' + l + '].Element', 
      name: 'this.context.config.Extraction.Filter.Input[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }
  
  openApiNavigator(selectionType:number, entity:any) {
    let newApiList:any = []
    if (entity != undefined) {
      this.context.apiList.every((a:any) => {
        if (a.id == entity) {
          newApiList.push(a)
          return false
        }
        return true
      }) 
    } else {
      newApiList = this.context.apiList
    }
    console.log(newApiList)
    this.showApinavigator = true
    this.apiNavigator?.open(newApiList, selectionType)
  }

  removeFilterField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  isArray(element:any): boolean { return Array.isArray(element) }

}

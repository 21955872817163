import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AngularEditorConfig  } from '@kolkov/angular-editor';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service';
import { v4 as uuidv4 } from 'uuid';
import { MatDrawer } from '@angular/material/sidenav';
 

@Component({
  selector: 'component-cp050',
  templateUrl: './cp050.component.html',
  styleUrls: ['./cp050.component.scss']
})
export class Cp050Component implements OnInit {

  @Input()
  context:any;
  @Input()
  username:any;

  events: string[] = [];
  openedDrawer: boolean = true;
  screenWidth:any;
  @ViewChild('drawer') private drawer!: MatDrawer;
  @ViewChild('chatList') private chatList!: ElementRef;
  @ViewChild('messageListContainer') private myScrollContainer!: ElementRef;
  scrollTop:number = 9999;

  newConversation:boolean = false;
  selectedRecipient:any;
  conversationSubject:any;
  filteredRecipientList:any = [];

  currentChat:any | undefined;
  currentChatIndex:number = -1;
  chatMessages:any = []
  messageText:string = "";

  filteredChatList:any = [];
  searchWordConversation:string = "";
  searchWordRecipient:string = "";
  unreadOnly:boolean = false;
  translationEnabled:boolean = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text",
    defaultParagraphSeparator: '',
    defaultFontName: 'Calibri',
    defaultFontSize: '3',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName'
      ],
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat'
      ]
    ]
  };

  constructor(private _api:ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
   }

  ngOnInit(): void {
    this.filteredChatList = this.context.conversazioni;
    this.filteredChatList.forEach((c:any) => {
      if ((c.letto == 0 && c.mittente_id == c.sonoio_id) || c.letto == 1) {
        c.letto = 1;
      } else c.letto = 0;
    });
    this.filteredRecipientList = this.context.destinatari;
    
  }

  selectChat(index?:number) {
    if (index != undefined) {
      this.currentChat = this.filteredChatList[index];
      this.currentChatIndex = index;
    } else this.currentChat = this.filteredChatList[this.currentChatIndex];
    this.messageText = "";
    //this.orderedMessageList = []
    //this.context.messaggi.forEach((m:any) => {

    //})
    this.chatMessages = [];
    this.context.messaggi.forEach((m:any) => {
      if (this.currentChat.conversazione_id == m.conversazione_id) this.chatMessages.push(m)
    });

    if (this.chatMessages.length > 0) {
      if (this.chatMessages[this.chatMessages.length - 1].bozza == 1 &&
        this.chatMessages[this.chatMessages.length - 1].mittente_id == this.chatMessages[this.chatMessages.length - 1].sonoio_id)
        this.messageText = this.chatMessages[this.chatMessages.length - 1].messaggio;
    }

    //this.chatList.selected;
    try {
      this.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }

    this.chatMessages.forEach((m:any) => {
      //console.log(m.letto + " - " + m.mittente)
      if(m.letto == 0 && m.mittente_id != m.sonoio_id) {
          m.letto = 1;
          this.sendRead(m.messaggio_id, m.conversazione_id);
      }
    })

    if (this.drawer.mode == 'over') this.drawer.close();
  }

  sendRead(messageId:string, conversationId:string) {
    let context_data = JSON.parse(JSON.stringify(this.context))
    context_data['messaggio_api'] = "/mex",
    context_data['messaggio_id'] = messageId,
    context_data['conversazione_id'] = conversationId,
    context_data['letto'] = true

    this._api.loading = true;
    this._api.doButtonPostRequest(this.getContextData(context_data), this.context.messaggio_api).subscribe((response) => {
      if(response) {
        this._api.printResponse(response)
        this._api.loading = false;
        var mes = JSON.parse(JSON.stringify(response))
        this._snackbarManager.open(mes.successMessage, 'ok');
      }
    }, (error) => {
      if(error) {
        console.log(error);
        this._api.loading = false;
        var mes = JSON.parse(JSON.stringify(error))
        this._snackbarManager.open(mes.errorMessage, 'ko');
      }
    });

  }

  selectRecipient(recipient:any) {
    this.selectedRecipient = recipient;
  }

  searchRecipient() {
    if (this.searchWordRecipient == "") {
      this.filteredRecipientList = this.context.destinatari;
    } else {
      this.filteredRecipientList = []
      this.context.destinatari.forEach((c:any) => {
        if(c.name.toLowerCase().includes(this.searchWordRecipient.toLowerCase())) this.filteredRecipientList.push(c);
      })
    }
  }

  searchConversation() {
    if (this.searchWordConversation == "") {
      if (this.unreadOnly) {
        this.filteredChatList = [];
        this.context.conversazioni.forEach((c:any) => {
          if(c.letto == 0) this.filteredChatList.push(c);
        })
      } else this.filteredChatList = this.context.conversazioni;
    } else {
      this.filteredChatList = []
      this.context.conversazioni.forEach((c:any) => {
        if(c.searchfield.toLowerCase().includes(this.searchWordConversation.toLowerCase())) {
          if (this.unreadOnly) {
            if(c.letto != 0) this.filteredChatList.push(c);
          } else this.filteredChatList.push(c);
        }
      })
    }
  }

  toggleUnreadOnly() {
    this.unreadOnly = !this.unreadOnly;
    this.searchConversation()
  }

  toggleTranslation() {
    this.translationEnabled = !this.translationEnabled;
  }

  createConversation() {
    this._api.loading = true;
    this.selectedRecipient = undefined;
    this.conversationSubject = "";
    this.newConversation = true;
    this.toggleDrawerContentZindex(true);
    this._api.loading = false;
  }

  closeCard(create:boolean) {
    this._api.loading = true;

    this.searchWordConversation = "";
    if (!create) {
      this.newConversation = false;
      this.toggleDrawerContentZindex(false);
    } else {
      if (this.context.conversazioni.length == 0) {
        var mittente_id = "";
        for (var i = 0; i < this.context.destinatari.length; i++) {
          if (this.context.destinatari[i].name == this.username) {
            mittente_id = this.context.destinatari[i].id;
            break;
          }
        }
        if (mittente_id == "") {
          this._snackbarManager.open("Can't find sender ID, the conversation will not be created.", "ko")
          console.log("Username: " + this.username)
          //console.log("Users list: ")
          //console.log(this.context.destinatari)
        } else {
          var moment = require("moment");
          this.context.conversazioni.push({
            bozza : 0,
            conversazione_id : uuidv4(),
            intestatario : this.selectedRecipient.name,
            intestatario_id : this.selectedRecipient.id,
            letto : 1,
            mittente : this.username,
            mittente_id : mittente_id,
            oggetto : this.conversationSubject,
            original_status: "I",
            searchfield : "",
            short_plain_text : this.conversationSubject,
            sonoio_id : mittente_id,
            sonoio_nome : this.username,
            timestamp : moment().format("YYYY-MM-DD HH:mm:ss"),
            translated_message : ""
          })          
          this.filteredChatList = this.context.conversazioni;
          this.selectChat(this.filteredChatList.length - 1);
          this.newConversation = false;          
          this.toggleDrawerContentZindex(false);
        }
      } else {
        var moment = require("moment");
        this.context.conversazioni.push({
          bozza : 0,
          conversazione_id : uuidv4(),
          intestatario : this.selectedRecipient.name,
          intestatario_id : this.selectedRecipient.id,
          letto : 1,
          mittente : this.context.conversazioni[0].mittente,
          mittente_id : this.context.conversazioni[0].mittente_id,
          oggetto : this.conversationSubject,
          original_status: "I",
          searchfield : "",
          short_plain_text : this.conversationSubject,
          sonoio_id : this.context.conversazioni[0].mittente_id,
          sonoio_nome : this.context.conversazioni[0].mittente,
          timestamp : moment().format("YYYY-MM-DD HH:mm:ss"),
          translated_message : ""
        })        
        this.filteredChatList = this.context.conversazioni;
        this.selectChat(this.filteredChatList.length - 1);
        this.newConversation = false;        
        this.toggleDrawerContentZindex(false);
      }
    }
    this._api.loading = false;
  }

  toggleDrawerContentZindex(isAbove:boolean) {
    var myCss = ".mat-drawer-content { z-index: " + (isAbove ? "2" : "1") + "}";
    var style = document.createElement("STYLE");
    style.innerText = myCss;
    document.body.appendChild(style);
  }

  clear() {
    this.messageText = ""
  }

  send(asDraft:boolean) {
    var moment = require("moment");
    if (asDraft) this.currentChat.bozza = 1;
    else this.currentChat.bozza = 0;

    var message = {
        bozza : this.currentChat.bozza,
        conversazione_id : this.currentChat.conversazione_id,
        intestatario : this.currentChat.intestatario,
        intestatario_id : this.currentChat.intestatario_id,
        letto : this.currentChat.letto,
        messaggio : this.messageText,
        messaggio_id : uuidv4(),
        mittente : this.currentChat.mittente,
        mittente_id : this.currentChat.mittente_id,
        oggetto : this.currentChat.oggetto,
        original_status: this.currentChat.original_status,
        searchfield : this.currentChat.searchfield,
        short_plain_text :this.currentChat.oggetto,
        sonoio_id : this.currentChat.sonoio_id,//???
        sonoio_nome : this.currentChat.sonoio_nome,//???
        timestamp : moment().format("YYYY-MM-DD HH:mm:ss"),
        translated_message : ""
    }
    //console.log(message)
    this._api.loading = true;
    this._api.doButtonPostRequest(this.getContextData(message), this.context.messaggio_api).subscribe((response) => {
      if(response) {
        this._api.printResponse(response)
        this._api.loading = false;

        if (this.currentChat.bozza == 1 && !asDraft) {
          this.currentChat.bozza = 0;
          this.chatMessages[this.chatMessages.length - 1].bozza = 0;
        } else this.context.messaggi.push(message);
        if (this.currentChat.bozza == 0) this.clear();

        this.selectChat();

        var mes = JSON.parse(JSON.stringify(response))
        this._snackbarManager.open(mes.successMessage, 'ok');
      }
    }, (error) => {
      if(error) {
        console.log(error);
        this._api.loading = false;
        var mes = JSON.parse(JSON.stringify(error))
        this._snackbarManager.open(mes.errorMessage, 'ko');
      }
    });
  }

  getContextData(message:any) {
    let context_data = JSON.parse(JSON.stringify(this.context))

    context_data["messaggio_id"] = message.messaggio_id;
    context_data["letto"] = message.letto == 1 ? true : false;
    context_data["oggetto"] = message.oggetto;
    context_data["messaggio"] = message.messaggio;
    context_data["destinatario_id"] = message.intestatario_id;
    context_data["bozza"] = message.bozza == 1 ? true : false;
    context_data["timestamp"] = message.timestamp;
    context_data["conversazione_id"] = message.conversazione_id;
    context_data["email"] = true; //???
    context_data["message_api"] = this.context.messaggio_api;

    //console.log(context_data)

    return context_data
  }

}

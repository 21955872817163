import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { ApiService } from '../../services/api.service'
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { ExcelexporterService } from 'src/app/services/excelexporter.service';
 


@Component({
  selector: 'component-cp029',
  templateUrl: './cp029.component.html',
  styleUrls: ['./cp029.component.scss']
})
export class Cp029Component implements OnInit {

  @Input() context: any;
  @ViewChild('dt') table: Table | undefined;
  columns:any = [];
  selectedRowIndex: number = 0;
  selectedRows: any[] = [];
  filteredRows: any[] = [];

  displaySection:boolean = false;
  @ViewChild('picker1', {static: false}) private picker1: MatDatepicker<Date> | undefined;
  startDate:any;
  @ViewChild('picker2', {static: false}) private picker2: MatDatepicker<Date> | undefined;
  endDate:any;
  template:any;
  group:any;
  status:any;
  description:any;
  translatedDescription:boolean = false;
  editMode:boolean = false;
  newProcess:boolean = false;

  employeesSection:boolean = false;
  @ViewChild('dtEmployees') tableEmployees: Table | undefined;
  contextEmployees:any;
  columnsEmployees:any = [];
  selectedEmployeeIndex: number = 0;
  selectedEmployee: any;
  singleprocessPressedLambda:string = "";

  contextPublish:any;
  columnsPublishDel:any = [];
  columnsPublishIns:any = [];

  actorsSection:boolean = false;
  contextActors:any;
  columnsActors:any = [];
  selectedActor:any;
  editedActorTable:any = [];
  changeActor:boolean = false;
  currentActorName:string = "";


  compareCard:boolean = false;
  confirmStartStopCard:boolean = false;
  confirmPublishCard:boolean = false;
  confirmExitCard:boolean = false;
  confirmStartStopDeleteCard:boolean = false;

  loading: boolean = true;
  multiSortMeta:SortMeta[] = [];
  needToSave:boolean = false;

  excelRows: any;

  constructor(private _api:ApiService, public _excel:ExcelexporterService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService) {
  }

  ngOnInit(): void {
    //fare una struttura dati fatta bene per le colonne serve per il sort e il filter
    this.columns.push({field: "Elemento2", header: this.context.Column[0]});
    this.columns.push({field: "Elemento3", header: this.context.Column[1]});
    this.columns.push({field: "Elemento4", header: this.context.Column[2]});
    this.columns.push({field: "Elemento6", header: this.context.Column[3]});
    this.columns.push({field: "Elemento8", header: this.context.Column[4]});
    this.columns.push({field: "Elemento9", header: this.context.Column[5]});

    this.multiSortMeta.push({field: '', order: 1});
    this.loading = false;
  }

  onFilter(event:any) { 
    this.filteredRows = event.filteredValue; 
  }

  listEmployees() {
    this._api.loading = true;
    var contxt = this.context.ButtonListUserLambda;
    var context_data = this.getContextData(0);

    this._api.doButtonPostRequest(context_data, contxt).subscribe((response) => {
        if(response) {
          this._api.printResponse(response);
          this.loading = true;
          this.contextEmployees = response;
          this.contextEmployees = this.contextEmployees.tableUserList;
          //console.log(this.contextEmployees)

          this.loading = true;
          this.employeesSection = true;

          this.columnsEmployees = [];
          for (var i = 9; i < 13; i++) {
            this.columnsEmployees.push({field: "Elemento" + i, header: this.contextEmployees.Column[i - 1]})
          }
          //console.log(this.columnsEmployees);

          this.loading = false;
          this._api.loading = false;
        } else {
          this.loading = false;
          this._api.loading = false;
          this._snackbarManager.open(this.context.NoDataFound, 'ko');
        }
      },
      (error) => {
        if(error) {
          console.log(error);
          this.loading = false;
          this._api.loading = false;
          this._snackbarManager.open(this.context.NoDataFound, 'ko');
        }
      });

  }

  toggleDisplaySection(editMode:boolean, newProcess:boolean) {
    if (!this.displaySection) {
      if (!newProcess) {
        this.context.Row.forEach((row:any, index:number) => {
          if (row == this.selectedRows[0]) this.selectedRowIndex = index
        })
        this.restoreRow();
      } else {
        this.startDate = moment().toDate();
        this.endDate = moment().toDate();        
        this.endDate.setHours(this.endDate.getHours() + 24);
        this.template = this.context.Template[0].Text;
        this.group = this.context.Gruppo[0].Text;
        this.description = "";
        this.status = 0;
        this.newProcess = true;
      }
      this.displaySection = true;
      if (editMode || newProcess) this.editMode = true;

    } else {
      this.displaySection = false;
      this.newProcess = false;
      this.editMode = false;
      this.selectedRows = [];
    }
    this.closeCard();

  }

  toggleEditMode(restoreProcess?:boolean) {
    this.editMode = !this.editMode;
    if (restoreProcess != undefined && restoreProcess) this.restoreRow();
    this.closeCard();
  }

  convertDateToMoment(date:Date) {
    return moment(date);
  }

  restoreRow() {
    this.startDate = moment(this.selectedRows[0].Elemento2, 'YYYY-MM-DD').toDate();
    this.endDate = moment(this.selectedRows[0].Elemento3, 'YYYY-MM-DD').toDate();
    this.template = this.selectedRows[0].Elemento6;
    this.group = this.selectedRows[0].Elemento8;
    this.description = this.selectedRows[0].Elemento4;
    this.status = this.selectedRows[0].Elemento9;
  }

  checkChanges() {
    //this.startDate.format('YYYY-MM-DD')
    if (moment(this.startDate).format('YYYY-MM-DD') != this.context.Row[this.selectedRowIndex].Elemento2 ||
        moment(this.endDate).format('YYYY-MM-DD') != this.context.Row[this.selectedRowIndex].Elemento3 ||
        this.group != this.context.Row[this.selectedRowIndex].Elemento8 ||
        this.template != this.context.Row[this.selectedRowIndex].Elemento6 ||
        this.description != this.context.Row[this.selectedRowIndex].Elemento4)
        return true;
    else return false;
  }

  getContextData(lambdaType:number) {
    var templateId = "";
    this.context.Template.forEach((e:any) => {
      if (e.Text == this.template) templateId = e.Identificativo;
    })

    var groupId = ""
    this.context.Gruppo.forEach((e:any) => {
      if (e.Text == this.group) groupId = e.Identificativo;
    });
    var processId = this.newProcess ? "" : this.selectedRows[0].Elemento1;
    this.status = lambdaType == 1 ? (this.status == 0 ? 1 : 0) : this.status;
    //console.log(this.startDate)

    let context_data = {
      "DataInizio": this.convertDateToMoment(this.startDate).format('YYYY-MM-DD'),
      "DataFine": this.convertDateToMoment(this.endDate).format('YYYY-MM-DD'),
      "Descrizione": this.description,
      "Template": templateId,
      "Gruppo": groupId,
      "ProcessId": processId,
      "Stato": this.status
    }

    
    if (lambdaType == 2 || lambdaType == 3) {
      var peopleIns:any = []
      this.contextPublish.tableIns.Rows.forEach((row:any) => {
        peopleIns.push({"PEGUID": row["Elemento1"]})
      })
      Object.assign(context_data, {"PeopleIns": peopleIns});
    }
    if (lambdaType == 3) {
      var peopleDel:any = []
      this.contextPublish.tableDel.Rows.forEach((row:any) => {
        peopleDel.push({"PEGUID": row["Elemento1"]})
      })
      Object.assign(context_data, {"PeopleDel": peopleDel});
    }
    return context_data;
  }

  changeStatus() {
    this._api.loading = true;
    var contxt = this.context.ButtonStartStopLambda;
    //???
  }

  save(lambdaType:number) {   //0 = default, 1 = startstop status, 2 = publish
    this._api.loading = true;
    var contxt = "";
    switch (lambdaType) {
      case 0:
        contxt = this.context.ButtonSaveLambda;
        break;
      case 1:
        contxt = this.context.ButtonStartStopLambda;
        break;
      case 2:
        contxt = this.contextPublish.ButtonOKLambda;
        break;
      case 3:
        contxt = this.contextPublish.ButtonOKwoDelLambda;
        break;
    }
    var context_data = this.getContextData(lambdaType);

    this._api.doButtonPostRequest(context_data, contxt).subscribe((response) => {
        if(response) {
          this._api.printResponse(response);
          if (!this.newProcess) {
            //console.log("save")
            this.selectedRows[0].Elemento2 = this.convertDateToMoment(this.startDate).format('YYYY-MM-DD');
            this.selectedRows[0].Elemento3 = this.convertDateToMoment(this.endDate).format('YYYY-MM-DD')
            this.selectedRows[0].Elemento4 = this.description;
            this.selectedRows[0].Elemento5 = context_data['Template'];
            this.selectedRows[0].Elemento6 = this.template;
            this.selectedRows[0].Elemento7 = context_data['Gruppo'];
            this.selectedRows[0].Elemento8 = this.group;
            this.selectedRows[0].Elemento9 = this.status;
            this.selectedRows[0].Elemento10 = "";
            //console.log(this.selectedRowIndex);
            this.context.Row[this.selectedRowIndex] = this.selectedRows[0];
            //console.log(this.context.Row[this.selectedRowIndex]);
            this.toggleEditMode();
          } else {
            var row = {
              Elemento1 : JSON.parse(JSON.stringify(response))['ProcessId'],
              Elemento2 : this.convertDateToMoment(this.startDate).format('YYYY-MM-DD'),
              Elemento3 : this.convertDateToMoment(this.endDate).format('YYYY-MM-DD'),
              Elemento4 : this.description,
              Elemento5 : context_data['Template'],
              Elemento6 : this.template,
              Elemento7 : context_data['Gruppo'],
              Elemento8 : this.group,
              Elemento9 : this.status,
              Elemento10 : this.description
            }
            this.context.Row.push(row)
            //console.log(this.context.Row)
            this.toggleDisplaySection(false, false);
          }
          this.closeCard();
          this._api.loading = false;
          this._snackbarManager.open(this.context.SaveSuccess, 'ok');
        }
      },
      (error) => {
        if(error){
          console.log(error);
          this._snackbarManager.open(this.context.SaveFailure, 'ko');
        }
      });

  }

  publish() {
    this._api.loading = true;
    var contxt = this.context.ButtonPublishLambda;
    var context_data = this.getContextData(0);

    this._api.doButtonPostRequest(context_data, contxt).subscribe((response) => {
      if(response) {
        this._api.printResponse(response);
        this.contextPublish = response;
        //console.log(this.contextPublish)
        
        if (this.contextPublish.tableDel == undefined && this.contextPublish.tableIns == undefined) {          
          this._api.loading = false;
          this._snackbarManager.open(this.context.NoDataFound, 'ko')
        } else {
          this.loading = true;

          this.columnsPublishDel = [];
          if (this.contextPublish.tableDel != undefined) {
            for (var i = 3; i < 6; i++) {
              this.columnsPublishDel.push({field: "Elemento" + i, header: this.contextPublish.tableDel.Column[i - 1]})
            }
          }
          //console.log(this.columnsPublishDel);

          this.columnsPublishIns = [];
          if (this.contextPublish.tableIns != undefined) {
            this.columnsPublishIns.push({field: "Elemento2", header: this.contextPublish.tableIns.Column[1]})
            this.columnsPublishIns.push({field: "Elemento3", header: this.contextPublish.tableIns.Column[2]})
            this.columnsPublishIns.push({field: "Elemento4", header: this.contextPublish.tableIns.Column[3]})
          }
            //console.log(this.columnsPublishIns);

          this.loading = false;
          
          this._api.loading = false;
          this.openCard(2);
        }
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this._api.loading = false;
        this._snackbarManager.open(this.context.NoDataFound, 'ko');
      }
    });

  }

  toggleEmployeeSection() {
    this.employeesSection = !this.employeesSection;
    this.selectedEmployee = undefined;
  }


  manageSingleuser(contxt:string) {
    this._api.loading = true;
    var context_data;
    if (!this.changeActor) {
      context_data = {
        "ProcessId": this.selectedEmployee.Elemento1,
        "UserId": this.selectedEmployee.Elemento2,
        "VAGUID": this.selectedEmployee.Elemento13,
        "UserEmpName": this.selectedEmployee.Elemento9
      }
    } else {
      //console.log(this.currentActorName);
      this.contextActors.tableChangeActor.Rows.forEach((a:any) => {
      if (a.Elemento2 == this.currentActorName) {
          this.selectedActor.Elemento2 = a.Elemento1;
          this.selectedActor.Elemento5 = a.Elemento2;
          //console.log(a)
        }
      });

      context_data = {
        "TKGUID": this.selectedActor.Elemento3,
        "PEGUID": this.selectedActor.Elemento2
      }
    }
    //console.log(context_data)

    this._api.doButtonPostRequest(context_data, contxt).subscribe((response) => {
      if(response) {
        this._api.printResponse(response);
        //this.listEmployees();   //uncomment these lines for dynamic table refreshing
        //this.selectedEmployee = undefined;
       // this.selectedEmployeeIndex = -1;
        if (contxt != this.context.ButtonSingleActorLambda) {
          this._api.loading = false;
          if (this.changeActor) this.toggleChangeActor()
          this._snackbarManager.open(this.context.SaveSuccess, 'ok');
          this.listEmployees();
          this.closeCard();
        } else {
          this.contextActors = response;
          console.log(this.contextActors);
          if (this.changeActor) this.toggleChangeActor()

          this.loading = true;

          this.columnsActors = [];
          this.columnsActors.push({field: "Elemento4", header: this.contextActors.tablePhaseList.Column[3]})
          this.columnsActors.push({field: "Elemento5", header: this.contextActors.tablePhaseList.Column[4]})
          this.editedActorTable = this.contextActors.tablePhaseList.Rows;

          //console.log(this.columnsPublishDel);
          this.loading = false;
          this._api.loading = false;
          this.toggleActorSection();
        }
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this._api.loading = false;
        this._snackbarManager.open(this.context.SaveFailure, 'ko');
      }
    });

  }

  toggleActorSection() {
    this.actorsSection = !this.actorsSection;
    this.changeActor = false;
    this.selectedEmployee = undefined;
  }

  toggleChangeActor() {
    this.changeActor = !this.changeActor;
  }

  selectRow(event:any, tableId:number) { //0 = processes, 1 = employees, 2 = actors
    switch (tableId) {
      case 0:
        this.selectedRows = [];
        if (typeof event.data !== 'string') this.selectedRows.push(event.data)
        break;
      case 1:
        this.selectedEmployee = event.data;
        break;
      case 2:
        this.selectedActor = event.data;
        this.currentActorName = this.selectedActor.Elemento5;
        break;
    }
  }

  unselectRow(event:any, tableId:number) { //0 = processes, 1 = employees
    switch (tableId) {
      case 0:
        this.selectedRows.forEach((row:any, index:number) => {
          if (row == event.data) this.selectedRows.splice(index, 1)
        })
        break;
      case 1:
        this.selectedEmployee = undefined;
        break;
      case 2:
        this.selectedActor = undefined;
        this.changeActor = false;
        break;
    }
  }

  openCard(cardType:number, lambdaType?:string) {
    switch (cardType) {
      case 0:
        this.compareCard = true;
        break;
      case 1:
        this.confirmStartStopCard = true;
        break;
      case 2:
        this.confirmPublishCard = true;
        break;
      case 3:
        this.confirmExitCard = true;
        break;
      case 4:
        this.confirmStartStopDeleteCard = true;
        if (lambdaType != undefined) this.singleprocessPressedLambda = lambdaType;
        break;
    }
    this.toggleDrawerContentZindex(true);
  }

  closeCard() {

    this.compareCard = false;
    this.confirmStartStopCard = false;
    this.confirmExitCard = false;
    this.confirmPublishCard = false;
    this.confirmStartStopDeleteCard = false;
    //this.selectedRowIndex = 0
    //this.selectedRows = []
    this.toggleDrawerContentZindex(false);
  }

  toggleDrawerContentZindex(isAbove:boolean) {
    var myCss = ".mat-drawer-content { z-index: " + (isAbove ? "2" : "1") + "}";
    var style = document.createElement("STYLE");
    style.innerText = myCss;
    document.body.appendChild(style);
  }

  exportTable(rows:any) {
    this._excel.export(this.context.Title, this.columns, rows)
  }


  dateHandler1(ev:any, input:any){
    let { _d } = ev;
    this.startDate = _d;
    this.picker1?.close();
  }

  dateHandler2(ev:any, input:any){
    let { _d } = ev;
    this.endDate = _d;
    this.picker2?.close();
  }
  
}

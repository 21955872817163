import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
 

@Component({
  selector: 'component-cp010',
  templateUrl: './cp010.component.html',
  styleUrls: ['./cp010.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Cp010Component implements OnInit {

  @Input() context: any;
  @Input() position: any;
  sliders:any[] = [];
  
  multipleSliders:boolean = false;
  commentTextareas:boolean[] = [];
  translateButtonToggles:boolean[] = [];
  translateButtonTogglesComment:boolean[] = [];

  goldenImg:string="url(../../../../../assets/icon/dot-slider.svg)";
  labelWidth1 = '17%'
  labelWidth2 = '33%'

  constructor(private _errorthrower:ErrorthrowerService) {}

  ngOnInit(): void {
    if (this.context.Display == '') {
      this._errorthrower.throw("Unauthorized.", 400, false, false);
    }

    let url = window.location.pathname;
    let path = url.split("/");
    if(path.find((e)=>e == "AP002")){
      this.goldenImg = "url(../../../../../assets/icon/iconGoldenGoose.png)"
    }
    
    this.labelWidth2 =  (100 / (this.context.Labels.length - 1)) + '%'
    this.labelWidth1 = (100 - ((100 / (this.context.Labels.length - 1)) * (this.context.Labels.length - 2))) / 2 + '%'

    document.documentElement.style.setProperty('--golden-img', this.goldenImg);

    this.multipleSliders = (this.context.Sliders instanceof Array);
    //console.log(this.context)
    //console.log(this.multipleSliders);
    if (this.multipleSliders) {
      this.context.Sliders.forEach((e:any) => {
        this.commentTextareas.push(e.Elemento5 != '' && e.Elemento5 != null ? true : false);
        this.translateButtonToggles.push(false);      
        this.translateButtonTogglesComment.push(false);
        this.sliders.push(e);
      })
    } else {
      this.commentTextareas.push(this.context.Sliders.Elemento5 != '' && this.context.Sliders.Elemento5 != null ? true : false);
      this.translateButtonToggles.push(false);      
      this.translateButtonTogglesComment.push(false);
      this.sliders.push(this.context.Sliders)
    }
  }

  toggleCommentArea(i:number, status:boolean) {
    this.commentTextareas[i] = status;
  }

  updateSliderValue(event:any, i:number) {
    this.sliders[i].Elemento2 = event.value / (100 / (this.context.Labels.length - 1))
  }

  toggleTranslateButton(i:number) {
    this.translateButtonToggles[i] = !this.translateButtonToggles[i];
  }

  toggleCommentTranslateButton(i:number) {
    this.translateButtonTogglesComment[i] = !this.translateButtonTogglesComment[i];
  }

  clearSlider(i:number) {
    this.sliders[i].Elemento2 = 0;
    this.sliders[i].Elemento1 = "";
    this.sliders[i].Elemento4 = "";
  }

  updateTextareaValue(event:any, i:number) {
    this.sliders[i].Elemento5 = event.target.value;
    this.sliders[i].Elemento3 = "";
  }

  updateTextareaValueSubtitle(event:any, i:number) {
    this.sliders[i].Elemento4 = event.target.value;
    this.sliders[i].Elemento1 = "";
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';

@Component({
  selector: 'component-cp046',
  templateUrl: './cp046.component.html',
  styleUrls: ['./cp046.component.scss']
})
export class Cp046Component implements OnInit {

  @Input()
  context:any
  messageVisible:boolean = false

  constructor(private _api:ApiService, private router:Router, private errorThrower:ErrorthrowerService) {
  }

  ngOnInit(): void {
    var currentUrl = this.router.url

    //setup
    setTimeout(() => {
      this.messageVisible = true
    }, 3000)

    //how to open link
    if (this.context.link != undefined) {      
      if (this.context.tokencookie != undefined && this.context.tokencookie == true) {
        this.addTokenCookie()
      }

      if (this.context.openaswindow != undefined && this.context.openaswindow) {
        let h = window.screen.availHeight-100
        let w = window.screen.availWidth-150
        window.open(this.context.link, "_blank", `toolbar=no, menubar=no, resizable=yes, location=yes, width=${w},height=${h}, scrollbars=yes, status=yes`)
      } else if ((this.context.openastab != undefined && this.context.openastab) || (this.context.newPage != undefined && this.context.newPage)) {
        window.open(this.context.link, "_blank")
      } else {
        history.back()
        document.location.href = this.context.link
      }
    } else {
      this.errorThrower.throw("Missing link", 0, false, false)
    }

    //how to get out of the function
    if ((this.context.openastab != undefined && this.context.openastab) || (this.context.openaswindow != undefined && this.context.openaswindow)) {
      var urlParams;
      if (this.context.followup != undefined) {
        urlParams = this.context.followup.split('/')
        try {
          this._api.navigate(urlParams[1], urlParams[2], urlParams[3], urlParams[4])          
          history.replaceState({}, currentUrl, this.context.followup)
        } catch (e) {
          console.log("Bad link!")
          console.log(this.context.followup)
          history.back()
        }
      } else {
        var urlCopy = JSON.parse(JSON.stringify(this._api.lastUrl))
        urlParams = this._api.lastUrl.split('/')
        this._api.navigate(urlParams[1], urlParams[2], urlParams[3], urlParams[4])
        history.replaceState({}, currentUrl, urlCopy)
      }
      this.messageVisible = false
    }
    
  }

  addTokenCookie() {
    var env = this._api.ENVIRONMENT
    var headers = this._api.getHttpHeader();
    var domain = "";
    var splitArr = this.context.link.split('.');
    var arrLen = splitArr.length;
    if (arrLen > 2) {
      domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
      if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
        domain = splitArr[arrLen - 3] + '.' + domain;
      }
    }
    domain = "." + domain.replace("/", "")
    console.log(domain)
    document.cookie = "Token" + env + "=" + headers["Authorization"].replace("Bearer ", "") + "; Domain=" + domain + "; Path=/; Secure; Samesite=Lax;"
  }

}

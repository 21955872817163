<!--
<div class="back_button_div">
    <button class="back_button" (click)="closeWfSection()" mat-mini-fab color="accent">
        <mat-icon>arrow_back</mat-icon>
    </button>
</div>
!-->

<div *ngIf="context != undefined && context.NoDataFound != '' && (context.Data.length == 0 || context.Data[0].Cards[0].LastRow.length < 3)" style="text-align: center;">
    <mat-card class="tables_container">
        <mat-card-content>{{context.NoDataFound}}</mat-card-content>
    </mat-card>
</div>

<ng-container *ngIf="context != undefined && context.Data.length > 0 && context.Data[0].Cards[0].LastRow.length >= 3">
    <mat-card class="tables_container" *ngFor="let data of context.Data; index as i;">
        <mat-card-title *ngIf="context.Data.length > 0" class="mat-card-title d-flex align-items-center justify-content-center text-center title-card">
        {{data.Title}}
        </mat-card-title>

        <mat-card-content>
            <div class="parent_container">

                <div class="child_container_1">

                <ng-container *ngFor="let card of data.Cards; index as j;">
                    <ng-container *ngIf="j % 2 == 0">
                        <div *ngIf="card.Title != ''" class="publish_table_title">{{card.Title}}</div>

                        <table class="slider_table" style="margin-bottom:16px;">
                            <tr *ngFor="let row of card.Rows">

                                <td *ngFor="let r of row; index as rowIndex" [style.width]="rowIndex == 0 ? '40%' : '20%'" [style.text-align]="rowIndex == 0 ? 'left' : 'center'">

                                    <img *ngIf="r == '1'" src="../../../assets/icon/iconGoldenGoose.png">
                                    <ng-container *ngIf="(rowIndex > 1 && r == '') || rowIndex == 0">
                                        <span style="text-align: right;">{{r}}</span>
                                    </ng-container>

                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let r of card.LastRow" style="text-align: center;">
                                    {{r}}
                                </td>
                            </tr>

                        </table>
                    </ng-container>
                </ng-container>

                </div>

                <div class="child_container_2">

                <ng-container *ngFor="let card of data.Cards; index as j;">
                    <ng-container *ngIf="j % 2 != 0">
                    <div *ngIf="card.Title != ''" class="publish_table_title">{{card.Title}}</div>

                    <table class="slider_table" style="margin-bottom:16px;">
                        <tr *ngFor="let row of card.Rows">

                        <td *ngFor="let r of row; index as rowIndex" [style.width]="rowIndex == 0 ? '40%' : '20%'" [style.text-align]="rowIndex == 0 ? 'left' : 'center'">

                            <img *ngIf="r == '1'" src="../../../assets/icon/iconGoldenGoose.png">
                            <ng-container *ngIf="(rowIndex > 1 && r == '') || rowIndex == 0">
                                {{r}}   
                            </ng-container>

                        </td>
                        </tr>
                        <tr>
                        <td *ngFor="let r of card.LastRow" style="text-align: center;">
                            {{r}}
                        </td>
                        </tr>

                    </table>
                    </ng-container>
                </ng-container>

                </div>

            </div>
        </mat-card-content>
    </mat-card>
</ng-container>

<mat-card>
    <mat-card-content class="mat-card-content">
        <div style="display:flex; justify-content: space-between;">
            <div class="refresh-buttons">
                <button class="refresh-button" mat-raised-button color="accent" (click)="refresh()" [disabled]="loading">
                    <mat-icon>refresh</mat-icon>
                </button>
                <!--
                <div class="autorefresh-div">
                    <mat-checkbox ngDefaultControl [(ngModel)]="autorefreshEnabled">Auto refresh {{autorefreshEnabled ? 'enabled' : 'disabled'}}</mat-checkbox>
                </div>-->
            </div>
            <div class="disclaimer">{{context.DisclaimerResults}}</div>
            <div></div>
        </div>

        <p-table #dt [rowHover]="false" [rows]="10" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
            [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" styleClass="p-datatable-sm"
            [columns]="context.Column" [value]="context.Row" sortMode="multiple" [multiSortMeta]="multiSortMeta"
            [globalFilterFields]="['Elemento2', 'Elemento3', 'Elemento4', 'Elemento5', 'Elemento6']"
            selectionMode="single" (onRowSelect)="selectRow($event)" (onRowUnselect)="unselectRow($event)">

            <ng-template pTemplate="caption" id="hideIcons">
                <div class="table-header">
                    <div class="search-div">
                        <span class="p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" placeholder="Search" />
                        </span>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th class="column-header" *ngFor="let item of [].constructor(columns.length); let i = index" [pSortableColumn]="columns[i].field" pResizableColumn>
                        <span class="column-title">
                            {{columns[i].header}}
                            <span class="sort-icons" id="hideIcons">
                                <p-columnFilter type="text" [field]="columns[i].field" display="menu" class="ml-auto"></p-columnFilter>
                                <p-sortIcon [field]="columns[i].field"></p-sortIcon>
                            </span>
                        </span>
                    </th>
                    <th class="empty-th">
                        <!--buttons-->
                    </th>                        
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-rowIndex>
                <tr class="p-selectable-row" [pSelectableRowDisabled]="true" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                    <td>
                        <span class="table-data">{{ rowData['Elemento2'] | date: this.dateFormat }} </span>
                    </td>
                    <td *ngFor="let item of [].constructor(3); let i = index">
                        <span class="table-data">{{ rowData['Elemento' + (i + 3)] }} </span>
                    </td>
                    <td style="vertical-align:bottom;">
                        <mat-icon *ngIf="rowData['Elemento6'] == '01'" style="color:gray" matTooltip="Elaborating" matTooltipPosition="after">watch_later</mat-icon>
                        <mat-icon *ngIf="rowData['Elemento6'] == '02' || rowData['Elemento5'].includes('ERROR')" color="warn" matTooltip="Failed" matTooltipPosition="after">error</mat-icon>
                        <mat-icon *ngIf="rowData['Elemento6'] == '03' && !rowData['Elemento5'].includes('ERROR')" style="color:#00aa00" matTooltip="Completed" matTooltipPosition="after">check_circle</mat-icon>
                        <mat-icon *ngIf="rowData['Elemento6'] == '04'" style="color:#ffb100" matTooltip="Warning" matTooltipPosition="after">warning</mat-icon>
                    </td>
                    <td style="width:200px">
                        <div class="buttons-div">
                            <button class="table-button" mat-raised-button 
                                    (click)="downloadReport(rowIndex)" color="accent" [disabled]="(rowData['Elemento7'] == null || rowData['Elemento7'] == '')">
                                <mat-icon>save_alt</mat-icon>
                            </button>
                            <button *ngIf="teguid == 'TE0006'" class="table-button" mat-raised-button 
                                    (click)="downloadLogs(rowIndex)" color="accent">
                                <mat-icon>list_alt</mat-icon>
                            </button>
                            <button class="table-button" mat-raised-button 
                                    (click)="removeReport(rowIndex)" color="accent">
                                <mat-icon>remove_circle</mat-icon>
                            </button>                         
                        </div>
                    </td>
                </tr>            
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length" class="nodata-cell">
                        {{context.NoDataFound}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </mat-card-content>
</mat-card>
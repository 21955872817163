import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
//import { CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard  {

  //user: CognitoUserInterface | undefined;
  //authState: AuthState | undefined;

  constructor(private auth: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.auth.isAuthenticated();
  }

}

import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'component-cp015',
  templateUrl: './cp015.component.html',
  styleUrls: ['./cp015.component.scss']
})
export class Cp015Component implements OnInit {

  @Input()
  context:any;

  constructor() { }

  ngOnInit(): void {
  }

}


<mat-card class="mat-card-content" style="background-color:whitesmoke; padding:8px!important; padding-top:0 !important">  
    <mat-tab-group *ngIf="!isEmpty(config)" class="tab-group" [@inOutAnimation] color="accent">
        
        <mat-tab label="Preferences">
            <div style="padding:8px;padding-top:16px; display:flex; flex-wrap:wrap; gap:32px">
                <mat-card style="padding:12px!important">
                    <div style="display:flex; flex-direction: column;">
                        <mat-checkbox [(ngModel)]="companyDecodingsEnabled" [disabled]="!companyDecodings" class="companydecodings-checkbox"><b>Company Decodings</b></mat-checkbox>
                        <mat-card @inOutAnimation *ngIf="companyDecodingsEnabled" style="margin-top:8px; max-width:375px;">
                            <mat-card-content>
                                <table *ngIf="companyDecodingsEnabled" mat-table [dataSource]="companyDecodingsTableData">

                                    <ng-container matColumnDef="input">
                                        <th mat-header-cell *matHeaderCellDef> Input </th>
                                        <td mat-cell *matCellDef="let element, index as i">
                                            <mat-form-field style="max-width: 100px; margin-right:16px">
                                                <input matInput [(ngModel)]="element.input">
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="output">
                                        <th mat-header-cell *matHeaderCellDef> Output </th>
                                        <td mat-cell *matCellDef="let element">
                                            <mat-form-field style="max-width: 100px;">
                                                <input matInput [(ngModel)]="element.output">
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="remove">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element, index as i">
                                            <button mat-mini-fab (click)="removeDecoding(i)" style="margin-left:24px">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="companyDecodingsDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: companyDecodingsDisplayedColumns;"></tr>
                                </table>
                                
                                <div style="display:flex; justify-content:center; margin-top:8px; padding-bottom:8px">
                                    <button mat-mini-fab (click)="addDecoding()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                                
                            </mat-card-content>
                        </mat-card>
                        <br><br>
                        
                        <button mat-raised-button (click)="buildConfig()" color="warn">DEBUG BUILD</button><br>
                    </div>
                </mat-card>
                <mat-card style="padding:12px!important;height:142px;">
                    <div style="display:flex; flex-direction: column">
                        <span><b>Get Payroll Code from...</b></span>
                        <mat-radio-group [(ngModel)]="payrollSource">
                            <mat-radio-button value="payrollcode" class="payrollsource-radio">Employments.PayrollCode</mat-radio-button><br>
                            <mat-radio-button value="employmentid" class="payrollsource-radio">Employments.EmploymentID</mat-radio-button><br>
                            <mat-radio-button value="visadetails" class="payrollsource-radio">VisaDetails.Number</mat-radio-button>
                        </mat-radio-group>
                        <br>
                    </div>
                </mat-card>
                <div>
                    <!--
                    <mat-radio-group [(ngModel)]="filterOn">
                        <mat-radio-button value="level" class="filteron-radio">Level</mat-radio-button>
                        <mat-radio-button value="ccnl" class="filteron-radio">CCNL </mat-radio-button>
                    </mat-radio-group>
                    -->
                    <mat-card *ngIf="!isEvalValid" style="padding:12px !important">
                        <span><b>Filter expression (exclude when <i>level</i> is...):</b></span><br>
                        <mat-form-field>
                            <mat-label>Eval</mat-label>
                            <input matInput required [(ngModel)]="filterEvalStr">
                        </mat-form-field>
                    </mat-card>
                    <mat-card @inOutAnimation *ngIf="filterOn != '' && isEvalValid" class="filter-card">
                        <span><b>Exclude if <i>level</i> is...</b></span><br>
                        <ng-container *ngFor="let condition of filterEval; index as i">
                            <mat-card @inOutAnimation class="condition-card">
                                <mat-form-field style="max-width: 110px;">
                                    <mat-label>Operator</mat-label>
                                    <mat-select [(ngModel)]="condition.equals" required>
                                    <mat-option [value]="true">Equals</mat-option>
                                    <mat-option [value]="false">Not Equals</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Value</mat-label>
                                    <input matInput required [(ngModel)]="condition.value">
                                </mat-form-field>
                                <button mat-mini-fab (click)="removeCondition(i)" class="remove-button">
                                    <mat-icon>remove</mat-icon>
                                </button>
                            </mat-card>
                            <div style="display:flex; justify-content: center;">
                            <mat-button-toggle-group *ngIf="condition.and != undefined" [(ngModel)]="condition.and" (change)="onToggleValueChange($event, i)">
                                <mat-button-toggle [value]="true">And</mat-button-toggle>
                                <mat-button-toggle [value]="false">Or</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        </ng-container>
                        <div style="display:flex; justify-content:center;">
                        <button mat-mini-fab (click)="addCondition()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    </mat-card>
                    </div>
                </div>
        
        </mat-tab>

        <mat-tab label="Records" *ngIf="config.voci != undefined && config.voci.VOCI != undefined"> 
            <div class="nomatches-div" *ngIf="config.voci.VOCI.length == 0">
                <span>
                    There are no records for the configuration selected.
                </span><br>
            </div>

            <div style="margin-top:8px; margin-bottom:8px; padding:8px !important">
                <b>Sources</b>
                <mat-chip-listbox selectable multiple>
                    <mat-chip-option color="accent" [selected]="isRecordsSource('AS')" (click)="modifyRecordsSource('AS')">Absences</mat-chip-option>
                    <mat-chip-option color="accent" [selected]="isRecordsSource('PA')" (click)="modifyRecordsSource('PA')">Proposed Salary</mat-chip-option>
                    <mat-chip-option color="accent" [selected]="isRecordsSource('WS')" (click)="modifyRecordsSource('WS')">Worksheets</mat-chip-option>
                    <mat-chip-option color="accent" [selected]="isRecordsSource('NT')" (click)="modifyRecordsSource('NT')">Expense Reports</mat-chip-option>
                    <mat-chip-option color="accent" [selected]="isRecordsSource('TO')" (click)="modifyRecordsSource('TO')">TOIL</mat-chip-option>
                    <mat-chip-option color="accent" [selected]="isRecordsSource('TR')" (click)="modifyRecordsSource('TR')">Training</mat-chip-option>
                    <mat-chip-option color="accent" [selected]="isRecordsSource('EV')" (click)="modifyRecordsSource('EV')">Person Events</mat-chip-option>
                    <mat-chip-option color="accent" [selected]="isRecordsSource('FL')" (click)="modifyRecordsSource('FL')">Input File</mat-chip-option>
                </mat-chip-listbox>
                <mat-expansion-panel style="margin-top:8px; margin-bottom:8px; width:400px">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Details</mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field style="width:100%">
                        <mat-label></mat-label>
                        <textarea matInput cdkTextareaAutosize [value]="recordSourcesString" (change)="setRecordsSources($event)"></textarea>
                    </mat-form-field>
                    <div *ngIf="!isRecordsSourcesStringGood" style="display:flex; align-items: center; margin-bottom: 16px;">
                        <mat-icon color="warn">error</mat-icon>
                        <span color="warn">There are errors in the dictionary.</span>
                    </div>
                    <button mat-raised-button color="accent" [disabled]="compareRecordsSources()" (click)="restoreRecordsSources()">Restore</button>                    
                </mat-expansion-panel>
            </div>
            <br>
              
            <div class="recordsTable-div">
                <b>Records</b>
                <p-table #dt *ngIf="recordsColumns.length > 0" [rowHover]="false" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-lg"
                    [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll" [paginator]="true" [scrollable]="true"
                    [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" [first]="firstRow"
                    [columns]="recordsColumns" [value]="config.voci.VOCI" sortMode="multiple" [(selection)]="selectedRows">

                    <ng-template pTemplate="caption" id="hideIcons">
                        <div class="table-header" id="hideIcons">
                            <div class="table_buttons">
                                <button mat-raised-button (click)="addRecord()" color="accent">
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                                <button mat-raised-button [disabled]="selectedRows.length == 0" (click)="removeRecords()" color="accent">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                                <input type="file" id="uploadFiles" style="display: none" [accept]="allowedFileExtension" (change)="onFileSelected($event)" #fileUpload single>
                                <button mat-raised-button (click)="fileUpload.click()" matTooltip="Load file" color="accent">
                                    <mat-icon>cloud_upload</mat-icon>
                                </button>
                            </div>
                            <div class="search-div">
                                <span class="p-input-icon-right searchbox-span" id="hideIcons">
                                    <i class="pi pi-search"></i>
                                    <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" placeholder="Search" class="search-input"/>
                                </span>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3rem">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th class="column-header" *ngFor="let item of [].constructor(recordsColumns.length); let i = index" [pSortableColumn]="recordsColumns[i]" pResizableColumn>
                                <span class="column-title">
                                    {{recordsColumns[i]}}
                                </span>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData>
                        <tr [pSelectableRow]="rowData">
                            <td>
                                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <ng-container *ngFor="let item of [].constructor(recordsColumns.length); let i = index">
                                <td [pEditableColumn]="rowData[recordsColumns[i]]" [pEditableColumnField]="recordsColumns[i]" >
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" [(ngModel)]="rowData[recordsColumns[i]]">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ rowData[recordsColumns[i]] }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr *ngIf="config.voci.VOCI.length == 0" >
                            <td [attr.colspan]="recordsColumns.length" class="nodata-cell">
                                No data found.
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
            
        </mat-tab>
    </mat-tab-group>
</mat-card>
import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'component-cp030',
  templateUrl: './cp030.component.html',
  styleUrls: ['./cp030.component.scss']
})
export class CP030Component implements OnInit {

  @Input()
  context:any

  constructor(private title:Title) { }

  ngOnInit(): void {
    if (this.context.Title != undefined && this.context.Title != "") this.setTitle();
  }

  setTitle() {
    var splitStr = this.context.Title.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    this.title.setTitle(this.title.getTitle().replace("...", splitStr.join(' ')));
  }

  back() {
    history.back()
  }

}

<div class="page-div">

    <mat-expansion-panel class="expansion-card-roles">
        <mat-expansion-panel-header>
            <mat-icon>security</mat-icon>
            <span>&nbsp;&nbsp;{{labels.configureRoles}}</span>
        </mat-expansion-panel-header>

        <div class="roles-management-card-content">
            <!-- Contenuto della card -->
            <div class="roles-container">
                <!-- Prima card con la lista dei ruoli -->
                <div class="roles-card">
                    <ng-container *ngIf="context?.roles && context.roles.length > 0; else noRoles">
                        <mat-list class="button-list">
                            <ng-container *ngFor="let role of context.roles; index as i">
                                <button mat-button [ngClass]="{'mat-raised-button': selectedRole == role,                                               
                                                'mat-unselected-button': selectedRole != role}" color="accent"
                                    class="function-button" (click)="selectRole(role)">

                                    <div class="function-text">
                                        <!--<span>{{ role.RUDESC }} ({{ role.RUCODE }})</span> -->
                                        <span>{{ role.RUDESC }}</span>
                                    </div>
                                </button>
                            </ng-container>
                        </mat-list>
                    </ng-container>
                    <!-- Template alternativo se non ci sono ruoli -->
                    <ng-template #noRoles>
                        <p class="no-roles-message">{{labels.noRoleAvailable}}</p>
                    </ng-template>
                </div>
                <mat-divider></mat-divider>
                <!-- Seconda card con dettagli e tabella funzioni -->
                <div class="details-card">
                    <!--
                    <div *ngIf="selectedRole" class="selected-details">
                        <div class="action-bar">
                            <div><strong>Ruolo Selezionato:</strong> {{ selectedRole.RUDESC }} ({{ selectedRole.RUCODE }})</div>
                            <button mat-stroked-button (click)="toggleAllModules()" class="action-button">
                                <mat-icon>{{ allExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
                                {{ allExpanded ? 'Collassa Tutto' : 'Espandi Tutto' }}
                            </button>

                            <button mat-stroked-button class="action-button">
                                <mat-icon>refresh</mat-icon> Aggiorna
                            </button>
                            
                            <button mat-stroked-button class="action-button">
                                <mat-icon>settings</mat-icon> Configura
                            </button>                        
                        </div>
                    </div> -->

                    <!-- Debug: Stato di moduleVisibility -->
                    <div *ngIf="1 < 0" class="debug-container">
                        <h4 class="debug-title"> Stato dei moduli (moduleVisibility)</h4>
                        <pre>{{ moduleVisibility | json }}</pre>
                    </div>

                    <!-- Raggruppamento per modulo -->
                    <div *ngIf="selectedRole?.functions?.length > 0">
                        <div class="functions-list-containerxx">
                            <div
                                *ngIf="groupedFunctionsForRole && groupedFunctionsForRole.length > 0; else noFunctions">
                                <mat-accordion multi>
                                    <mat-expansion-panel *ngFor="let module of groupedFunctionsForRole"
                                        [expanded]="moduleRolesState[module.moduleCode]"
                                        (opened)="onPanelRoleOpened(module.moduleCode)"
                                        (closed)="onPanelRoleClosed(module.moduleCode)"
                                        class="expansion-card-module-function">
                                        <mat-expansion-panel-header>
                                            <span>{{ module.moduleCode }} - {{ module.moduleName }}</span>
                                        </mat-expansion-panel-header>
                                        <div class="function-table-container">
                                            <table mat-table [dataSource]="module.functions"
                                                class="function-table-grouped">
                                                <!-- Colonna Codice Funzione -->
                                                <ng-container matColumnDef="FACODE">
                                                    <th mat-header-cell *matHeaderCellDef>{{labels.functionCode}}</th>
                                                    <td mat-cell *matCellDef="let func"> {{ func.FACODE }} </td>
                                                </ng-container>
                                                <!-- Colonna Funzione -->
                                                <ng-container matColumnDef="FADESC">
                                                    <th mat-header-cell *matHeaderCellDef>{{labels.functionName}}</th>
                                                    <td mat-cell *matCellDef="let func"> {{ func.FADESC }} </td>
                                                </ng-container>
                                                <!-- Colonna Attiva -->
                                                <ng-container matColumnDef="FAENABLED">
                                                    <th mat-header-cell *matHeaderCellDef class="centered-column">{{labels.functionStatus}}
                                                    </th>
                                                    <td mat-cell *matCellDef="let func" class="centered-column">
                                                        <mat-icon class="status-icon" *ngIf="func.FAENABLED"
                                                            color="primary">check_circle</mat-icon>
                                                        <mat-icon class="status-icon" *ngIf="!func.FAENABLED"
                                                            color="primary">cancel</mat-icon>
                                                    </td>
                                                </ng-container>
                                                <!-- Colonna Personalizzata -->
                                                <ng-container matColumnDef="IS_STANDARD">
                                                    <th mat-header-cell *matHeaderCellDef class="centered-column">
                                                        {{labels.functionPersonalizationStatus}}</th>
                                                    <td mat-cell *matCellDef="let func" class="centered-column">
                                                        <mat-icon class="status-icon" *ngIf="!func.IS_STANDARD"
                                                            color="primary">check_circle</mat-icon>
                                                    </td>
                                                </ng-container>

                                                <!-- Colonna Attributi -->
                                                <ng-container matColumnDef="configure">
                                                    <th mat-header-cell *matHeaderCellDef class="centered-column">
                                                        {{labels.configureFunction}}</th>
                                                    <td mat-cell *matCellDef="let func" class="centered-column">
                                                        <button mat-button (click)="openFunctionConfig(func)">
                                                            <mat-icon>edit_square</mat-icon>
                                                        </button>
                                                    </td>
                                                </ng-container>
                                                <!-- Altre colonne se necessario -->

                                                <tr mat-header-row
                                                    *matHeaderRowDef="['configure', 'FACODE', 'FADESC', 'FAENABLED', 'IS_STANDARD']">
                                                </tr>
                                                <tr mat-row
                                                    *matRowDef="let row; columns: ['configure', 'FACODE', 'FADESC', 'FAENABLED', 'IS_STANDARD'];">
                                                </tr>
                                            </table>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>

                    <!-- Messaggio se il ruolo non ha funzioni -->
                    <div *ngIf="!selectedRole?.functions || selectedRole?.functions?.length === 0"
                        class="selected-details">
                        <p class="no-functions-message"><strong>{{labels.noFunctionAvailable}}</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>


    <mat-expansion-panel class="expansion-card-functions">
        <mat-expansion-panel-header>
            <mat-icon>functions</mat-icon>
            <span>&nbsp;&nbsp;{{labels.configureFunctions}}</span>
        </mat-expansion-panel-header>
        <div class="functions-management-card-content">
            <ng-container *ngIf="groupedFunctions && groupedFunctions.length > 0; else noFunctions">
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let module of groupedFunctions"
                        [expanded]="moduleFunctionsState[module.moduleCode]"
                        (opened)="onPanelFunctionOpened(module.moduleCode)"
                        (closed)="onPanelFunctionClosed(module.moduleCode)" class="expansion-card-module-function">
                        <mat-expansion-panel-header class="expansion-card-module-function-title">
                            <span>{{ module.moduleCode }} - {{ module.moduleName }}</span>
                        </mat-expansion-panel-header>

                        <!-- Qui inserisci la tua tabella delle funzioni -->
                        <div class="function-table-container">
                            <table mat-table [dataSource]="module.functions" class="function-table-grouped">
                                <!-- Colonna Codice Funzione -->
                                <ng-container matColumnDef="FACODE">
                                    <th mat-header-cell *matHeaderCellDef>{{labels.functionCode}}</th>
                                    <td mat-cell *matCellDef="let func"> {{ func.FACODE }} </td>
                                </ng-container>
                                <!-- Colonna Funzione -->
                                <ng-container matColumnDef="FADESC">
                                    <th mat-header-cell *matHeaderCellDef>{{labels.functionName}}</th>
                                    <td mat-cell *matCellDef="let func"> {{ func.FADESC }} </td>
                                </ng-container>
                                <!-- Colonna IS_STANDARD -->
                                <ng-container matColumnDef="IS_STANDARD">
                                    <th mat-header-cell *matHeaderCellDef class="centered-column">{{labels.functionPersonalized}}</th>
                                    <td mat-cell *matCellDef="let func" class="centered-column">
                                        <!-- Mostra l'icona se la funzione non è standard -->
                                        <mat-icon class="status-icon" *ngIf="!func.IS_STANDARD"
                                            color="primary">check_circle</mat-icon>
                                    </td>
                                </ng-container>

                                <!-- Colonna Configurazione -->
                                <ng-container matColumnDef="configureFunction">
                                    <th mat-header-cell *matHeaderCellDef class="centered-column">{{labels.configureFunction}}</th>
                                    <td mat-cell *matCellDef="let func" class="centered-column">
                                        <button mat-button (click)="openFunctionSettings(func) ">
                                            <mat-icon>edit_square</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <!-- Colonna Layout -->
                                <ng-container matColumnDef="configureLayout">
                                    <th mat-header-cell *matHeaderCellDef class="centered-column">{{labels.configureFunctionLayout}}</th>
                                    <td mat-cell *matCellDef="let func" class="centered-column">
                                        <button mat-button
                                            (click)="openLayoutConfig(func)"><mat-icon>dashboard_customize</mat-icon></button>
                                    </td>
                                </ng-container>

                                <!-- Definizione delle righe -->
                                <tr mat-header-row
                                    *matHeaderRowDef="['configureFunction', 'configureLayout', 'FACODE', 'FADESC', 'IS_STANDARD']">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ['configureFunction', 'configureLayout', 'FACODE', 'FADESC', 'IS_STANDARD'];">
                                </tr>
                            </table>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-container>
            <ng-template #noFunctions>
                <p class="no-functions-message"><strong>{{labels.noFunctionAvailable}}</strong></p>
            </ng-template>
        </div>
    </mat-expansion-panel>

</div>

<!-- Finestra Modale con Material Card -->
<div class="configure-function-overlay" *ngIf="selectedFunction">
    <mat-card class="configure-function-card">
        <mat-card-header>
            <mat-card-title class="configure-function-title">{{labels.functionAttributes}}</mat-card-title>
        </mat-card-header>

        <mat-card-content class="configure-function-content">
            <!-- Dettagli funzione in orizzontale -->
            <div class="configure-function-details">
                <!-- RIGA 1: Codice Funzione e Nome -->
                <div class="configure-function-row">

                    <input type="hidden" [(ngModel)]="selectedFunction.FTGUID" name="FTGUID">

                    <mat-form-field appearance="outline" class="configure-function-field">
                        <mat-label>{{labels.functionCode}}</mat-label>
                        <input matInput [value]="selectedFunction.FACODE" disabled>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="configure-function-field">
                        <mat-label>{{labels.functionName}}</mat-label>
                        <input matInput [value]="selectedFunction.FADESC" disabled>
                    </mat-form-field>
                </div>

                <!-- RIGA 2: Stato e Stato Personalizzazione -->
                <div class="configure-function-row">
                    <mat-form-field appearance="outline" class="configure-function-field">
                        <mat-label>{{labels.functionStatus}}</mat-label>
                        <input matInput [value]="selectedFunction.FAENABLED ? 'Attiva' : 'Disattivata'" disabled>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="configure-function-field">
                        <mat-label>{{labels.functionPersonalized}}</mat-label>
                        <input matInput [value]="selectedFunction.IS_STANDARD ? 'Standard' : 'Personalizzata'" disabled>
                    </mat-form-field>
                </div>

                <!-- Checkbox per attivare/disattivare la funzione -->
                <div class="configure-function-checkbox-container">
                    <mat-checkbox [(ngModel)]="selectedFunction.FAENABLED" color="primary">
                        {{labels.functionActivation}}
                    </mat-checkbox>
                    <!--
                    <mat-checkbox [(ngModel)]="selectedFunction.IS_STANDARD" color="accent">
                        Usa Versione Standard
                    </mat-checkbox> 
                    -->
                </div>

            </div>

        </mat-card-content>
        <!-- Bottom Bar per i pulsanti -->
        <div class="configure-function-bottom-div">
            <mat-card-actions class="configure-function-bottom-bar">
                <button mat-stroked-button (click)="closeModal()">{{labels.buttonCancel}}</button>
                <button mat-raised-button color="primary" (click)="saveFunction()">{{labels.buttonSave}}</button>
            </mat-card-actions>
        </div>
    </mat-card>
</div>

<!-- Nuova Finestra Modale per Configura Funzione -->
<div class="configure-function-overlay" *ngIf="selectedFunctionForSettings">
    <mat-card class="configure-function-card-large">
        <mat-card-header>
            <mat-card-title class="configure-function-title">{{labels.configureFunctions}}</mat-card-title>
        </mat-card-header>

        <mat-card-content class="configure-function-content">
            <div class="configure-function-row">
                <mat-form-field appearance="outline" class="configure-function-field">
                    <mat-label>{{labels.functionCode}}</mat-label>
                    <input matInput [value]="selectedFunctionForSettings.FACODE" disabled>
                </mat-form-field>

                <mat-form-field appearance="outline" class="configure-function-field">
                    <mat-label>{{labels.functionName}}</mat-label>
                    <!--<input matInput [value]="selectedFunctionForSettings.FADESC" readonly>-->
                    <input matInput [(ngModel)]="selectedFunctionForSettings.FADESC"
                        [disabled]="selectedFunctionForSettings.IS_STANDARD" />
                </mat-form-field>
            </div>

            <div class="configure-function-details">
                <mat-checkbox [checked]="!selectedFunctionForSettings.IS_STANDARD"
                    (change)="onTogglePersonalization($event)" [disabled]="!selectedFunctionForSettings.IS_STANDARD"
                    color="accent">
                    {{labels.functionPersonalizedActivation}}
                </mat-checkbox>
            </div>

            <!-- Campo per modificare il JSON di configurazione -->
            <div class="configure-function-details">
                <mat-form-field appearance="outline" class="configure-function-field json-editor">
                    <mat-label>{{labels.configureFunctionsJSON}}</mat-label>
                    <!--<textarea matInput [(ngModel)]="selectedFunctionForSettings.FAPARA" rows="10"></textarea>-->
                    <textarea matInput [(ngModel)]="selectedFunctionForSettings.FAPARA" rows="20"
                        [disabled]="selectedFunctionForSettings.IS_STANDARD"></textarea>
                </mat-form-field>
            </div>

        </mat-card-content>

        <div class="configure-function-bottom-div">
            <mat-card-actions class="configure-function-bottom-bar">
                <button mat-stroked-button (click)="closeFunctionSettings()">{{labels.buttonCancel}}</button>
                <button mat-raised-button color="primary" (click)="saveFunctionSettings()">{{labels.buttonSave}}</button>
            </mat-card-actions>
        </div>
    </mat-card>
</div>


<div class="configure-layout-overlay" *ngIf="selectedFunctionForLayout">
    <mat-card class="configure-layout-card">
        <mat-card-header>
            <mat-card-title class="configure-function-title">{{labels.configureFunctionLayout}}</mat-card-title>
        </mat-card-header>

        <mat-card-content class="configure-layout-content">
            <div class="configure-function-row">

                <mat-form-field appearance="outline" class="configure-function-field">
                    <mat-label>{{labels.functionCode}}</mat-label>
                    <input matInput [value]="selectedFunctionForLayout.FACODE" disabled>
                </mat-form-field>

                <mat-form-field appearance="outline" class="configure-function-field">
                    <mat-label>{{labels.functionName}}</mat-label>
                    <input matInput [value]="selectedFunctionForLayout.FADESC" disabled>
                </mat-form-field>
            </div>
            <div class="configure-function-row">
                <mat-form-field appearance="outline" class="configure-function-field">
                    <mat-label>{{labels.functionStatus}}</mat-label>
                    <input matInput [value]="selectedFunctionForLayout.FAENABLED ? 'Attiva' : 'Disattivata'" disabled>
                </mat-form-field>

                <mat-form-field appearance="outline" class="configure-function-field">
                    <mat-label>{{labels.functionPersonalizationStatus}}</mat-label>
                    <input matInput [value]="selectedFunctionForLayout.IS_STANDARD ? 'Standard' : 'Personalizzata'"
                        disabled>
                </mat-form-field>

            </div>

            <!-- Tabella Componenti della Funzione -->
            <h3 class="configure-components-title">{{labels.functionComponents}}</h3>
            <section class="configure-layout-table-container">
                <table mat-table [dataSource]="selectedFunctionForLayout.components" class="configure-layout-table">

                    <!-- APGUID Column -->
                    <ng-container matColumnDef="APGUID">
                        <th mat-header-cell *matHeaderCellDef>APGUID</th>
                        <td mat-cell *matCellDef="let component">{{ component.APGUID }}</td>
                    </ng-container>

                    <!-- TEGUID Column -->
                    <ng-container matColumnDef="TEGUID">
                        <th mat-header-cell *matHeaderCellDef>TEGUID</th>
                        <td mat-cell *matCellDef="let component">{{ component.TEGUID || 'N/A' }}</td>
                    </ng-container>

                    <!-- MOGUID Column -->
                    <ng-container matColumnDef="MOGUID">
                        <th mat-header-cell *matHeaderCellDef>MOGUID</th>
                        <td mat-cell *matCellDef="let component">{{ component.MOGUID }}</td>
                    </ng-container>

                    <!-- FAGUID Column -->
                    <ng-container matColumnDef="FAGUID">
                        <th mat-header-cell *matHeaderCellDef>FAGUID</th>
                        <td mat-cell *matCellDef="let component">{{ component.FAGUID }}</td>
                    </ng-container>

                    <!-- SZGUID Column -->
                    <ng-container matColumnDef="SZGUID">
                        <th mat-header-cell *matHeaderCellDef>SZGUID</th>
                        <td mat-cell *matCellDef="let component">{{ component.SZGUID }}</td>
                    </ng-container>

                    <!-- COGUID Column -->
                    <ng-container matColumnDef="COGUID">
                        <th mat-header-cell *matHeaderCellDef>COGUID</th>
                        <td mat-cell *matCellDef="let component">{{ component.COGUID }}</td>
                    </ng-container>

                    <!-- COKEY Column -->
                    <ng-container matColumnDef="COKEY_READ">
                        <th mat-header-cell *matHeaderCellDef>COKEY</th>
                        <td mat-cell *matCellDef="let component">{{ component.COKEY }}</td>
                    </ng-container>
                    <!--<ng-container matColumnDef="COKEY">
                        <th mat-header-cell *matHeaderCellDef>COKEY</th>
                        <td mat-cell *matCellDef="let component">
                          <mat-form-field appearance="outline">
                            <mat-label>COKEY</mat-label>
                            <mat-select [(ngModel)]="component.COKEY">
                              <mat-option *ngFor="let option of coKeyOptions" [value]="option.value">
                                {{ option.label }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                    </ng-container> -->
                    <ng-container matColumnDef="COKEY">
                        <th mat-header-cell *matHeaderCellDef> CONAME </th>
                        <td mat-cell *matCellDef="let component">
                            <ng-container *ngIf="!selectedFunctionForLayout?.IS_STANDARD; else readOnlyCokey">
                                <mat-form-field class="component-name-field">
                                    <mat-select [(ngModel)]="component.COKEY"
                                        [matTooltip]="getSelectedOptionConame(component)" matTooltipPosition="above"
                                        [disabled]="selectedFunctionForLayout?.IS_STANDARD">
                                        <mat-option *ngFor="let option of getCokeyOptions(component.COGUID)"
                                            [value]="option.COKEY" [matTooltip]="option.CONAME"
                                            matTooltipPosition="above">
                                            {{ option.CONAME }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </ng-container>

                            <ng-template #readOnlyCokey>
                                <span>{{ component.CONAME }}</span>
                            </ng-template>

                        </td>
                    </ng-container>

                    <!-- SORT Column -->
                    <ng-container matColumnDef="SORT">
                        <th mat-header-cell *matHeaderCellDef>ORDINE</th>
                        <td mat-cell *matCellDef="let component">{{ component.SORT }}</td>
                    </ng-container>

                    <!--<tr mat-header-row *matHeaderRowDef="['APGUID', 'TEGUID', 'MOGUID', 'FAGUID', 'SZGUID', 'COGUID', 'COKEY', 'SORT'];  sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['APGUID', 'TEGUID', 'MOGUID', 'FAGUID', 'SZGUID', 'COGUID', 'COKEY', 'SORT'];"></tr>-->
                    <tr mat-header-row *matHeaderRowDef="['COGUID', 'COKEY', 'COKEY_READ', 'SORT'];  sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['COGUID', 'COKEY', 'COKEY_READ', 'SORT'];"></tr>
                </table>

            </section>
            <!-- Campi di configurazione layout -->
            <!--
            <h3 class="configure-layout-options-title">Opzioni di Layout</h3>
            <mat-form-field appearance="outline">
                <mat-label>Colonne Layout</mat-label>
                <input matInput [(ngModel)]="selectedFunctionForLayout.layoutColumns">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Opzioni Extra</mat-label>
                <input matInput [(ngModel)]="selectedFunctionForLayout.extraOptions">
            </mat-form-field>
                -->
        </mat-card-content>

        <div class="configure-function-bottom-div">
            <mat-card-actions class="configure-layout-bottom-bar">
                <button mat-stroked-button (click)="closeLayoutModal()">Annulla</button>
                <button mat-raised-button color="primary" (click)="saveLayout()">Salva</button>
            </mat-card-actions>
        </div>
    </mat-card>

</div>
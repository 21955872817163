import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import { ApiService } from '../../services/api.service'
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
 

@Component({
  selector: 'component-cp009',
  templateUrl: './cp009.component.html',
  styleUrls: ['./cp009.component.scss']
})
export class Cp009Component implements OnInit {

  @Input() context: any;
  @ViewChild('dt') table: Table | undefined;
  columns:any = []

  loading: boolean = true;
  multiSortMeta:SortMeta[] = [];
  selectedRowIndex: number = 0;
  selectedRows: any[] = [];
  autorefreshEnabled:boolean = false;
  dateFormat = 'MM/dd/YYYY h:mm:ss aaa'
  teguid = "TE0000"

  constructor(private _api:ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService) {
    setInterval(()=> { if (this.autorefreshEnabled) this.refresh() }, 10000); 
  }

  ngOnInit(): void {
    //fare una struttura dati fatta bene per le colonne serve per il sort e il filter
    this.columns.push({field: "Elemento2", header: this.context.Column[0]})
    this.columns.push({field: "Elemento3", header: this.context.Column[1]})
    this.columns.push({field: "Elemento4", header: this.context.Column[2]})
    this.columns.push({field: "Elemento5", header: this.context.Column[3]})
    this.columns.push({field: "Elemento6", header: this.context.Column[4]})

    this.multiSortMeta.push({field: '', order: 1});
    this.loading = false; 
    this.teguid = this._api.getUrlParameters()["teguid"]
    if (this.teguid != 'TE0006') {
      this.dateFormat = 'YYYY/MM/dd h:mm:ss aaa'
    }
  }

  refresh() {
    //location.reload()
    this.loading = true;

    this._api.getPageSections().subscribe((response) => {
      if(response) {
        try {
          this.context = JSON.parse(JSON.stringify(response)).Sections[0].Components[0].Component.Context;
          this._api.printResponse(response);           
          this.loading = false;
        } catch (e) {
          location.reload()
        }
      }
    }, (error) => {
      if(error) {
        location.reload()
      }
    });
  }

  removeReport(row:any) {
    this._api.loading = true;
    var context = "/delete-result";
    var context_data = {
      'ProcessGuid' : row.Elemento1,
      'fileName' : row.Elemento5,
      'Status' : row.Elemento6
    };
    var backupRowObject = this.context.Row;

    this._api.doButtonPostRequest(context_data, context).subscribe((response) => {
      if(response) {
        this._api.printResponse(response)
        this._snackbarManager.open(this.context.DeleteReportSuccess, 'ok');
        for (var j = 0; j < this.context.Row.length; j++) {
          if (this.context.Row[j] == row) {
            this.context.Row.splice(j, 1)
            break;
          }
        }
        if (this.table != undefined) {
          this.table.value = this.context.Row
          this.selectedRowIndex = 0
          this.selectedRows = []
        }
        this._api.loading = false;
        //console.log(this.table?.value)
      }
    },
    (error) => {
      if(error) {
        console.log(error);
        this.context.Row = backupRowObject;
        this._snackbarManager.open(this.context.DeleteReportError, 'ko');
        this._api.loading = false;
      }
    });
  }

  downloadReport(row:any) {
    var context = "/download-report";
    var context_data = {
      'ProcessGuid' : row.Elemento1,
      'fileName' : row.Elemento5,
      'completeFileName' : row.Elemento7,
    };

    this.doButtonPost(context_data, context);
  }

  downloadLogs(row:any) {
    var context = "/logs";
    var context_data = {
      'OUGUID' : row.Elemento1
    };

    this.doButtonPost(context_data, context);
  }

  doButtonPost(context_data:any, context:any) {    
    this._api.loading = true;
    this._api.doButtonPostRequest(context_data, context).subscribe((response:any) => {
      if(response) {
        this._api.printResponse(response)
        this._api.loading = false; 
        window.open(response.downloadUri)
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this._snackbarManager.open(this.context.DownloadReportError, 'ko');
        //this._api.lastError = JSON.parse(JSON.stringify(error));
        //this._api.navigate("","","","","ErrorPage");
        this._api.loading = false; 
      }
    });
  }
  

  selectRow(event:any) {
    this.selectedRows = []
    if (typeof event.data !== 'string') this.selectedRows.push(event.data)
  }

  unselectRow(event:any) {
    this.selectedRows.forEach((row:any, index:number) => {
      if (row == event.data) this.selectedRows.splice(index, 1)
    })
  }

}

import { Component, HostListener, OnInit, ViewChild, Output, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ApiService } from '../services/api.service'
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { FuncMenuComponent } from '../components/func-menu/func-menu.component';
import { AuthService } from '../services/auth.service';
import { Title } from '@angular/platform-browser';
import { MatDrawer } from '@angular/material/sidenav';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { StylerService } from '../services/styler.service';


@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})

export class PageComponent implements OnInit {

  app:any = {};

  appIndexOld = -2;
  appIndex = -1;
  tenantIndex = -1;
  moduleIndex = -1;
  functionIndex = -1;

  username = "";
  cognitoUser = "";
  magicBanner = "";
  chatBanner = "";
  language = "";

  showAppName:boolean[] = [];
  showAppsList:boolean = true;
  mobileView:boolean = false;
  mobileUseragent:boolean = true;
  fatalError:string = "";


  @ViewChild(FuncMenuComponent) funcMenu:FuncMenuComponent | undefined;
  funcmenuVisible:boolean = true;

  @ViewChild("tenantsMenu") tenantsMenu:MatMenuTrigger | undefined;
  @ViewChild("drawer") drawer:MatDrawer | undefined;
  events: string[] = [];
  drawerAnimation: boolean = true;
  drawerEnabled:boolean = false;
  compactDrawer: boolean = true;
  animatingDrawer:boolean = false;
  drawerEvent:any = undefined;
  screenWidth: number;
  loggingOut:boolean = false;

  changingApp:number = -1;

  helpCardVisible:boolean = false;

  @ViewChild(MatMenuTrigger, {static: true}) rightClickMenuTrigger: MatMenuTrigger | undefined; 
  rightClickMenuPosition =  {x: '0', y: '0'} 

  @Output()
  isSticky: boolean = false;
  
  //keyboard shortcuts
  @HostListener('document:keydown.alt.1') sf0() { this.changeFunction(0); }
  @HostListener('document:keydown.alt.2') sf1() { this.changeFunction(1); }
  @HostListener('document:keydown.alt.3') sf2() { this.changeFunction(2); }
  @HostListener('document:keydown.alt.4') sf3() { this.changeFunction(3); }
  @HostListener('document:keydown.alt.5') sf4() { this.changeFunction(4); }
  @HostListener('document:keydown.alt.6') sf5() { this.changeFunction(5); }
  @HostListener('document:keydown.alt.7') sf6() { this.changeFunction(6); }
  @HostListener('document:keydown.alt.8') sf7() { this.changeFunction(7); }
  @HostListener('document:keydown.alt.9') sf8() { this.changeFunction(8); }
  @HostListener('document:keydown.alt.control.1') sm0() { this.changeModule(0); }
  @HostListener('document:keydown.alt.control.2') sm1() { this.changeModule(1); }
  @HostListener('document:keydown.alt.control.3') sm2() { this.changeModule(2); }
  @HostListener('document:keydown.alt.control.4') sm3() { this.changeModule(3); }
  @HostListener('document:keydown.alt.control.q') sa0() { this.changeApp(0); }
  @HostListener('document:keydown.alt.control.w') sa1() { this.changeApp(1); }
  @HostListener('document:keydown.alt.control.e') sa2() { this.changeApp(2); }

  @HostListener('contextmenu')
  preventContextMenu() {
      return true;//true==context menu enabled
  }


  constructor(public _api:ApiService, private router: Router, private auth:AuthService, private title:Title, 
    private _styler:StylerService) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
    router.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        var urlParams = this._api.getUrlParameters();
        
        //for back/forward button
        if (urlParams.apguid != '') {//excluding logout
            if (Object.keys(urlParams).length > 1) 
            this.setAllIndexes(urlParams.apguid, urlParams.teguid, urlParams.moguid, urlParams.fmguid)
            this.changeDrawerState();
            this.setPageTitle();

          //for page refresh
          if (Object.keys(this.app).length !== 0) this.savePage()
        } 
        
        
      } 
      if (e instanceof NavigationStart) {
        //scrolls up page
        if (!this.mobileView) {
          const a = document.querySelector("mat-drawer-content.drawer-content");
          if (a != null) a.scrollTo(0,0);
        } else {
          const a = document.querySelector("mat-drawer-content.drawer-content-full");          
          if (a != null) a.scrollTo(0,0);
        }
          
      }   
    })
  }

  ngOnInit(): void {    
    if (sessionStorage.getItem("page") === null || this._api.getUrlParameters().apguid == '') this.construct();
    else this.refreshCurrentPage();

    //force setAllIndexes when an url has been pasted
    try {
      var urlParams = this._api.getUrlParameters();  
      this.setAllIndexes(urlParams.apguid, urlParams.teguid, urlParams.moguid, urlParams.fmguid)
    } catch (e) {}

    if (this.app.length == 1) this.appIndexOld = this.appIndex
    
    this._api.startSessionTimer();

    this.onWindowResize();
    //console.log(window.navigator.userAgent)
    if (window.navigator.userAgent.includes("Android") || window.navigator.userAgent.includes("iPad") || window.navigator.userAgent.includes("iPhone") || window.navigator.userAgent.includes("iOS")) this.mobileUseragent = true
    else this.mobileUseragent = false
    //console.log(this.mobileUseragent)
  }

  ngAfterViewInit() {
    //sticky function menu
    const funcWrap = document.querySelector('.func-wrap')
    const observer = new IntersectionObserver( 
      ([e]) => this.isSticky = e.intersectionRatio < 1 ? true : false,
      {threshold: [1]}
    );
    //e.target.classList.toggle('isSticky', e.intersectionRatio < 1)
    observer.observe(funcWrap!);
    
    this.onWindowResize();
  }
  

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.mobileView = window.innerWidth < 600 ? true : false;
    this.changeDrawerState();
    this.checkFunctionMenuVisibility();
  }

  savePage() {
    let indexList = {
      app : this.appIndex,
      tenant : this.tenantIndex,
      module : this.moduleIndex,
      function : this.functionIndex
    }
    let otherVariables = {
      username : this.username,
      cognitoUser : this.cognitoUser,
      magicbanner : this.magicBanner,
      chatbanner : this.chatBanner,
      language : this.language
    }
    sessionStorage.setItem("page", JSON.stringify({
      app : this.app, 
      indexes : indexList,
      other : otherVariables
    }));  
  }

  refreshCurrentPage() {
    var page: any = JSON.parse("" +sessionStorage.getItem("page")); 
    this.app = page['app'];
    //console.log(this.app)

    var indexes:any = page['indexes'];
    //console.log(indexes)
    this.appIndex = indexes.app;
    this.appIndexOld =  this.appIndex 
    this.tenantIndex = indexes.tenant;
    this.moduleIndex = indexes.module;
    var functionIndexCheck = this.getObjectIndex(this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].function, 
                                this._api.getUrlParameters().fmguid)
    if (functionIndexCheck == indexes.function)
      this.functionIndex = indexes.function;
    else 
      this.functionIndex = functionIndexCheck

    var other:any = page['other'];
    this.username = other.username;
    this.cognitoUser = other.cognitoUser;
    this.magicBanner = other.magicbanner;
    this.chatBanner = other.chatbanner;
    this.language = other.language;
    
    //sessionStorage.removeItem("page")
    //this._styler.setCurrentTenant(this._api.getUrlParameters().teguid);
    this._styler.updateTheme();
    this.changeDrawerState();

    this.app.forEach((a:any) => {
      this.showAppName.push(false);
    })

    this.setPwaStatus();
    this.setPageTitle();

    this._api.constructionComplete = true;
  }

  setPageTitle() {    
    var urlParams = this._api.getUrlParameters();
    try {
      if (!this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].function[this.functionIndex].function.Description.includes("...") ||
          urlParams.apguid != "") {        
          this.title.setTitle(this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].function[this.functionIndex].function.Description 
            + " / " + this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].module.Description);
          if (urlParams.obguid.includes("wf-")) {
            var t:any = urlParams.obguid.replace("wf-","").split("-");
            for (var i = 0; i < t.length; i++) {
              t[i] = t[i][0].toUpperCase() + t[i].substring(1);
            }
            t = t.join(" ")
            this.title.setTitle(t + " / " + this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].module.Description);
          }
      }    
    } catch (e) { //if function is not in func-menu
      this.title.setTitle("... / " + this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].module.Description);  
    }
  }

  construct() {
    this._api.loading = true;

    this._api.getConstruction().subscribe((response) => {
      if(response) {
        console.log("\n\n----- CONSTRUCTION ----")
        console.log(response);
        console.log("-----------------------\n\n\n")
        //console.log("PARSING DEL JSON PRESO Dapp'API /construction");

        //clean function array, just for testing (FOUR nested loops, yikes...)
        for (var x = 0; x < response.All.length; x++) {
          for (var y = 0; y < response.All[x].tenant.length; y++) {
            for (var z = 0; z < response.All[x].tenant[y].module.length; z++) {
              for (var i = 0; i < response.All[x].tenant[y].module[z].function.length; i++) {
                if (i > 0 && response.All[x].tenant[y].module[z].function[i].function.ID == response.All[x].tenant[y].module[z].function[i-1].function.ID) {
                  response.All[x].tenant[y].module[z].function.splice(i - 1, 1);           
                }
              }
            }
          }
        }

        /*
        //reorder app tenants by name
        for (var x = 0; x < response.All.length; x++) {
          if (response.All[x].tenant.length > 1) {
            response.All[x].tenant = response.All[x].tenant.sort(function(a:any, b:any) {
              var textA = a.tenant.Description;
              var textB = b.tenant.Description;
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });            
          }
        }*/
        
        this.app = response.All;        
        this.username = response.userName;
        this.cognitoUser = response.cognitoUser;
        localStorage.removeItem("lastuser")   
        try {
          if (this.cognitoUser.includes('_')) localStorage.setItem("lastuser", this.cognitoUser.split('_')[0])          
        } catch(e) {
          console.log("Error while saving last cognito user.")
        }
        this.magicBanner = response.MagicBanner;
        this.chatBanner = response.ChatBanner;
        this.language = response.Language;
        sessionStorage.setItem("UTGUID", response.userGuid)
        if (sessionStorage.getItem("LANGU") !== null) {
          var constrLang = response.Language.toLowerCase().slice(0, -1);
          if (sessionStorage.getItem("LANGU") != constrLang) sessionStorage.setItem("LANGU", constrLang)
        }
        
        
        var resumeUrl = sessionStorage.getItem("RESUME_URL");
        if (resumeUrl != null) {
          try {
            var resumeUrlObj = JSON.parse(resumeUrl);
            //console.log(resumeUrlObj)
            this.setAllIndexes(resumeUrlObj.apguid, resumeUrlObj.teguid, resumeUrlObj.moguid, resumeUrlObj.fmguid)
            this.router.navigate([resumeUrlObj.apguid, resumeUrlObj.teguid, resumeUrlObj.moguid, resumeUrlObj.fmguid, resumeUrlObj.obguid])
          } catch (e) {
            this.setAllIndexes(response.App.ID, response.Tenant.ID, response.Module.ID, response.Function.ID)
            this.router.navigate([response.App.ID, response.Tenant.ID, response.Module.ID, response.Function.ID])          
          }
        } else {
          this.setAllIndexes(response.App.ID, response.Tenant.ID, response.Module.ID, response.Function.ID)
          this.router.navigate([response.App.ID, response.Tenant.ID, response.Module.ID, response.Function.ID])          
        }

        this.setPageTitle();

        sessionStorage.removeItem("RESUME_URL");

        this._api.constructionComplete = true;
        sessionStorage.removeItem("app");
            
        this.app.forEach((a:any) => {
          this.showAppName.push(false)
        })

        //if (this.app[this.appIndex].tenant[this.tenantIndex].module.length > 1) this.toggleDrawer();      
      }
    }, (error) => {
      if(error){
        console.log("FATAL ERROR");
        console.log(error);
        var errorObj = JSON.parse(JSON.stringify(error));
        if (errorObj.error != null && errorObj.error.message != null && errorObj.error.message != undefined) this.fatalError = errorObj.error.message;
        else if (errorObj.error != null && (typeof errorObj.error === 'string' || errorObj.error instanceof String)) this.fatalError = errorObj.error;
        else this.fatalError = errorObj.statusText
        window.history.pushState(null, "", window.location.href);
        this._api.loading = false;
        this.auth.deleteAmplifyCookies();
      }
    }
  );
  }

  changeApp(appIndex: number, tenantIndex?:number){  
    if (appIndex < this.app.length && !this._api.loading) {
      if (this.appIndex != appIndex) this._api.loading = true;
      this.changingApp = appIndex;
      setTimeout(() => {
        this.changingApp = -1;
        this.appIndexOld = this.appIndexOld;
        this.appIndex = appIndex;
        this.moduleIndex = 0;
        this.functionIndex = 0;
        
        if (tenantIndex != undefined) this.tenantIndex = tenantIndex;
        this.changeDrawerState();
              
        this.showAppName[this.appIndex] = false;
        this.showAppsList = true;
        this.changeRoute(); 
        const elem = document.querySelector(".func-menu");
        if (elem != undefined) elem.scrollBy(-9999, 0);
        this.setPwaStatus();
      }, this.appIndex != appIndex ? 700 : 0);
    }
  }

  changeModule(moduleIndex: number){
    if (moduleIndex < this.app[this.appIndex].tenant[this.tenantIndex].module.length && !this._api.loading) {
      this.moduleIndex = moduleIndex;
      this.functionIndex = 0;

      if (this.mobileView) {
        this.animatingDrawer = true;
        this.drawer?.close().then(() => {  
          this.animatingDrawer = false;
        });
      }

      this.changeRoute();
      const elem = document.querySelector(".func-menu");
      if (elem != undefined) elem.scrollBy(-9999, 0);
    }
  }

  changeFunction(functionIndex:number) {
    if (functionIndex < this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].function.length && !this._api.loading) {
      this.functionIndex = functionIndex;
      this.changeRoute();
      this.checkFunctionMenuVisibility();
    }
  }

  changeRoute() {
    this._api.navigate(this.app[this.appIndex].app.ID,
      this.app[this.appIndex].tenant[this.tenantIndex].tenant.ID,      
      this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].module.ID,
      this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].function[this.functionIndex].function.ID);
      
    this.setPageTitle();
  }
  
  openConversations() {    
    this._api.navigate("AP002", "TE0006", "MO002", "FA0034");
  }

  setAllIndexes(appId:string, tenantId:string, moduleId:string, functionId:string) {     
    //this.appIndexOld = this.appIndex
    this.appIndex = this.getObjectIndex(this.app, appId)       
    this.tenantIndex = this.getObjectIndex(this.app[this.appIndex].tenant, tenantId)
    this.moduleIndex = this.getObjectIndex(this.app[this.appIndex].tenant[this.tenantIndex].module, moduleId) 
    this.functionIndex = this.getObjectIndex(this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].function, functionId) 

    if (this.appIndex != this.appIndexOld) {
      //this.changingApp = true;
      this.appIndexOld = this.appIndex;
    } //else this.changingApp = false;    
    
    this._styler.updateTheme();
    if (this.functionIndex == -1) {
      this.setPageTitle();
    }    
    this.checkFunctionMenuVisibility();
  }  
  
  checkFunctionMenuVisibility() {
    if (window.innerWidth < 600 && this.tenantIndex >= 0 && this.mobileUseragent) {
      this.funcmenuVisible = false;
      var functions = this.app[this.appIndex].tenant[this.tenantIndex].module[this.moduleIndex].function
      for (var i = 0; i < functions.length; i++) {
        if (functions[i].function.Mobile == 1) {
          this.funcmenuVisible = true;
          break;
        }
      }
    } else this.funcmenuVisible = true;
    //if (this._api.getUrlParameters().fmguid == "FA0020") this.funcmenuVisible = false;
  }

  getObjectIndex(objectArray:any, guid:any) {
    for (var i = 0; i < objectArray.length; i++) {
      var obj = Object.keys(objectArray[i])[0] as any
      if (guid == objectArray[i][obj].ID) {
        return i;
      }
    }
    //console.log(objectArray, guid)
    return -1;
  }

  openTenantsMenu(index:number) {
    if (this.app[index].tenant.length > 1) this.tenantsMenu?.openMenu();
  }

  help() {
    this.helpCardVisible = true;
  }

  settings() {
    var urlParams = this._api.getUrlParameters();
    if (urlParams.fmguid != "FA0020" && !this._api.loading) {
      this._api.loading = true;
      this._api.navigate(urlParams.apguid, urlParams.teguid, urlParams.moguid, "FA0020")
      //this._api.changeFunction("FA0020"); 
    }
  }

  logout() {
    this.loggingOut = true;
    this._api.loading = true;
    setTimeout(() => {
      this.auth.signOut();
    }, 300);
  }  
  
  changeDrawerState() {
    if (this.app[this.appIndex] != undefined && this.app[this.appIndex].tenant[this.tenantIndex].module.length > 1) {
      if (!this.drawerEnabled) this.enableDrawer();  
      
      //this.animatingDrawer = true;   
      if (this.mobileView) {
        //console.log("mobile close")    
        this.drawer?.close().then(() => {
          if (this.compactDrawer && this.mobileView) this.compactDrawer = false;  
          this.animatingDrawer = false;
        });
      } else {
        //console.log("open")
        
        this.compactDrawer = true;
        if (!this.drawer?.opened) {
          this.drawer?.open().then(() => {
            this.animatingDrawer = false;
          });
        }
      
      } 

    } else this.disableDrawer();
  }

  enableDrawer() {
    this.drawerEnabled = true;    
    if (this.mobileView) {
      this.compactDrawer = false;
      this.drawer?.close().then(() => {
        this.animatingDrawer = false;
      });
      //console.log("enabled (mobile)")
    } else {
      this.compactDrawer = true;
      this.drawer?.open().then(() => {
        this.animatingDrawer = false;
      });
      //console.log("enabled")
    }    
  }

  disableDrawer() {
    this.drawer?.close();
    this.drawerEnabled = false;
  }

  toggleDrawer() {
    //console.log("toggle")
    this.animatingDrawer = true;
    if (this.drawer?.opened) {
      if (this.mobileView) {          
        //console.log("close mobile drawer")
        this.compactDrawer = false;
        this.drawer?.close().then(() => {
          this.animatingDrawer = false;
        });  
      } else {        
        //console.log("expand/shrink drawer")      
        if (!this.compactDrawer) this.compactDrawer = true;
        else this.compactDrawer = false;
      }
    } else {
      //console.log("open mobile drawer")      
      this.compactDrawer = false;
      this.drawer?.open().then(() => {
        this.animatingDrawer = false;
      });
    }
  }

  desktopDrawerAnimationEnd() {
    if (!this.mobileView && this.animatingDrawer) this.animatingDrawer = false;
  }

  isModuleDisabled(moguid:string) {
    var disabled = true;
    this.app.forEach((a:any) => {
      a.tenant.forEach((t:any) => {
        t.module.forEach((m:any) => {
          if (m.module.ID == moguid) {
            m.function.forEach((f:any) => {
              if (f.function.Mobile == 1) {
                disabled = false
                return true
              }
              return false
            });
            return true
          }
          return false
        });
        return true
      });
      return true
    });
    return disabled
  }

  enableDefaultLogo(event:any) {
    (event.target as HTMLImageElement).src = "./assets/icon/moduleicon.png";
  }

  onRightClick(event: MouseEvent, item:any, i:any) { 
    event.preventDefault(); 
    this.rightClickMenuPosition.x = event.clientX + 'px'; 
    this.rightClickMenuPosition.y = event.clientY + 'px'; 
    this.rightClickMenuTrigger!.menuData = {item: item, i: i} 
    this.rightClickMenuTrigger!.openMenu(); 
  } 

  openNewTab(i?:any) {
    if (i != undefined) {
      var newUrl = "/" + this.app[i.app].app.ID + "/" + this.app[i.app].tenant[i.tenant].tenant.ID + "/" +
        this.app[i.app].tenant[i.tenant].module[i.module].module.ID + "/" + this.app[i.app].tenant[i.tenant].module[i.module].function[i.function].function.ID;
      window.open(newUrl, "_blank");
    } else window.open(this.router.url, "_blank");
  }

  setPwaStatus() {
    if (this.app[0].app.ID == "AP002" && this.app[0].tenant[0].tenant.ID == "TE0006") {
      document.getElementById('manifest-link')?.setAttribute('href', "manifest.webmanifest");
    } else {
      document.getElementById('manifest-link')?.removeAttribute('href');
    }
  }

}

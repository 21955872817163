import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

var awsconfig = {
  Auth: {
      region: environment.cognitoData.region,
      userPoolId: environment.cognitoData.user_pool,
      userPoolWebClientId: environment.cognitoData.client_id,
      mandatorySignIn: false,
      cookieStorage: {
          domain: environment.cognitoData.cookie_storage.domain,
          path: environment.cognitoData.cookie_storage.path,
          expires: environment.cognitoData.cookie_storage.expires,
          sameSite: environment.cognitoData.cookie_storage.same_site,
          secure: environment.cognitoData.cookie_storage.secure
      },
      oauth: {
          domain: environment.cognitoData.oauth.domain,
          scope: environment.cognitoData.oauth.scope,
          redirectSignIn: environment.cognitoData.oauth.redirect_sign_in,
          redirectSignOut: environment.cognitoData.oauth.redirect_sign_out,
          responseType: environment.cognitoData.oauth.response_type
      }
  }
}

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

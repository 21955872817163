import { Component, Input, SimpleChanges } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'component-cp082',
  templateUrl: './cp082.component.html',
  styleUrls: ['./cp082.component.scss']
})
export class Cp082Component {

  @Input() context:any
  contextBak:any
  @Input() teguid:any = "TE0000"
  saving:boolean = false

  constructor(private _snackbarManager:SnackbarmanagerService, public dialog: MatDialog, private _errorThrower:ErrorthrowerService, private _api:ApiService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["teguid"] != undefined && changes['teguid'].currentValue != undefined && changes['teguid'].currentValue != '') {
      this.context = undefined
      this.getFunctions()
    }
  }

  getFunctions() {
    this._api.doButtonPostRequest({"TEGUID":this.teguid}, "/client-functions").subscribe((response) => {
      if (response) {
        this._api.printResponse(response);
        this.context = JSON.parse(JSON.stringify(response))
        this.contextBak = JSON.parse(JSON.stringify(response))
        console.log(this.context.functions)
        console.log(this.contextBak.functions)
        this.saving = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
         else this._snackbarManager.open(error.error, 'ko');
        this._api.loading = false; 
        this.saving = false;
      }
    });
  }

  normalizeFTATTI() {
    this.context.functions.forEach((f:any) => {
      if (f.FTATTI == true) f.FTATTI = 1
      else f.FTATTI = 0
    });
  }

  checkChanges() {
    this.normalizeFTATTI()
    return JSON.stringify(this.context.functions) === JSON.stringify(this.contextBak.functions)
  }

  save() {
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: "Change active functions",
        content: "Would you like to apply these changes?",
        accept: "Yes",
        reject: "No"
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {  
        this.saving = true;
        this.normalizeFTATTI()
        
        this._api.doButtonPostRequest(this.context, "/client-functions").subscribe((response) => {      
          if (response) {
            this._api.printResponse(response);
            var responseObj = JSON.parse(JSON.stringify(response));  
            this.contextBak = JSON.parse(JSON.stringify(response))
            if (responseObj.isSuccess) {
              //console.log(responseObj.clients)
              this._snackbarManager.open("Success!", 'ok');
            }
            else this._snackbarManager.open(responseObj.error, 'ko');
            this._api.loading = false;
            this.saving = false;
          }
        }, (error) => {
          if(error) {
            console.log(error);    
            if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
            else if (error.error != undefined) this._snackbarManager.open(error.error, 'ko');
            else if (error.message != undefined) this._snackbarManager.open(error.message, 'ko');
            else this._snackbarManager.open("Failed!", 'ko');
            this._api.loading = false; 
            this.saving = false;
          }
        });
      }
    })
  }
}

<mat-card>

  <mat-card-content>
    <div *ngIf="context.TitleCompany != null && context.TitleCompany != ''" class="title-datepicker" [style.background-color]="_styler.currentAccent">
      {{ context.TitleUpload }}
    </div>

    <input type="file" id="uploadFiles" style="display: none" [accept]="allowedFileExtension" (change)="onFileSelected($event)" #fileUpload multiple>
    <div class="file-upload">
      {{ context.Upload != undefined && context.Upload.length > 0 ? context.Upload[0].Text : context.Title }}
      <button mat-raised-button style="margin-left: 15px; margin-right: 15px;" color="accent" [disabled]="disabledUpload" (click)="fileUpload.click()">
        <mat-icon class="button-icon">upload</mat-icon>
        {{ context.LabelUpload }}
      </button>
      <!--
      <button mat-raised-button style="margin-right: 15px;" color="primary" (click)="onClickUploadNew()"> {{ context.LabelUpload != undefined ?context.LabelUpload }} </button>
        -->
    </div>
    <div *ngIf="selectedFiles.length > 0">
      <mat-card style="width: 50%; margin-top: 15px;">
        <!--<mat-card-title class="title-card text-center">File selezionati</mat-card-title>!-->
        <mat-card-content class="inner-card-light">
          <div *ngFor="let file of selectedFiles; let i = index" class="filelist-div">
            <div style="display:flex; flex-direction:column">
              <span>{{ file.name }}</span>
              <span style="font-size:10px; font-style:italic">Last modified: {{file.lastModified | date:'MMM d h:mm a'}}</span> <!--, Size: {{ (file.size / 1000).toFixed(0) }} kb-->
            </div>
            <button mat-mini-fab color="accent" [disabled]="!selectedFilesUploadStatus[i] || disabledDelete" (click)="onClickDeleteUpload(file.name, i)">
              <mat-spinner *ngIf="!selectedFilesUploadStatus[i]" diameter="24" color="accent"></mat-spinner>
              <mat-icon *ngIf="selectedFilesUploadStatus[i]">delete</mat-icon>
            </button>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>

</mat-card>

<div [style.display]="cardVisible ? 'block' : 'none'" class="card-background">
    <mat-card class="form-card">
        <mat-card-title *ngIf="formType != 0" class="mat-card-title">
            <mat-icon *ngIf="formType == 2" class="title-icon">add_circle</mat-icon>
            <mat-icon *ngIf="formType == 1 && _api.getUrlParameters().teguid == 'TE0006'" class="title-icon">search</mat-icon>           
            <mat-icon *ngIf="formType == 1 && _api.getUrlParameters().teguid != 'TE0006'" class="title-icon">create</mat-icon>           
            <span *ngIf="formType == 2" class="title_span">{{ this.context.buttonNewTitleTooltip }}</span>
            <span *ngIf="formType == 1" class="title_span">{{ this.context.buttonChangeTitleTooltip }}</span>            
        </mat-card-title>
        <mat-card-content class="form-card-content">
            <genericform-component #card [context]="context" (closeFormEvent)="closeForm($event)" ></genericform-component>
        </mat-card-content>
    </mat-card>   
</div>

<p-table #dt [rowHover]="context.EnableHover" [rows]="10" [showCurrentPageReport]="true"  styleClass="p-datatable-md"
    [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true" [scrollable]="true"
    [filterDelay]="0" [reorderableColumns]="context.AllowReordering" [resizableColumns]="context.AllowResizing"
    [columns]="usedColumns" [value]="context.rows" [sortMode]="context.AllowSorting ? (context.AllowMultiSorting ? 'multiple' : 'single') : 'single'" [multiSortMeta]="multiSortMeta"
    [globalFilterFields]="filterFields" [selectionMode]="!cardVisible ? (context.SelectionType == 'Multiple' ? 'multiple' : 'single') : null"
    (onRowSelect)="selectRow($event)" (onRowUnselect)="unselectRow($event)"> 

    <ng-template pTemplate="caption" id="hideIcons">
        <div class="table-header" id="hideIcons">
            <div class="table_buttons">
                <button *ngIf="context.buttonNewTitle != undefined && context.buttonNewTitle != '' && (context.ReadOnlyGrid.isreadonly != undefined && !context.ReadOnlyGrid.isreadonly)" [matTooltip]="context.buttonNewTitleTooltip" mat-raised-button (click)="openForm(2)" [disabled]="selectedRows.length == 0 ? false : true" color="accent" >
                    <mat-icon>add_circle</mat-icon>
                    <!--<span class="button_label"> {{context.buttonNewTitle}} </span>-->
                </button>
                <button *ngIf="context.buttonDeleteTitle != undefined && context.buttonDeleteTitle != '' && (context.ReadOnlyGrid.isreadonly != undefined && !context.ReadOnlyGrid.isreadonly)" [matTooltip]="context.buttonDeleteTitleTooltip" mat-raised-button (click)="context.DeletionPopupLabels != undefined && context.DeletionPopupLabels.length > 0 ? removeRowPopup() : removeRow()" [disabled]="context.SelectionType == 'Multiple' ? !(selectedRows.length >= 1) : !(selectedRows.length == 1)" color="accent">
                    <mat-icon>remove_circle</mat-icon>
                    <!--<span class="button_label"> {{context.buttonDeleteTitle}} </span>-->
                </button>
                <button *ngIf="context.buttonChangeTitle != undefined && context.buttonChangeTitle != ''" mat-raised-button [matTooltip]="context.buttonChangeTitleTooltip" (click)="openForm(1)" [disabled]="selectedRows.length < 1 ? true : false" color="accent">
                    <mat-icon *ngIf="_api.getUrlParameters().teguid == 'TE0006'">search</mat-icon>
                    <mat-icon *ngIf="_api.getUrlParameters().teguid != 'TE0006'">create</mat-icon>
                    <!--<span class="button_label"> {{context.buttonChangeTitle}} </span>-->
                </button>
                <button *ngIf="context.buttonViewTitle != undefined && context.buttonViewTitle != ''" mat-raised-button [matTooltip]="context.buttonViewTitleTooltip" (click)="view()" [disabled]="selectedRows.length < 1 ? true : false" color="accent">
                    <mat-icon>remove_red_eye</mat-icon>
                    <!--<span class="button_label"> {{context.buttonViewTitle}} </span>-->
                </button>
                <button *ngIf="context.buttonExcelTitle != undefined && context.buttonExcelTitle != ''" (click)="exportTable()" [matTooltip]="context.buttonExcelTitleTooltip" mat-raised-button color="accent">
                    <mat-icon>table_chart</mat-icon>
                    <!--<span class="button_label"> {{context.buttonExcelTitle}} </span>-->
                </button>
            </div>
            <div *ngIf="context.Search != undefined && context.Search != ''" class="search_div">
                <span *ngIf="context.Search != ''" class="p-input-icon-right searchbox-span" id="hideIcons">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" [placeholder]="context.Search" />
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngIf="context.SelectionCheckBox == 'true'">
                <th style="max-width:50px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
            </ng-container>
            
            <th class="column-header" *ngFor="let col of columns" [pSelectableRow]="col.elemento" [pSortableColumn]="col.elemento" pResizableColumn pReorderableColumn [style.width]="col.width">
                <div [style.text-align]="col.align" [style.width]="col.width">
                    {{col.title}}
                </div>
                <!--
                <span class="sort-icons" id="hideIcons"  [style.justify-content]="col.align" [style.width]="col.width">
                    <p-columnFilter type="text" [field]="col.elemento" display="menu" class="ml-auto"></p-columnFilter>
                    <p-sortIcon [field]="col.elemento"></p-sortIcon>
                </span>                
                -->
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
        <tr class="p-selectable-row" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <ng-container *ngIf="context.SelectionCheckBox == 'true'">
                <td style="max-width:50px">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
            </ng-container>
            <td *ngFor="let col of columns" style="white-space: break-spaces;" >
                <div class="p-column-title">{{col.title}}</div>
                <div class="w-100" *ngIf="col.format.includes('##,#')" [style.text-align]="col.align">{{ rowData[col.elemento] | currency:col.format.replaceAll("##,#", "") : col.format.replaceAll("##,#", "") : '.0'  }}</div>
                <div class="w-100" *ngIf="col.format == 'p0'" [style.text-align]="col.align">{{ rowData[col.elemento] | percent:'.0'}}</div>
                <div class="w-100" *ngIf="col.format == 'p1'" [style.text-align]="col.align">{{ rowData[col.elemento] | percent:'.1'}}</div>
                <div class="w-100" *ngIf="col.format == ''" [style.text-align]="col.align" [style.width]="col.width">{{ rowData[col.elemento] }}</div>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td>{{context.NoDataFound}}</td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer" *ngIf="aggregationEnabled">
        <tr>
            <td class="table-footer" *ngFor="let col of usedColumns" [style.text-align]="col.align">
                <span class="w-100" *ngIf="(col.aggregation | json) != ({} | json)">
                    {{col.aggregation.aggrLabel}}<br>
                    <span *ngIf="col.aggregation.aggrFormat.includes('##,#')">{{ aggregateColumn(col, 0) | currency:col.format.replaceAll("##,#", "") : col.format.replaceAll("##,#", ""):'.0' }}</span>
                    <span *ngIf="col.aggregation.aggrFormat == 'p0'">{{ aggregateColumn(col, 0) | percent:'.0'}}</span>
                    <span *ngIf="col.aggregation.aggrFormat == 'p1'">{{ aggregateColumn(col, 0) | percent:'.1'}}</span>
                    <span *ngIf="col.aggregation.aggrFormat == ''">{{ aggregateColumn(col, 0) }}</span>
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { Cp071Component } from '../cp071/cp071.component';
import { Cp072Component } from '../cp072/cp072.component';
import { Cp073Component } from '../cp073/cp073.component';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'component-cp070',
  templateUrl: './cp070.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp070.component.scss']
})
export class Cp070Component implements OnInit {
  
  @Input()
  context:any

  loading:boolean = false
  saving:boolean = false
  saveButtonEnabled:boolean |undefined = false
  type:string = ""
  selectedInguid:string = ""
  emptyConfig:boolean = false

  config:any = {}

  @ViewChild(Cp071Component) cp071:Cp071Component | undefined;
  @ViewChild(Cp072Component) cp072:Cp072Component | undefined;
  @ViewChild(Cp073Component) cp073:Cp072Component | undefined;
  @ViewChild('select') select:MatSelect | undefined;


  constructor(private _snackbarManager:SnackbarmanagerService, private _errorThrower:ErrorthrowerService, private _api:ApiService) { }

  ngOnInit(): void {
    try {
      if (this.context.clients.length > 0) this.type = this.context.clients[0].INCODE
      //console.log(this.context)
    } catch(e) {
      this._errorThrower.throw("Received bad base configuration!")
      console.log(e)
    }
  }

  async runLoadConfig(value:any) {
    await this.loadConfig(value).then((x:any) => {
      this.loading = false
    })
  }


  async loadConfig(value:any) {
    for (var i = 0; i < this.context.clients.length; i++) {
      if (this.context.clients[i].INGUID == value) {
        this.config = JSON.parse(this.context.clients[i].INPARA)
        if (Object.keys(this.config).length == 0) {
          this.config = this.context.base
          this.emptyConfig = true
        }
        this.type = this.context.clients[i].INCODE
        this.selectedInguid = value
        break
      }
    }
    switch (this.type) {
      case "ToPaghe":
        //this.saveButtonEnabled = this.cp071?.loadConfig(this.config, this.context.base, this.context.labels)
        break;
      case "Paghe":
        await this.cp072?.loadConfig(this.config, this.context.base)
        this.saveButtonEnabled = true
        break;
      default:
          console.log("Unknown configuration type!")
        break;
    }
  }
  

  buildConfig() {
    var newConfig
    switch (this.type) {
      case "ToPaghe":
        newConfig = this.cp071?.buildConfig()
        break;
      case "Paghe":
        newConfig = this.cp072?.buildConfig()
        break;
      default:
          console.log("Unknown configuration type!")
        break;
    }
    this.save(newConfig)
  }

  save(newConfig:any) {
    //this._api.loading = true;
    this.saving = true;
    var context_data = {
      newConfig : {
        "INGUID" : this.selectedInguid,
        "INPARA" : JSON.stringify(newConfig)
      },
      teguid : this._api.getUrlParameters().teguid
    }
    
    this._api.doButtonPostRequest(context_data, "/cez2zuc").subscribe((response) => {      
      if (response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response));  
        if (responseObj.isSuccess) {
          //console.log(responseObj.clients)
          this.context.clients = responseObj.clients
          this.emptyConfig = false
          this.loadConfig(this.selectedInguid)
          this._snackbarManager.open("Success!", 'ok');
        }
        else this._snackbarManager.open(responseObj.error, 'ko');
        this._api.loading = false;
        this.saving = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);    
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
         else this._snackbarManager.open(error.error, 'ko');
        this._api.loading = false; 
        this.saving = false;
      }
    });          
  }

  isEmpty(obj:any) {
    return Object.keys(obj).length == 0
  }

}

import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { Cp062Component } from '../cp062/cp062.component';
import { Cp063Component } from '../cp063/cp063.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { DialogComponent } from '../dialog/dialog.component';


@Component({
  selector: 'component-cp071',
  templateUrl: './cp071.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp071.component.scss']
})
export class Cp071Component implements OnInit {
  
  @Input() inguid = ''
  @Input() labels:any = {}
  @Output() closeFormEvent = new EventEmitter<any>();
  /*
  modelsCheckboxes:any = {}
  modelsCheckboxesBak:any = {}
  decodingsCheckboxes:any = {}
  sourcesList:any = {}
  sourcesRadios:any = {}
  hasSources:boolean = false
  matches:any = []*/

  loading:boolean = true
  /*
  detailsCard:boolean = false
  detailsReceived:any = {}
  Orders:any = []
  */

  
  @ViewChild('apiNavigator') apiNavigator: Cp062Component | undefined;
  showApinavigator:boolean = false
  configElementPaths:any = {}

  selectedInguid = ""
  labelsTable:any = undefined
  dateKeys:any = {}

  context:any = {}


  constructor(private _snackbarManager:SnackbarmanagerService, private _api:ApiService, public dialog: MatDialog, private _errorThrower:ErrorthrowerService) { }

  ngOnInit(): void {
    //console.log(this.labels)
    this._api.doButtonPostRequest({"action": "get", "configuration": { "id": this.inguid }}, "/configure-integration").subscribe((response) => {
      if (response) {
        this._api.printResponse(response);
        this.context = JSON.parse(JSON.stringify(response))
        this.loadConfig()
      }
    }, (error) => {
      if(error) {
        console.log(error);
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
         else this._snackbarManager.open(error.error, 'ko');
        this.loading = false;
      }
    });
  }

  loadConfig() {
    try {
      console.log(this.context.config)
      this.loading = false
      return true
    } catch (e) { 
      console.log(e)
      this._snackbarManager.open("An error occurred!", 'ko')
      this.close(false)
      return false 
    }
  }

  buildConfig() {
    console.log(this.context.config.Groups)
    console.log(this.context.config.Settings)
  }

  getFieldLabel(fieldObj:any) {
    var label = ""
    Object.keys(this.context.apiLabels).every((entity:any) => {
      if (fieldObj["Entity"] == entity) {
        Object.keys(this.context.apiLabels[entity]).every((field:any) => {
          if (fieldObj["Id"] == field) {
            console.log(this.context.apiLabels[entity][field])
            label = this.context.apiLabels[entity][field]
            return false
          }
          return true
        })
      }
      return true
    });
    return label
  }

  hasField(group:any, field:any) {
    var ret = false
    group.Fields.every((f:any) => {
      if (f == field) {
        ret = true
        return false
      }
      return true
    })
    return ret
  }

  toggleFieldDecoding(field:any) {
    if (field.Decoding == undefined) {
      field.Decoding = ''
    } else {
      delete field.Decoding
    }
  }

  toggleFieldDecodingDefault(field:any) {
    if (field.Default == undefined) {
      field.Default = ''
    } else {
      delete field.Default
    }
  }

  isDatekey(field:any, entity:any) {
    if (field.Id == "LastModifiedDate") return true
    if (entity.DateKeys == undefined) return false
    var isDateKey = false
    this.context.dateKeys[entity.DateKeys.Key].every((e:any) => {
      if (field.Id == e) {
        isDateKey = true
        return false
      }
      return true
    })    
    return isDateKey
  }

  entityHasDatekeys(entity:any) {
    if (entity.DateKeys != undefined) {
      return true
    }
    return false
  }

  switchDateKeys(g:any, f:any) {   
    console.log(this.context.config.Groups[g].Fields[f].Id)
    if (this.context.config.Groups[g].Fields[f].Id == "LastModifiedDate") {
      var datekey = "EffectiveFrom"
      if (this.context.config.Groups[g].Entities[0].DateKeys != undefined) {
        datekey = this.context.config.Groups[g].Entities[0].DateKeys.Value[0]
      }
      this.context.config.Groups[g].Fields[f].Id = datekey
    } else {
      this.context.config.Groups[g].Fields[f].Id = "LastModifiedDate"
    }
    console.log(this.context.config.Groups[g].Fields[f].Id)
  }

  addCompanyDecoding() {
    this.context.config.Settings.CompaniesDecodings.push({"Input":"input", "Output": "output"})
  }

  removeCompanyDecoding(i:number) {
    this.context.config.Settings.CompaniesDecodings.splice(i, 1)
  }

  getEntityLabel(group:any, id:string) {
    var label = id
    group.Entities.every((entity:any) => {
      if (entity.Id == id) {
        label = entity.Label
        return false
      }
      return true
    })
    return label
  }

  updateFieldStatus(config:any, groupIndex:number, fieldIndex:number) {
    var datekeyIndex = 0
    var allDisabled = true
    var i = 0
    config.Groups[groupIndex].Fields.every((field:any) => {
      if (i == fieldIndex) {
        field.Enabled = !field.Enabled
      }
      if (field.DateKey == undefined && field.Enabled) {
        allDisabled = false
      }
      if (field.DateKey != undefined) {
        datekeyIndex = i
      }
      i++
      return true
    })
    if (allDisabled) {
      config.Groups[groupIndex].Fields[datekeyIndex].Enabled = false
      config.Groups[groupIndex].Enabled = false
    } else {
      config.Groups[groupIndex].Fields[datekeyIndex].Enabled = true
      config.Groups[groupIndex].Enabled = true
    }
  }

  removeInputVariable(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  addInputVariable(groupIndex:number, fieldIndex:number) {
    this.context.config.Groups[groupIndex].Fields[fieldIndex].Calculation.Input.push({'Label': '', 'Element': ''});
    var l = this.context.config.Groups[groupIndex].Fields[fieldIndex].Calculation.Input.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Groups[' + groupIndex + '].Fields[' + fieldIndex + '].Calculation.Input[' + l + '].Element', 
      name: 'this.context.config.Groups[' + groupIndex + '].Fields[' + fieldIndex + '].Calculation.Input[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }

  addFilterField() {
    this.context.config.Settings.ExtractionFilter.Input.push({'Label': '', 'Element': ''});
    var l = this.context.config.Settings.ExtractionFilter.Input.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Settings.ExtractionFilter.Input[' + l + '].Element', 
      name: 'this.context.config.Settings.ExtractionFilter.Input[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }

  removeFilterField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  addSortField() {
    this.context.config.Settings.ExtractionSort.push({'Label': '', 'Element': ''});
    var l = this.context.config.Settings.ExtractionSort.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Settings.ExtractionSort[' + l + '].Element', 
      name: 'this.context.config.Settings.ExtractionSort[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }

  /**
   * 
   * {
      'Eval': '', 
      'Fields': [],
      'Range':[]
    });
   */

  addRangeField(r:number) {
    this.context.config.Settings.PayrollcodeRanges[r].Fields.push({'Label': '', 'Element': ''})
    var l = this.context.config.Settings.PayrollcodeRanges[r].Fields.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Settings.PayrollcodeRanges[r].Fields[' + l + '].Element', 
      name: 'this.context.config.Settings.PayrollcodeRanges[r].Fields[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }


  removeRangeField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }


  removeSortField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  openApiNavigator(selectionType:number, entity:any) {
    let newApiList:any = []
    if (entity != undefined) {
      this.context.apiList.every((a:any) => {
        if (a.id == entity) {
          newApiList.push(a)
          return false
        }
        return true
      }) 
    } else {
      newApiList = this.context.apiList
    }
    console.log(newApiList)
    this.showApinavigator = true
    this.apiNavigator?.open(newApiList, selectionType)
  }

  getJsonPath(key:any, value:string) {
    var path = ""
    path = value + "=event." + key
    if (!value.includes("this.")) {
      value = "this." + value
    }
    return path
  }

  changeEntityConfig(groupIndex:number, entityIndex:number, element:string, selectionType:number, entity:any) {
    var path = "this.context.config.Groups[" + groupIndex + "].Entities[" + entityIndex + "]." + element + "=event.id"
    this.configElementPaths = []
    this.configElementPaths.push(path)
    this.openApiNavigator(selectionType, entity)
  }

  evalInContext(js:string, context:any, event:any) {
    return function() { return eval(js); }.call(context);
  }

  closeApiNavigator(event:any) {
    console.log(event)
    this.showApinavigator = false
    console.log(this.configElementPaths)  
    this.configElementPaths.forEach((path:any) => {
      this.evalInContext(path, this, event)
    })
    this.configElementPaths = []
  }

  close(success?:boolean) {
    this.closeFormEvent.emit({
      isSuccess: success
    });
  }

  saveConfig() {
    var nonstandardDeselected = false
    this.context.config["Groups"].every((group:any) => {
      if (group.Nonstandard != undefined && !group.Enabled) {
        nonstandardDeselected = true
        return false
      }
      group["Fields"].every((field:any) => {
        if (field.Nonstandard != undefined && !field.Enabled) {
          nonstandardDeselected = true
          return false
        }
        return true
      })
      return true
    })
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: this.labels.titleLabel,
        content: this.labels.contentLabel + (nonstandardDeselected ? '<br><br><b>' + this.labels.nonstandardText + '</b>' : ''),
        accept: this.labels.okLabel,
        reject: this.labels.koLabel
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {  
        console.log(this.context.config)
        this._api.loading = true
        this._api.doButtonPostRequest({"action": "set", "configuration": { "id": this.inguid, "body": this.context.config }}, "/configure-integration").subscribe((response) => {
          if (response) {
            this._api.printResponse(response)
            var responseObj = JSON.parse(JSON.stringify(response));  
            if (responseObj.isSuccess != undefined) {
              this.close(responseObj.isSuccess)
            }            
            this._snackbarManager.open("Success!", 'ok');
            this._api.loading = false
          }
        }, (error) => {
          if(error) {
            console.log(error);
            if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
            else this._snackbarManager.open(error.error, 'ko');            
            this._api.loading = false
          }
        });
      }
    })
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';
import { AuthService } from './auth.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { SnackbarmanagerService } from './snackbarmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpHeaders = {
    'content-type' :'application/json',
    'Authorization' : 'Bearer x',
  }

  BASE_ENDPOINT = "https://nwbgqqbl0m.execute-api.eu-west-1.amazonaws.com"  
  BASE_ENDPOINT_PROD_old = "https://3603pww396.execute-api.eu-west-1.amazonaws.com"
  BASE_ENDPOINT_PROD = "https://2pf5qlch33.execute-api.eu-west-1.amazonaws.com"

  // endpoint per prendere le sezioni e i componenti.
  SECTION_COMPONENTS_ENDPOINT = "/sections_components";
  // endpoint per prendere il login con tutte le funzioni ed i moduli.
  CONSTRUCTION_ENDPOINT = "/construction";

  ENVIRONMENT:number = environment.code;

  public lastSections: any;
  public lastOUCODA: any;
  public lastError: any; //unused
  public lastUrl:any;

  private idleTime = 10; //minutes
  private sessionDuration = 60 - this.idleTime; //minutes
  sessionTimedout: boolean = false;

  public constructionComplete:boolean = false;
  public firstBoot:boolean = true;
  public loading:boolean = false;
  public gettingSections:boolean = true;
  public gettingSectionTime = 0;
  public stillGettingSections:boolean = false;
  
  private subject = new Subject();

  constructor(private router:Router, private httpClient: HttpClient, private auth:AuthService, 
              private idle: Idle) { }

  getConstruction(debugUrl?:any) : Observable<any> {
    this.firstBoot = true;
    this.checkSessionStatus();
    let headers = this.getHttpHeader();

    this.setLanguage("" + sessionStorage.getItem('LANGU'))
    //console.log(this.getLanguage())
    let body = {
      // i parametri che non hanno valore, per ora, devono rimanere vuoti. (e' cosi' che l'api prende la richiesta. )
      "uuid": uuidv4(),
      "environment": this.ENVIRONMENT,
      "requestBody": {
        "APGUID": "",
        "TEGUID": "",
        "MOGUID": "",
        "FMGUID": "",
        "UTGUID": sessionStorage.getItem('UTGUID'),
        "OBGUID": "",
        "LANGU": this.getLanguage()
      }
    };
    let requestPayload = {
      "expiryToken": "0",
      "request": body
    };

    this.printPayload((this.ENVIRONMENT >= 5 ? this.BASE_ENDPOINT_PROD : this.BASE_ENDPOINT) + this.CONSTRUCTION_ENDPOINT, requestPayload);

    return this.doHttpPost(debugUrl != undefined ? debugUrl : ((this.ENVIRONMENT >= 5 ? this.BASE_ENDPOINT_PROD : this.BASE_ENDPOINT) + this.CONSTRUCTION_ENDPOINT), {headers: headers}, requestPayload);
  }

  setLanguage(lang:string) {
    if (lang == undefined || lang == "null") {
      console.log("Missing user language, using english as default.");
      sessionStorage.setItem("LANGU", "en");
    } else sessionStorage.setItem("LANGU", lang);
  }

  getLanguage() {
    return sessionStorage.getItem('LANGU')
  }

  doButtonPostRequest(context_data?:any, context?:string, fmguid?:string) {
    this.checkSessionStatus();
    var endpoint;
    if (context != undefined) endpoint = (this.ENVIRONMENT >= 5 ? this.BASE_ENDPOINT_PROD : this.BASE_ENDPOINT) + context;
    else endpoint = (this.ENVIRONMENT >= 5 ? this.BASE_ENDPOINT_PROD : this.BASE_ENDPOINT) + this.SECTION_COMPONENTS_ENDPOINT;

    return this.doHttpPost(endpoint,
                          { headers: this.getHttpHeader() },
                          this.createRequestJson(context_data, fmguid));
  }

  doUploadFile(endpoint: string, body: FormData){
    this.checkSessionStatus();
    return this.doHttpPost(endpoint, 1, body);
  }

  getCustomResponse(fileName:string) { //for debug only
    this.checkSessionStatus();
    return this.httpClient.get("http://localhost:4000/wospee/" + fileName + ".json");
  }

  getPageSections() {
    this.checkSessionStatus();
    return this.doHttpPost((this.ENVIRONMENT >= 5 ? this.BASE_ENDPOINT_PROD : this.BASE_ENDPOINT) + this.SECTION_COMPONENTS_ENDPOINT,
                          { headers: this.getHttpHeader() },
                          this.createRequestJson());  
  }

  changeFunction(fmguid:string, obguid?:string, obguidParam?:string) {
    this.navigate("",
                  "",
                  "",
                  fmguid,
                  obguid != undefined ? obguid : "",
                  obguidParam != undefined ? obguidParam : "",);
  }

  changeOBGUID(obguid:string, obguidParam?:string) {
    if (obguidParam == undefined) this.navigate("", "", "", "", obguid);
    else this.navigate("", "", "", "", obguid, obguidParam);
    /*
    return this.doHttpPost((this.ENVIRONMENT >= 5 ? this.BASE_ENDPOINT_PROD : this.BASE_ENDPOINT) + this.SECTION_COMPONENTS_ENDPOINT,
                          { headers: this.getHttpHeader() },
                          this.createRequestJson());*/
  }

  refreshSectionsSignal() {
    return this.subject.asObservable();
  }

  refreshSections() {
    this.subject.next({a:0})
  }

  navigateToLogin() {
    //this.router.navigate(['']);    
    this.checkSessionStatus();
    //this.auth.clearSessionStorage();    
    //location.reload();
  }

  navigate(apguid:string, teguid:string, moguid:string, fmguid:string, obguid?:string, obguidParam?:string) {
    this.lastUrl = this.router.url;
    var urlParams = this.getUrlParameters();
    if (obguid == undefined) {
      this.router.navigate([
        apguid == "" ? urlParams.apguid : apguid,
        teguid == "" ? urlParams.teguid : teguid,
        moguid == "" ? urlParams.moguid : moguid,
        fmguid == "" ? urlParams.fmguid : fmguid]);
    } else {
      if (obguidParam == undefined) {
        this.router.navigate([
          apguid == "" ? urlParams.apguid : apguid,
          teguid == "" ? urlParams.teguid : teguid,
          moguid == "" ? urlParams.moguid : moguid,
          fmguid == "" ? urlParams.fmguid : fmguid,
          obguid]);
      } else {
        this.router.navigate([
          apguid == "" ? urlParams.apguid : apguid,
          teguid == "" ? urlParams.teguid : teguid,
          moguid == "" ? urlParams.moguid : moguid,
          fmguid == "" ? urlParams.fmguid : fmguid,
          obguid, obguidParam]);
      }
    }
  }

  getUrlParameters() {
    var currentUrl = this.router.url.split("/");

    let obguid: string = "";
    let obguidParam: string = "";
    if(currentUrl.length > 5) { // se ci sono piu' di 4 componenti dell'url
        // accesso al valore dell'OBGUID
        obguid = currentUrl[5];
        if(currentUrl.length > 6) obguidParam = currentUrl[6];
    }
    return {
      apguid: currentUrl[1],
      teguid: currentUrl[2],
      moguid : currentUrl[3],
      fmguid : currentUrl[4],
      obguid : obguid,
      obguidParam : obguidParam
    }
  }

  isNakedSection() {
    var urlParams = this.getUrlParameters(); //urlParams.fmguid == 'FA0008' || urlParams.fmguid == 'FA0016' || urlParams.fmguid == 'FA0020' || 
    return (urlParams.obguid.includes('wf-') || urlParams.obguidParam.includes('wf-') && !this.loading)
  }

  getAccessToken(): string {
    let cookies: string[] = document.cookie.split(";");
    let accessToken: string = "";
    for(let i = 0; i < cookies.length; ++i){
      if(cookies[i].includes(".accessToken=")){
        accessToken = cookies[i].split("=")[1].replace('"', '');
        break;
      }
    }
    return accessToken;
  }

  getHttpHeader(contentType: number = 0) {
    if(contentType == 1){
      this.httpHeaders = {
        'content-type' : 'multipart/form-data',
        'Authorization' : `Bearer ${this.getAccessToken()}`
      };
    }else{
      this.httpHeaders.Authorization = `Bearer ${this.getAccessToken()}`;
    }

    return this.httpHeaders;
  }

  createRequestJson(context_data?:any, fmguid?:string) {
    let urlParams = this.getUrlParameters();
    let utguid = sessionStorage.getItem('UTGUID')
    if (utguid == "") console.log("UTGUID is null, redo login!")

    let requestBody = {
      'APGUID' : urlParams.apguid,
      'TEGUID' : urlParams.teguid,
      'MOGUID' : urlParams.moguid,
      'FMGUID' : fmguid != undefined ? fmguid : urlParams.fmguid,
      'UTGUID' : utguid,
      'OBGUID' : urlParams.obguid,
      'LANGU' : this.getLanguage()
    };
    if (context_data != undefined) {
      Object.assign(requestBody, { 'CONTEXT_DATA': context_data });
    }

    //if (context != undefined) Object.assign(requestBody, { 'CONTEXT': context });

    let requestJson = {
      "expiryToken": "0", //hardcoded
      "request": {
        "uuid": uuidv4(),
        "environment" : this.ENVIRONMENT,
        "requestBody": requestBody,
      }
    }
    return requestJson;
  }

  doHttpPost(endpoint: string, options: any, requestJson: any) {
    if (!endpoint.includes(this.CONSTRUCTION_ENDPOINT))
      this.printPayload(endpoint, requestJson);
    
    var ret;
    if (options == null) {
      options = { header : this.getHttpHeader(), responseType : 'json'}
    } else if (options == 1){
      return this.httpClient.post(endpoint, requestJson);      
    }
    return this.httpClient.post(endpoint, requestJson, options);    
  }

  startSessionTimer() {
    //this.idle.setIdle(5);
    //this.idle.setTimeout(5);
    this.idle.setIdle(this.idleTime * 60);
    this.idle.setTimeout(this.sessionDuration * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => { 
      if (!this.sessionTimedout && localStorage.getItem("ng2Idle.main.timeout") === undefined) this.idle.watch();
      else {
        if (localStorage.getItem("ng2Idle.main.timeout") === "true") {
          this.sessionTimedout = true;
          this.auth.signOut(true);
          this.idle.stop();
        }
        /*
        this.auth.loggedIn.subscribe((response) => {
          if (!response) {
            console.log("aa")
            this.sessionTimedout = true;
          }
        })*/
      }
    });
    this.idle.onTimeout.subscribe(() => {
      this.sessionTimedout = true;
      localStorage.setItem("ng2Idle.main.timeout", "true");
      this.auth.signOut(true);
      this.idle.stop();
    });    
    this.idle.watch();
    
    /*
    var sessionStart = sessionStorage.getItem("SESSION_START");
    //console.log(sessionStart)
    var a = (this.sessionDuration * 60) - moment().diff(moment(sessionStart), 'seconds');
    //console.log(a)
    if (a > (this.sessionDuration * 60)) {
      sessionStorage.setItem("RESUME_URL", JSON.stringify(this.getUrlParameters()));
      this.auth.signOut();
    } else {
      const source = timer(a * 1000);
      const subscribe = source.subscribe(val => { 
        if (val == 0) this.sessionTimedout = true
      });
    }
    */

    //debugonly this.sessionTimedout = true;
  }

  checkSessionStatus() {
    if (this.sessionTimedout) {
      sessionStorage.setItem("RESUME_URL", JSON.stringify(this.getUrlParameters()));
      //this._snackbar.open("Session timeout, redirecting...", "warning");
      //location.reload();
      this.auth.signOut();
    }/* else if (sessionStorage.getItem("UTGUID") === null || (sessionStorage.getItem("LANGU") !== null && sessionStorage.getItem("LANGU") == "null")) {
      sessionStorage.setItem("RESUME_URL", JSON.stringify(this.getUrlParameters()));
      //this.auth.signOut();
    }*/
    /*
    //session timeout management
    var sessionStart = sessionStorage.getItem("SESSION_START")
    if (sessionStart != null) {
      var startMoment = moment(sessionStart);
      if (moment().diff(startMoment, 'minutes') >= this.sessionDuration) {        
        sessionStorage.setItem("RESUME_URL", JSON.stringify(this.getUrlParameters()));
        this.auth.signOut();
      }
    } else {
      this.auth.loggedIn.subscribe((response) => {
        if (response) {
          sessionStorage.setItem("RESUME_URL", JSON.stringify(this.getUrlParameters()));
          this.auth.signOut();
        }
      })
    }*/ 
  }

  printPayload(endpoint: string, requestJson: any) {
    console.log("\n\n---- PAYLOAD ----")
    try {
      console.log(requestJson.request.requestBody)
    } catch (e) {
      console.log(requestJson)
    }
    console.log(endpoint)
    console.log("-----------------\n")
  }

  printResponse(response: any) {
    console.log("---- RESPONSE ----")
    console.log(response)
    console.log("------------------\n\n\n")
  }

}

import { Component, Input, OnInit } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { DialogComponent } from '../dialog/dialog.component';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'component-cp045',
  templateUrl: './cp045.component.html',
  styleUrls: ['./cp045.component.scss']
})
export class Cp045Component implements OnInit {

  @Input()
  context:any;
  @Input()
  sections:any;
  @Input()
  position:any;

  constructor(private _api:ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService) { }

  ngOnInit(): void {
  }

  openDialog(){
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: this.context.PopupLabels[0],
        content: this.context.PopupLabels[1],
        accept: this.context.PopupLabels[2],
        reject: this.context.PopupLabels[3]
      },
    });
    dialogRef.afterClosed().subscribe(result => {   
      if(result) {              
        this._api.loading = true;    
        var context_data = {
          "Sections" : this.sections
        }
        
        this._api.doButtonPostRequest(context_data, this.context.ActionAPI).subscribe((response) => {      
          if(response) {        
            this._api.printResponse(response); 
            var responseObj = JSON.parse(JSON.stringify(response))  
            if (responseObj.isSuccess) {
              if (this.context.ActionDownload != "") this.printPDF()     
              else this._snackbarManager.open(this.context.PopupLabels[4], 'ok');
            } else {
              this._snackbarManager.open(responseObj.errorMessage, 'ko');
            }
            this._api.loading = false;
          }
        }, (error) => {
          if(error) {
            console.log(error);    
            this._snackbarManager.open(this.context.PopupLabels[5], 'ko');
            this._api.loading = false; 
          }
        });  
        
      }
    });
  }

  printPDF() {
    var context_data = {
      "Sections" : this.sections
    }

    if (this.context.downloadPDF != null && this.context.downloadPDF.active) {
      this._api.doButtonPostRequest(context_data, this.context.ActionDownload).subscribe((response) => {      
        if(response) {        
          this._api.printResponse(response);    
          var responseObj = JSON.parse(JSON.stringify(response));  
          if (responseObj.temp_url != undefined) window.open(responseObj.temp_url)
          this._api.loading = false; 
        }
      }, (error) => {
        if(error) {
          console.log(error);    
          this._snackbarManager.open(this.context.PopupLabels[5], 'ko');
          this._api.loading = false; 
        }
      });  
    }
  }

}

import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { GenericformComponent } from '../genericform/genericform.component';


@Component({
  selector: 'component-cp060',
  templateUrl: './cp060.component.html',
  styleUrls: ['./cp060.component.scss']
})
export class Cp060Component implements OnInit {

  @Input()
  context:any;

  formCalled:boolean = false;
  
  @ViewChild('form') form: GenericformComponent | undefined;
  @Output() openForm: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {  
    setTimeout(() => {}, 1); //triggers ngaftercontentchecked
  }

  ngAfterContentChecked() {
    if (this.form != undefined && !this.formCalled) {
      this.form.openForm(this.context.rows[0]);
      this.formCalled = true;
    }
  }

  closeCard(event:any) {
    console.log("closed")
  }

}

<ng-container *ngIf="context.titles.length > 0">
    <div class="container-div">
        <ng-container *ngFor="let title of context.titles">
            <div *ngIf="title.value" class="text-div">
                <span style="font-weight:700">{{title.name}}</span><br>
                {{formatValue(title.systemFormat, title.value)}}
            </div>
        </ng-container>
    </div>
</ng-container>

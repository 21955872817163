import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { ApiService } from '../../services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { ExcelexporterService } from 'src/app/services/excelexporter.service';
 

@Component({
  selector: 'component-cp027',
  templateUrl: './cp027.component.html',
  styleUrls: ['./cp027.component.scss']
})
export class Cp027Component implements OnInit {

  @Input() context: any;
  @Input() selectedYear: any;

  @ViewChild('dt1') table1: Table | undefined;
  columns:any = []

  loading: boolean = true
  multiSortMeta:SortMeta[] = [];
  excelRows: any;

  selectedRow:any = {}
  starMemberOpened:boolean = false;
  starStatus:boolean = false;

  constructor(private _api:ApiService, private _excel:ExcelexporterService, private  _snackbarManager:SnackbarmanagerService) { }

  ngOnInit(): void {
    //fare una struttura dati fatta bene per le colonne serve per il sort e il filter
    for (var i = 0; i < this.context.Column.length; i++) {
      this.columns.push({field: "Elemento" + i, header: this.context.Column[i]})
    }
    //console.log(this.columns)

    this.multiSortMeta.push({field: '', order: 1});
    this.loading = false;    

    var urlParams = this._api.getUrlParameters();
    if (urlParams.obguidParam != "") {
      this.selectedYear = urlParams.obguidParam;
    } else {
      this.selectedYear = "";
    }
  }

  view(obguid:any) {
    this._api.changeFunction(this.context.function, obguid);
  }

  toggleStar() {
    this.selectedRow.Elemento6 = !this.selectedRow.Elemento6;
  }

  openMemberCard(row:any) {
    var rowObj = JSON.parse(JSON.stringify(row));
    this.selectedRow = rowObj;
    this.starMemberOpened = true;
    this.starStatus = rowObj.Elemento6;
    this.toggleDrawerContentZindex(true);
  }

  closeMemberCard() {
    this.starMemberOpened = false;    
    this.selectedRow = {};
    this.starStatus = false;
    this.toggleDrawerContentZindex(false);
  }

  toggleDrawerContentZindex(isAbove:boolean) {
    var myCss = ".mat-drawer-content { z-index: " + (isAbove ? "2" : "1") + "}";
    var style = document.createElement("STYLE");
    style.innerText = myCss;
    document.body.appendChild(style);
  }

  confirmStar() {
    this._api.loading = true;
    var context_data = {
      SRSTAR : this.selectedRow.Elemento6,
      SRGUID : this.selectedRow.Elemento1,
      PEGUID : this.selectedRow.Elemento8,
      SRNOTA : this.selectedRow.Elemento7,
      Year : this.selectedYear
    };

    this._api.doButtonPostRequest(context_data, this.context.StarYourTeamMemberLambda).subscribe((response) => {
      if(response) {
        this._api.printResponse(response);

        for (var i = 0; i < this.context.Row; i++) {
          if (this.selectedRow.Elemento1 = this.context.Row[i].Elemento1) {
            this.context.Row[i] = this.selectedRow
            break;
          }
        }

        this._snackbarManager.open("Success", 'ko');
        this._api.loading = false;
        this.closeMemberCard();
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this._api.loading = false;
        this._snackbarManager.open(error.error.message, 'ko');
      }
    });

  }
  
  exportTable() {
    this._excel.export("star", this.columns, this.context.Row)
  }

}

import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import moment, { Moment } from 'moment';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'genericform-component',
  templateUrl: './genericform.component.html',
  styleUrls: ['./genericform.component.scss']
})
export class GenericformComponent implements OnInit {

  @Input()
  context:any;

  Orders: any;  
  OrdersBak:any;
  ordersIndex:number = -1

  formVisible:boolean = false;  
  showVariantsTable: boolean = false;

  basicData: any;
  multiAxisData: any;
  multiAxisOptions: any;
  lineStylesData: any;
  basicOptions: any;

  X : number[] = [];
  Y : number[] = [];
  yEval: any[] = [];
  xEval: any[] = [];
  currency:string = "";

  profileForm:FormGroup;
  formControls:any;

  @Output() closeFormEvent = new EventEmitter<any>();

  constructor(private fb:FormBuilder, private  _snackbarManager:SnackbarmanagerService, private _api:ApiService) { 
    this.profileForm = this.fb.group({});
    this.formControls = {}
  }

  ngOnInit(): void {    
  }

  openForm(orders:any, ordersIndex?:number) {
    this.Orders = orders;
    this.OrdersBak = JSON.parse(JSON.stringify(this.Orders));
    this.ordersIndex = ordersIndex != undefined ? ordersIndex : -1;


    //initialize formgroup and formcontrols    
    this.context.columns.forEach((col:any) => {
      if (col.formComponent != "" && col.formComponent != "GridButton" && col.formComponent != "ShortcutButton") {
        var val;
        //if (this.cardType == 2) val = this.getDefaultValue(col);
        if (col.formComponent == "DateMonthField") val = this.getDefaultValue(col);
        val = this.getFormattedValue(this.Orders[col.elemento], col)

        this.formControls[col.elemento] = new FormControl({ 
          value: val, 
          disabled: this.isFormReadonly(col)
        });
        if (col.formComponent == "NumberField") {
          var min = col.format.includes("p") ? col.minValue * 100 : col.minValue;
          var max = col.format.includes("p") ? col.maxValue * 100 : col.maxValue;
          if (col.isFormMandatory) this.formControls[col.elemento].setValidators([Validators.required, Validators.min(min), Validators.max(max)])
          else this.formControls[col.elemento].setValidators([Validators.min(min), Validators.max(max)]);
        } else {
          if (col.isFormMandatory) this.formControls[col.elemento].setValidators([Validators.required])
        }
      }
    }) 
  
    this.profileForm = this.fb.group(this.formControls);
    this.profileForm.markAllAsTouched();  

    this.updateFormControls()
    
    if (this.context.isFormChartable && this.Orders[this.context.formChartType.onFieldValue] != "") this.loadChart();

    this.formVisible = true;  
  }

  closeForm(success?:boolean) {
    this.closeFormEvent.emit({
      orders: JSON.parse(JSON.stringify(this.OrdersBak)), 
      ordersIndex: this.ordersIndex,
      modifyRow: this.ordersIndex != -1 ? true : false,
      success : success == undefined ? false : success
    });
    this.showVariantsTable = false;
    this.formVisible = false;
  }
  
  getDefaultValue(col:any) {
    var val = col.defaultValue;
    switch(col.formComponent) {
      case "NumberField":
        val = col.defaultValue;
        break;
      case "DateField":
      case "DateMonthField":
        if (col.defaultValue != "") val = moment(col.defaultValue, col.format).toDate();
        else val = moment().toDate();
        this.Orders[col.elemento] = moment().format("YYYY-MM-DD")
        break;
      case "ComboBox":
        val = col.defaultValue;
        /*if (col.ComboBoxList.length > 0) { need to update elemento14 with this and it's too hard
          val = col.ComboBoxList[0].Kkey;
        } else //console.log("Comboboxlist empty!");*/
        break;
      case "TextArea":
      case "TextField":
        val = col.defaultValue;
    }
    return val;
  }
  
  getFormattedValue(value:any, col:any) {
    var val;
    switch(col.formComponent) {
      case "NumberField":
        switch (col.format) {
          case "p0":
            val = (value * 1000 / 10).toFixed(0);
            break;
          case "p1":
            val = (value * 1000 / 10).toFixed(1);
            break;
          case "n0":
            val = (value * 10 / 10).toFixed(0);
            break;
          case "n1":
            val = (value * 10 / 10).toFixed(1);
            break;
          default:
            if (col.format.includes("##")) val = Math.round((value * 10 / 10)).toFixed(0);   
            else val = (value * 10 / 10);
            break;
        }
        break;

      case "ComboBox":
        val = this.getComboboxKey(col);
        break;
        
      default:
        val = value;
        break
      }

    return val;
  }


  updateNumberfield(event:any, col:any) {
    //console.log("Number: " + event.target.value)

    switch(col.format) {
      case "p0":
        this.profileForm.controls[col.elemento].setValue((Number(event.target.value * 10 / 10)).toFixed(0));
        this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value / 100;
        break;
      case "p1":
        this.profileForm.controls[col.elemento].setValue((Number(event.target.value * 10 / 10)).toFixed(1));
        this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value / 100;
        break;
      case "n0":
        this.profileForm.controls[col.elemento].setValue(Number(event.target.value * 10 / 10).toFixed(0));
        this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value 
        break;
      case "n1":
        this.profileForm.controls[col.elemento].setValue(Number(event.target.value * 10 / 10).toFixed(1));
        this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value 
        break;
      default:   
        if (col.format.includes("#,##")) this.profileForm.controls[col.elemento].setValue(Number(event.target.value * 10 / 10).toFixed(2));
        else this.profileForm.controls[col.elemento].setValue(Number(event.target.value * 10 / 10).toFixed(0));
        this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value 
        break;
    }
    this.updateFormControl(col);
    // && this.ordersIndex != -1
    if (this.context.isFormChartable && this.ordersIndex != -1) this.loadChart();
  }

  updateTextfield(event:any, col:any) {
    //console.log("String: " + event.target.value);
    this.profileForm.controls[col.elemento].setValue(event.target.value);
    this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value;
    this.updateFormControl(col);
  }

  updateCombobox(event:any, col:any) {
    //console.log("Combobox event value: " + event.value);
    this.profileForm.controls[col.elemento].setValue(event.value);
    this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value;
    if (col.comboValueIn != null) {
      col.comboValueIn.forEach((e:any) => {
        //console.log("Combovaluein: " + e)
        this.profileForm.controls[e].setValue(this.getComboboxValue(event.value, col));
        this.Orders[e] = this.profileForm.controls[e].value;
        //console.log(this.Orders[e])
      });
    }

    this.updateFormControl(col);
    if (this.context.isFormChartable && this.ordersIndex != -1) this.loadChart();
  }

  updateDatefield(event:any, col:any) {
    const ctrlValue = moment(this.formControls[col.elemento].value);
    //console.log(ctrlValue)
    ctrlValue.month(moment(event.value).month());
    this.formControls[col.elemento].setValue(ctrlValue.toDate());
    //console.log(this.formControls[col.elemento].value);
    this.Orders[col.elemento] = ctrlValue.format("YYYY-MM-DD");
    //console.log(this.Orders[col.elemento]);
    this.updateFormControl(col);
  }

  updateDatemonthfield(event: any, col:any, datepicker?: MatDatepicker<Moment>) {
    //var ctrlValue = moment()
    //if (this.formControls[col.elemento].value != undefined) ctrlValue = moment(this.formControls[col.elemento].value);
    //console.log(ctrlValue);
    //console.log(moment(normalizedMonth));
    var input = moment()
    try {
      input = moment(event.target.value, col.format)
    } catch(e) { 
      input = moment(event);
    }
    //ctrlValue.month(input.month());
    this.formControls[col.elemento].setValue(input.toDate());
    //console.log(this.formControls[col.elemento].value);
    this.Orders[col.elemento] = input.format("YYYY-MM-DD");
    //console.log(this.Orders[col.elemento]);
    if (datepicker != undefined) datepicker.close();
    this.updateFormControl(col);
  }

  updateFormControls() {
    this.context.columns.forEach((col:any) => {
      if (col.formComponent != "Void" && col.formComponent != "" && col.formComponent != "GridButton" && col.formComponent != "ShortcutButton") {
        this.updateFormControl(col);
      }
    });   
    this.evaluateColumns();
  } 

  updateFormControl(col:any) {
    this.profileForm.controls[col.elemento].updateValueAndValidity();
    this.profileForm.controls[col.elemento].markAsTouched();
    this.profileForm.controls[col.elemento].updateValueAndValidity();
    ////console.log(this.Orders[col.elemento]);
    this.evaluateColumns();
    //this.printFormcontrolsValidity();
  }

  printFormcontrolsValidity() {
    var k = Object.keys(this.profileForm.controls)
    k.forEach((key:any) => {
      //console.log(key + " - " + this.profileForm.controls[key].valid + " - " + this.profileForm.controls[key].value)
    })
    //console.log(this.profileForm.valid)
  }
  

  evaluateColumns() {
    this.context.columns.forEach((column:any) => {
      column.isFormVisible = this.isFormVisible(column)
      column.isFormEditable = !this.isFormReadonly(column)

      if (column.isEvalField) {
          var Orders = this.Orders
          var ev = this.formatEval(column.evaluation)
          try {
            this.Orders[column.elemento] = eval(ev);
          } catch(e) {
            console.log("Eval error at " + column.elemento)
          }
          //if form has a form control
          /*
          if (this.profileForm.controls[column.elemento] != undefined) {            
            ////console.log("i am a number and im being evaluated" + column.title + column.elemento + " " + ev)
            var toFixedNumbers = column.format.includes("p") || column.format.includes("n") ? 
              Number(column.format.charAt(1)) : (column.format.includes("#,##") ? 2 : 0);
            //this.profileForm.controls[column.elemento].setValue(this.Orders[column.elemento].toFixed(toFixedNumbers))            
            
            
            //console.log(this.profileForm.controls[column.elemento].value);
          }*/
      }
      
      if (this.profileForm.controls[column.elemento] != undefined) {
        if (column.isFormEditable) this.profileForm.controls[column.elemento].enable();
        else this.profileForm.controls[column.elemento].disable();
        
        this.profileForm.controls[column.elemento].setValue(this.getFormattedValue(this.Orders[column.elemento], column))
        
        //console.log(column.title);
        //console.log(column.elemento); 
        //console.log(this.profileForm.controls[column.elemento].value);
      }
      
    })
  }

  isFormVisible(col:any) {
    ////console.log(col.title + " - " + col.isFormVisible + " - " + col.isFormVisibleEval + " - " + col.isFormVisibleChanges)
    //if (!col.isFormVisible)
    //  return false;

    if (!col.isFormVisibleEval)
      return col.isFormVisible;

    if (col.isFormVisibleChanges == undefined || Object.keys(col.isFormVisibleChanges).length == 0) 
      return col.isFormVisible;
    
    try {
      var onfieldValue = this.Orders[col.isFormVisibleChanges.onFieldValue];
      var valueBool = col.isFormVisibleChanges.values[onfieldValue] == undefined ? false : col.isFormVisibleChanges.values[onfieldValue];    
      var overrideValue = col.isFormVisibleChanges.overrideValues[onfieldValue];
      //console.log(overrideValue.overRide)
      if (valueBool && overrideValue.overRide) {
        var valueOverride = null;
        if (overrideValue.isEvalValue) {
          var Orders = this.Orders;
          try {
            valueOverride = eval(overrideValue.replaceAll("ToString","toString").replaceAll(".toString('0'.toString())", ".toString()"));
          } catch (e) {
            valueOverride = overrideValue.valueOverride
          }
        } else {
          valueOverride = overrideValue.valueOverride
        }
        if (valueOverride != null) {
          this.profileForm.controls[col.elemento].setValue(valueOverride);
          this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value;

        }
      }   
    } catch (e) {return false;}
    return !valueBool;
  }

  isFormReadonly(col:any) {    
    if (this.context.ReadOnlyGrid.isreadonly)
      return true;

    if (!col.isFormEditable && !col.isFormEditableEval)
      return true;
    
    if (!col.isFormEditableEval) 
      return false;

    if (col.isFormEditableChanges == undefined) 
      return false;
    
    try {
      var onfieldValue = this.Orders[col.isFormEditableChanges.onFieldValue];
      var valueBool = col.isFormEditableChanges.values[onfieldValue] == undefined ? false : col.isFormEditableChanges.values[onfieldValue];    
      var overrideValue = col.isFormEditableChanges.overrideValues[onfieldValue];
      //console.log(overrideValue.overRide)
      if (valueBool && overrideValue.overRide) {
        var valueOverride = null;
        if (overrideValue.isEvalValue) {
          try {
            var Orders = this.Orders;
            valueOverride = eval(overrideValue.replaceAll("ToString","toString").replaceAll(".toString('0'.toString())", ".toString()"));
          } catch (e) {
            valueOverride = null;
          }
        } else {
          valueOverride = overrideValue.valueOverride
        }
        if (valueOverride != null){
          this.profileForm.controls[col.elemento].setValue(valueOverride);
          this.Orders[col.elemento] = this.profileForm.controls[col.elemento].value;
        }
      }   
    } catch (e) {}
    return valueBool;
    /*
    if (col.isFormVisible && col.isFormEditableEval && Object.keys(col.isFormEditableChanges).length !== 0 && this.Orders[col.isFormEditableChanges.onFieldValue] != '') { 
      var valueBool = col.isFormEditableChanges.values[this.Orders[col.isFormEditableChanges.onFieldValue]];
      var overrideValueBool = col.isFormEditableChanges.overrideValues[this.Orders[col.isFormEditableChanges.onFieldValue]].overRide;
      if (valueBool == overrideValueBool) {
        var evalValueBool = col.isFormEditableChanges.overrideValues[this.Orders[col.isFormEditableChanges.onFieldValue]].isEvalValue;
        var valueOverride = col.isFormEditableChanges.overrideValues[this.Orders[col.isFormEditableChanges.onFieldValue]].valueOverride;
        if (evalValueBool) {
          var Orders = this.Orders
          return eval(valueOverride.replaceAll("ToString","toString").replaceAll(".toString('0'.toString())", ".toString()"));
        } else {
          return (valueOverride == 1 ? false : true);
        }
      }
    }*/
  }  

  selectVariant(event: any){
    /*
    this.context.columns.forEach((col:any) => {
      this.Orders[col.elemento] = this.context.variantValues[event.index][col.elemento];
      this.updateFormControl(col);
    });
    */
    Object.keys(this.context.variantValues[event.index]).forEach((variantKey:any) => {      
      var val = this.context.variantValues[event.index][variantKey];
      this.context.columns.every((col:any) => {
        if (col.elemento == variantKey) {
          this.profileForm.controls[col.elemento].setValue(val)
          this.Orders[col.elemento] = val
          this.updateFormControl(col);
          return false
        }
        return true
      });
    })
    //console.log(this.context.variantValues[event.index])
    //console.log(this.Orders)
    /*
    this.context.columns.forEach((col:any) => {
      var e = document.createElement('input');
      e.setAttribute('value', this.context.variantValues[event.index][col.elemento]);
      var o = {target : e}
      this.updateRow(o, col, true)
    })*/
  }

  saveRow() {
    /*
    this.Orders['Elemento25']  = this.Orders['Elemento8']
    this.context.columns[4].ComboBoxList.forEach((v:any) => {
      if (v.Kkey == this.Orders['Elemento4']) this.Orders['Elemento14'] = "" + v.Vvalue
    })*/    
    this._api.loading = true;
    var context_data = {
      "OUCODA" : this._api.lastOUCODA,
      "Sections" : this._api.lastSections
    }
    //console.log(this._api.lastSections[2].Components[0].Component.Context.rows);

    this._api.doButtonPostRequest(context_data, this.context.apiFormSave).subscribe((response) => {      
      if(response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response))
        if (responseObj.isSuccess) {
          this._snackbarManager.open("Success!", 'ok');
          this.OrdersBak = JSON.parse(JSON.stringify(this.Orders));

          //console.log(this.Orders)
          this.closeForm(true);
          //location.reload();
        } else {
          if (responseObj.error != undefined) {
            if (responseObj.error.message != undefined)
              this._snackbarManager.open(responseObj.error.message, 'ko');
            else
              this._snackbarManager.open(responseObj.error, 'ko');
          } else {
            this._snackbarManager.open(responseObj.errorMessage, 'ko');
          }
          //this._snackbarManager.open(responseObj, 'ko');
          //this.context.rows[this.ordersIndex] = JSON.parse(JSON.stringify(this.OrdersBak));
          //this.Orders = this.context.rows[this.ordersIndex]
          //this.updateFormControls();
          this._api.loading = false;   
        }
        //this._api.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);
        var errorObj = JSON.parse(JSON.stringify(error)); 
        if (errorObj.errorMessage != undefined) this._snackbarManager.open(errorObj.errorMessage, 'ko');
        else if (errorObj.error != undefined && errorObj.error.message != undefined) this._snackbarManager.open(errorObj.error.message, 'ko');
        else if (errorObj.error != undefined) this._snackbarManager.open(errorObj.error, 'ko');
        else this._snackbarManager.open("Error!", 'ko');
        //this.context.rows[this.ordersIndex] = JSON.parse(JSON.stringify(this.OrdersBak));
        //this.Orders = this.context.rows[this.ordersIndex]
        //this.updateFormControls();
        this._api.loading = false; 
      }
    });    
  }


  aggregateColumn(col:any, value:number) {
    var result = 0
    switch (col.aggregation.aggrType) {
      case "AggregateType.Sum":
        this.context.rows.forEach((row:any) => {
          result += row[col.elemento]
        })   
        if (value != 0) {
          result = result - (this.OrdersBak[col.elemento] - this.Orders[col.elemento]) + (value - this.Orders[col.elemento])
        }
        break
    }
    ////console.log(result)
    return result
  }

  getColumnFormat(title:string) {
    for(var i = 0; i < this.context.columns.length; i++) {
      if (title == this.context.columns[i].title){
        return this.context.columns[i].format
      }
    }
    return ''
  }

  getComboboxKey(col:any) {
    for(var i = 0; i < col.ComboBoxList.length; i++) {
      if (col.comboValueIn != null && col.comboValueIn.length > 0) {
        if (col.ComboBoxList[i].Vvalue == this.Orders[col.comboValueIn[0]]) 
          return col.ComboBoxList[i].Kkey;
      } else {
        console.log("ComboValueIn empty!")
        return col.ComboBoxList[0].Kkey;
      }
    }
    //console.log("Can't find comboboxlist key");
    return col.defaultValue;
  }

  getComboboxValue(key:any, col:any) {
    //console.log(col.ComboBoxList)
    //console.log(key)
    if (col.ComboBoxList.length > 0) {
      for (var i = 0; i < col.ComboBoxList.length; i++) {
        //console.log(col.ComboBoxList[i].Kkey)
        if (col.ComboBoxList[i].Kkey == key)
          return col.ComboBoxList[i].Vvalue
      }
    }
    console.log("Can't find comboboxlist value (key: " + key + ")");
    return col.defaultValue;
  }

  parseStyle(s:any) {
    var regex = /([\w-]*)\s*:\s*([^;]*)/g;
    var match:any, formStyle:any ={};
    while(match=regex.exec(s)) {
      formStyle[match[1]] = match[2].trim();
    }
    return formStyle;
  }
 
  loadChart() {
    var basicOptions:any
    var data:any[] = []
    var lbl:any = ""
    
    this.setChartValues()
    for (var i = 0; i < this.X.length; i++) {
      data.push({x:this.X[i], y:this.Y[i]})
    }    
    var achievedBar = {x:-1, y:this.Y.reduce((a, b) => Math.max(a, b), -Infinity)}
     
    switch(this.Orders[this.context.formChartType.onFieldValue].toUpperCase()) {
      case "ONOFF":
        lbl = this.context.charts.onoff.title
        basicOptions = this.setOptionsChart(this.context.charts.onoff.yTitle, this.context.charts.onoff.xTitle, this.currency)
        achievedBar.x = this.getAcheivedBar(this.context.charts.onoff)
        break
      case "NOLINEAR":
        lbl = this.context.charts.nolinear.title
        basicOptions = this.setOptionsChart(this.context.charts.nolinear.yTitle, this.context.charts.nolinear.xTitle, this.currency)
        achievedBar.x = this.getAcheivedBar(this.context.charts.nolinear)
        break
      case "LINEAR":
        lbl = this.context.charts.linear.title
        basicOptions = this.setOptionsChart(this.context.charts.linear.yTitle, this.context.charts.linear.xTitle, this.currency)
        achievedBar.x = this.getAcheivedBar(this.context.charts.linear)
        break
      case "ONOFF TRESHOLD":
        lbl = this.context.charts.onofftreshold.title
        basicOptions = this.setOptionsChart(this.context.charts.onofftreshold.yTitle,this.context.charts.onofftreshold.xTitle, this.currency)
        achievedBar.x = this.getAcheivedBar(this.context.charts.onofftreshold)
        break
    }

    var datasets = []
    datasets.push({
      data: data,
      fill: false,
      borderColor: '#2fc466',
      pointBorderColor: '#2fc466',
      pointBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#2fc466',
      pointHoverBackgroundColor: '#ffffff',
      tension: 0,
    })
    if (achievedBar.x > 0) {
      datasets.push({
        data: [{x:0, y:achievedBar.y}, achievedBar],
        fill: true,
        backgroundColor:'#2fc46633',
        borderColor: '#00000000',
        pointBorderColor: '#00000000',
        pointBackgroundColor: '#00000000',
        pointHoverBorderColor: '#00000000',
        pointHoverBackgroundColor: '#00000000',
        tension: 0,
      })
    }
    //console.log(this.basicData)
    //console.log(this.basicOptions) 
    //console.log(basicOptions.scales.y.ticks.stepSize)
    this.basicData = { datasets: datasets }
    this.basicOptions = basicOptions
  }

  getAcheivedBar(graph:any) {
    var Orders = this.Orders
    if (graph.verticalBarAt != undefined) {
      if (!graph.verticalBarAt.isEvalX) return graph.verticalBarAt.X;
      else return eval(graph.verticalBarAt.X);
    }
  }

  setChartValues() {
    this.yEval = [];
    this.xEval = [];
    this.X = [];
    this.Y = [];
    var chartType;
    
    switch (this.Orders[this.context.formChartType.onFieldValue].toUpperCase()) {
      case "ONOFF":
        chartType = this.context.charts.onoff.values;
        break; 
      case "NOLINEAR":
        chartType = this.context.charts.nolinear.values;
        break;
      case "LINEAR":
        chartType = this.context.charts.linear.values;
        break;
      case "ONOFF TRESHOLD":
        chartType = this.context.charts.onofftreshold.values;
        break;
    }    
    this.evalChartValues(chartType);
  }

  evalChartValues(values:any) {
    var i = 0;
    values.forEach((v:any) => {
      var Orders = this.Orders
      var yEval = ""
      var xEval = ""

      //if (element.isEvalY) {
      if (!v.yLabelEval.includes("Orders")) {
        if (v.yLabelEval == " " && v.isEvalY) {
          yEval = eval(this.formatEval(values[i - 1].yLabelEval))
          this.yEval.push(Math.round(Number(yEval)).toFixed(0))
        }
        else this.yEval.push(v.yLabelEval)
      } else {
        yEval = eval(this.formatEval(v.yLabelEval))
        this.yEval.push(Math.round(Number(yEval)).toFixed(0))
      }
      //} else this.yEval.push(yEval)

      if (v.isEvalX && v.xLabelEval.includes("Orders")){
        xEval = eval(this.formatEval(v.xLabelEval))
      }

      var x = eval(v.X)
      this.X.push(xEval == "" ? Number(x) : Number(xEval))
      var y = eval(v.Y)
      var newY = (yEval == "" ? (Number(y) != 1 ? Number(y) : this.Y[this.Y.length - 1]) : Number(yEval))
      this.Y.push(Math.round( (Number(newY.toFixed(0)) * 100 / 100) ))

      i++;
    });
    //console.log(this.X)
    //console.log(this.Y)
  }

  setOptionsChart(titleY: string, titleX : string, currency:string) {
    var tooltip:any
    var other_data = this.yEval

    tooltip = {
      enabled: true,
      callbacks: {
        otherData: other_data,
        currency: currency,
        label: function(data: any) {
          if (other_data[data.element.$context.dataIndex] != "" && other_data[data.element.$context.dataIndex] != " ") {
            return currency + other_data[data.element.$context.dataIndex]}
          else return null
        }
      }
    }
    //console.log(this.Y)
    var basicOptions = {
      scales: {
        y: {
          title: {
            display: true,
            text: titleY,
          },
          ticks: {
            display: true,
            /*
            stepSize: Math.abs(Math.round((
              (Math.round(this.Y[this.Y.length / 2] * 2 * 100) / 100 > this.Y[this.Y.length - 1] ? 
            this.Y[this.Y.length - 1] - Math.round(this.Y[this.Y.length / 2] * 100) / 100 : this.Y[this.Y.length / 2])
            ) * 100) / 100)*/
            stepSize: this.getStepSize()          
          },
          min: 0
        },
        x: {
          type:'linear',
          title: {
            display: true,
            text: titleX
          },
          ticks: {
            display: true,
            stepSize:.5,
            format: {
              style:'percent'
            }
          },
          min: 0,
          max: this.X[this.X.length - 1]
        },
      },
      plugins: {
        tooltip: tooltip,
        legend: {
          display: false
        }
      },
      elements: {
        point: {
          pointRadius: 4,
          pointHitRadius: 8,
          pointHoverRadius: 6,
          pointHoverBackgroundColor:"#ffffff",
        }
      }
    }
    return basicOptions
  }

  getStepSize() {
    var ret = this.Y[this.Y.length / 2] > this.Y[this.Y.length - 1] ? Math.round(this.Y[this.Y.length / 2] / 2) : Math.round(this.Y[this.Y.length - 1] / 2) 
    //var ret = this.Y[this.Y.length / 2] != this.Y[this.Y.length - 1] ? this.Y[this.Y.length / 2] : Math.round(this.Y[this.Y.length / 2] / 2) 
    return ret
  }

  formatEval(e:any) {
    return e.replaceAll("ToString","toString").replaceAll("'0'.toString()", "").replaceAll("'0'.toString()", "").
      replaceAll("System.", "").replaceAll("Round", "round")
  }

  openShortcut(url:string) {
    var urlSplit = url.split('/')
    var currentUrl = this._api.getUrlParameters()
    if (urlSplit.length > 1) this._api.navigate(currentUrl.apguid, currentUrl.teguid, currentUrl.moguid, urlSplit[0], urlSplit[1])
    else this._api.navigate(currentUrl.apguid, currentUrl.teguid, currentUrl.moguid, urlSplit[0])
  }

}

<!--<div class="backbutton_div" *ngIf="context.ButtonTitle != ''">
    <button mat-raised-button color="accent" (click)="back()">
        {{context.ButtonTitle}}
    </button>
</div>-->
<div *ngIf="(context.Logo == undefined || context.Logo != '') || (context.Subtitle != undefined && context.Subtitle != '')" class="col-12 row" style="margin-top:16px;">
    <div [class]="context.Subtitle != undefined && context.Subtitle != '' ? 'col-6' : 'col-12'">
        <img *ngIf="context.NoLogo == undefined" src="{{context.Logo ? context.Logo : 'https://s3.eu-west-1.amazonaws.com/showcase.wpdp.com/logoGoldenGooseEsteso.png'}}" alt="logo-app" class="logo-cp030 d-block mx-auto">
    </div><!--../../../../../assets/icon/logoGoldenGooseEsteso.png-->
    <div *ngIf="context.Subtitle != undefined && context.Subtitle != ''" class="col-6">
        <div class="sub-cp030">{{context.Subtitle}}</div>
    </div>
</div>
<div *ngIf="context.Title != undefined && context.Title != '' && (this.context.NoText == undefined || (this.context.NoText != undefined && this.context.NoText == false))" class="col-12">
    <h1 class="text-center mt-5 main-title">{{context.Title}}</h1>
</div>


<ng-container *ngFor="let item of component">

        <component-cp017 *ngIf="item.Component.Type == 'CP017'"
            [context]="item.Component.Context" [sections]="sections"
            [position]="position">
        </component-cp017>
        <component-cp018 *ngIf="item.Component.Type == 'CP018'"
            [context]="item.Component.Context" [sections]="sections"
            [position]="position">
        </component-cp018>
        <component-cp019 *ngIf="item.Component.Type == 'CP019'"
            [context]="item.Component.Context" [sections]="sections"
            [position]="position">
        </component-cp019>
        <component-cp020 *ngIf="item.Component.Type == 'CP020'"
            [context]="item.Component.Context" [sections]="sections"
            [position]="position">
        </component-cp020>
        <component-cp021 *ngIf="item.Component.Type == 'CP021'"
            [context]="item.Component.Context" [sections]="sections"
            [position]="position">
        </component-cp021>
        <component-cp022 *ngIf="item.Component.Type == 'CP022'"
            [context]="item.Component.Context"
            [position]="position">
        </component-cp022>
        <component-cp023 *ngIf="item.Component.Type == 'CP023'"
            [context]="item.Component.Context"
            [position]="position">
        </component-cp023>
        <component-cp024 *ngIf="item.Component.Type == 'CP024'"
            [context]="item.Component.Context"
            [position]="position">
        </component-cp024>
        <component-cp025 *ngIf="item.Component.Type == 'CP025'"
        [context]="item.Component.Context" [sections]="sections"
        [position]="position">
        </component-cp025>
        <component-cp032 *ngIf="item.Component.Type == 'CP032'"
        [context]="item.Component.Context" [sections]="sections"
        [position]="position">
        </component-cp032>
        <component-cp037 *ngIf="item.Component.Type == 'CP037'"
        [context]="item.Component.Context" [sections]="sections"
        [position]="position">
        </component-cp037>
        <component-cp045 *ngIf="item.Component.Type == 'CP045'"
        [context]="item.Component.Context" [sections]="sections"
        [position]="position">
        </component-cp045>
  
</ng-container>

import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { DialogComponent } from '../dialog/dialog.component';
import {v4 as uuidv4} from 'uuid';
import { Cp062Component } from '../cp062/cp062.component';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { Table } from 'primeng/table';
import { ExcelexporterService } from 'src/app/services/excelexporter.service';


@Component({
  selector: 'component-cp072',
  templateUrl: './cp072.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp072.component.scss']
})
export class Cp072Component implements OnInit {

  @Input() inguid = ''
  @Input() labels:any = {}
  @Output() closeFormEvent = new EventEmitter<any>();
 
  @ViewChild('apiNavigator') apiNavigator: Cp062Component | undefined;
  showApinavigator:boolean = false
  configElementPaths:any = {}

  selectedInguid = ""
  labelsTable:any = undefined
  dateKeys:any = {}
  recordsColumns = ["Input", "Output", "Type"]
  recordsColumns2 = ["Input", "Output", "Past month Output", "Policy", "Type"]
  selectedRows = []
  selectedRows2 = []
  selectedRows3 = []
  @ViewChild('dt') table: Table | undefined;
  @ViewChild('dt2') table2: Table | undefined;
  @ViewChild('dt3') table3: Table | undefined;
  @ViewChild('fileUpload') fileUpload: any | undefined;
  firstRow:any = 0
  firstRow2:any = 0
  firstRow3:any = 0
  allowedFileExtension:string = "csv"
  filterFieldValue:string = ""
  filterType:number = -1
  asItemTypes:any = []
  ntItemTypes:any = []
  paItemTypes:any = []
  ntReservedItems:any = []
  ntReservedItemsOutdated = true
  visibleRows:number = 7
  tableVisible:boolean = true

  context:any = {}
  loading:boolean = true

  
  @HostListener('window:keydown.esc', ['$event'])
  closeShortcut(event: KeyboardEvent) {
    event.preventDefault();
    if (this.showApinavigator) {
      this.showApinavigator = false
    } else {
      this.close()
    }
  }


  constructor(private _snackbarManager:SnackbarmanagerService, private _api:ApiService, public dialog: MatDialog, private ngxCsvParser: NgxCsvParser,  private _excel:ExcelexporterService, private _errorThrower:ErrorthrowerService) { }

  ngOnInit(): void {
    this._api.doButtonPostRequest({"action": "get", "configuration": { "id": this.inguid }}, "/configure-integration").subscribe((response) => {
      if (response) {
        this._api.printResponse(response)
        this.context = JSON.parse(JSON.stringify(response))
        if (!this.context.isSuccess) {
            this._snackbarManager.open(this.context.message, 'ko')
            this.loading = false
            this.close(false)
        } else {
          this.context.itemTypes.forEach((item:any) => {
            if (item.Key.charAt(0) == "N") {
              this.ntItemTypes.push(item)
            } else if (item.Key.charAt(0) == "V" && this.context.config.Items.Payroll != undefined) {
              this.paItemTypes.push(item)
              if (item.Key == "VG") {
                this.asItemTypes.push(item)
              }
            } else {
              this.asItemTypes.push(item)
            }
          })
          
          this.updateNtReservedItems()
          this.updatePaReservedItems()

          var f = 0
          this.filterType = -1
          this.context.config.Settings.ExtractionFilters.forEach((item:any) => {
            var itemString = JSON.stringify(item)
            if (item.length == 3 && this.context.config.Extraction.Filter.length == 3 && item[2].Value == "XYZ") {
              itemString = itemString.replace("XYZ", this.context.config.Extraction.Filter[2].Value)
            }
            if (item.length == 1 && this.context.config.Extraction.Filter.length == 1 && item[0].Value == "XYZ") {
              itemString = itemString.replace("XYZ", this.context.config.Extraction.Filter[0].Value)
            }
            if (itemString === JSON.stringify(this.context.config.Extraction.Filter)) {
              this.filterType = f
              if (f == 1) {
                this.filterFieldValue = this.context.config.Extraction.Filter[0].Value
              } else if (f == 2) {
                this.filterFieldValue = this.context.config.Extraction.Filter[2].Value
              }
            }
            f += 1
          })
          console.log(this.ntReservedItems)
          this.loadConfig()
        }
      }
    }, (error) => {
      if(error) {
        console.log(error);
        if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko')
        else this._snackbarManager.open(error.error, 'ko')
        this.loading = false
        this.close(false)
      }
    });
  }

  loadConfig() {
    try {
      console.log(this.context.config)
      this.loading = false
      return true
    } catch (e) { 
      console.log(e)
      this._snackbarManager.open(this.labels.errorLabel, 'ko')
      this.close(false)
      return false 
    }
  }

  close(success?:boolean) {
    this.closeFormEvent.emit({
      isSuccess: success
    });
  }

  changePayrollcodeSource(event:any) {   
    var source = event.target.textContent.replace(" ", "")
    if (event.target.textContent.includes("PersonCode")) {
      source = this.getPersoncode()
    }
    source = source.replace(" ", "")
    this.context.config.Settings.PayrollcodeSource = source
  }

  changeExtractionFilter(event:any) { 
    if (event.value != undefined) {
      this.filterType = event.value
      this.context.config.Extraction.Filter = this.context.config.Settings.ExtractionFilters[this.filterType]
    }
    if (this.context.config.Extraction.Filter.length > 0) {
      if (this.filterType == 1) {
        this.context.config.Extraction.Filter[0].Value = this.filterFieldValue
      } else if (this.filterType == 2) {
        this.context.config.Extraction.Filter[2].Value = this.filterFieldValue
      }
    }
    console.log(this.context.config.Extraction.Filter)
  }

    
  addRecord(tableIndex:number = 0) {
    var newItem = {}
    if (tableIndex == 0 || tableIndex == 2) {
      newItem = {
        "Id": uuidv4(),
        "Input":"Input",
        "Type": tableIndex == 0 ? "AA" : "VC",
        "Output":"Output",
        "Amount": 0,
        "Hours": 0
      }
      if (tableIndex == 0) {
        this.context.config.Items.Absence.push(newItem)
        this.changeTablePage(this.table, "Absence", this.firstRow)
        /*
        if (this.context.config.Items.Absence.length > this.visibleRows) this.firstRow = (this.context.config.Items.Absence.length - 1) - (this.context.config.Items.Absence.length % this.visibleRows)  
        this.table?.reset();*/
      } else if (tableIndex == 2) {
        this.context.config.Items.Payroll.push(newItem)
        this.changeTablePage(this.table3, "Payroll", this.firstRow3)
        this.ntReservedItemsOutdated = true
        /*
        if (this.context.config.Items.Payroll.length > this.visibleRows) this.firstRow3 = (this.context.config.Items.Payroll.length - 1) - (this.context.config.Items.Payroll.length % this.visibleRows)  
        this.table3?.reset();*/
      }
    } else {  
      newItem = {        
        "Id": uuidv4(),
        "Input":"Input",
        "Type":"NT",
        "Output":"Output",
        "Amount": 0,
        "Hours": 0,
        "PastMonthOutput":"",
        "Policy":"",
      }
      
      this.context.config.Items.ExpenseReport.push(newItem)
      this.changeTablePage(this.table2, "ExpenseReport", this.firstRow2)
    }
    this._snackbarManager.open(this.labels.newitemaddedLabel, "ok")
  }

  changeTablePage(table:any, itemsType:string, first:number) {
    var newFirstRow = 0
    var items = this.context.config.Items[itemsType]
    //console.log("length " + this.context.config.Items.ExpenseReport.length)
    var resto = ~~(items.length / this.visibleRows)      
    //console.log("resto " + resto)
    newFirstRow = (this.visibleRows * resto)
    //console.log("prima riga " + newFirstRow)
    if (items.length > this.visibleRows && items.length > newFirstRow) {      
      //console.log("first row NOW!!!!") 
      first = newFirstRow
    }
    if (items.length == 1 + (this.visibleRows * resto)) {        
      //console.log("reset NOW!!!!")
      table?.reset()
    }
  }
  
  removeRecords(tableIndex:number = 0) {
    if (tableIndex == 0) {      
      if (this.selectedRows.length > 1) this._snackbarManager.open(this.labels.itemsremovedLabel, "ok")
      else this._snackbarManager.open(this.labels.itemremovedLabel, "ok")
      this.selectedRows.forEach((r:any) => {
        this.context.config.Items.Absence = this.context.config.Items.Absence.filter( (item:any) => item !== r );
      })
      //if (this.selectedRows.length == this.context.config.Items.Absence) this.firstRow = 0
      this.selectedRows = []
    } else if (tableIndex == 2) {      
      if (this.selectedRows3.length > 1) this._snackbarManager.open(this.labels.itemsremovedLabel, "ok")
      else this._snackbarManager.open(this.labels.itemremovedLabel, "ok")
      this.selectedRows3.forEach((r:any) => {
        this.context.config.Items.Payroll = this.context.config.Items.Payroll.filter( (item:any) => item !== r );
      })
      //if (this.selectedRows3.length == this.context.config.Items.Payroll) this.firstRow3 = 0
      this.selectedRows3 = []
      this.ntReservedItemsOutdated = true
      this.updateNtReservedItems()
    } else { 
      if (this.selectedRows2.length > 1) this._snackbarManager.open(this.labels.itemsremovedLabel, "ok")
      else this._snackbarManager.open(this.labels.itemremovedLabel, "ok")     
      this.selectedRows2.forEach((r:any) => {
        this.context.config.Items.ExpenseReport = this.context.config.Items.ExpenseReport.filter( (item:any) => item !== r );
      })
      //if (this.selectedRows2.length == this.context.config.Items.ExpenseReport) this.firstRow2 = 0
      this.selectedRows2 = []
      this.updatePaReservedItems()
    }
  }

  updateNtReservedItems() {    
    if (this.context.config.Items.ExpenseReport != undefined && this.context.config.Items.Payroll != undefined && this.ntReservedItemsOutdated) {
      this.ntReservedItems = []
      this.context.config.Items.Payroll.forEach((item:any) => {
        this.ntReservedItems.push({
          "Id": item.Id,
          "Name": item.Input,
          "Value": item.Input + " (" + item.Output + ")"
        })
      })
    }
  }

  updatePaReservedItems() {    
    if (this.context.config.Items.ExpenseReport != undefined && this.context.config.Items.Payroll != undefined) {
      this.context.config.Items.Payroll.forEach((itemPa:any) => {
        this.context.config.Items.ExpenseReport.every((itemNt:any) => {
          if (itemNt.Output == itemPa.Input) {
            itemPa.ExpensesReportsReserved = true
            return false
          }
          itemPa.ExpensesReportsReserved = false
          return true
        })
      })
      console.log(this.context.config.Items.Payroll)
    }
  }

  toggleNoTicket(index:number) {
    if (this.context.config.Items.ExpenseReport[index].Type == 'NF') {
      this.context.config.Items.ExpenseReport[index].Input = "NoTicket"
      this.context.config.Items.ExpenseReport[index].Output = ""
      this.updatePaReservedItems()
    }
  }

  onFileSelected(event:any){
    var expensesBackup = this.context.config.Items.ExpenseReport
    var payrollBackup = this.context.config.Items.Payroll
    var absenceBackup = this.context.config.Items.Absence
    var failedImport = false
    this.context.config.Items.Absence = []
    this.context.config.Items.Payroll = []
    this.context.config.Items.ExpenseReport = []
    this.loading = true
    let filesArray = event.target.files;    
    if (filesArray.length > 0) {
      this.ngxCsvParser.parse(filesArray[0], { header: false, delimiter: ',', encoding: 'utf8' })
      .pipe().subscribe({
        next: (result:any): void => {
          result.every((v:any) => {
            v = v[0].split(';')
            //console.log(v)
            if (v[0] == "INPUT") {
              return true
            }
            if (v.length != 3 || (v[2] != '*' && v[2].length != 2)) {
              this._snackbarManager.open("File formattato male!", "ko")
              this.context.config.Items.ExpenseReport = expensesBackup
              this.context.config.Items.Payroll = payrollBackup
              this.context.config.Items.Absence = absenceBackup
              failedImport = true
              return false
            }
            if (v[2].charAt(0) == "N" && this.context.config.Items.ExpenseReport != undefined) {       
              this.context.config.Items.ExpenseReport.push({
                "Input":v[0],
                "Type":v[2],
                "Output":v[1],
                "PastMonthOutput":v[3],
                "Policy":v[4],
                "Amount": 0,
                "Hours": 0
              })
            } else if (v[2].charAt(0) == "V" && v[2] != "VG" && this.context.config.Items.Payroll != undefined) {
              this.context.config.Items.Payroll.push({
                "Input":v[0],
                "Type":v[2],
                "Output":v[1],
                "Amount": 0,
                "Hours": 0
              })
            } else if (this.context.config.Items.Absence != undefined) {
              this.context.config.Items.Absence.push({
                "Input":v[0],
                "Type":v[2],
                "Output":v[1],
                "Amount": 0,
                "Hours": 0
              })
            }
            return true
          });
          this.ntReservedItemsOutdated = true
          this.updateNtReservedItems()
          this.updatePaReservedItems()
          //this.firstRow = 0
          if (this.table != undefined) {
            this.table.reset()
          }
          if (this.table2 != undefined) {
            this.table2?.reset()
          }
          if (this.table3 != undefined) {
            this.table3?.reset()
          }
          if (!failedImport) this._snackbarManager.open(this.labels.itemsimportedLabel, "ok")
        },
        error: (error: NgxCSVParserError): void => {
          console.log('Error', error);
          this._snackbarManager.open(error.message, "ko")
        }
      });
    }
    
    this.fileUpload.nativeElement.value = null; 
    this.loading = false
  }

  addCompanyDecoding() {
    this.context.config.Settings.CompaniesDecodings.push({"Input":"input", "Output": "output"})
  }

  removeCompanyDecoding(i:number) {
    this.context.config.Settings.CompaniesDecodings.splice(i, 1)
  }

  getPersoncode() {
    var entityFound = false
    var entityName = ""
    var entityIndex = 0
    this.context.config.Extraction.Entities.every((entity:any) => {
      if (entity.Id == "AllPeopleSearch") {
        entityFound = true
        entityName = entity.Id
        return false
      }
      else if (entity.Id == "People") {
        entityFound = true
        entityName = entity.Id
        return false
      }
      entityIndex += 1
      return true
    })
    if (entityName == "AllPeopleSearch") {
      return "AllPeopleSearch.PersonCode_Search"
    } else {
      return "People.PersonCode"
    }
  }
  
  closeApiNavigator(event:any) {
    this.showApinavigator = false
    console.log(this.configElementPaths)  
    this.configElementPaths.forEach((path:any) => {
      this.evalInContext(path, this, event)
    })
    this.configElementPaths = []
  }

  evalInContext(js:string, context:any, event:any) {
    return function() { return eval(js); }.call(context);
  }

  saveConfig() {
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: this.labels.titleLabel,
        content: this.labels.contentLabel,
        accept: this.labels.okLabel,
        reject: this.labels.koLabel
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {  
        console.log(this.context.config)
        this._api.loading = true
        this._api.doButtonPostRequest({"action": "set", "configuration": { "id": this.inguid, "body": this.context.config }}, "/configure-integration").subscribe((response) => {
          if (response) {
            this._api.printResponse(response)
            var responseObj = JSON.parse(JSON.stringify(response));  
            if (responseObj.isSuccess != undefined) {
              this.close(responseObj.isSuccess)
            }            
            this._snackbarManager.open(this.labels.successLabel, 'ok');
            this._api.loading = false
          }
        }, (error) => {
          if(error) {
            console.log(error);
            if (error.error.message != undefined) this._snackbarManager.open(error.error.message, 'ko');
            else this._snackbarManager.open(error.error, 'ko');            
            this._api.loading = false
          }
        });
      }
    })
  }

  removeFilterField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  addFilterField() {
    this.context.config.Extraction.Filter.Input.push({'Label': '', 'Element': ''});
    var l = this.context.config.Extraction.Filter.Input.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Extraction.Filter.Input[' + l + '].Element', 
      name: 'this.context.config.Extraction.Filter.Input[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path); 
    });
    this.openApiNavigator(1, undefined)
  }

  openApiNavigator(selectionType:number, entity:any) {
    let newApiList:any = []
    if (entity != undefined) {
      this.context.apiList.every((a:any) => {
        if (a.id == entity) {
          newApiList.push(a)
          return false
        }
        return true
      }) 
    } else {
      newApiList = this.context.apiList
    }
    console.log(newApiList)
    this.showApinavigator = true
    this.apiNavigator?.open(newApiList, selectionType)
  }

  addSortField() {
    this.context.config.Extraction.Sorting.push({'Label': '', 'Element': ''});
    var l = this.context.config.Extraction.Sorting.length - 1
    var pathsObj:any = {
      id: 'this.context.config.Extraction.Sorting[' + l + '].Element', 
      name: 'this.context.config.Extraction.Sorting[' + l + '].Label'
    }
    this.configElementPaths = []
    var path = ""
    Object.keys(pathsObj).forEach((key:string) => { 
      path = pathsObj[key] + "=event." + key
      if (!path.includes("this.")) {
        path = "this." + path
      }
      this.configElementPaths.push(path)
    });
    this.openApiNavigator(1, undefined)
  }

  removeSortField(inputArray:any, index:number) {
    inputArray = inputArray.splice(index, 1)
  }

  downloadTemplate() {
    let columns = [
      {field: "Elemento1", header: "INPUT"},
      {field: "Elemento2", header: "OUTPUT"},
      {field: "Elemento3", header: "TYPE"},
    ]
    let rows = [
      {"Elemento1":"Lavorato", "Elemento2":"01", "Elemento3":"LA"},
      {"Elemento1":"Ordinario", "Elemento2":"", "Elemento3":"OR"},
    ]
    let options = {
      "hideExportdate": true, 
      "hideTitle": true, 
      "hideDateFromTitle": true, 
      "format": "csv"
    }
    this._excel.export("Template", columns, rows, options)
  }

  downloadTypesLegenda() {
    let columns = [
      {field: "Elemento1", header: "TIPO"},
      {field: "Elemento2", header: "DESCRIZIONE"},
    ]
    let rows:any = []
    this.context.itemTypes.forEach((type:any) => {
      rows.push({"Elemento1":type.Key, "Elemento2": type.Value})
    })
    let options = {
      "hideExportdate": true, 
      "hideDateFromTitle": true, 
      "hideTitle": true, 
      "format": "xlsx"
    }
    this._excel.export("lista_tipi_voce", columns, rows, options)
  }

  isArray(element:any): boolean { return Array.isArray(element) }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service'
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
 

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'component-cp043',
  templateUrl: './cp043.component.html',
  styleUrls: ['./cp043.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS 
    },
  ]
})
export class Cp043Component implements OnInit {

  @ViewChild('picker', {static: false}) private picker: MatDatepicker<Date> | undefined;
  @Input() context: any;
  @Input() response: any;
  basicData1: any;
  basicOptions1: any;
  basicData2: any;
  basicOptions2: any;

  selectYear:any;
  selectRegion:any = "ALL";
  selectJob:any = "ALL";
  selectManager:any = "ALL";
  selectArea:any = "ALL";
  selectLevel:any = "0";

  constructor(private _api:ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService) {
  }

  ngOnInit(): void {
    this.selectYear = new Date();
    this.selectYear.setFullYear(this.context.Anno);
    this.setupChartData();
  }

  setupChartData() {   
    if (this.context.table1.length > 0) {
      var datasets1 = [];      
      for (var i = 0; i < this.context.table1.length; i++) {
        var data1:number[] = [];
        for (var j = 1; j < this.context.table1[i].length; j++) {
          data1.push(this.context.table1[i][j] * 1000 / 10);
        }
        datasets1.push({
          label: this.context.chart1.labels[i],
          data: data1,
          fill: false,
          tension: .5,
          backgroundColor: this.context.chart1.colors[i],
          borderColor: this.context.chart1.colors[i],

          pointBorderColor:this.context.chart1.colors[i],
          pointHoverBorderColor:this.context.chart1.colors[i],
          pointBackgroundColor:this.context.chart1.colors[i]
        });
      }
      var labels1:string[] = [];
      for (var j = 1; j < this.context.table1[0].length; j++) {
        //labels1.push(this.context.table1[0][j] * 1000 / 10 + "%");
        labels1.push('');
      }
      this.basicData1 = {
        labels: labels1,
        datasets: datasets1
      };
      this.basicOptions1 = {
        responsive: true,
        maintainAspectRatio: true,
        showTooltips: true,
        plugins: {
          legend: {
            position:'bottom',
            labels: {
              color: '#495057'
            }
          },
          tooltip : {
            callbacks: {
              label: function(tooltipItem:any) {
                let perc = "";
                perc = tooltipItem.parsed.y + "%";
                return perc;
              }
            },
            intersect: false
          },
          datalables: {
            align: 'end',
            anchor: 'end',
            borderRadius: 4,
            backgroundColor:'teal',
            color: 'white',
            font: {
              weight: 'bold'
            }
          }
        },
        elements: {
          point: {
            pointRadius: 4,
            pointHitRadius: 8,
            pointHoverRadius: 6,
            pointHoverBackgroundColor:"#ffffff",
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#f5f5f5'
            }
          },
          y: {
            ticks: {
              color: '#f5f5f5'
            },
            grid: {
              color: '#f5f5f5'
            }
          }
        }
      };
    }

    if (this.context.table2.length > 0) {
      var datasets2 = [];
      for (var i = 0; i < this.context.table2.length; i++) {
        var data2:number[] = [];
        for (var j = 1; j < this.context.table2[i].length; j++) {
          data2.push(this.context.table2[i][j] * 1000 / 10);
        }
        datasets2.push({
          label: this.context.chart2.labels[i],
            data: data2,
            fill: false,
            tension: .5,
            backgroundColor: this.context.chart2.colors[i],
            borderColor: this.context.chart2.colors[i],

            pointBorderColor:this.context.chart2.colors[i],
            pointHoverBorderColor:this.context.chart2.colors[i],
            pointBackgroundColor:this.context.chart2.colors[i]
        })
      };
      var labels2:string[] = [];
      for (var j = 1; j < this.context.table2[0].length; j++) {
        //labels2.push(this.context.table1[0][j] * 1000 / 10 + "%");
        labels2.push('');
      }
      this.basicData2 = {
        labels: labels2,
        datasets: datasets2
      };
      this.basicOptions2 = {
        plugins: {
          legend: {
            position:'bottom',
            labels: {
              color: '#495057'
            }
          },
          tooltip : {
            callbacks: {
              label: function(tooltipItem:any) {
                let perc = "";
                perc = tooltipItem.parsed.y + "%";
                return perc;
              }
            }
          }
        },
        elements: {
          point: {
            pointRadius: 4,
            pointHitRadius: 8,
            pointHoverRadius: 6,
            pointHoverBackgroundColor:"#ffffff",
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#f5f5f5'
            }
          },
          y: {
            ticks: {
              color: '#f5f5f5'
            },
            grid: {
              color: '#f5f5f5'
            }
          }
        }
      };
    }
  }

  chosenYearHandler(ev:any, input:any){
    let { _d } = ev;
    this.selectYear = _d;
    this.context.Anno = this.selectYear.getFullYear()
    this.picker?.close();
  }

  report() {
    this._api.loading = true;
    this.response.Sections[0].Components[0].Component.Context = this.context;
    var context_data = this.response;

    this._api.doButtonPostRequest(context_data, "/o-action-save", this.context.ButtonReportFunction).subscribe((response) => {      
      if(response) {        
        this._api.printResponse(response);        
        this._snackbarManager.open(this.context.ButtonReportMessageSuccess, 'ok');        
        this._api.loading = false;   
      }
    }, (error) => {
      if(error) {
        console.log(error);        
        this._snackbarManager.open(this.context.ButtonReportMessageError, 'ko');           
        this._api.loading = false;
      }      
    });  
  }

  search() {
    this._api.loading = true;

    this._api.doButtonPostRequest(this.getContextDataSearch()).subscribe((response) => {      
      if(response) {        
        this._api.printResponse(response);   
        var jsonResponse = JSON.parse(JSON.stringify(response));
        this.context.table1 = jsonResponse.Sections[0].Components[0].Component.Context.table1;
        this.context.table2 = jsonResponse.Sections[0].Components[0].Component.Context.table2;
        this.setupChartData();
      }
      this._api.loading = false;
    }, (error) => {
      if(error) {
        console.log(error);  
        this._api.loading = false;      
        this._snackbarManager.open(error.error, 'ko');   
      }
    });  
    
  }

  getContextDataRelevance() {   
    let context_data = {
      "Year": this.selectYear.getFullYear(),
      "Area": "CT90999",
      "TPRUOL": this.selectJob,
      "GEGERE": this.selectManager,
      "TPAREC": this.selectArea,
      "Where": "", //query sql data dal livello
      "TPREGI": this.selectRegion,
      "TimeDelta" : Intl.DateTimeFormat().resolvedOptions().timeZone
    }    

    if (context_data.TPRUOL != "ALL")
      context_data.Where = " AND (TPRUOL = \'" + this.selectJob + "\' OR TPRUOL IS NULL)"
    if (context_data.GEGERE != "ALL")
      context_data.Where += " AND ((GEGERX LIKE \'" + this.selectManager + "%\' AND GEGERX <> \'" + this.selectManager +"\') OR GEGERX IS NULL)";
    if (context_data.Area != "ALL")
      context_data.Where += " AND (TPAREC = \'" + this.selectArea + "\' OR TPAREC IS NULL)";
    if (this.selectLevel == "1")
      context_data.Where += " AND LENGTH(GEGERX) <> LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectLevel == "2")
      context_data.Where += " AND LENGTH(GEGERX) = LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectRegion == "ALL") {
      let regionList:any = [];
      this.context.RegionList.forEach((e:any) => {
        regionList.push(e.Elemento1);
      });
      context_data.TPREGI = regionList;
      //context_data.WHERE += " AND (TPREGI = '" + this.selectRegion + "' OR TPREGI IS NULL)";
    } else {
      context_data.TPREGI = this.selectRegion;
      context_data.Where += "and (TPREGI = \'" + this.selectRegion + "\' OR TPREGI IS NULL)"
    }
    
    return context_data;
  }

  getContextDataSearch() {
    let context_data = {
      "ANNO": this.selectYear.getFullYear(),
      "JOB": this.selectJob,
      "GEGERE": this.selectManager,
      "AREA": this.selectArea,
      "WHERE": "", //query sql data dal livello
      "REGION": ""
    }

    if (context_data.JOB != "ALL")
      context_data.WHERE = " AND (TPRUOL = \'" + this.selectJob + "\' OR TPRUOL IS NULL)"
    if (context_data.GEGERE != "ALL")
      context_data.WHERE += " AND ((GEGERX LIKE \'" + this.selectManager + "%\' AND GEGERX <> \'" + this.selectManager +"\') OR GEGERX IS NULL)";
    if (context_data.AREA != "ALL")
      context_data.WHERE += " AND (TPAREC = \'" + this.selectArea + "\' OR TPAREC IS NULL)";
    if (this.selectLevel == "1")
      context_data.WHERE += " AND LENGTH(GEGERX) <> LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectLevel == "2")
      context_data.WHERE += " AND LENGTH(GEGERX) = LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectRegion == "ALL") {
      let regionList:any = [];
      this.context.RegionList.forEach((e:any) => {
        regionList.push(e.Elemento1);
      });
      context_data.REGION = regionList;
      //context_data.WHERE += " AND (TPREGI = '" + this.selectRegion + "' OR TPREGI IS NULL)";
    } else {
      context_data.REGION = this.selectRegion;
      context_data.WHERE += "and (TPREGI = \'" + this.selectRegion + "\' OR TPREGI IS NULL)"
    };

    return context_data
  }

}
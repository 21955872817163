<div [class]="!_api.gettingSections ? 'sections-div-in' : 'sections-div-out'" [style.background-color]="debugEnabled ? '#fcbdbd' : ''" [style.padding]="debugEnabled ? '8px' : ''">

    <!--
    <button *ngIf="(_api.getUrlParameters().fmguid == 'FA0008' || _api.getUrlParameters().fmguid == 'FA0016' || _api.getUrlParameters().obguid == 'wf-star-team-member' || _api.getUrlParameters().obguid == 'wf-star-report') && !_api.loading" mat-mini-fab color="accent" class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>-->

    <ng-container *ngFor="let section of sections">
        <!--deprecated! <app-section-header *ngIf="section.Section.Context.Position == 'v'" [component]="section.Components" [response]="response" [username]="username" [maxiLogo]="maxiLogo"></app-section-header>!-->
        <app-page-actions *ngIf="(section.Section.Context.Position == 'b1' || section.Section.Context.Position == 'b2') && section.Section.Context.PositionButton == 'dx'"
            [position]="section.Section.Context.PositionButton == 'sx' ? 'sx' : 'dx'" [component]="section.Components" [sections]="sections"
            [ngClass]="section.Section.Context.PositionButton == 'sx' ? 'text-left' : 'text-right'" class="page-action" [style.justify-content]="section.Section.Context.PositionButton == 'sx' ? 'flex-start' : 'flex-end'">
        </app-page-actions>

        <ng-container *ngIf="section.Section.Context.Position == 'sx' || section.Section.Context.Position == 'dx' || section.Section.Context.Position == 'c' || section.Section.Context.Position == 'v'">
            <mat-card class="component-card" *ngIf="section.Section.Context.Position != 'v'" [ngClass]="section.Section.Context.Position == 'sx' ? 'col-6 my-5 text-align-left' : section.Section.Context.Position == 'dx' ? 'col-6 mr-0 my-5 text-align-right' : section.Section.Context.Position == 'c' || section.Section.Context.Position == '' ? 'col-12' : ''"> <!-- my-5-->
                <mat-card-title *ngIf="section.Section.Context.Title != ''" class="d-flex align-items-center justify-content-center text-center" class="title-card">
                    {{section.Section.Context.Title}}
                </mat-card-title>
                <mat-card-content class="mat-card-content">      
                    <app-components [component]="section.Components" [title]="section.Section.Context.Title" [response]="response" [username]="username">
                    </app-components>
                </mat-card-content>
            </mat-card>   

            <app-components *ngIf="section.Section.Context.Position == 'v'" [component]="section.Components" [response]="response" [username]="username">
            </app-components>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let section of sections">                        
        <app-page-actions *ngIf="(section.Section.Context.Position == 'b1' || section.Section.Context.Position == 'b2') && section.Section.Context.PositionButton == 'sx'"
            [position]="section.Section.Context.PositionButton == 'sx' ? 'sx' : 'dx'"
            [component]="section.Components" [sections]="sections"
            [ngClass]="section.Section.Context.PositionButton == 'sx' ? 'text-left' : 'text-right'">
        </app-page-actions>
    </ng-container>

</div>
<!--section loader-->
<div *ngIf="!_api.firstBoot" [class]="_api.gettingSections ? 'loader-div loader-div-in' : 'loader-div loader-div-out'" [style.min-height]="innerHeightDiv">
    <mat-spinner [class]="_api.gettingSections ? 'loader loader-in' : 'loader loader-out'" [style.top]="innerHeight"></mat-spinner>
    <div *ngIf="_api.loading && _api.gettingSections && _api.stillGettingSections" class="loader-text">Still waiting for a response...</div>
</div>

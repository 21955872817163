import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

import { MatDatepicker } from '@angular/material/datepicker';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service'

import { Chart } from 'chart.js';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
 

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'component-cp031',
  templateUrl: './cp031.component.html',
  styleUrls: ['./cp031.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS 
    },
  ]
})
export class Cp031Component implements OnInit {

  @ViewChild('picker', {static: false}) private picker: MatDatepicker<Date> | undefined;
  @ViewChild('picker2', {static: false}) private picker2: MatDatepicker<Date> | undefined;
  @Input() context: any;
  basicData1: any;
  basicOptions1: any;
  basicData2: any;
  basicOptions2: any;

  selectYear:any;
  selectRegion:any = "ALL";
  selectJob:any = "ALL";
  selectManager:any = "ALL";
  selectArea:any = "ALL";
  selectLevel:any = "0";

  valArray:any = [];
  rowsArray = [];
  rowsArray2 = [];
  noChartDataFound:boolean = false;
  noDataFound:boolean = false;

  radarChart: any;
  radarChartCanvas: any;
  radarChartData: any;
  radarChartParameters: string[] = [];
  radarChartValues: number[] = [];
  radarChartOptions: any;

  constructor(private _api:ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService) { }

  ngOnInit(): void {
    this.selectYear = new Date();
    this.selectYear.setFullYear(this.context.Anno);
    if (this.context.TableRows1[0].length > 0) {
      this.parseTableRows(this.context.TableRows1, this.rowsArray);
    }
    if (this.context.TableRows2[0].length > 0) { 
      this.parseTableRows(this.context.TableRows2, this.rowsArray2);
    }
    if (!this.isChartDataAvailable()) this.noChartDataFound = true;
    else this.createPolarChart();

    if (!this.isDataAvailable()) this.noDataFound = true;
  }

  ngAfterViewInit() {
    if (this.isChartDataAvailable()) {
      this.noChartDataFound = false; 
      this.createPolarChart();
    }
  }

  ngOnDestroy() {
    try {
      const radarChartCanvas = <HTMLCanvasElement>document.getElementById("RadarChart");
      radarChartCanvas.remove()
    } catch (e) {}
  }
  

  isChartDataAvailable() {
    return this.context.AverageRows1 != null && (this.context.AverageRows1.length > 1 && this.context.AverageRows1.Elemento1 != "")
  }

  isDataAvailable() {
    return this.context.TableRows1 != undefined && this.context.TableRows1.length > 0 && this.context.TableRows1[0].length > 0;
  }

  chosenYearHandler(ev:any, input:any) {
    let { _d } = ev;
    this.selectYear = _d;
    this.picker?.close();
    this.picker2?.close();
    this.updateYearContext();
  }

  search() {
    this._api.loading = true;

    this._api.doButtonPostRequest(this.getContextData()).subscribe((response) => {
      if(response) {
        this._api.printResponse(response);
        var jsonResponse = JSON.parse(JSON.stringify(response));
        //this.context.table1 = jsonResponse.Sections[0].Components[0].Component.Context.table1;
        //this.context.table2 = jsonResponse.Sections[0].Components[0].Component.Context.table2;
        var c = 0;
        jsonResponse.Sections[0].Components.every((comp:any) => {
          if (comp.Component.Type == "CP031" || comp.Component.Type == "CP032") return false
          c += 1;
          return true
        })
        this.context.AverageRows1 = jsonResponse.Sections[0].Components[c].Component.Context.AverageRows1;
        this.context.AverageRows2 = jsonResponse.Sections[0].Components[c].Component.Context.AverageRows2;  
        this.context.AverageRows3 = jsonResponse.Sections[0].Components[c].Component.Context.AverageRows3;
        this.context.AverageRows4 = jsonResponse.Sections[0].Components[c].Component.Context.AverageRows4;

        this.context.TableRows1 = jsonResponse.Sections[0].Components[c].Component.Context.TableRows1;
        this.context.TableRows2 = jsonResponse.Sections[0].Components[c].Component.Context.TableRows2;
        this.context.Dashboard2Active = jsonResponse.Sections[0].Components[c].Component.Context.Dashboard2Active;

        if (this.context.TableRows1[0].length > 0 || this.context.TableRows2[0].length > 0) { 
          this.noChartDataFound = false;
          //this.context.TableRows1 = jsonResponse.Sections[0].Components[0].Component.Context.TableRows1;
          //this.context.TableRows2 = jsonResponse.Sections[0].Components[0].Component.Context.TableRows2;
          this.rowsArray = [];
          this.rowsArray2 = [];
          if (this.context.TableRows1[0].length > 0) {
            this.parseTableRows(this.context.TableRows1, this.rowsArray);
          }
          if (this.context.TableRows2[0].length > 0) { 
            this.parseTableRows(this.context.TableRows2, this.rowsArray2);
          }
          //console.log(this.rowsArray)
          //console.log(this.rowsArray2)
          if ((this.context.NoAverageRows1 == undefined || !this.context.NoAverageRows1) && this.context.AverageTitle1 != '') 
            if (this.rowsArray.length > 0) this.createPolarChart();
            else this.noChartDataFound = true;
        } else {
          this.noChartDataFound = true;
        }

        if (!this.isDataAvailable()) this.noDataFound = true;
        else this.noDataFound = false;
      }
      this._api.loading = false;
    }, (error) => {
      if(error) {
        console.log(error);
        this._api.loading = false;
        this._snackbarManager.open("Error: can't search data.", 'ko');
      }
    });
  }

  starMember() {
    //How does it really work?
    //this._api.changeFunction("FA0084", "wf-star-team-member", "" + this.selectYear.getFullYear())
    this._api.changeOBGUID("wf-star-team-member", "" + this.selectYear.getFullYear());
  }
  
  starReport() {
    this._api.changeOBGUID("wf-star-report");
  }

  relevanceReport() {
    this._api.loading = true;
    var context = "/relevance-report";

    this._api.doButtonPostRequest(this.getContextRelevanceReport(), context).subscribe((response) => {      
      if(response) {        
        //this._api.printResponse(response);        
        //this._snackbarManager.open(JSON.parse(JSON.stringify(response)).message, 'ok');        
        //this._api.loading = false;   
      }
    }, (error) => {
      if(error) {
        console.log(error);        
        //this._snackbarManager.open(error.error.message, 'ko');           
        //this._api.loading = false;
      }
      
    });
    setTimeout(() => {
      this._api.loading = false;   
      this._snackbarManager.open("Your report procedure has been started.\nPlease see the output in the Reports function", 'ok');        
    }, 500);
  }

  getContextRelevanceReport() {
    let context_data = {
      "Year": "" + this.selectYear.getFullYear(),
      "Area": this._api.getUrlParameters().fmguid == "FA0009" ? "CT00010" : "CT00011",
      "TimeDelta" : new Date().getTimezoneOffset(), //Intl.DateTimeFormat().resolvedOptions().timeZone      
      "TPREGI": this.selectRegion,
      "TPRUOL": this.selectJob,
      "GEGERE": null,
      "TPAREC": this.selectArea
    }  
    return context_data
  }

  getContextDataRelevance() {   
    let context_data = {
      "Year": this.selectYear.getFullYear(),
      "Area": this._api.getUrlParameters().fmguid == "FA0009" ? "CT00010" : "CT00011",
      "TPRUOL": this.selectJob,
      "GEGERE": this.selectManager,
      "TPAREC": this.selectArea,
      "Where": "", //query sql data dal livello
      "TPREGI": this.selectRegion,
      "TimeDelta" : new Date().getTimezoneOffset() //Intl.DateTimeFormat().resolvedOptions().timeZone
    }    

    if (context_data.TPRUOL != "ALL")
      context_data.Where = " AND (TPRUOL = \'" + this.selectJob + "\' OR TPRUOL IS NULL)"
    if (context_data.GEGERE != "ALL")
      context_data.Where += " AND ((GEGERX LIKE \'" + this.selectManager + "%\' AND GEGERX <> \'" + this.selectManager +"\') OR GEGERX IS NULL)";
    if (context_data.Area != "ALL")
      context_data.Where += " AND (TPAREC = \'" + this.selectArea + "\' OR TPAREC IS NULL)";
    if (this.selectLevel == "1")
      context_data.Where += " AND LENGTH(GEGERX) <> LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectLevel == "2")
      context_data.Where += " AND LENGTH(GEGERX) = LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectRegion == "ALL") {
      let regionList:any = [];
      this.context.RegionList.forEach((e:any) => {
        regionList.push(e.Elemento1);
      });
      context_data.TPREGI = regionList;
      //context_data.WHERE += " AND (TPREGI = '" + this.selectRegion + "' OR TPREGI IS NULL)";
    } else {
      context_data.TPREGI = this.selectRegion;
      context_data.Where += "and (TPREGI = \'" + this.selectRegion + "\' OR TPREGI IS NULL)"
    }
    
    return context_data;
  }

  updateYearContext() {
    this.context.Anno = this.selectYear.getFullYear();
    //console.log(this.context.Anno);
  }

  getContextData() {
    let context_data = {
      "ANNO": "" + this.selectYear.getFullYear(),
      "JOB": this.selectJob,
      "GEGERE": null,
      "AREA": this.selectArea,
      "WHERE": "", //query sql data dal livello
      "REGION": ""
    }

    if (context_data.JOB != "ALL")
      context_data.WHERE = " AND (TPRUOL = \'" + this.selectJob + "\' OR TPRUOL IS NULL)"
    if (context_data.GEGERE != "ALL")
      context_data.WHERE += " AND ((GEGERX LIKE \'%\' AND GEGERX <> \'\') OR GEGERX IS NULL)";
    if (context_data.AREA != "ALL")
      context_data.WHERE += " AND (TPAREC = \'" + this.selectArea + "\' OR TPAREC IS NULL)";
    if (this.selectLevel == "1")
      context_data.WHERE += " AND LENGTH(GEGERX) <> LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectLevel == "2")
      context_data.WHERE += " AND LENGTH(GEGERX) = LENGTH(\'" + this.selectLevel + "\') + 2";
    if (this.selectRegion == "ALL") {
      let regionList:any = [];
      this.context.RegionList.forEach((e:any) => {
        regionList.push(e.Elemento1);
      });
      context_data.REGION = regionList;
      //context_data.WHERE += " AND (TPREGI = '" + this.selectRegion + "' OR TPREGI IS NULL)";
    } else {
      context_data.REGION = this.selectRegion;
      context_data.WHERE += "and (TPREGI = \'" + this.selectRegion + "\' OR TPREGI IS NULL)"
    }

    return context_data;
  }

  parseTableRows(table:any, rows:any) {
    let maxLength: number = 0;
    for(let i = 0; i < table.length; ++i){
      if(table[i].length > maxLength){
        maxLength = table[i].length;
      }
    }

    for(let i = 0; i < maxLength; ++i){
      let rowArray = [];
      for(let j = 0; j < table.length; ++j){
        if(table[j][i] === undefined){
          rowArray[j] = "---";
        }else{
          rowArray[j] = table[j][i];
        }
      }
      rows.push(rowArray);
    }

    for(let i = 0; i < rows.length; ++i){
      if(rows[i] === undefined){
        rows.splice(i, 1);
      }
    }
    for(let i = 0; i < rows.length; ++i){
      if(rows[i] === undefined){
        rows.splice(i, 1);
      }
    }
  }

  createPolarChart() {      
    this.radarChartValues = [];
    this.radarChartParameters = [];
    this.valArray = JSON.parse(JSON.stringify(this.context.AverageRows1))
    for(let i = 0; i < this.valArray.length; i++) {
      this.radarChartParameters.push(this.valArray[i].Elemento1);
      this.radarChartValues.push(Number(Number(this.valArray[i].Elemento2).toFixed(2)));
    }
  
    this.radarChartOptions = {
      responsive: true,
      mantainAspectRatio: true,
      scale: {
        min:0,
        max: this.context.MaxValuePolarChart * 100 / 100
      },
      plugins: {
        legend: {
          display: false
        },
      }
    };
    this.radarChartData = {
      labels: this.radarChartParameters,
      datasets: [{
        label: "Data",
        borderColor: "rgba(47, 172, 102)",
        backgroundColor: "rgba(47, 172, 102, 0.2)",
        pointBackgroundColor: "rgba(47, 172, 102)",
        pointBorderColor: "rgba(47, 172, 102)",
        pointHoverBackgroundColor: "rgba(47, 172, 102)",
        pointHoverBorderColor: "rgba(47, 172, 102)",
        data: this.radarChartValues
      }]
    };
    
    const radarChartCanvas = <HTMLCanvasElement>document.getElementById("RadarChart");
    if (this.radarChart != undefined)  this.radarChart.destroy();
    //console.log(radarChartCanvas)
    //console.log(this.radarChartData)
    //console.log(this.radarChartOptions)
    try {
      this.radarChart = new Chart(radarChartCanvas, {
        type: 'radar',
        data: this.radarChartData,
        options: this.radarChartOptions
      });
    } catch (e) {}
  }

}
<component-starteammemberbuttons *ngIf="context.ButtonStarYourTeamMemberActive != 'None'" [context]="context" [selectYear]="selectYear" [selectRegion]="selectRegion" 
                    [selectJob]="selectJob" [selectManager]="selectManager" [selectArea]="selectArea" 
                    [selectLevel]="selectLevel" componentType="CP044">
</component-starteammemberbuttons>


<mat-card>
    <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card"> 
        {{context.Title}}
    </mat-card-title>
    <mat-card-content class="mat-card-content">
        <div class="form-table">
            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelAnno}}</mat-label>
                <input [(ngModel)]="selectYear" matInput [matDatepicker]="picker"/>
                <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                <mat-datepicker #picker [startAt]="selectYear" startView="multi-year" (yearSelected)="chosenYearHandler($event, picker)"></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelRegion}}</mat-label>
                <mat-select  [(ngModel)]="selectRegion" [(value)]="context.Region">
                    <mat-option *ngFor="let e of context.RegionList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelJob}}</mat-label>
                <mat-select [(ngModel)]="selectJob" [(value)]="context.Job">
                    <mat-option *ngFor="let e of context.JobList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelArea}}</mat-label>
                <mat-select [(ngModel)]="selectArea" [(value)]="context.Area">
                    <mat-option *ngFor="let e of context.AreaList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-table" style="justify-content: flex-end;;">
            <div class="formfield"></div>
            <div class="formfield"></div>

            <mat-form-field class="formfield" appearance="fill">
                <mat-label>{{context.LabelManager}}</mat-label>
                <mat-select [(ngModel)]="selectManager" [(value)]="context.Manager">
                    <mat-option *ngFor="let e of context.ManagerList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field class="formfield" appearance="fill">
                <mat-label>Level</mat-label>
                <mat-select [(ngModel)]="selectLevel" [(value)]="context.Level">
                    <mat-option *ngFor="let e of context.LevelList" [value]="e.Elemento1">
                        {{e.Elemento2}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="searchbutton-div">
            <button mat-raised-button class="generic-button" color="accent" [matTooltip]="context.ButtonTooltip" (click)="search()">
                <mat-icon>search</mat-icon> {{context.ButtonLabel}}
            </button>
        </div>

        <button *ngIf="context.table3.length > 0"  mat-raised-button [style]="context.ButtonReportStyle" [matTooltip]="context.ButtonReportTooltip" (click)="report()">
            {{context.ButtonReportLabel}}
        </button>

        <div *ngIf="context.DescriptionTop != '' || context.NoDataFound != ''" class="descriptiontop-div">
            {{ context.table3.length > 0 ? context.DescriptionTop : context.NoDataFound }}
        </div>


        <div *ngIf="context.table3.length > 0" class="parent-container"> 

            <div class="child-container-1">
                <div class="side-string-matrix">
                    {{context.LeadershipAndCulture}}
                </div> 
                <table #matrix class="matrix matrix-and-bottom-string">
                    <tr *ngFor="let item of [].constructor(context.EndColY); let j = index;">
                        <th class="side-number-matrix">{{ (j - context.EndColY) * -1 }}</th>
                        <th [ngClass]="selectedCell.x == i + 1 && selectedCell.y == (j - context.EndColY) * -1 ? 'selected-matrix-th' : 'matrix-th'" 
                            (click)="updateTable4(i + 1, (j - context.EndColY) * -1)"
                            *ngFor="let item of [].constructor(context.EndColX); let i = index;">
                            {{ countElements(context.table3, i + 1,  (j - context.EndColY) * -1) }}<br>
                            <ng-template ngFor let-name [ngForOf]="getNameList(context.table3, i + 1 ,  (j - context.EndColY) * -1)" let-i="index">
                                <div [style.color]="getColor(getWillingness(context.table3, name))">
                                    {{name.split(',')[0]}}  
                                </div>
                            </ng-template>    
                        </th>
                    </tr>
                    <tr>
                        <th></th>
                        <th *ngFor="let item of [].constructor(context.EndColX); let i = index;" class="bottom-number-matrix">{{i + 1}}</th>
                    </tr>
                </table>            
                <div class="bottom-string-matrix matrix-and-bottom-string">{{context.Performance}}</div>
            </div>

            <div class="child-container-2">                
                <div class="matrix-xy-legend">
                    &#8593;  {{context.LeadershipAndCulture}},  
                    &#8594;  {{context.Performance}}
                </div>
                <table class="legenda-table">
                    <tr style="border-bottom:2px solid black;">
                        <th>{{context.RatingScaleLab}}</th>
                        <th>{{context.ColorsLab}}</th>
                    </tr>
                    <tr style="padding:4px;">
                        <th class="ratingscalelegend-th">
                            {{context.RatingScaleLegend}}
                        </th>
                        <th class="colorslegend-th">
                            <span *ngFor="let item of context.Colors; let i = index" [style.color]="item.color"
                                style="font-family:'Roboto'">
                                {{item.label}}<br>
                            </span>
                        </th>
                    </tr>
                </table>
                <br>
                <table class="people-table">
                    <tr class="reallydark-row">
                        <th *ngIf="context.Name != ''" class="people-table-th">{{context.Name}}</th>
                        <th *ngIf="context.LeadershipAndCulture != ''" class="people-table-th">{{context.LeadershipAndCulture.replace("+", " + ")}}</th>
                        <th *ngIf="context.Performance != ''" class="people-table-th">{{context.Performance}}</th>
                        <th *ngIf="context.Willingness != ''" class="people-table-th">{{context.Willingness}}</th>
                        <th *ngIf="context.Mobility != ''" class="people-table-th">{{context.Mobility}}</th>
                    </tr>
                    <ng-template ngFor let-item [ngForOf]="tableData" let-i="index">
                        <ng-container *ngIf="i <= context.MaxGridInitialData">
                            <tr class="light-row" *ngIf="i % 2 == 0">
                                <ng-container *ngFor="let number of [].constructor(tableData[0].length); let j = index;">
                                    <th *ngIf="item[j] != null && item[j] != 0" class="people-table-th">
                                        {{item[j]}}
                                    </th>
                                </ng-container>  
                            </tr>
                            <tr class="dark-row" *ngIf="i % 2 != 0">
                                <ng-container *ngFor="let number of [].constructor(tableData[0].length); let j = index;">
                                    <th *ngIf="item[j] != null && item[j] != 0" class="people-table-th">
                                        {{item[j]}}
                                    </th>
                                </ng-container>
                            </tr>  
                        </ng-container>  
                        <ng-container *ngIf="i == context.MaxGridInitialData">
                            <tr class="dark-row">
                                <ng-container *ngFor="let number of [].constructor(tableData[0].length); let j = index;">
                                    <th *ngIf="item[j] != null && item[j] != 0" class="people-table-th">
                                        ...
                                    </th>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </ng-template>   
                </table>
            </div>
            
        </div>

        <div *ngIf="context.DescriptionBottom != '' && context.table3.length > 0" class="descriptiontop-div">
            {{context.DescriptionBottom}}
        </div>
    </mat-card-content>
</mat-card>
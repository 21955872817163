<div [style.display]="showMappingDiv ? 'block' : 'none'" @inOutAnimation style="position:absolute" class="card-background">
    <mat-card *ngIf="selectedRows2 != null && selectedRows2.length == 1 && showMappingDiv" style="margin:2.5%;max-height:94%!important;position: relative;overflow: hidden;">
        <mat-card-title class="title-card" style="background-color:white">
            <mat-icon>table</mat-icon>
            {{labels.datamappingLabel}}
        </mat-card-title>
        <mat-card-content style="background-color:white; overflow-y: scroll;max-height: 80vh;">
            <mat-card style="margin-bottom:8px">
                <mat-card-title class="title-card" style="height: 36px;font-size: 16px !important;">{{labels.employeecolumnsLabel}}</mat-card-title>
                <mat-card-content style="display:flex; flex-direction: row; gap:8px; justify-content: center;">
                    
                    <mat-form-field *ngIf="selectedRows2[0].DataMapping.EmployeeColumns.Key != undefined" appearance="fill" color="accent" style="width:24%">
                        <mat-label>{{labels.keyLabel}}</mat-label>
                        <mat-select *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders != undefined" type="text" (selectionChange)="selectedRows2[0].DataMapping.EmployeeColumns.Key = $event.value" 
                            [value]="selectedRows2[0].DataMapping.EmployeeColumns.Key">
                            <mat-option *ngFor="let e of selectedRows2[0].DataMapping.AllColumnsHeaders" [value]="e">{{e}}</mat-option>
                        </mat-select>
                        <input matInput *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders == undefined" [(ngModel)]="selectedRows2[0].DataMapping.EmployeeColumns.Key" type="text">
                    </mat-form-field>

                    <mat-form-field *ngIf="selectedRows2[0].DataMapping.EmployeeColumns.FirstName != undefined" appearance="fill" color="accent" style="width:24%">
                        <mat-label>{{labels.Name}}</mat-label>
                        <mat-select *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders != undefined" type="text" (selectionChange)="selectedRows2[0].DataMapping.EmployeeColumns.FirstName = $event.value" 
                            [value]="selectedRows2[0].DataMapping.EmployeeColumns.FirstName">
                            <mat-option *ngFor="let e of selectedRows2[0].DataMapping.AllColumnsHeaders" [value]="e">{{e}}</mat-option>
                        </mat-select>
                        <input matInput *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders == undefined" [(ngModel)]="selectedRows2[0].DataMapping.EmployeeColumns.FirstName" type="text">
                    </mat-form-field>

                    <mat-form-field *ngIf="selectedRows2[0].DataMapping.EmployeeColumns.FamilyName != undefined" appearance="fill" color="accent" style="width:24%">
                        <mat-label>{{labels.familynameLabel}}</mat-label>
                        <mat-select *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders != undefined" type="text" (selectionChange)="selectedRows2[0].DataMapping.EmployeeColumns.FamilyName = $event.value" 
                            [value]="selectedRows2[0].DataMapping.EmployeeColumns.FamilyName">
                            <mat-option *ngFor="let e of selectedRows2[0].DataMapping.AllColumnsHeaders" [value]="e">{{e}}</mat-option>
                        </mat-select>
                        <input matInput *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders == undefined" [(ngModel)]="selectedRows2[0].DataMapping.EmployeeColumns.FamilyName" type="text">
                    </mat-form-field>

                    <mat-form-field *ngIf="selectedRows2[0].DataMapping.EmployeeColumns.Company != undefined" appearance="fill" color="accent" style="width:24%">
                        <mat-label>{{labels.Company}}</mat-label>
                        <mat-select *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders != undefined" type="text" (selectionChange)="selectedRows2[0].DataMapping.EmployeeColumns.Company = $event.value" 
                            [value]="selectedRows2[0].DataMapping.EmployeeColumns.Company">
                            <mat-option *ngFor="let e of selectedRows2[0].DataMapping.AllColumnsHeaders" [value]="e">{{e}}</mat-option>
                        </mat-select>
                        <input matInput *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders == undefined" [(ngModel)]="selectedRows2[0].DataMapping.EmployeeColumns.Company" type="text">
                    </mat-form-field>

                </mat-card-content>
            </mat-card>
            
            <div style="display:flex; flex-direction: row; gap:8px;margin-bottom:8px;">
                <mat-card [style.width]="selectedRows2[0].DataMapping.FieldKeyColumnHeader == undefined ? '100%' : '66%'">
                    <mat-card-title class="title-card" style="height: 36px;font-size: 16px !important;">{{labels.itemcolumnsLabel}}</mat-card-title>
                    <mat-card-content style="display:flex; flex-direction: row; gap:8px; align-items: flex-start;" [style.justify-content]="selectedRows2[0].DataMapping.FieldKeyColumnHeader != undefined ? 'space-evenly' : 'center'">
                        
                        <div style="display:flex; flex-direction: column; gap:8px">
                            <mat-form-field *ngIf="selectedRows2[0].DataMapping.ItemKeysColumnHeader != undefined" style="margin-bottom:-16px" appearance="fill" color="accent">
                                <mat-label>{{labels.keysLabel}}</mat-label>
                                <input matInput [(ngModel)]="selectedRows2[0].DataMapping.ItemKeysColumnHeader" type="text">
                                <mat-icon matSuffix [matTooltip]="labels.keyscolumnsubstringLabel" style="opacity:.3">help</mat-icon>
                            </mat-form-field>
        
                            <mat-form-field *ngIf="selectedRows2[0].DataMapping.ItemValuesColumnHeader != undefined" appearance="fill" color="accent">
                                <mat-label>{{labels.valuesLabel}}</mat-label>
                                <mat-select *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders != undefined" type="text" (selectionChange)="selectedRows2[0].DataMapping.ItemValuesColumnHeader = $event.value" 
                                    [value]="selectedRows2[0].DataMapping.ItemValuesColumnHeader" >
                                    <mat-option *ngFor="let e of selectedRows2[0].DataMapping.AllColumnsHeaders" [value]="e">{{e}}</mat-option>
                                </mat-select>
                                <input matInput *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders == undefined" [(ngModel)]="selectedRows2[0].DataMapping.ItemValuesColumnHeader" type="text">
                            </mat-form-field>

                            <mat-form-field *ngIf="selectedRows2[0].DataMapping.ItemValuesColumnHeaders != undefined" appearance="fill" color="accent">
                                <mat-label>{{labels.valuesLabel}}</mat-label>
                                <mat-select *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders != undefined" type="text" (selectionChange)="selectedRows2[0].DataMapping.ItemValuesColumnHeaders = $event.value" 
                                    [value]="selectedRows2[0].DataMapping.ItemValuesColumnHeaders" multiple>
                                    <mat-option *ngFor="let e of selectedRows2[0].DataMapping.AllColumnsHeaders" [value]="e">{{e}}</mat-option>
                                </mat-select>
                                <input matInput *ngIf="selectedRows2[0].DataMapping.AllColumnsHeaders == undefined" [(ngModel)]="selectedRows2[0].DataMapping.ItemValuesColumnHeaders" type="text">
                            </mat-form-field>
        
                            <mat-form-field *ngIf="selectedRows2[0].DataMapping.ItemValuesColumnOffset != undefined" appearance="fill" color="accent">
                                <mat-label>{{labels.valuesoffsetLabel}}</mat-label>
                                <input matInput [(ngModel)]="selectedRows2[0].DataMapping.ItemValuesColumnOffset" type="number" [min]="-10" [max]="10">
                            </mat-form-field>
                        </div>
                        
                        <div style="display:flex; flex-direction: column; gap:8px; align-items: center">
                            <span>{{labels.sourcedateLabel}}</span>
                            <mat-button-toggle-group (change)="changeDate($event)" [value]="dateDefinitionFromHeader">
                                <mat-button-toggle [value]="true">{{labels.columnLabel}}</mat-button-toggle>
                                <mat-button-toggle [value]="false">{{labels.ResultsFilename}}</mat-button-toggle>
                            </mat-button-toggle-group>
                            <mat-form-field appearance="fill" color="accent" style="margin-bottom:-16px">
                                <mat-label *ngIf="this.dateDefinitionFromHeader">{{labels.valuesLabel}}</mat-label>
                                <mat-label *ngIf="!this.dateDefinitionFromHeader">Eval</mat-label>
                                <input *ngIf="this.dateDefinitionFromHeader" matInput [(ngModel)]="selectedRows2[0].DataMapping.ItemDateColumnHeader" type="text">
                                <input *ngIf="!this.dateDefinitionFromHeader" matInput [(ngModel)]="selectedRows2[0].DataMapping.ItemDateValue.Eval" type="text">
                                <mat-icon *ngIf="this.dateDefinitionFromHeader" matSuffix [matTooltip]="labels.datecolumnsLabel" style="opacity:.3">help</mat-icon>
                                <mat-icon *ngIf="!this.dateDefinitionFromHeader" matSuffix [matTooltip]="labels.filenamewildcardLabel" style="opacity:.3">help</mat-icon>
                            </mat-form-field>
                            <mat-form-field *ngIf="selectedRows2[0].DataMapping.DateFormat != undefined" appearance="fill" color="accent" style="width:100%">
                                <mat-label>{{labels.formatLabel}}</mat-label>
                                <input matInput [(ngModel)]="selectedRows2[0].DataMapping.DateFormat" type="text">
                                <mat-icon matSuffix [matTooltip]="labels.dateformattooltipLabel" style="opacity:.3">help</mat-icon>
                            </mat-form-field>
                        </div>
                    </mat-card-content>    
                </mat-card>

                <mat-card *ngIf="selectedRows2[0].DataMapping.FieldKeyColumnHeader != undefined" style="width:33%">
                    <mat-card-title class="title-card" style="height: 36px;font-size: 16px !important;">Field Column</mat-card-title>
                    <mat-card-content style="display:flex; flex-direction: column; gap:8px; justify-content: flex-start; height:250px">
                        
                        <mat-form-field *ngIf="selectedRows2[0].DataMapping.FieldKeyColumnHeader != undefined" style="margin-bottom:-16px" appearance="fill" color="accent">
                            <mat-label>{{labels.keysLabel}}</mat-label>
                            <input matInput [(ngModel)]="selectedRows2[0].DataMapping.FieldKeyColumnHeader" type="text">
                            <mat-error>Error!</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="selectedRows2[0].DataMapping.FieldValuesColumnOffset != undefined" appearance="fill" color="accent">
                            <mat-label>{{labels.valuesoffsetLabel}}</mat-label>
                            <input matInput [(ngModel)]="selectedRows2[0].DataMapping.FieldValuesColumnOffset" type="number" [min]="-10" [max]="10">
                            <mat-error>Error!</mat-error>
                        </mat-form-field>

                    </mat-card-content>
                </mat-card>

            </div>

            <mat-card *ngIf="selectedRows2[0].DataMapping.ColumnsAlteration != undefined || selectedRows2[0].DataMapping.ColumnSuffix != undefined">
                <mat-card-title class="title-card" style="height: 36px;font-size: 16px !important;">{{labels.columnsalterationLabel}}</mat-card-title>
                <mat-card-content>
                    
                    <mat-card *ngIf="selectedRows2[0].DataMapping.ColumnsAlteration != undefined">
                        <mat-card-content style="background-color:white; ">
                            <span *ngIf="selectedRows2[0].DataMapping.ColumnsAlteration.length == 0" style="width:100%; text-align:center; font-style:italic">
                                {{labels.noelementsLabel}}
                            </span>
                            <table *ngIf="selectedRows2[0].DataMapping.ColumnsAlteration.length > 0" style="width:100%">
                                <tr>
                                    <th>Input</th>
                                    <th>Output</th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let element of selectedRows2[0].DataMapping.ColumnsAlteration, index as i">
                                    <td>                                     
                                        <ng-container *ngIf="element.AllColumnsHeaders != undefined">    
                                            <mat-form-field style="width:95%" appearance="fill">                                           
                                                <mat-label>{{labels.Company}}</mat-label>
                                                <mat-select required [(ngModel)]="element.Input">
                                                    <mat-option *ngFor="let table of context.companiesList" style="width: 100%;" [value]="table.Code" (click)="element.Input = table.Code">{{table.Code}} - {{table.Name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="element.AllColumnsHeaders == undefined">                                            
                                            <mat-form-field style="width:95%" appearance="fill">   
                                                <mat-label>{{labels.codeLabel}}</mat-label>
                                                <input matInput [(ngModel)]="element.Input">
                                            </mat-form-field>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <mat-form-field style="width:100%" appearance="fill">
                                            <mat-label>{{labels.codeLabel}}</mat-label>
                                            <input matInput [(ngModel)]="element.Output">
                                        </mat-form-field>
                                    </td>
                                    <td style="display: flex;
                                        align-items: center;
                                        max-width: 64px;
                                        height: 64px;
                                        margin-top: -2px;
                                        justify-content: center;">
                                        <button mat-mini-fab (click)="removeColumnalteration(selectedFileIndex)" style="transform:scale(1)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                            
                            <div class="addcompanymapping-div">
                                <button mat-mini-fab (click)="addColumnalteration(selectedFileIndex)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-form-field *ngIf="selectedRows2[0].DataMapping.ColumnSuffix != undefined" style="margin-bottom:-16px; margin-top:16px" appearance="fill" color="accent">
                        <mat-label>{{labels.columnssuffixLabel}}</mat-label>
                        <input matInput [(ngModel)]="selectedRows2[0].DataMapping.ColumnSuffix" type="text">
                        <mat-error>Error!</mat-error>
                    </mat-form-field>
                    
                </mat-card-content>
            </mat-card>

            <div style="display:flex; justify-content: flex-end; margin-top: 16px;">
                <button mat-raised-button color="basic" (click)="showMappingDiv = false" style="margin-right:8px">
                    <mat-icon style="margin-top:-4px">clear</mat-icon> {{labels.closeLabel}}
                </button>   
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div [style.display]="showFiletypesDescriptionDiv ? 'flex' : 'none'" @inOutAnimation style="position:absolute" class="card-background">
    <mat-card style="width: 70%">
        <mat-card-title class="title-card">{{labels.filetypesLabel}}</mat-card-title>
        <mat-card-content>
            <ol>
                <li>{{labels.filetype1Label}}</li>
                <li>{{labels.filetype2Label}}</li>
                <li>{{labels.filetype3Label}}</li>
                <li>{{labels.filetype4Label}}</li>
                <li>{{labels.filetype5Label.replace("{{filetype2Label}}", labels.filetype2Label)}}</li>
            </ol>    
            <div style="display:flex; justify-content: flex-end; margin-top:8px">        
                <button mat-raised-button color="basic" (click)="showFiletypesDescriptionDiv = false" style="margin-right:8px">
                    <mat-icon style="margin-top:-4px">clear</mat-icon> {{labels.closeLabel}}
                </button>  
            </div> 
        </mat-card-content>
    </mat-card>
</div>

<div style="display:flex; justify-content: center;">
    <div *ngIf="context == undefined || loading" class="spinner-div">
        <mat-spinner></mat-spinner>
    </div>
</div>

<mat-tab-group *ngIf="!loading" class="tab-group" color="accent">
    <mat-tab [label]="labels.compensationitemsLabel" *ngIf="context.config.Items != undefined"> 
        <p-table #dt *ngIf="recordsColumns.length > 0" [rowHover]="false" [rows]="visibleRows" [showCurrentPageReport]="true" styleClass="p-datatable-sm p-datatable-striped" 
            [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" [first]="firstRow" responsiveLayout="scroll" [paginator]="false" [scrollHeight]="'flex'" [scrollable]="true"
            [columns]="recordsColumns" [value]="context.config.Items" sortMode="single" [(selection)]="selectedRows">

            <ng-template pTemplate="caption" id="hideIcons">
                <div class="table-header" id="hideIcons">
                    <div class="table_buttons">
                        <button mat-raised-button [disabled]="selectedRows.length > 0" (click)="addRecord(0)" color="accent">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                        <button mat-raised-button [disabled]="selectedRows.length == 0" (click)="removeRecords(0)" color="accent">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                    </div>
                    <div class="search-div">
                        <span class="p-input-icon-right searchbox-span" id="hideIcons">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" [placeholder]="labels.searchLabel" class="search-input"/>
                        </span>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="column-header" [pSortableColumn]="recordsColumns[0]" pResizableColumn>
                        <span class="column-title">
                            Input
                        </span>
                    </th>
                    <th class="column-header" [pSortableColumn]="recordsColumns[1]" pResizableColumn>
                        <span class="column-title">
                            Output
                        </span>
                    </th>
                    <th class="column-header" [pSortableColumn]="recordsColumns[2]" pResizableColumn>
                        <span class="column-title">
                            {{labels.typeLabel}}
                        </span>
                    </th>
                    <th class="column-header" [pSortableColumn]="recordsColumns[3]" pResizableColumn>
                        <span class="column-title">
                            {{labels.decimalsLabel}}
                        </span>
                    </th>
                    <th class="column-header" [pSortableColumn]="recordsColumns[4]" pResizableColumn>
                        <span class="column-title">
                            {{labels.labelLabel}}
                        </span>
                    </th>
                    <!--
                    <th class="column-header" *ngFor="let item of [].constructor(recordsColumns.length); let i = index" [pSortableColumn]="recordsColumns[i]" pResizableColumn>
                        <span class="column-title">
                            {{recordsColumns[i]}}
                        </span>
                    </th>
                    -->
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr [pSelectableRowDisabled]="rowData['Editable'] != undefined && !rowData['Editable']" [pSelectableRow]="rowData">
                    <td style="width:50px !important">
                        <p-tableCheckbox [value]="rowData" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']"></p-tableCheckbox>
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns[0]]" [pEditableColumnField]="recordsColumns[0]" [style.width]="itemKeyColumnHeaders.length > 0 ? '230px !important' : '400px !important'">
                        <p-multiSelect *ngIf="itemKeyColumnHeaders.length > 0" #itemColumns [options]="itemKeyColumnHeaders" [(ngModel)]="rowData[recordsColumns[0]]" placeholder="Select columns" [maxSelectedLabels]="1" (keydown.space)="$event.stopPropagation()" styleClass="w-full md:w-80" appendTo="body"/>
                        <p-cellEditor *ngIf="itemKeyColumnHeaders.length == 0">
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']" [(ngModel)]="rowData[recordsColumns[0]]" (keydown.space)="$event.stopPropagation()" (keydown.ArrowLeft)="$event.stopPropagation()" (keydown.ArrowRight)="$event.stopPropagation()" style="width:100%">
                            </ng-template>                                        
                            <ng-template pTemplate="output">
                                <div style="max-width:230px">{{ rowData[recordsColumns[0]] }}</div>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns[1]]" [pEditableColumnField]="recordsColumns[1]" style="width:230px !important">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']" [(ngModel)]="rowData[recordsColumns[1]]">
                            </ng-template>                                        
                            <ng-template pTemplate="output">
                                {{ rowData[recordsColumns[1]] }}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns[2]]" [pEditableColumnField]="recordsColumns[2]" style="width:230px !important">
                        <p-dropdown appendTo="body" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']"
                            [options]="context.itemTypes"
                            [(ngModel)]="rowData[recordsColumns[2]]" 
                            optionValue="Key" dataKey="Key"
                            optionLabel="Value" 
                            placeholder="Type" />
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns[3]]" [pEditableColumnField]="recordsColumns[3]" style="width:50px !important">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="number" [max]="3" [min]="0" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']" [(ngModel)]="rowData[recordsColumns[3]]" style="width:50px !important">
                            </ng-template>                                        
                            <ng-template pTemplate="output">
                                {{ rowData[recordsColumns[3]] }}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns[4]]" [pEditableColumnField]="recordsColumns[4]" style="width:230px !important">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']" [(ngModel)]="rowData[recordsColumns[4]]" (keydown.space)="$event.stopPropagation()" (keydown.ArrowLeft)="$event.stopPropagation()" (keydown.ArrowRight)="$event.stopPropagation()">
                            </ng-template>                                        
                            <ng-template pTemplate="output">
                                {{ rowData[recordsColumns[4]] }}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>         
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="context.config.Items.length == 0" >
                    <td [attr.colspan]="recordsColumns.length" class="nodata-cell">
                        No data found.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </mat-tab>


    <mat-tab label="Files" *ngIf="context.config.Files != undefined">
        <p-table #dt2 [rowHover]="false" [rows]="10" styleClass="p-datatable-sm p-datatable-striped" responsiveLayout="scroll"
            [reorderableColumns]="false" [resizableColumns]="true" [scrollHeight]="'flex'" [paginator]="false" [scrollable]="true" [metaKeySelection]="false"
            [columns]="recordsColumns2" [value]="context.config.Files" selectionMode="single" [(selection)]="selectedRows2" (onRowSelect)="onFileSelect($event)" (onRowUnselect)="onFileUnselect($event)">

            <ng-template pTemplate="caption" id="hideIcons">
                <div class="table-header" id="hideIcons">
                    <div class="table_buttons">
                        <button mat-raised-button [disabled]="selectedRows2 != undefined && selectedRows2.length > 0" (click)="addRecord(1)" color="accent">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                        <button mat-raised-button [disabled]="selectedRows2 != undefined && selectedRows2.length != 1" (click)="removeRecords(1)" color="accent">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                        <button mat-raised-button [disabled]="selectedRows2 != undefined && selectedRows2.length != 1" (click)="openMappingCard()" color="accent">
                            {{labels.mapLabel}}
                        </button>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        
                    </th>
                    
                    <th class="column-header" style="width:100%">
                        <span class="column-title">
                            {{labels.Name}}
                        </span>
                    </th>
                    <th class="column-header">
                        <span class="column-title">
                            {{labels.typeLabel}}
                        </span>
                        <button matTooltip="Click for more details" mat-mini-fab (click)="this.showFiletypesDescriptionDiv = true" style="transform:scale(.5)">
                            <mat-icon style="margin-top:-4px; margin-left:-2px">question_mark</mat-icon>
                        </button>
                    </th>
                    <th class="column-header">
                        <span class="column-title">
                            {{labels.formatLabel}}
                        </span>
                    </th>
                    <th class="column-header">
                        <span class="column-title">
                            {{labels.separatorLabel}}
                        </span>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr [pSelectableRowDisabled]="rowData['Editable'] != undefined && !rowData['Editable']" [pSelectableRow]="rowData">
                    <td style="width:50px !important" (click)="$event.stopImmediatePropagation();" >
                        <p-tableCheckbox [value]="rowData" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']"></p-tableCheckbox>
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns2[0]]" [pEditableColumnField]="recordsColumns2[0]" style="width:230px !important" (click)="$event.stopImmediatePropagation();"  >
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']" [(ngModel)]="rowData[recordsColumns2[0]]" (keydown.space)="$event.stopPropagation()" (keydown.ArrowLeft)="$event.stopPropagation()" (keydown.ArrowRight)="$event.stopPropagation()" >  
                            </ng-template>                                        
                            <ng-template pTemplate="output">
                                <span>{{ rowData[recordsColumns2[0]] }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns2[1]]" [pEditableColumnField]="recordsColumns2[1]" (click)="$event.stopImmediatePropagation();">
                        <p-dropdown appendTo="body" [disabled]="rowData['Editable'] != undefined && !rowData['Editable']"
                            [options]="context.fileTypes" (onChange)="changeFileType(i)"
                            [(ngModel)]="rowData[recordsColumns2[1]]" 
                            optionValue="Key" dataKey="Key"
                            optionLabel="Value" 
                            placeholder="Type" />
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns2[2]]" [pEditableColumnField]="recordsColumns2[2]" (click)="$event.stopImmediatePropagation();">
                        <p-dropdown appendTo="body"
                            [options]="context.fileFormats"
                            [(ngModel)]="rowData[recordsColumns2[2]]" 
                            optionValue="Key" dataKey="Key"
                            optionLabel="Value" 
                            placeholder="Type" />
                    </td>
                    <td [pEditableColumn]="rowData[recordsColumns2[3]]" [pEditableColumnField]="recordsColumns2[3]" style="width:50px !important" (click)="$event.stopImmediatePropagation();">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="rowData[recordsColumns2[3]]" style="width:20px !important" (keydown.ArrowLeft)="$event.stopPropagation()" (keydown.ArrowRight)="$event.stopPropagation()" >
                            </ng-template>                                        
                            <ng-template pTemplate="output">
                                <span style="font-weight: bold;">{{ rowData[recordsColumns2[3]] }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>         
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="context.config.Files.length == 0" >
                    <td [attr.colspan]="recordsColumns2.length" class="nodata-cell">
                        No data found.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </mat-tab>

    <mat-tab [label]="labels.preferencesLabel">
        <mat-card *ngIf="context.config.Settings.Process.AutomaticIncreases != undefined" style="margin:12px">
            <mat-card-content style="display: flex;flex-direction: row;background-color:#ddf4ff">
                <mat-icon>info</mat-icon>
                <span style="width:100%; text-align:center; font-style:italic">{{labels.automaticincreasenoticeLabel}}</span>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="context.config.Settings.Process.NextReviewDates != undefined" style="margin:12px">
            <mat-card-content style="display: flex;flex-direction: row;background-color:#ddf4ff">
                <mat-icon>info</mat-icon>
                <span style="width:100%; text-align:center; font-style:italic">{{labels.nextreviewdatesLabel}}</span>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="context.config.Settings.ColumnsInsertion != undefined && false" style="margin:12px">
            <mat-card-title class="title-card-slim">{{labels.columnsinsertionLabel}}</mat-card-title>
            <mat-card-content>
                <span *ngIf="context.config.Settings.ColumnsInsertion.length == 0" style="width:100%; text-align:center; font-style:italic">
                    {{labels.noelementsLabel}}
                </span>
                <table *ngIf="context.config.Settings.ColumnsInsertion.length > 0" style="width:100%">
                    <tr>
                        <th>{{labels.headerLabel}}</th>
                        <th>{{labels.typeLabel}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let element of context.config.Settings.ColumnsInsertion, index as i">
                        <td>                                                      
                            <mat-form-field style="width:95%" appearance="fill">   
                                <mat-label>{{labels.codeLabel}}</mat-label>
                                <input matInput [(ngModel)]="element.Label">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field style="width:100%" appearance="fill">
                                <mat-label>{{labels.typeLabel}}</mat-label>
                                <input matInput [(ngModel)]="element.Type">
                            </mat-form-field>
                        </td>
                        <td style="display: flex;
                            align-items: center;
                            max-width: 64px;
                            height: 64px;
                            margin-top: -2px;
                            justify-content: center;">
                            <button mat-mini-fab (click)="removeColumnsinsertion(i)" style="transform:scale(1)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
                
                <div class="addcompanymapping-div">
                    <button mat-mini-fab (click)="addColumnsinsertion()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="context.config.Settings.FieldsInsertion != undefined && false" style="margin:12px">
            <mat-card-title class="title-card-slim">{{labels.fieldsinsertionLabel}}</mat-card-title>
            <mat-card-content>
                <span *ngIf="context.config.Settings.FieldsInsertion.length == 0" style="width:100%; text-align:center; font-style:italic">
                    {{labels.noelementsLabel}}
                </span>
                <table *ngIf="context.config.Settings.FieldsInsertion.length > 0" style="width:100%">
                    <tr>
                        <th>{{labels.labelLabel}}</th>
                        <th>{{labels.typeLabel}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let element of context.config.Settings.FieldsInsertion, index as i">
                        <td>                                                      
                            <mat-form-field style="width:95%" appearance="fill">   
                                <mat-label>{{labels.codeLabel}}</mat-label>
                                <input matInput [(ngModel)]="element.Label">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field style="width:100%" appearance="fill">
                                <mat-label>{{labels.typeLabel}}</mat-label>
                                <input matInput [(ngModel)]="element.Type">
                            </mat-form-field>
                        </td>
                        <td style="display: flex;
                            align-items: center;
                            max-width: 64px;
                            height: 64px;
                            margin-top: -2px;
                            justify-content: center;">
                            <button mat-mini-fab (click)="removeFieldsinsertion(i)" style="transform:scale(1)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
                
                <div class="addcompanymapping-div">
                    <button mat-mini-fab (click)="addFieldsinsertion()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                
            </mat-card-content>
        </mat-card>

        <mat-card @inOutAnimation *ngIf="context.config.Settings.CompaniesDecodings != undefined" style="margin:12px">
            <mat-card-title class="title-card-slim">{{labels.companiesmappingLabel}}</mat-card-title>
            <mat-card-content>
                <span *ngIf="context.config.Settings.CompaniesDecodings.length == 0" style="width:100%; text-align:center; font-style:italic">
                    {{labels.nocompanymappingsLabel}}
                </span>
                <table *ngIf="context.config.Settings.CompaniesDecodings.length > 0" style="width:100%">
                    <tr>
                        <th>Input (Cezanne)</th>
                        <th>Output</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let element of context.config.Settings.CompaniesDecodings, index as i">
                        <td>                                     
                            <ng-container *ngIf="context.companiesList.length > 0">    
                                <mat-form-field style="width:95%" appearance="fill">                                           
                                    <mat-label>{{labels.Company}}</mat-label>
                                    <mat-select required [(ngModel)]="element.Input">
                                        <mat-option *ngFor="let table of context.companiesList" style="width: 100%;" [value]="table.Code" (click)="element.Input = table.Code">{{table.Code}} - {{table.Name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container *ngIf="context.companiesList.length == 0">                                            
                                <mat-form-field style="width:95%" appearance="fill">   
                                    <mat-label>{{labels.codeLabel}}</mat-label>
                                    <input matInput [(ngModel)]="element.Input">
                                </mat-form-field>
                            </ng-container>
                        </td>
                        <td>
                            <mat-form-field style="width:100%" appearance="fill">
                                <mat-label>{{labels.codeLabel}}</mat-label>
                                <input matInput [(ngModel)]="element.Output">
                            </mat-form-field>
                        </td>
                        <td style="display: flex;
                            align-items: center;
                            max-width: 64px;
                            height: 64px;
                            margin-top: -2px;
                            justify-content: center;">
                            <button mat-mini-fab (click)="removeCompanyDecoding(i)" style="transform:scale(1)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
                
                <div class="addcompanymapping-div">
                    <button *ngIf="(context.companiesList.length > 0 && context.config.Settings.CompaniesDecodings.length < context.companiesList.length) || context.companiesList.length == 0" mat-mini-fab (click)="addCompanyDecoding()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                
            </mat-card-content>
        </mat-card>
        <div class="sortandmappings-div">
            <mat-card @inOutAnimation *ngIf="context.config.Extraction.Sorting != undefined" style="margin:12px; margin-right:0px; flex:50%">
                <mat-card-title class="title-card-slim">{{labels.extractionsortLabel}}</mat-card-title>
                <mat-card-content style="min-height:144px">
                    <div class="sort-div">
                        <span class="innercardtitle-span">{{labels.elementsLabel}}</span>
                        <mat-chip-set>
                            <ng-container *ngFor="let element of context.config.Extraction.Sorting; index as i">
                                <mat-chip (removed)="removeSortField(context.config.Extraction.Sorting, i)">
                                    <b>{{i}})</b> {{element.Label}}
                                    <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip> 
                            </ng-container>                                      
                            <button mat-mini-fab style="transform:scale(.5)" color="accent" (click)="addSortField()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-chip-set>
                    </div>                        
                </mat-card-content>
            </mat-card>

            <mat-card style="margin:12px!important; flex:50%">
                <mat-card-title class="title-card-slim">{{labels.payrollcodeoriginLabel}}</mat-card-title>
                <mat-card-content>
                    <mat-radio-group [(ngModel)]="context.config.Settings.PayrollcodeSource" (click)="changePayrollcodeSource($event)" style="display:flex; flex-direction: column; gap: 8px">
                        <mat-radio-button [value]=0 class="payrollsource-radio">{{context.config.Settings.PayrollcodeSources[0].Label}}</mat-radio-button>
                        <mat-radio-button [value]=1 class="payrollsource-radio">{{context.config.Settings.PayrollcodeSources[1].Label}}</mat-radio-button>
                        <mat-radio-button [value]=2 class="payrollsource-radio">{{context.config.Settings.PayrollcodeSources[2].Label}}</mat-radio-button>
                        <mat-radio-button [value]=3 class="payrollsource-radio">{{context.config.Settings.PayrollcodeSources[3].Label}}</mat-radio-button>
                        <mat-radio-button [value]=-1 class="payrollsource-radio">Custom</mat-radio-button>
                    </mat-radio-group>
                </mat-card-content>
            </mat-card>
        </div>
            
        <div class="sortandmappings-div">
            <mat-card style="margin:12px!important; flex:100%">            
                <mat-card-title class="title-card-slim">{{labels.extractionfilterLabel}}</mat-card-title>
                <ng-container *ngIf="isArray(context.config.Extraction.Filter) && filterType < 0">
                    <mat-card-content class="filter-cardcontent">    
                        <span style="font-style:italic">{{labels.filterdescriptionLabel}}</span><br>
                        <component-cp063 [evalObj]="context.config.Extraction.Filter" [apiList]="context.apiList" [labels]="labels" style="width:100%"></component-cp063>
                    </mat-card-content> 
                </ng-container>
                <ng-container *ngIf="isArray(context.config.Extraction.Filter) && filterType >= 0">
                    <mat-card-content>                        
                        <span style="font-style:italic">{{labels.filterdescriptionLabel}}</span><br><br>
                        <mat-radio-group [(ngModel)]="filterType" (change)="changeExtractionFilter($event)" style="display:flex; flex-direction: column; gap:8px">
                            <mat-radio-button [value]=0 class="payrollsource-radio">{{labels.salarylevelfilterLabel}}</mat-radio-button>
                            <mat-radio-button [value]=1 class="payrollsource-radio">
                                {{labels.ccnlfilterLabel}}
                                <mat-form-field appearance="fill" style="margin-bottom:-6px; margin-left:8px" (click)="$event.stopImmediatePropagation();" >
                                    <mat-label>{{labels.valueLabel}}</mat-label>
                                    <input matInput [(ngModel)]="filterFieldValue" (click)="$event.stopImmediatePropagation();" (keydown.Space)="$event.stopImmediatePropagation();">
                                </mat-form-field>
                            </mat-radio-button>
                            <mat-radio-button [value]=2 class="payrollsource-radio"> 
                                <span>
                                    {{labels.salarylevelccnlfilterLabel}}
                                    <mat-form-field appearance="fill" (click)="$event.stopImmediatePropagation();" style="margin-bottom:-6px; margin-left:8px">
                                        <mat-label>{{labels.valueLabel}}</mat-label>
                                        <input matInput [(ngModel)]="filterFieldValue" (click)="$event.stopImmediatePropagation();"  (keydown.Space)="$event.stopImmediatePropagation();">
                                    </mat-form-field>
                                </span>
                            </mat-radio-button>
                            <mat-radio-button [value]=3 class="payrollsource-radio">{{labels.noneLabel}}</mat-radio-button>
                        </mat-radio-group>
                    </mat-card-content> 
                </ng-container>
                <ng-container *ngIf="!isArray(context.config.Extraction.Filter)">
                    <mat-card-content class="filter-cardcontent">                    
                        <div class="filter-div">
                            <span class="innercardtitle-span">Input</span>
                            <mat-chip-set>
                                <ng-container *ngFor="let input of context.config.Extraction.Filter.Input, index as i">
                                    <mat-chip (removed)="removeFilterField(context.config.Extraction.Filter.Input, i)">
                                        <b>{{i}})</b> {{input.Label}}
                                        <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip> 
                                </ng-container>
                                <button mat-mini-fab style="transform:scale(.5)" color="accent" (click)="addFilterField()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-chip-set>
                        </div>
                        <br>
                        <mat-form-field appearance="fill" *ngIf="context.config.Extraction.Filter != undefined" style="width:100%">
                            <mat-label>Eval</mat-label>
                            <input matInput [(ngModel)]="context.config.Extraction.Filter.Eval">
                        </mat-form-field>
                    </mat-card-content> 
                </ng-container>
                <!--
                <div style="display:flex; flex-direction: column">
                    <span><b>Exclude people that have...</b></span>
                    <mat-radio-group [(ngModel)]="filterType" (change)="changeExtractionFilter($event)">
                        <mat-radio-button value="0" class="payrollsource-radio">Salary Level is empty</mat-radio-button><br>
                        <mat-radio-button value="1" class="payrollsource-radio">CCNL = 
                            <mat-form-field>
                                <mat-label>Value</mat-label>
                                <input matInput [(ngModel)]="filterFieldValue" (keydown.Space)="$event.stopImmediatePropagation();">
                            </mat-form-field>
                        </mat-radio-button><br>
                        <mat-radio-button value="2" class="payrollsource-radio">Salary Level is empty and CCNL = 
                            <mat-form-field>
                                <mat-label>Value</mat-label>
                                <input matInput [(ngModel)]="filterFieldValue" (keydown.Space)="$event.stopImmediatePropagation();">
                            </mat-form-field>
                        </mat-radio-button>
                    </mat-radio-group>
                    <br>
                </div>-->
            </mat-card>
            <!--
            <div style="margin:12px!important; flex:50%"></div>
            -->
        </div>
    </mat-tab>
</mat-tab-group>

<div *ngIf="!loading" class="buttons-div">
    <button mat-raised-button color="basic" (click)="close()" style="margin-right:8px">
        <mat-icon style="margin-top:-4px">clear</mat-icon> {{labels.closeLabel}}
    </button>    
    <button mat-raised-button color="accent" (click)="saveConfig()">
        <mat-icon style="margin-top:-4px">save</mat-icon> {{labels.saveLabel}}
    </button>    
</div>
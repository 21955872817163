import { Component, Input, Output, ViewChild, OnInit, EventEmitter, HostListener, ChangeDetectorRef, SimpleChange, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiService } from '../../services/api.service'
import { NavigationEnd, Router, Event} from '@angular/router';


@Component({
  selector: 'app-func-menu',
  templateUrl: './func-menu.component.html',
  styleUrls: ['./func-menu.component.scss']
})
export class FuncMenuComponent implements OnInit {

  @Input()
  functions:any = {};
  @Input()
  functionIndex = -1;
  @Output()
  changeFunctionEvent = new EventEmitter<number>();
  @Input()
  language: string = "ENG"
  @Input()
  mobileView: boolean = false;
  @Input()
  showMenu: boolean = true;
  @Input()
  isSticky: boolean = false;

  parentFunctions:any = {
    "FA0016":[
      "FA0015",
      "FA0031"
    ],
    "FA0008":[
      "FA0007",
      "FA0030"
    ],
    "FA0077": ["FA0076"],
  }

  scrollbarPosition:number = 0;
  scrollbarWidth:number = 0;
  toolbarWidth:number = 0;
  screenWidth:number = 0;

  
  constructor(public _api:ApiService) { 
  }

  ngOnInit(): void {  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isSticky'] != undefined && changes['isSticky'].previousValue != changes['isSticky'].currentValue) {
      this.checkOverflow();
    }
  }

  ngAfterContentChecked() {
    this.checkOverflow();
  }

  changeFunction(i: number) {
    if (!this._api.loading) this.changeFunctionEvent.emit(i);
  }

  goBack() {
    var urlParams = this._api.getUrlParameters();
    if (urlParams.obguid.includes("wf-")) this._api.changeFunction(urlParams.fmguid);
    else if (urlParams.obguidParam.includes("wf-")) this._api.changeFunction(urlParams.fmguid, urlParams.obguid);
    else history.back();
  }

  leftScroll() {
    const a = document.querySelector(".func-menu");
    a!.scrollBy(-10, 0);
    //console.log("l")
  }
  
  rightScroll() {
    const a = document.querySelector(".func-menu");
    a!.scrollBy(10, 0);
    //console.log("r")
  }

  scrollToTop() {        
    var a = document.querySelector("mat-drawer-content.drawer-content");
    if (a != null) a.scrollTo(0,0);
    else {
      a = document.querySelector("mat-drawer-content.drawer-content-full");          
      if (a != null) a.scrollTo(0,0);
    }    
  }

  checkOverflow() {
    const elem = document.querySelector(".func-menu");
    this.scrollbarPosition = elem?.scrollLeft != undefined ? elem?.scrollLeft : 0; 
    this.scrollbarWidth = elem?.scrollWidth != undefined ? elem!.scrollWidth - elem!.clientWidth : 0;     
    this.screenWidth = window.innerWidth;
    this.toolbarWidth = elem?.clientWidth != undefined ? elem!.clientWidth : 0;
    //console.log("scrollbarPosition " + this.scrollbarPosition);
    //console.log("scrollbarWidth " + this.scrollbarWidth);
  }

  onWheel(event: any): void {
    const a = document.querySelector(".func-menu");
    a!.scrollBy(event.deltaY, 0);
    event.preventDefault();
 } 

  getHiddenFunctionName() {    
    var urlParams = this._api.getUrlParameters();
    return urlParams.fmguid == "FA0016" || urlParams.fmguid == "FA0008"  ? 'View' : (urlParams.fmguid == "FA0020" ? 'Settings' : '???');
  }

}

import { ElementRef, Injectable } from '@angular/core';
//import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SnackbarmanagerService {

  classicSnackbar:boolean = false; 

  constructor(private _snackBar:MatSnackBar) { }

  
  open(message: string, state:string, timeout?:number) {
    if (message == "") message = "Error!"
    var panelClasses = []
    var iconName = "";
    switch (state.toLowerCase()) {
      case 'ok':
        panelClasses.push('success-snackbar');
        iconName = "check_circle";
        break;
      case 'ko':
        panelClasses.push('error-snackbar');
        iconName = "error";
        break;
      case 'warning':
        panelClasses.push('warning-snackbar');
        iconName = "warning";
        break
    }
    if (!this.classicSnackbar) panelClasses.push('center-snackbar');
    this._snackBar.open(message, '', {
        horizontalPosition: this.classicSnackbar ? 'right' : 'center',
        verticalPosition:  this.classicSnackbar ? 'top' : 'bottom',
        duration: (timeout == undefined ? 3000 : timeout),
        panelClass: panelClasses
      }
    );
  }
}

<mat-card>
    <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
        {{context.Title}}
    </mat-card-title>
    <mat-card-content class="mat-card-content">
        <p-table #dt [rowHover]="false" [rows]="10" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
            [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true"
            [columns]="context.Column" [value]="context.Row" sortMode="multiple" [multiSortMeta]="multiSortMeta"
            [globalFilterFields]="['Elemento2', 'Elemento3', 'Elemento4', 'Elemento5', 'Elemento6', 'Elemento7']">

            <ng-template pTemplate="caption" id="hideIcons">
                <div class="table-header" id="hideIcons">
                    <div style="width:160px">
                        <button class="table-button" *ngIf="context.ButtonExcelExportContent != ''" 
                            (click)="exportTable()" [matTooltip]="context.ButtonExcelExportContent" mat-raised-button color="accent">
                        <mat-icon>table_chart</mat-icon>
                        <span class="excel-button-label"> {{ context.ButtonExcelExportContent }} </span>
                    </button>
                    </div>
                    <div class="search-div">                        
                        <span class="p-input-icon-right" id="hideIcons">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText (input)="dt.filterGlobal($any($event.target).value,'contains')" [placeholder]="context.Search" />
                        </span>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th class="column-header" *ngFor="let item of columns; let i = index" [pSortableColumn]="columns[i].field" pResizableColumn>
                        <span class="column-title">
                            {{columns[i].header}}
                        </span>
                        <span class="sort-icons" id="hideIcons">
                            <p-columnFilter type="text" [field]="columns[i].field" display="menu" class="ml-auto"></p-columnFilter>
                            <p-sortIcon [field]="columns[i].field"></p-sortIcon>
                        </span>
                    </th>
                    <th>
                        <!-- empty -->
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr pSelectableRowDisabled="true">
                    <td *ngFor="let item of [].constructor(3); let i = index">
                        <span class="table-data">{{ rowData['Elemento' + (i + 2)] }} </span>
                    </td>
                    <td>
                        <span class="table-data">{{ rowData['Elemento5'] }}</span>
                    </td>
                    <td>
                        <span class="table-data">{{ rowData['Elemento6'] }}</span>
                    </td>
                    <td>
                        <mat-icon *ngIf="rowData['Elemento7'] == '01' || rowData['Elemento7'] == '03'" style="color:#00aa00">check_circle</mat-icon>
                        <mat-icon *ngIf="rowData['Elemento7'] == '02'" style="color:#dfb704"><mat-icon>warning</mat-icon></mat-icon>
                    </td>
                    <td class="view-button-cell">
                        <button class="table-button"  mat-raised-button 
                        color="primary" (click)="view(rowData['Elemento9'], rowData['Elemento1'])" [matTooltip]="context.ButtonView">{{ context.ButtonView }}</button>
                    </td>                
                </tr>
            </ng-template>

            
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="context.Row.length == 0" >
                    <td [attr.colspan]="columns.length" class="nodata-cell">
                        {{context.NoDataFound}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </mat-card-content>
</mat-card>

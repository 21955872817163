import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import { ApiService } from '../../services/api.service';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
 

@Component({
  selector: 'component-cp014',
  templateUrl: './cp014.component.html',
  styleUrls: ['./cp014.component.scss']
})
export class Cp014Component implements OnInit {

  @Input() context: any;
  @ViewChild('dt') table: Table | undefined;

  columns:any = [];
  translateButtonToggle:boolean = false;
  commentStar:string = "";
  commentStarOriginal:string = "";

  loading: boolean = true
  multiSortMeta:SortMeta[] = [];
  
  @ViewChild(MatMenuTrigger, {static: true}) rightClickMenuTrigger: MatMenuTrigger | undefined; 
  rightClickMenuPosition =  {x: '0', y: '0'}

  constructor(private _api:ApiService) { }

  ngOnInit(): void {    
    for (var i = 0; i < this.context.Column.length; i++) {
      this.columns.push({field: "Elemento" + (i == 2 ? i + 2 : i + 1), header: this.context.Column[i]})
    }
    this.loading = false;

    if(this.context.StarInfo.Commento != undefined) {
      if (this.context.ApiceAperto != null && this.context.ApiceAperto != "" && this.context.ApiceChiuso != null && this.context.ApiceChiuso != "") {
        this.commentStar = this.context.ApiceAperto + this.context.StarInfo.Commento + this.context.ApiceChiuso;
        this.commentStarOriginal = this.context.ApiceAperto + this.context.StarInfo.CommentoTradotto + this.context.ApiceChiuso;
      } else {
        this.commentStar = this.context.StarInfo.Commento;
        this.commentStarOriginal = this.context.StarInfo.CommentoTradotto;
      }
    }
  }

  getRowProperties(){
    return Object.getOwnPropertyNames(this.context.Columns);
  }

  viewNewTab(fmguid:any, obguid:any) {
    var urlParams = this._api.getUrlParameters();
    var tabUrl = urlParams.apguid + '/' + urlParams.teguid + '/' + 
      urlParams.moguid + '/' + fmguid + '/' + obguid; 
    window.open(tabUrl, "_blank");
  }

  view(fmguid:any, obguid:any) {
    this._api.changeFunction(fmguid, obguid);
  }

  onRightClick(event: MouseEvent, fmguid:any, obguid:any) { 
    event.preventDefault(); 
    this.rightClickMenuPosition.x = event.clientX + 'px'; 
    this.rightClickMenuPosition.y = event.clientY + 'px'; 
    this.rightClickMenuTrigger!.menuData = {fmguid:fmguid, obguid: obguid};
    this.rightClickMenuTrigger!.openMenu(); 
  } 
}

import { Component, OnInit, Input } from '@angular/core';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';

@Component({
  selector: 'component-cp004',
  templateUrl: './cp004.component.html',
  styleUrls: ['./cp004.component.scss']
})
export class Cp004Component implements OnInit {

  @Input() context: any;
  sliders:any[] = [];

  translateButtonToggles:boolean[] = [];
  multipleSliders:boolean = false;

  constructor(private _errorthrower:ErrorthrowerService) {}

  ngOnInit() {
    if (this.context.Display == '') {
      this._errorthrower.throw("Unauthorized.", 400, false, false);
    }
    this.multipleSliders = (this.context.Sliders instanceof Array);
    if (this.multipleSliders) {
      this.context.Sliders.forEach((e:any) => {
        if (e.Elemento3 == e.Elemento5) e.Elemento3 = "";
        this.sliders.push(e);
        this.translateButtonToggles.push(false);
      })
    } else {
      if (this.context.Slider.Elemento3 == this.context.Slider.Elemento5) this.context.Slider.Elemento3 = "";
      this.sliders.push(this.context.Slider);
      this.translateButtonToggles.push(false);
    }
  }

  toggleTranslateButton(i:number) {
    this.translateButtonToggles[i] = !this.translateButtonToggles[i];
  }

  toggleStar(i:number) {
    this.context.Sliders[i].Status = !this.context.Sliders[i].Status;
  }
}

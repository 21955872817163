<mat-card style="margin-bottom:32px">
    <mat-card-title *ngIf="context.Title2 != ''" class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
        {{context.Title2}}
    </mat-card-title>
    <mat-card-content class="mat-card-content">
        <p-table #dt2 [rowHover]="false" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm"
            [rowsPerPageOptions]="[10,25,50]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
            [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" (onFilter)="onFilter($event, 2)"
            [columns]="context.Column" [value]="context.Row2" sortMode="multiple" [multiSortMeta]="multiSortMeta"
            [globalFilterFields]="['Elemento1', 'Elemento2', 'Elemento3', 'Elemento4',
            'Elemento6','Elemento7']">

            <ng-template pTemplate="caption" id="hideIcons">            
                <div class="table-header" id="hideIcons">
                    <div class="table_buttons">
                        <button class="table-button" *ngIf="context.ButtonContent != ''" (click)="exportTable(context.Title2, filteredRows2.length == 0 ? context.Row2 : filteredRows2)" [matTooltip]="context.ButtonContent" mat-raised-button color="accent" [disabled]="context.Row2.length == 0">
                            <mat-icon>table_chart</mat-icon>
                            <span class="excel-button-label"> {{ context.ButtonContent }} </span>
                        </button>
                    </div>
                    <div class="search-div">
                        <span class="p-input-icon-right searchbox-span" id="hideIcons">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText (input)="dt2.filterGlobal($any($event.target).value,'contains')" [placeholder]="context.Search" class="search-input"/>
                        </span>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th class="column-header" *ngFor="let item of [].constructor(row2ElementsCount - 1); let i = index" [pSortableColumn]="columns[i].field" pResizableColumn>
                        <span class="column-title">
                            {{columns[i].header}}
                            <span class="sort-icons" id="hideIcons">
                                <p-columnFilter type="text" [field]="columns[i].field" display="menu" class="ml-auto"></p-columnFilter>
                                <p-sortIcon [field]="columns[i].field"></p-sortIcon>
                            </span>
                        </span>                        
                    </th>
                    <th>
                        <!-- empty -->
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr pSelectableRowDisabled="true">
                    <ng-container *ngFor="let item of [].constructor(row2ElementsCount); let i = index">
                        <td *ngIf="i != 4 && rowData['Elemento' + (i + 1)] != undefined">
                            <span class="table-data">{{ rowData['Elemento' + (i + 1)] }} </span>
                        </td>
                    </ng-container>
                    <td class="view-button-cell">
                        <button class="table-button" mat-raised-button color="accent" (click)="view(rowData['Elemento5'])" (contextmenu)="onRightClick($event,rowData['Elemento5'])">{{ context.ButtonGoToLinkContent }}</button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="context.Row2.length == 0" >
                    <td [attr.colspan]="columns.length" class="nodata-cell">
                        {{context.NoDataFound}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </mat-card-content>
</mat-card>


<mat-card *ngIf="context.Row != undefined && context.Row.length > 0">
    <mat-card-title class="mat-card-title d-flex align-items-center justify-content-center text-center" class="title-card">
        {{context.Title}}
    </mat-card-title>
    <mat-card-content class="mat-card-content">
        <p-table #dt1 [rowHover]="false" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm"
            [rowsPerPageOptions]="[10,25,50,100]" [loading]="loading" responsiveLayout="scroll" [paginator]="true"  [scrollable]="true"
            [filterDelay]="0" [reorderableColumns]="false" [resizableColumns]="true" (onFilter)="onFilter($event, 1)"
            [columns]="context.Column" [value]="context.Row" sortMode="multiple" [multiSortMeta]="multiSortMeta"
            [globalFilterFields]="['Elemento1', 'Elemento2', 'Elemento3', 'Elemento4',
            'Elemento6','Elemento7','Elemento8','Elemento9']">

            <ng-template pTemplate="caption" id="hideIcons">            
                <div class="table-header" id="hideIcons">
                    <div style="width:160px">
                        <button class="table-button" *ngIf="context.ButtonContent != ''" (click)="exportTable(context.Title, filteredRows.length == 0 ? context.Row : filteredRows)" [matTooltip]="context.ButtonContent" mat-raised-button color="accent">
                            <mat-icon>table_chart</mat-icon>
                            <span class="excel-button-label"> {{ context.ButtonContent }} </span>
                        </button>
                    </div>
                    <div class="search-div">
                        <span class="p-input-icon-right" id="hideIcons">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText (input)="dt1.filterGlobal($any($event.target).value,'contains')" [placeholder]="context.Search" class="search-input"/>
                        </span>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th class="column-header" *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn>
                        <span class="column-title">
                            {{col.header}}
                            <span class="sort-icons" id="hideIcons">
                                <p-columnFilter type="text" [field]="col.field" display="menu" class="ml-auto"></p-columnFilter>
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </span>    
                        </span>                                            
                    </th>
                    <th>
                        <!-- empty -->
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr pSelectableRowDisabled="true">
                    <ng-container *ngFor="let item of [].constructor(rowElementsCount); let i = index">
                        <td *ngIf="i != 4 && rowData['Elemento' + (i + 1)] != undefined">
                            <span class="table-data">{{ rowData['Elemento' + (i + 1)] }} </span>
                        </td>
                        <td *ngIf="i != 4 && rowData['Elemento' + (i + 1)] == null">
                            <span class="table-data">-</span>
                        </td>
                    </ng-container>
                    <td class="view-button-cell">
                        <button class="table-button" mat-raised-button color="accent" 
                            (click)="view(rowData['Elemento5'])" (contextmenu)="onRightClick($event,rowData['Elemento5'])">
                            {{ context.ButtonGoToLinkContent }}
                        </button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="context.Row.length == 0" >
                    <td [attr.colspan]="columns.length" class="nodata-cell">
                        {{context.NoDataFound}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </mat-card-content>
</mat-card>

<!--right click menu-->
<div style="visibility: hidden; position: fixed;" 
[style.left]="rightClickMenuPosition.x" 
[style.top]="rightClickMenuPosition.y" 
[matMenuTriggerFor]="rightMenu"></div> 

<mat-menu #rightMenu="matMenu"> 
  <ng-template matMenuContent let-obguid="obguid"> 
    <button mat-menu-item (click)="viewNewTab(obguid)">View in new tab</button> 
  </ng-template> 
</mat-menu> 
import { Component, OnInit, Input } from '@angular/core';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service';
import { DialogComponent } from '../dialog/dialog.component';
 

@Component({
  selector: 'component-cp041',
  templateUrl: './cp041.component.html',
  styleUrls: ['./cp041.component.scss']
})
export class Cp041Component implements OnInit {

  @Input()
  context:any;
  
  constructor(private _api:ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService) { }

  ngOnInit(): void {
  }

  action() {
    if (this.context.isLink) window.open(this.context.ACTION, '_blank');
    else this.openDialog()
  }

  openDialog() {
    if (this.context.popupToConfirm || this.context.PopupLabels[1] != "") {
      const dialogRef = this.dialog.open(DialogComponent,{
        data: {
          title: this.context.popupTitle,
          content: this.context.popupContent,
          accept: this.context.popupBottonOK,
          reject: this.context.popupButtonCancel
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.post();
        }
      });
    } else this.post();
  }

  post() {
    this._api.loading = true;
    var context_data = {
      "OUCODA" : this._api.lastOUCODA,
      "Sections" : this._api.lastSections
    }
    //console.log(this._api.lastSections)

    this._api.doButtonPostRequest(context_data, this.context.ActionAPI ? 
        this.context.ActionAPI : (this.context.ACTION ? this.context.ACTION : "/error"), this.context.FAGUID != undefined ? this.context.FAGUID : undefined).subscribe((response) => {
      if (response) {
        this._api.printResponse(response);
        var responseObj = JSON.parse(JSON.stringify(response));    
        if (responseObj.isSuccess) {              
          if (this.context.PopupLabels[1] != "") this._snackbarManager.open(this.context.PopupLabels[4], 'ok'); 
          else this._snackbarManager.open("Success!", 'ok'); 
          var urlParams = this._api.getUrlParameters();
          if (this.context.Navigation != undefined && this.context.Navigation != "") {
            if (this.context.Navigation.includes('/')) {
              var newUrl = this.context.Navigation.split('/')
              if (newUrl[2] != urlParams.teguid) this._snackbarManager.open("Operation was successful but redirect has failed.", 'warning');
              else this._api.navigate(newUrl[1], newUrl[2], newUrl[3], newUrl[4]);
            } else this._api.navigate(urlParams.apguid, urlParams.teguid, urlParams.moguid, this.context.Navigation);
          }    
        } else {
          this._snackbarManager.open(responseObj.errorMessage, 'ko');
        }     
        this._api.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);
        this._api.loading = false;
        this._snackbarManager.open("Error", 'ko');
      }
    });
  }

}

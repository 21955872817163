export const environment = {
  production: false,
  code: 1,
  
  cognitoData: {
    region: "eu-west-1",
    user_pool: "eu-west-1_nGQ9zRnhp",
    client_id: "5q7atch2bid042d46a2dd0ecbl",
    mandatory_sign_in: true,
    cookie_storage: {
      domain: "w3.wpdpwharol.com",
      secure: true,
      path: "/",
      expires: 365,
      same_site: "lax"
    },
    oauth: {
      domain: "idp-wospee-staging.auth.eu-west-1.amazoncognito.com",
      scope: ['email', 'openid'],
      redirect_sign_in: 'https://w3.wpdpwharol.com/',
      redirect_sign_out: 'https://w3.wpdpwharol.com/',
      response_type: 'code'
    }
  }
};

<div class="error_div">
    <span *ngIf="errorType == 0" class="title_h1">An Error Occured... :(</span>
    <span *ngIf="errorType == 1" class="title_h1">This page doesn't exist... :(</span>
    <p class="description_p">Click the details button to show the error and ask for support.</p>
    <div class="buttons-div">
        <button mat-raised-button class="generic-button" (click)="goToHomePage()" color="accent">{{context.reloadable != undefined && context.reloadable ? 'Reload function' : 'Go back'}}</button>
        <mat-button-toggle class="generic-button" (click)="detailsVisible = !detailsVisible">Details</mat-button-toggle>
    </div>
    <div *ngIf="detailsVisible" class="errormessage_div">
        >{{context.status}}<br>
        >{{context.error.message != undefined ? context.error.message : (context.status == 0 ? context.statusText : context.error)}}
    </div>  
    <button mat-raised-button color="accent" *ngIf="detailsVisible && !supportSent && (context.reportable == undefined || (context.reportable != undefined && context.reportable))" (click)="sendSupport()">Send to Support</button>
    <p *ngIf="supportSent" class="description_p">Thank you for your patience!</p>
</div>

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { ApiService } from '../../services/api.service'
import { ExcelexporterService } from 'src/app/services/excelexporter.service';
 

@Component({
  selector: 'component-cp016',
  templateUrl: './cp016.component.html',
  styleUrls: ['./cp016.component.scss']
})
export class Cp016Component implements OnInit {
  
  @Input() context: any;
  @ViewChild('dt1') table1: Table | undefined;
  columns:any = []

  loading: boolean = true
  multiSortMeta:SortMeta[] = [];
  filterType = "";

  excelRows: any;


  constructor(private _api:ApiService, private _excel:ExcelexporterService) { }

  ngOnInit(): void {

    //fare una struttura dati fatta bene per le colonne serve per il sort e il filter
    for (var i = 0; i < this.context.Column.length; i++) {
      this.columns.push({field: "Elemento" + (i + 1), header: this.context.Column[i]})
      //if (i == 2) i++;
    }
    this.filterType = "personal";
    //console.log(this.columns);
    
    this.multiSortMeta.push({field: '', order: 1});
    this.loading = false;
 
  }

  view(fmguid:any, obguid:any) {
    this._api.changeFunction(fmguid, obguid);
  }


  exportTable() {
    this._excel.export('table', this.columns, this.context.Row)
  }


}

import { Component, Input, Output, OnInit, ViewChild, ChangeDetectorRef, EventEmitter} from '@angular/core';
import { Table } from 'primeng/table';
import { SortMeta } from 'primeng/api';
import { FormBuilder, Validators, AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { GenericformComponent } from '../genericform/genericform.component';
import { ApiService } from 'src/app/services/api.service';
import { DialogComponent } from '../dialog/dialog.component';
import { ExcelexporterService } from 'src/app/services/excelexporter.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'component-cp040',
  templateUrl: './cp040.component.html',
  styleUrls: ['./cp040.component.scss']
})
export class CP040Component implements OnInit {

  @Input() context: any;
  @Input() title: any;

  selectedRowIndex: number = 0
  selectedRows: any[] = []
  usedColumns: any[] = []
  Orders: any = {};

  @Output() openCard: EventEmitter<any> = new EventEmitter();

  X : number[] = []
  Y : number[] = []
  yEval: any[]= []
  xEval: any[]= []

  showtable: boolean= false

  selected : boolean = false
  validInputs:{ [id: string] : boolean } = {};
  tmp:number = 0
  aggregationEnabled:boolean = false;

  loading: boolean = true
  cardVisible:boolean = false
  cardVisibleType:number = 0
  formType:number = 0
  multiSortMeta:SortMeta[] = [];
  filterFields:string[] = []
  @ViewChild('dt') table: Table | undefined;
  @ViewChild('card') card: GenericformComponent | undefined;

  basicData: any;
  multiAxisData: any;
  multiAxisOptions: any;
  lineStylesData: any;
  basicOptions: any;
  needToSave:boolean = false;

  currency:string = "";
  contextData:any = {};

  excelRows: any;

  constructor(private fb:FormBuilder, private _snackbarManager:SnackbarmanagerService, public _api:ApiService,
              public _excel:ExcelexporterService, private ref:ChangeDetectorRef, public dialog: MatDialog) { }

  ngOnInit(): void {
    //console.log(this.context.rows)
    if (this.context.rows.length > 0){
      this.context.columns.forEach((column:any) => {
        if (column.isEvalField == true) {
          this.context.rows.forEach((row:any) => {
            var Orders = row
            //console.log("ORIGINAL:\n" + column.evaluation)
            //console.log("TOSTRING:\n" + column.evaluation.replaceAll("ToString","toString"))
            //console.log("0:\n" + column.evaluation.replaceAll("ToString","toString").replaceAll("'0'.toString()","").replaceAll("&","&&"))
            var replacedEvalString = column.evaluation.replaceAll("ToString","toString").replaceAll("'0'.toString()","").replaceAll("&","&&");
            //check if there are too many parethesis or too less
            var parethesisCheck = replacedEvalString.split("(").length - replacedEvalString.split(")").length;
            if (parethesisCheck < 0) {
              replacedEvalString = replacedEvalString.substring(0, replacedEvalString.length - (parethesisCheck * -1)); 
            } else if (parethesisCheck > 0) {
              for (var i = 0; i < parethesisCheck; i++) {
                replacedEvalString += ")";
              }
            }

            try {
              row[column.elemento] = eval(replacedEvalString)            
              //console.log("Parsed!")
            } catch(e:any) {
              console.log("Eval error at \n" + column.elemento)
              console.log(e)
              row[column.elemento] = 0
            }
            if (this.currency == "" && column.format.includes('##,#')) {
              this.currency = column.format.replace("##,#", "")
            }
          })
          if (Object.keys(column.aggregation).length > 0) {
            this.aggregationEnabled = true;
          }
        }
        if (column.isTableVisible) this.usedColumns.push(column)
      })

      this.multiSortMeta.push({field: '', order: 1})
      if (this.context.FilterFields != undefined && this.context.FilterFields.length > 0) {
        this.filterFields = this.context.FilterFields
      }
    } //else this.loading = false
    /*
    else if (this.context.hasOwnProperty('rowsCheck') && this.context.rowsCheck.length > 0){
      this.context.columns.forEach((column:any) => {
        if (column.isEvalField == true) {
          this.context.rowsCheck.forEach((row:any) => {
            var Orders = row
            
            var replacedEvalString = column.evaluation.replaceAll("ToString","toString").replaceAll("'0'.toString()","").replaceAll("&","&&");
            //check if there are too many parethesis or too less
            var parethesisCheck = replacedEvalString.split("(").length - replacedEvalString.split(")").length;
            if (parethesisCheck < 0) {
              replacedEvalString = replacedEvalString.substring(0, replacedEvalString.length - (parethesisCheck * -1)); 
            } else if (parethesisCheck > 0) {
              for (var i = 0; i < parethesisCheck; i++) {
                replacedEvalString += ")";
              }
            }

            try {
              row[column.elemento] = eval(replacedEvalString)
              //console.log("Parsed!")
            } catch(e) {
              console.log("Eval error at \n" + column.elemento)
              console.log(e)
              row[column.elemento] = 0
            }
            //row[column.elemento] = eval(column.evaluation.replace(this.regexToString,''))
            if (this.currency == "" && column.format.includes('##,#')) {
              this.currency = column.format.replace("##,#", "")
            }
          })
        }
        if (column.isTableVisible) this.usedColumns.push(column)
      })

      this.multiSortMeta.push({field: '', order: 1})
    }*/
    this.loading = false

    //console.log(this.context.columns)
  }

  print(a:any) {
    console.log(a)
  }

  showTable(){
    this.showtable = !this.showtable
  }

  profileForm = this.fb.group({
    TextField : this.fb.control("", [Validators.required]),
    TextArea : this.fb.control("", [Validators.required]),
    Weight : new FormControl(0, {
      validators: [
         this.isWeightValid.bind(this),
      ]
    }),
    WeightAdd : new FormControl(0, {
      validators: [
         this.isWeightAddValid.bind(this),
      ]
    })

  });

  isWeightValid(control: AbstractControl):  ValidationErrors | null { //unused
    const a = Number(control.value)
    if (this.context != undefined) {
      if (this.aggregateColumn(this.context.columns[1], a / 100) >= 1.01)
        return { valid:false }
      else {
        this.Orders[this.context.columns[1].elemento] = Number(a.toFixed(0)) / 100
        return null
      }
    }
    else return null
  }

  isWeightAddValid(control: AbstractControl):  ValidationErrors | null { //unused
    const a = Number(control.value)
    if (this.context != undefined) {
      if (this.aggregateColumn(this.context.columns[1], 0) + (a/100) >= 1.01)
        return { valid:false }
      else {
        this.Orders[this.context.columns[1].elemento] = Number(a.toFixed(0)) / 100
        return null
      }
    }
    else return null
  }

  parseStyle(s:any) {
    var tmp2 = "color:red;font-weight:1000"
    var regex = /([\w-]*)\s*:\s*([^;]*)/g;
    var match:any, formStyle:any ={};
    while(match=regex.exec(s)) {
      formStyle[match[1]] = match[2].trim();
    }
    return formStyle
  }

  finishedLoading() {
    this.loading = false;
  }

  getRowProprieties() {
    return Object.getOwnPropertyNames(this.context.rows[0]);
  }

  toggleDrawerContentZindex(isAbove:boolean) {
    var myCss = ".mat-drawer-content { z-index: " + (isAbove ? "2" : "1") + "}";
    var style = document.createElement("STYLE");
    style.innerText = myCss;
    document.body.appendChild(style);
  }

  openForm(cardType:number) {
    //console.log(this.context.columns)
    this.formType = cardType
    if (this.formType == 2) {
      //this.Orders = JSON.parse(JSON.stringify(this.context.rows[0]))
      
      var newRow:any = {};
      this.context.columns.forEach((col:any) => {
        newRow[col.elemento] = this.card?.getDefaultValue(col)
      });
      this.context.rows.push(JSON.parse(JSON.stringify(newRow)));

      if (this.card != undefined) {
        this.toggleDrawerContentZindex(true);
        this.card.openForm(this.context.rows[this.context.rows.length - 1])
        this.cardVisible = true;  
      }

    } else {
      if (this.selectedRows != null && this.selectedRows.length == 1) {
        
        this.context.rows.forEach((row:any, index:number) => {
          if (row == this.selectedRows[0]) this.selectedRowIndex = index
        })

        if (this.card != undefined) {          
          this.cardVisible = true;            
          this.toggleDrawerContentZindex(true);
          this.card.openForm(this.selectedRows[0], this.selectedRowIndex)
        }
      } else if (this.selectedRows.length > 1) {
        this._snackbarManager.open(this.context.messageOnlySingleLineAllowed, 'ko');
      } else {
        this._snackbarManager.open(this.context.messageNoRowSelected, 'ko');
      }
      
    }
    //this._snackbarManager.open("Discarded changes", 'ko');
  }

  closeForm(event:any) {
    //console.log(event)
    //console.log(this.selectedRows)
    if (event.modifyRow) {   
      this.selectedRows[0] = event.orders;    
      this.context.rows[event.ordersIndex] = event.orders;   
      this.selectedRows = [];
      this.context.rowsCheck = JSON.parse(JSON.stringify(this.context.rows));      
      if (this.context.RefreshAfterChange != undefined && this.context.RefreshAfterChange) this._api.refreshSections();
      else {        
        this.table!.selection = [];
        this.table!.value = this.context.rows;
      }
    } else {
      if (!event.success) {
        this.context.rows.splice(this.context.rows.length - 1, 1);
        this.table!.value = this.context.rows;
      } else {
        this.table!.value = this.context.rows;        
        if (this.context.RefreshAfterChange != undefined && this.context.RefreshAfterChange) this._api.refreshSections();
      }
    }     
    this._api.loading = false;
    this.toggleDrawerContentZindex(false);
    this.cardVisible = false;
  }

  removeRowPopup() {
    var selectedRowText = "<br><br>"
    if (this.selectedRows.length == 1) {
      Object.keys(this.selectedRows[0]).forEach((e:any) => {
        this.context.columns.every((c:any) => {
          if (c.elemento == e && c.isTableVisible) {
            var value = ""
            if (this.selectedRows[0][e] == null) value = "-";
            else value = this.selectedRows[0][e];
            selectedRowText += "<b>" + c.title + "</b>: " + value + "<br>";
            return false;
          }
          return true;
        })
      })
    }
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: this.selectedRows.length > 1 ? this.context.DeletionPopupLabels[1] : this.context.DeletionPopupLabels[0],
        content: this.selectedRows.length > 1 ? this.context.DeletionPopupLabels[3] : this.context.DeletionPopupLabels[2] + selectedRowText,
        accept: this.context.DeletionPopupLabels[4],
        reject: this.context.DeletionPopupLabels[5]
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) this.removeRow() 
    })
  }

  removeRow() {
    this._api.loading = true;   
    
    var rowsBak = JSON.parse(JSON.stringify(this.context.rows));
    for (var i = 0; i < this.selectedRows.length; i++) {
      for (var j = 0; j < this.context.rows.length; j++) {
        if (this.selectedRows[i] == this.context.rows[j]) {
          this.context.rows.splice(j, 1)
          break;
        }
      }
    }
    //console.log(this.context.rows);
    //console.log(this._api.lastSections)

    this.selectedRows = [];
    this.table!.value = this.context.rows;

    //this._snackbarManager.open("Success!", 'ok');     
    
    var context_data = {
      "OUCODA" : this._api.lastOUCODA,
      "Sections" : this._api.lastSections
    }

    this._api.doButtonPostRequest(context_data, this.context.apiFormSave).subscribe((response) => {      
      if(response) {        
        this._api.printResponse(response);      
        var responseObj = JSON.parse(JSON.stringify(response))  
        if (responseObj.isSuccess) {
          this._snackbarManager.open("Success!", 'ok');
          this.selectedRows = [];
          if (this.context.RefreshAfterChange != undefined && this.context.RefreshAfterChange) this._api.refreshSections();
          else {            
            this.table!.value = this.context.rows;
            this.context.rowsCheck = JSON.parse(JSON.stringify(this.context.rows));
          }
        } else {
          this._snackbarManager.open(responseObj.errorMessage, 'ko');
          this.context.rows = JSON.parse(JSON.stringify(rowsBak));
        }
        this._api.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);   
        var responseObj = JSON.parse(JSON.stringify(error))   
        this._snackbarManager.open(responseObj.error, 'ko');
        this.context.rows = JSON.parse(JSON.stringify(rowsBak));        
        this._api.loading = false;  
      }
    });
        
  }

  selectRow(event:any) { //unused
    if (typeof event.data !== 'string') {
      if (this.context.SelectionType != 'Multiple') this.selectedRows = []
      this.selectedRows.push(event.data)
    }
    //console.log(this.selectedRows.length)
  }

  unselectRow(event:any) { //unused
    //console.log(event.data)
    this.selectedRows.forEach((row:any, index:number) => {
      if (row == event.data) this.selectedRows.splice(index, 1)
    })
    //console.log(this.selectedRows.length);
  }

  aggregateColumn(col:any, value:number) { //unused
    var result = 0
    switch (col.aggregation.aggrType) {
      case "AggregateType.Sum":
        this.context.rows.forEach((row:any) => {
          result += row[col.elemento]
        })        
        if (value != 0) {
          result = result - (this.tmp - this.Orders[col.elemento]) + (value - this.Orders[col.elemento])
        }
        break
    }
    return result
  }

  view() {
    var urlParams = this._api.getUrlParameters();
    this._api.navigate(urlParams.apguid, urlParams.teguid, urlParams.moguid, this.context.buttonViewFunction, this.selectedRows[0].Elemento1);
  }

  exportTable() {
    var cols:any = [];
    this.usedColumns.forEach((c:any) => {
      cols.push({header:c.title, field:c.elemento})
    });
    this._excel.export(this.context.excelTitle, cols, this.context.rows)
  }

  formatValues(value:any, format:any) : any{
    switch(format){
      case 'n0':
        return Number(value);
      case 'n1':
        return Number(value).toFixed(1);
      case 'p0':
        return Number(value) * 100 + " %";
      case 'p1':
        return (Number(value) * 100).toFixed(1) + " %";
    }
    if(format.includes('##,#')){
      var split = format.split(" ");
        return (Number(value).toFixed(1)) + " " + split[0];
    } else if(format == ""){
      return value;
    }

  }

  
}

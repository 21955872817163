
        <div *ngIf="_api.isNakedSection() || showMenu" class="flex-div">
                <!--<div *ngIf="_api.isNakedSection()" style="height:64px; width:96px; margin-top:16px; background-color:black; border-radius: 8px; display: flex; flex-direction: column; justify-content: center; align-items: center;">!-->
                <button *ngIf="_api.isNakedSection() && !isSticky && !_api.loading" mat-mini-fab color="accent" (click)="goBack()">
                        <mat-icon>arrow_back</mat-icon>
                </button>

                <button *ngIf="_api.isNakedSection() && isSticky" mat-flat-button color="accent" (click)="goBack()" class="back-button-square">
                        <mat-icon>arrow_back</mat-icon>
                </button>

                <mat-toolbar #funcmenu *ngIf="showMenu" [class]="isSticky? 'func-menu sticky' : 'func-menu'" [style.overflow-x]="screenWidth < 600 ? 'scroll' : 'hidden'" 
                        autosize color="accent" (wheel)="onWheel($event)" (scroll)="onWheel($event)" [style.border-radius]="isSticky ? (_api.isNakedSection() ? '0px 0px 0px 8px' : '0px') : '8px'">
                        <!--
                        <div *ngIf="scrollbarWidth > 0 && scrollbarPosition > 0" class="overflow-div-in" style="background:linear-gradient(to left, rgba(0,0,0,0)0 0%, white 90%);"
                                [style.opacity]="scrollbarPosition / 10" [style.left]="_api.isNakedSection() ? '52px' : '0px'"></div>
                        -->
                        <ng-template ngFor let-item [ngForOf]="functions" let-i="index">
                                <button mat-raised-button color="basic" class="func-button-selected" *ngIf="functionIndex == i &&
                                        (mobileView ? (functions[i].function.Mobile == 1 ? true : false) : true)"
                                        (click)="scrollToTop()">
                                        {{functions[i].function.Description}}
                                </button>
                                <button mat-button [class]="functionIndex != i && parentFunctions[_api.getUrlParameters().fmguid] != undefined && parentFunctions[_api.getUrlParameters().fmguid].includes(functions[i].function.ID) ? 'func-button parent-func-button' : 'func-button'" *ngIf="functionIndex != i && (mobileView ? (functions[i].function.Mobile == 1 ? true : false) : true)" (click)="changeFunction(i)" [style.padding-right]="parentFunctions[_api.getUrlParameters().fmguid] != undefined && parentFunctions[_api.getUrlParameters().fmguid].includes(functions[i].function.ID) ? '0': '16px'">
                                        {{functions[i].function.Description}}
                                        <ng-container *ngIf="functionIndex != i && parentFunctions[_api.getUrlParameters().fmguid] != undefined && parentFunctions[_api.getUrlParameters().fmguid].includes(functions[i].function.ID)">
                                        <mat-icon>arrow_right</mat-icon>
                                                <button mat-raised-button color="basic" class="func-button-selected"  (click)="scrollToTop()">
                                                        {{ language == 'ITA' ? "Dettaglio" : language == 'ESP' ? "Detallo" : "View" }}
                                                </button>
                                        </ng-container>
                                </button>
                                
                        </ng-template>
                        <button mat-raised-button color="basic" class="func-button-selected " *ngIf="functionIndex == -1 && parentFunctions[_api.getUrlParameters().fmguid] == undefined" style="margin-left:auto"
                                (click)="scrollToTop()">
                                <mat-icon *ngIf="_api.getUrlParameters().fmguid != 'FA0020'">remove_red_eye</mat-icon>
                                <mat-icon *ngIf="_api.getUrlParameters().fmguid == 'FA0020'">settings</mat-icon>
                                <!--
                                <span *ngIf="_api.getUrlParameters().fmguid != 'FA0020'">View</span>
                                <span *ngIf="_api.getUrlParameters().fmguid == 'FA0020'">Settings</span>
                                -->
                        </button>
                        <!--
                        <div *ngIf="scrollbarWidth > 0 && scrollbarPosition + 1 < scrollbarWidth" class="overflow-div-in" style="background:linear-gradient(to right, rgba(0,0,0,0) 0%, white 90%); right:0px;"
                                [style.opacity]="(scrollbarWidth - scrollbarPosition) / 10"></div>
                        -->
                </mat-toolbar>

                <!--
                <mat-toolbar #funcmenu2 *ngIf="mobileView" class="func-menu" color="accent" style="width:100%;justify-content: center;" [matMenuTriggerFor]="menu" >
                        <button mat-raised-button color="basic" class="func-button-selected"
                                *ngIf="functionIndex != -1 && functions[functionIndex].function.Mobile == 1 ? true : false">
                                {{functions[functionIndex].function.Description}} 
                        </button>
                        <button mat-raised-button color="basic" class="func-button-selected" *ngIf="functionIndex == -1">
                                {{getHiddenFunctionName()}}
                        </button>
                        <mat-icon style="position:absolute;right:3.5%">keyboard_arrow_down</mat-icon>
                        <mat-menu class="funcmenu-menu" #menu="matMenu" pgcCenter >
                                <ng-template ngFor let-item [ngForOf]="functions" let-i="index">                
                                        <button mat-menu-item class="func-button" class="display:block;" *ngIf="functionIndex != i &&
                                                (functions[i].function.Mobile == 1 ? true : false)" (click)="changeFunction(i)">
                                                {{functions[i].function.Description}} 
                                        </button>
                                </ng-template>
                        </mat-menu>
                </mat-toolbar>
                -->
        </div>

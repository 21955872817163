import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, of, from as fromPromise } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn: BehaviorSubject<boolean>;
  public idpListDownloaded = false;
  public idpList = [{id:"", url:""}];
  public lastIdpIndex = -1;


  constructor(private httpClient: HttpClient, private router:Router, private cookieService: CookieService) {
    Amplify.configure(environment.cognitoData);
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  downloadIdpList() {
    this.httpClient.post("https://3603pww396.execute-api.eu-west-1.amazonaws.com/idp-list", {}, {headers: {'Content-Type': 'application/json'}}).subscribe(
      (result:any) => { 
        this.idpList = result; 
        this.idpListDownloaded = true;

        if (localStorage.getItem("lastuser") !== null) {
          var i = 0;
          this.idpList.every((o:any) => {
            if (o.id.toLowerCase() == localStorage.getItem("lastuser")) {
              this.lastIdpIndex = i;
              return false;
            }
            i++;
            return true;
          }) 
        }
      },
      (error:any) => {
        this.idpList = []; 
        this.idpListDownloaded = true;
      }
    )
  }

  ngOnDestroy() {
    sessionStorage.clear();
  }

  public signIn(user:any, password:any): Observable<any> {
    return fromPromise(Auth.signIn(user, password))
      .pipe(tap(() => this.loggedIn.next(true)));
  }

  public sendPassword(username:any, oldPassword:any, newPassword:any): Observable<any> {    
    return fromPromise(Auth.signIn(username, oldPassword).then((user) => { 
      Auth.completeNewPassword(user, newPassword)
    }));
  }

  public sendUsername(username:any): Observable<any> {
    return fromPromise(Auth.forgotPassword(username).then((data) => console.log(data)))
  }

  public sendCodePassword(username:any, code:any, newPassword:any): Observable<any> {
    return fromPromise(Auth.forgotPasswordSubmit(username, code, newPassword).then((data) => console.log(data)))
  }

  public federatedSignIn() {
    Auth.federatedSignIn();
  }
  
  //Generate a Random String
  public getRandomString() {
    const randomItems = new Uint32Array(28);
    crypto.getRandomValues(randomItems);
    const binaryStringItems = randomItems.map(dec => Number(`0${dec.toString(16).substr(-2)}`))
    return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
  }

  //Encrypt a String with SHA256
  public encryptStringWithSHA256(str:any) {
    const PROTOCOL = 'SHA-256'
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return fromPromise(crypto.subtle.digest(PROTOCOL, encodedData));
  }

  //Convert Hash to Base64-URL
  public hashToBase64url(arrayBuffer:any) {
    const items = new Uint8Array(arrayBuffer)
    const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
    const decodedHash = btoa(stringifiedArrayHash)
    const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64URL
  }

  public signOut(noReload?:boolean) {
    this.deleteAmplifyCookies();
    var isFederated = false;
    if (sessionStorage.getItem("UTGUID") !== null && !sessionStorage.getItem("UTGUID")?.includes("UT")) isFederated = true;
    this.clearSessionStorage(); 
    if (isFederated) window.location.assign("https://idp-wospee.auth.eu-west-1.amazoncognito.com/logout?client_id=2flpa9l7efdndk3urv7gi376vo&logout_uri=https://w3.dp-wospee.com");
    //if (isFederated) window.location.assign("https://idp-wospee.auth.eu-west-1.amazoncognito.com/logout?client_id=5nn5hbdg0op0vo54h9fov980dh&logout_uri=https://w3.dp-wospee.com");
    else location.reload();
    /*
    fromPromise(Auth.signOut()).subscribe(
      result => {
        console.log(result)
        this.clearSessionStorage();
        this.loggedIn.next(false);
        this.router.navigate(['']);
        if (noReload == undefined) location.reload();
      },
      error => {
        console.log(error)
      }
    );
    */
  }

  public deleteAmplifyCookies() {
    switch (window.location.hostname) {      
      case "w3-prd.dp-wospee.com": 
        this.cookieService.deleteAll('/', 'w3-prd.dp-wospee.com');
        this.cookieService.deleteAll('/', 'dp-wospee.com');
        break;
      case "w3.dp-wospee.com": 
        this.cookieService.deleteAll('/', 'w3.dp-wospee.com');
        this.cookieService.deleteAll('/', 'dp-wospee.com');
        break;
      case "showcase.wpdpwharol.com": 
        this.cookieService.deleteAll('/', 'showcase.wpdpwharol.com');
        this.cookieService.deleteAll('/', 'wpdpwharol.com');
        break;
      case "w3.wpdpwharol.com": 
        this.cookieService.deleteAll('/', 'w3.wpdpwharol.com');
        this.cookieService.deleteAll('/', 'wpdpwharol.com');
        break;
      case "showcase.dp-wospee.com": 
        this.cookieService.deleteAll('/', 'showcase.dp-wospee.com');
        this.cookieService.deleteAll('/', 'dp-wospee.com');
        break;
      case "localhost": 
        this.cookieService.deleteAll('/', 'localhost');
        break;
    }
  }

  public isAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          this.clearSessionStorage();
          return of(false);
        })
      );
  }

  getFederatedLoginToken(code:any) {
    var details = {
      'grant_type': 'authorization_code',
      'client_id': environment.cognitoData.client_id,
      'code_verifier': sessionStorage.getItem("code_verifier"),
      'redirect_uri': environment.cognitoData.oauth.redirect_sign_in,
      'code': code
    };
    var header = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    console.log(details)
    return this.httpClient.post(environment.cognitoData.oauth.domain, details, {headers: header});   
  }

  getFederatedUserIndex(str:any) {
    var i = 0;
    var res = -1;
    this.idpList.every((e:any) => {
      if (e.url == str || e.id.toUpperCase() == str.toUpperCase) return false
      i++;
      return true
    })
    return res;
  }

  public setUserAttributes(attributes:any) {  
    if (attributes != undefined) {
      //if (attributes['custom:utguid'] != undefined) sessionStorage.setItem("UTGUID", attributes['custom:utguid']);
      //else if (sessionStorage.getItem("UTGUID") === null) sessionStorage.setItem("UTGUID", "");
      //if (sessionStorage.getItem("UTGUIDNEW") !== null) sessionStorage.setItem("UTGUID", sessionStorage.getItem("UTGUIDNEW")!);
      sessionStorage.setItem("LANGU", attributes['custom:language']);    
      //sessionStorage.setItem("SESSION_START", moment().toISOString(true));
      localStorage.removeItem("ng2Idle.main.timeout")
    } else console.log("No user attributes!")
  }

  public getUserAttributes() {
    return fromPromise(Auth.currentUserInfo());
  }

  clearSessionStorage() {
    sessionStorage.removeItem('page');
    //sessionStorage.removeItem('SESSION_START');
    sessionStorage.removeItem('LANGU');
    sessionStorage.removeItem('UTGUID');
    sessionStorage.removeItem('pkce_state');
    sessionStorage.removeItem('code_challenge');
    sessionStorage.removeItem('State');
    sessionStorage.removeItem('Token1');
    sessionStorage.removeItem('Token5');
    var resume = sessionStorage.getItem('RESUME_URL')
    if (resume != null && JSON.parse(resume).apguid == '') 
      sessionStorage.removeItem('RESUME_URL'); 
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-cp011',
  templateUrl: './cp011.component.html',
  styleUrls: ['./cp011.component.scss']
})
export class Cp011Component implements OnInit {

  @Input() context: any;
  basicData1: any;
  basicOptions1: any;
  
  valArray:any = [];

  radarChart: any;
  radarChartCanvas: any;
  radarChartData: any;
  radarChartParameters: string[] = [];
  radarChartValues: number[] = [];
  radarChartOptions: any;

  noChartDataFound:boolean = true;

  constructor() { }

  ngOnInit(): void {
    if (this.context.Row.length == 0) this.noChartDataFound = true;
    else this.createPolarChart();
  }

  createPolarChart() {      
    this.radarChartValues = [];
    this.radarChartParameters = [];
    this.valArray = JSON.parse(JSON.stringify(this.context.Row))
    for(let i = 0; i < this.valArray.length; i++) {
      this.radarChartParameters.push(this.valArray[i].Elemento1);
      this.radarChartValues.push(Number(Number(this.valArray[i].Elemento2).toFixed(1)));
    }
  
    this.radarChartOptions = {
      responsive: true,
      mantainAspectRatio: true,
      scale: {
        min: this.context.MinValue * 100 / 100,
        max: this.context.MaxValue * 100 / 100
      },
      plugins: {
        legend: {
          display: false
        },
      }
    };
    this.radarChartData = {
      labels: this.radarChartParameters,
      datasets: [{
        label: "Data",
        borderColor: "rgba(47, 172, 102)",
        backgroundColor: "rgba(47, 172, 102, 0.2)",
        pointBackgroundColor: "rgba(47, 172, 102)",
        pointBorderColor: "rgba(47, 172, 102)",
        pointHoverBackgroundColor: "rgba(47, 172, 102)",
        pointHoverBorderColor: "rgba(47, 172, 102)",
        data: this.radarChartValues
      }]
    };
    
    const radarChartCanvas = <HTMLCanvasElement>document.getElementById("RadarChart");
    if (this.radarChart != undefined)  this.radarChart.destroy();
    //console.log(radarChartCanvas)
    //console.log(this.radarChartData)
    //console.log(this.radarChartOptions)
    try {
      this.radarChart = new Chart(radarChartCanvas, {
        type: 'radar',
        data: this.radarChartData,
        options: this.radarChartOptions
      });
    } catch (e) {}
  }

}

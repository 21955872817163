
<div class="parent_container">
    <div *ngIf="context.Row.length > 0" class="child_container_1" [style.padding-right]="context.ButtonCezanneSyncActive != '' ? '8px' : '0'">
        <mat-card>
            <mat-card-content class="mat-card-content" style="display:flex; flex-direction: column;">
                <mat-form-field appearance="fill" color="accent">
                    <mat-select [(value)]="selectedLanguage">
                        <mat-option *ngFor="let a of context.Row" [value]="a.Elemento3">
                        {{a.Elemento3}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button class="generic-button" style="max-width:100px" color="accent" (click)="save()" [disabled]="origSelectedLanguage == selectedLanguage">{{context.ButtonSave}}</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="child_container_2">
        <mat-card *ngIf="context.ButtonCezanneSyncActive != ''">
            <mat-card-content class="mat-card-content">
                <button mat-raised-button class="generic-button" color="accent" (click)="sync()" matTooltip="{{context.ButtonCezanneSyncTooltip}}">{{context.ButtonCezanneSyncLabel}}</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div *ngIf="context.Row.length == 0 && context.ButtonCezanneSyncActive == ''">
        <mat-card>
            <mat-card-content class="mat-card-content" style="text-align: center;">There are no settings for this module.</mat-card-content>
        </mat-card>
    </div>
</div>

<div *ngIf="context.Sliders.length == 0" class="nodata-div">
  No data available.
</div>
<mat-card *ngIf="context.Display != ''" class="p-3 matCard slider-card">
  <span class="title-div" *ngIf="context.Title != ''">{{context.Title}}</span>
  <div class="subtitles-div">
    <ng-container *ngFor="let sub of context.Subtitles; index as i">
      <textarea class="subtitle2" matInput cdkTextareaAutosize [disabled]="true">{{translateButtonToggles[i] ? sub.Elemento2 : sub.Elemento1}}</textarea>
      <mat-button-toggle *ngIf="context.TranslateAWS != '' && sub.Elemento1 != sub.Elemento2 && sub.Elemento1 != ''" (click)="translateButtonToggles[i] = !translateButtonToggles[i]"
            class="translate-button">{{translateButtonToggles[i] ? context.OriginalText : context.TranslateAWS}}</mat-button-toggle>
    </ng-container> 
  </div>

  <ng-container *ngFor="let slider of sliders; index as i; first as isFirst">
    <div *ngIf="context.Subtitles.length == 0" class="subtitles-div" [style.margin-top]="isFirst ? '' : '32px'">
      <textarea class="subtitle2" matInput cdkTextareaAutosize [disabled]="true">{{translateButtonToggles[i] ? slider.Elemento4 : slider.Elemento1}}</textarea>
      <mat-button-toggle *ngIf="context.TranslateAWS != '' && slider.Elemento4 != slider.Elemento1 && slider.Elemento4 != '' && slider.Elemento1 != ''" (click)="translateButtonToggles[i] = !translateButtonToggles[i]"
            class="translate-button">{{translateButtonToggles[i] ? context.OriginalText : context.TranslateAWS}}</mat-button-toggle>
    </div> 
    <mat-slider style="width:100%;" [value]="slider.Elemento2 * (100 / (context.Labels.length - 1))" (change)="updateSliderValue($event, i)" [step]="100 / (context.Labels.length - 1)" [disabled]="context.Readonly != ''"></mat-slider>
    <table class="labelsTable">
      <tr>
        <td *ngFor="let l of context.Labels; index as j; first as isFirst; last as isLast" [style.text-align]="isFirst? 'left' : (isLast ? 'right' : 'center')"
            [width]="isFirst || isLast ?  labelWidth1 : labelWidth2">
            <span [class]="j == slider.Elemento2 ? 'sliderLabelSelected' : 'sliderLabel'">{{l}}</span>
        </td>
      </tr>
    </table>  
    <div class="commentarea-div" [style.gap]="commentTextareas[i] ? '16px' : '0px'">
      <div class="buttons-div">
        <button mat-raised-button class="comment-button" color="accent" *ngIf="context.ButtonAddComment" [disabled]="commentTextareas[i] == true"
                [matTooltip]="context.ButtonAddCommentTooltip" (click)="toggleCommentArea(i, true)">
                <mat-icon>message</mat-icon>
                {{context.ButtonAddCommentContent}}
        </button>
        <button mat-mini-fab class="close-button" color="accent" *ngIf="commentTextareas[i]" (click)="toggleCommentArea(i, false)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="comment-td">
        <textarea class="comment" matInput cdkTextareaAutosize *ngIf="commentTextareas[i]" [disabled]="context.Readonly != '' || translateButtonTogglesComment[i]" [placeholder]="context.Placeholder" 
                  [(ngModel)]="translateButtonTogglesComment[i] ? slider.Elemento3 : slider.Elemento5"></textarea>
        <mat-button-toggle *ngIf="commentTextareas[i] && context.TranslateAWS != '' && (slider.Elemento3 != slider.Elemento5 && slider.Elemento3 != '')" (click)="toggleCommentTranslateButton(i)"
                          class="translate-button">{{translateButtonTogglesComment[i] ? context.OriginalText : context.TranslateAWS}}
        </mat-button-toggle>
      </div>
  </div>
  </ng-container>

</mat-card>
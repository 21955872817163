import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';

@Component({
  selector: 'component-cp085',
  templateUrl: './cp085.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp085.component.scss']
})
export class Cp085Component {

  @Input() context:any
  @Input() teguid:any = "TE0000"
  @Input() labels:any
  loading:boolean = false
  nowDate:any = new Date()
  nowMinusWeekDate:any = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));

  constructor(private _snackbarManager:SnackbarmanagerService, public dialog: MatDialog, private _errorThrower:ErrorthrowerService, private _api:ApiService) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["teguid"] != undefined && changes['teguid'].currentValue != undefined && changes['teguid'].currentValue != '') {
      this.context = undefined
      this.getTokenStatus()
    }
  }

  getTokenStatus() {
    this._api.doButtonPostRequest({}, "/a3innuva").subscribe((response) => {      
      if(response) {        
        this._api.printResponse(response);    
        var responseObj = JSON.parse(JSON.stringify(response))
        this.context = responseObj.body
        this.loading = false;
      }
    }, (error) => {
      if(error) {
        console.log(error);            
        this.loading = false;
        this._snackbarManager.open("Error!", 'ko');
      }
    });  
  }

  navigate() {
    this._api.navigate("AP004", "TE0000", "MO098", "FA0446");
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { Table } from 'primeng/table';



export interface DecodingsTable {
  input: string;
  output: string;
}

export interface RecordsTable {
  Record: string;
  Type: string;
  Code: string;
}

export interface Condition {
  equals:boolean,
  value:string,
  and?:boolean
}

@Component({
  selector: 'component-cp072',
  templateUrl: './cp072.component.html',
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./cp072.component.scss']
})
export class Cp072Component implements OnInit {
 
  @ViewChild('dt') table: Table | undefined;
  firstRow:any = 0
  selectedRows:any[] = []
  recordsTable:RecordsTable[] = []
  recordsTableData:any = new MatTableDataSource(this.recordsTable);
  recordsDisplayedColumns: string[] = ['Record', 'Type', 'Code', '']
  recordsColumns: string[] = ['Voce', 'Tipo', 'Cod']
  recordsDataTable:any
  allowedFileExtension:string = "csv"
  recordSourcesString:any = ""
  recordsSourcesBak:any = {}
  isRecordsSourcesStringGood:any = true

  companyDecodings:boolean = false
  companyDecodingsEnabled:boolean = false
  companyDecodingsTable:DecodingsTable[] = []
  companyDecodingsTableData:any = new MatTableDataSource(this.companyDecodingsTable);
  companyDecodingsDisplayedColumns: string[] = ['input', 'output', 'remove']

  payrollSource:string = "payroll"

  isEvalValid:boolean = true
  filterOn:string = "level"
  filterEval:Condition[] = []
  filterEvalStr:string = ""
  /*
    {
      equals:true,
      value: "0",
      and:false
    },
    {
      equals:false,
      value: "1",
      and:true
    },
    {
      equals:false,
      value: "2"
    }
    */

  config:any = {}
  configBak:any = {}
  baseConfig:any = {}

  constructor(private _snackbarManager:SnackbarmanagerService, private ngxCsvParser: NgxCsvParser, private _api:ApiService) { }

  ngOnInit(): void {
  }

  async loadConfig(config:any, baseConfig:any) {
    this.config = config
    this.configBak = JSON.parse(JSON.stringify(config))
    this.baseConfig = JSON.parse(baseConfig)

    this.loadPreferences()
    this.loadRecords()
    return new Promise((resolve) => {
      resolve("Successfully resolved");
   });
  }

  loadPreferences() {
    //company decodings
    if (this.config.decode != undefined) {
      var decodings = this.config.decode.split(';')
      this.companyDecodingsTable = []
      decodings.forEach((d:string) => {
        var splittedDecoding = d.split(',')
        this.companyDecodingsTable.push({input:splittedDecoding[0], output:splittedDecoding[1]})
      })
      this.companyDecodingsTableData = new MatTableDataSource(this.companyDecodingsTable)

      this.companyDecodings = true
      this.companyDecodingsEnabled = true
    } else {
      this.companyDecodings = false
      console.log("No decodings found!")
    }

    //payroll source
    var i = this.getPayrollSourceIndex(this.config)
    if (i < 0) this.payrollSource = "employmentid"
    else {
      if (this.config.myExtract.FIELD[i].Field.includes("EmploymentID")) this.payrollSource = "employmentid"
      else if (this.config.myExtract.FIELD[i].Entita.includes("Visa")) this.payrollSource = "visadetails"
      else if (this.config.myExtract.FIELD[i].Field.includes("PayrollCode")) this.payrollSource = "payrollcode"
    }

    //Filter
    i = this.getFilterIndex(this.config)
    if (i < 0) this.filterOn = "level"
    else {
      if (this.config.myExtract.FIELD[i].Field.includes("CCNL")) this.filterOn = "ccnl"
      else if (this.config.myExtract.FIELD[i].Field.includes("Level"))  this.filterOn = "level"
      this.parseEval(this.config.myExtract.FIELD[i].Decode)
    }
  }


  parseEval(evalString:string) {
    evalString = "{0} == '1' && {0} != ''"
    var eval_splitted = evalString.replace('eval:', '').split(' ')
    this.isEvalValid = this.checkEvalValidity(eval_splitted)
    if (this.isEvalValid) {
      var condition:Condition = {
        equals: true,
        value: ""
      }
      var step = 0
      this.filterEval = []
      this.filterEvalStr = ""
      eval_splitted.forEach((e:string) => {
        switch (step) {
          case 1:
            if (e == '==') condition.equals = true
            else if (e == '!=') condition.equals = false
            break
          case 2:
            condition.value = e
            break
          case 3:
            if (e == '&&') {
              condition.and = true
              this.filterEval.push(condition)
              step = -1
            } else if (e == '||') {
              condition.and = false
              this.filterEval.push(condition)
              step = -1
            }
            else step = 0
        }
        step += 1
      })
    } else {
      this.filterEvalStr = evalString
      this.filterEval = []
    }
  }

  checkEvalValidity(evalSplitted:string[]) {    
    var i = 0
    var firstRange:number[] = []
    var secondRange:number[] = []
    var thirdRange:number[] = []
    var fourthRange:number[] = []
    for(i = 0; i < 32; i+=4) {
      firstRange.push(i)
      secondRange.push(i+1)
      thirdRange.push(i+2)
      fourthRange.push(i+3)
    }
    var isValid = true
    i = 0
    console.log(evalSplitted)
    evalSplitted.every((s:any) => {
      console.log(s)
      if ((firstRange.includes(i) && s.length != 3) ||
        (secondRange.includes(i) || fourthRange.includes(i)) && s.length != 2) {
        isValid = false
        console.log("Invalid filter eval, character [" + s + "] at position " + i + " of string '" + evalSplitted + "'")
        return 0
      } 
      i++
      return 1
    })
    return isValid
  }

  compileEval() {
    var evalString = "eval:"
    this.filterEval.forEach((c:any) => {
      evalString += "{0} "
      if (c.equals) evalString += "== '" + c.value + "' "
      else evalString += "!= '" + c.value + "' "
      if (c.and != undefined) {
        if (c.and) evalString += "&& "
        else evalString += "|| "
      }
    })
    console.log(evalString)
    return evalString
  }

  onToggleValueChange(event:any, index:number) {
    console.log(event)
    this.filterEval[index].and = event.value
  }

  addCondition() {
    if (this.filterEval.length == 0) {
      this.filterEval.push(
        {
          equals:true,
          value:"",
        }
      )
    } else {      
      this.filterEval[this.filterEval.length - 1].and = true
      this.filterEval.push(
        {
          equals:true,
          value:"",
        }
      )
    }
  }

  removeCondition(index:number) {
    if (this.filterEval[index].and != undefined) delete this.filterEval[index].and
    else delete this.filterEval[index - 1].and
    this.filterEval.splice(index, 1)
  }

  loadRecords() {
    this.recordSourcesString = JSON.stringify(this.config.voci.FONTI, null, 2)
    this.recordsSourcesBak = JSON.parse(JSON.stringify(this.config.voci.FONTI))
    if (this.config.voci.VOCI != undefined) {
      this.recordsTable = []
      this.config.voci.VOCI.forEach((v:any) => {
        this.recordsTable.push({Record:v.Voce, Type:v.Tipo, Code:v.Cod})
      })
      this.recordsTableData = new MatTableDataSource(this.recordsTable)
    }
  }

  addDecoding() {
    this.companyDecodingsTable.push({input:"", output:""})
    this.companyDecodingsTableData = new MatTableDataSource(this.companyDecodingsTable)
    console.log("a")
  }

  removeDecoding(index:number) {
    this.companyDecodingsTable.splice(index, 1)
    this.companyDecodingsTableData = new MatTableDataSource(this.companyDecodingsTable)
    console.log(this.companyDecodingsTable)
  }

  isRecordsSource(s:any) {
    if (Object.keys(this.config.voci.FONTI).includes(s)) return true
    else return false
  }

  modifyRecordsSource(source:string) {
    if (Object.keys(this.config.voci.FONTI).includes(source)) {
      delete this.config.voci.FONTI[source]
    } else {
      this.config.voci.FONTI[source] = {}
    }
    this.recordSourcesString = JSON.stringify(this.config.voci.FONTI, null, 2)
  }

  compareRecordsSources() {
    return this.recordSourcesString === JSON.stringify(this.recordsSourcesBak, null, 2)
  }

  setRecordsSources(event:any) {
    try {
      this.config.voci.FONTI = JSON.parse(event.target.value)
      this.recordSourcesString = JSON.stringify(this.config.voci.FONTI, null, 2)
      this.isRecordsSourcesStringGood = true
    } catch {
      this.recordSourcesString = event.target.value
      this.isRecordsSourcesStringGood = false
    }
  }

  restoreRecordsSources() {
    this.config.voci.FONTI = JSON.parse(JSON.stringify(this.recordsSourcesBak))
    this.recordSourcesString = JSON.stringify(this.config.voci.FONTI, null, 2)
    this.isRecordsSourcesStringGood = true
    this._snackbarManager.open("Records sources restored!", "ok")
  }
  
  addRecord() {
    this.config.voci.VOCI.push({
      "Voce":"New",
      "Tipo":"",
      "Cod":"",
      "Ore":0,
      "Imp":0
    })
    if (this.config.voci.VOCI.length > 10) this.firstRow = (this.config.voci.VOCI.length - 1) - (this.config.voci.VOCI.length % 10)
    this._snackbarManager.open("New record added!", "ok")
  }
  
  removeRecords() {
    this.selectedRows.forEach((r:any) => {
      this.config.voci.VOCI = this.config.voci.VOCI.filter( (item:any) => item !== r );
    })
    if (this.selectedRows.length == this.config.voci.VOCI) this.firstRow = 0
    this.selectedRows = []
    if (this.selectedRows.length > 1) this._snackbarManager.open("The selected rows have been removed.", "ok")
    else this._snackbarManager.open("The selected row has been removed.", "ok")
  }

  onFileSelected(event: any){
    let filesArray = event.target.files;
    if (filesArray.length > 0) {
      this.ngxCsvParser.parse(filesArray[0], { header: false, delimiter: ',', encoding: 'utf8' })
      .pipe().subscribe({
        next: (result:any): void => {
          result.forEach((v:any) => {
            this.config.voci.VOCI.push({
              "Voce":v[0],
              "Tipo":v[1],
              "Cod":v[2],
              "Ore":0,
              "Imp":0,
            })
          });
          this.firstRow = 0
          this._snackbarManager.open("New records have been imported.", "ok")
        },
        error: (error: NgxCSVParserError): void => {
          console.log('Error', error);
        }
      });
    }
  }

  isEmpty(obj:any) {
    return Object.keys(obj).length == 0
  }

  getFilterIndex(config:any) {
    var i = 0
    config.myExtract.FIELD.every((f:any) => {
      if (f.Entita == "$Filter" && (f.Field.includes("ContractualSalaryLevelCode") || f.Field.includes("CCNLCode"))) return false
      i += 1
      return true
    })
    if (i == config.myExtract.FIELD.length) i = -1
    return i
  }

  getPayrollSourceIndex(config:any) {
    var i = 0
    config.myExtract.FIELD.every((f:any) => {
      if (f.Entita == "$Calculator" && f.Field.includes("payrollCode:")) return false
      i += 1
      return true
    })
    if (i == config.myExtract.FIELD.length) i = -1
    console.log(config.myExtract.FIELD[i]) 
    return i
  }

  buildConfig() {
    //company decoding
    var companyDecodingsString = ""
    var i = 0
    this.companyDecodingsTable.forEach((d:any) => {
      if (this.companyDecodingsTable.length > 1 && i > 0) companyDecodingsString += ';'
      companyDecodingsString += d.input + ',' + d.output
      i += 1
    })
    this.config.decode = companyDecodingsString


    //payroll source
    var payrollField = "payrollCode:"
    if (this.payrollSource == "employmentid") payrollField += "Employments.EmploymentID"
    else if (this.payrollSource == "visadetails") payrollField += "Documents.VisaDetails"
    else if (this.payrollSource == "payrollcode") payrollField += "Employments.PayrollCode"
    var payrollSource = {
      "Entita": "$Calculator",
      "Field": payrollField,
      "Decode": "eval:{0}",
      "Type": "varchar",
      "Label": "Payroll ID"
    }
    i = this.getPayrollSourceIndex(this.config)
    if (i < 0) this.config.myExtract.FIELD.push(payrollSource)
    else this.config.myExtract.FIELD[i] = payrollSource


    //filter
    var fieldString = "noEmploy:NationalContractSalaryLevels."
    if (this.filterOn == "level") fieldString += "ContractualSalaryLevelCode" 
    else if (this.filterOn == "ccnl") fieldString += "CCNLCode" 
    var evalString = this.compileEval()
    var filter = {
				"Entita": "$Filter",
				"Field": fieldString,
				"Decode": evalString,
				"Type": "varchar",
				"Label": "NoPrint"
		}    
    i = this.getFilterIndex(this.config)
    if (i < 0) this.config.myExtract.FIELD.push(filter)
    else this.config.myExtract.FIELD[i] = filter

    console.log(this.configBak)
    console.log(this.config)
    this.config = JSON.parse(JSON.stringify(this.configBak))
    return {}
  }

}

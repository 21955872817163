<ng-container *ngFor="let item of component">
    <component-error *ngIf="item.Component.Type == 'ERROR'" [context]="item.Component.Context"></component-error>
    <component-cp001 *ngIf="item.Component.Type == 'CP001' || item.Component.Type == 'CP001_1' || item.Component.Type == 'CP001_2' || item.Component.Type == 'CP001_3'" [context]="item.Component.Context"></component-cp001>
    <component-cp002 *ngIf="item.Component.Type == 'CP002'" [context]="item.Component.Context"></component-cp002>
    <component-cp003 *ngIf="item.Component.Type == 'CP003'" [context]="item.Component.Context"></component-cp003>
    <component-cp004 *ngIf="item.Component.Type == 'CP004'" [context]="item.Component.Context"></component-cp004>
    <component-cp005 *ngIf="item.Component.Type == 'CP005'" [context]="item.Component.Context"></component-cp005>
    <component-cp006 *ngIf="item.Component.Type == 'CP006'" [context]="item.Component.Context"></component-cp006>
    <component-cp007 *ngIf="item.Component.Type == 'CP007'" [context]="item.Component.Context"></component-cp007>
    <component-cp008 *ngIf="item.Component.Type == 'CP008'" [context]="item.Component.Context"></component-cp008>
    <component-cp009 *ngIf="item.Component.Type == 'CP009'" [context]="item.Component.Context"></component-cp009>
    <component-cp010 *ngIf="item.Component.Type == 'CP010' || item.Component.Type == 'CP010_1'" [context]="item.Component.Context"></component-cp010>
    <component-cp013 *ngIf="item.Component.Type == 'CP013'" [context]="item.Component.Context"></component-cp013>
    <component-cp014 *ngIf="item.Component.Type == 'CP014'" [context]="item.Component.Context"></component-cp014>
    <component-cp015 *ngIf="item.Component.Type == 'CP015'" [context]="item.Component.Context"></component-cp015>
    <component-cp016 *ngIf="item.Component.Type == 'CP016'" [context]="item.Component.Context"></component-cp016>
    <component-cp025 *ngIf="item.Component.Type == 'CP025'" [context]="item.Component.Context"></component-cp025>
    <component-cp027 *ngIf="item.Component.Type == 'CP027'" [context]="item.Component.Context"></component-cp027>
    <component-cp028 *ngIf="item.Component.Type == 'CP028'" [context]="item.Component.Context"></component-cp028>
    <component-cp029 *ngIf="item.Component.Type == 'CP029'" [context]="item.Component.Context"></component-cp029>
    <component-cp030 *ngIf="item.Component.Type == 'CP030'" [context]="item.Component.Context"></component-cp030>
    <component-cp031 *ngIf="item.Component.Type == 'CP031'" [context]="item.Component.Context"></component-cp031>
    <component-cp031 *ngIf="item.Component.Type == 'CP032'" [context]="item.Component.Context"></component-cp031>
    <component-cp034 *ngIf="item.Component.Type == 'CP034'" [context]="item.Component.Context"></component-cp034>
    <component-cp035 *ngIf="item.Component.Type == 'CP035'" [context]="item.Component.Context"></component-cp035>
    <component-cp036 *ngIf="item.Component.Type == 'CP036'" [context]="item.Component.Context"></component-cp036>
    <component-cp038 *ngIf="item.Component.Type == 'CP038'" [context]="item.Component.Context"></component-cp038>
    <component-cp039 *ngIf="item.Component.Type == 'CP039'" [context]="item.Component.Context"></component-cp039>
    <component-cp040 *ngIf="item.Component.Type == 'CP040'" [context]="item.Component.Context" [title]="title"></component-cp040>
    <component-cp041 *ngIf="item.Component.Type == 'CP041'" [context]="item.Component.Context"></component-cp041>
    <component-cp043 *ngIf="item.Component.Type == 'CP043'" [context]="item.Component.Context" [response]="response"></component-cp043>
    <component-cp044 *ngIf="item.Component.Type == 'CP044'" [context]="item.Component.Context" [response]="response"></component-cp044>
    <component-cp045 *ngIf="item.Component.Type == 'CP045'" [context]="item.Component.Context"></component-cp045>
    <component-cp046 *ngIf="item.Component.Type == 'CP046'" [context]="item.Component.Context"></component-cp046>
    <component-cp047 *ngIf="item.Component.Type == 'CP047'" [context]="item.Component.Context"></component-cp047>
    <component-cp050 *ngIf="item.Component.Type == 'CP050'" [context]="item.Component.Context" [username]="username"></component-cp050>
    <component-cp060 *ngIf="item.Component.Type == 'CP060'" [context]="item.Component.Context"></component-cp060>
    <component-cp061 *ngIf="item.Component.Type == 'CP061'" [context]="item.Component.Context"></component-cp061>
    <component-cp064 *ngIf="item.Component.Type == 'CP064'" [context]="item.Component.Context"></component-cp064>
    <component-cp065 *ngIf="item.Component.Type == 'CP065'" [context]="item.Component.Context"></component-cp065>
    <component-cp070 *ngIf="item.Component.Type == 'CP070'" [context]="item.Component.Context"></component-cp070>
    <component-cp080 *ngIf="item.Component.Type == 'CP080'" [context]="item.Component.Context"></component-cp080>
    <component-cp090 *ngIf="item.Component.Type == 'CP090'" [context]="item.Component.Context"></component-cp090>
    <component-cp100 *ngIf="item.Component.Type == 'CP100'" [context]="item.Component.Context"></component-cp100>
    <component-wfroute *ngIf="item.Component.Type == 'WFROUTE'" [context]="item.Component.Context"></component-wfroute>
    <component-wfstarteammember *ngIf="item.Component.Type == 'WFSTARTEAMMEMBER'" [context]="item.Component.Context"></component-wfstarteammember>
    <component-cp999 *ngIf="item.Component.Type == 'CP999'" [context]="item.Component.Context"></component-cp999>
</ng-container>

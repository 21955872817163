import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ErrorthrowerService } from 'src/app/services/errorthrower.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { DialogComponent } from '../dialog/dialog.component';


@Component({
  selector: 'component-cp084',
  templateUrl: './cp084.component.html',
  styleUrls: ['./cp084.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('.2s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('.1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class Cp084Component {

  context:any = {}
  @Input() teguid:string = ""
  @Input() faguid:string = ""
  @Input() labels:any
  loadingList:boolean = true
  loadingDetail:boolean = true
  gettingFunctions:boolean = false
  selectedSchedule:any | undefined = undefined
  selectedScheduleIndex:number | undefined = undefined
  scheduleSelected:boolean = false;
  newSchedule:boolean = false;
  daysWeekArray:any = [
    {
      "name": "SUN",
      "checked": false,
    },
    {
      "name": "MON",
      "checked": false,
    },
    {
      "name": "TUE",
      "checked": false,
    },
    {
      "name": "WED",
      "checked": false,
    },
    {
      "name": "THU",
      "checked": false,
    },
    {
      "name": "FRI",
      "checked": false,
    },
    {
      "name": "SAT",
      "checked": false,
    },
  ]
  daysMonthArray:any[31] = [ ]
  convertedHour:any = "0:00 AM"
  daysMode:string = "week"
  lastMonthDayToggle:boolean = false
  
  @ViewChild('picker', {static: false}) private picker: any | undefined;
  @Output() closeFormEvent = new EventEmitter<any>();

  constructor(private _snackbarManager:SnackbarmanagerService, private errorThrower:ErrorthrowerService, private _api:ApiService, public dialog: MatDialog) {}

  ngOnInit() {
    this.daysWeekArray[0].name = this.labels.sunLabel
    this.daysWeekArray[1].name = this.labels.monLabel
    this.daysWeekArray[2].name = this.labels.tueLabel
    this.daysWeekArray[3].name = this.labels.wedLabel
    this.daysWeekArray[4].name = this.labels.thuLabel
    this.daysWeekArray[5].name = this.labels.friLabel
    this.daysWeekArray[6].name = this.labels.satLabel
    this.getSchedulesList()   
  }
  
  getSchedulesList() {
    this.loadingList = true
    this.selectedSchedule = undefined
    this.selectedScheduleIndex = undefined
    this.scheduleSelected = false;
    if (this.context.schedulersList != undefined) {
      this.context.schedulersList = undefined
    }
    this._api.doButtonPostRequest({"TEGUID": this.teguid, "action": "list"}, "/schedule").subscribe((response) => {      
      if(response) {        
        this._api.printResponse(response);    
        var responseObj = JSON.parse(JSON.stringify(response))        
        //console.log(responseObj)
        this.context = responseObj
        if (this.context["schedulersList"].length == 0) {
          this.addSchedule()
        } else {
          var i = 0
          var found = false
          this.context["schedulersList"].every((s:any) => {
            if (s.name.includes(this.faguid)) {              
              this.getScheduleDetails(i) 
              found = true
              return false
            }
            i += 1
            return true
          })
          if (!found) {
            this.addSchedule()
          }
        }
        this.loadingList = false;
      }
    }, (error) => {
      if(error) {
        this.context["schedulersList"] = []
        console.log(error);            
        this.loadingList = false;
        this._snackbarManager.open("Error!", 'ko');
      }
    });  
  }

    getScheduleDetails(i:any) { 
      this.selectedScheduleIndex = i
      this.selectedSchedule = undefined
      //console.log(this.context.schedulersList[i])
      if (this.context.schedulersList[i].isNew == undefined && Object.keys(this.context.schedulersList[i]).length < 4) {
        this.loadingDetail = true 
        var context_data = {
          "schedulerName": this.context.schedulersList[i].name,
          "action": "get"
        }
        this._api.doButtonPostRequest(context_data, "/schedule").subscribe((response) => {      
          if(response) {        
            this._api.printResponse(response);    
            var responseObj = JSON.parse(JSON.stringify(response))
            if (responseObj.isSuccess) {
              this.newSchedule = false
              this.selectedSchedule = responseObj.scheduler
              this.context.schedulersList[i] = responseObj.scheduler
              this.initDaysWeek()
              this.initDaysMonth()
              this.initTime()
              this.scheduleSelected = true
            } else {
              this._snackbarManager.open(responseObj.errorMessage, 'ko');
            }
            this.loadingDetail = false
          }
        }, (error) => {
          if(error) {
            //console.log(error);    
            this.loadingDetail = false
            this._snackbarManager.open("Error!", 'ko');
          }
        });  
      } else {
        this.selectedSchedule = this.context.schedulersList[i]
        this.initDaysWeek()
          this.initDaysMonth()
          this.initTime()
      }
      
    }

    initDaysWeek() {
      if (this.selectedSchedule.daysWeek != null) {
        this.daysWeekArray.forEach((day:any) => {
          day.checked = false
        })
        this.selectedSchedule.daysWeek.forEach((day:any) => {
          this.daysWeekArray[day-1].checked = true
        })
        this.daysMode = 'week'
      }
    }

    initDaysMonth() {
      if (this.selectedSchedule.daysMonth != null) {
        for (var i = 0; i < 31; i++) {
          this.daysMonthArray.push(false)
        }         
        if (this.selectedSchedule.daysMonth.length == 1 && this.selectedSchedule.daysMonth[0] == 32) {
          this.lastMonthDayToggle = true
        } else if (this.selectedSchedule.daysMonth.length == 1 && this.selectedSchedule.daysMonth[0] == 0) {
          for (var i = 0; i < 31; i++) {
            this.daysMonthArray[i] = true
          }         
        } else {
          this.selectedSchedule.daysMonth.forEach((day:number) => {
            this.daysMonthArray[day-1] = true
          })
        }
        this.daysMode = 'month'   
      }
    }

    initTime() {
      if (this.selectedSchedule.hours > 11 && this.selectedSchedule.hours < 24) {
        this.convertedHour = String(this.selectedSchedule.hours) + ":00 PM"
      } else if (this.selectedSchedule.hours == 24) {
        this.convertedHour = String(0) + ":00 AM"
      } else {
        this.convertedHour = String(this.selectedSchedule.hours) + ":00 AM"
      }
      //console.log(this.convertedHour)
    }

    addSchedule() {
      this.selectedSchedule = {
        "TEGUID": this.teguid,
        "FAGUID": this.faguid,
        "enabled": false,
        "hours": 0,
        "daysWeek": [ 1, 2, 3, 4, 5, 6, 7 ], 
        "daysMonth": null,
        "isNew": true
      }
      this.context.schedulersList.push(this.selectedSchedule)
      this.initDaysWeek()
      this.initDaysMonth()
      this.initTime()
      this.newSchedule = true
      this.scheduleSelected = true
      this.selectedScheduleIndex = this.context.schedulersList.length - 1      
      this.loadingDetail = false
      //console.log(this.context.schedulersList.length - 1)
    }
    
    removeSchedule() {
      if (this.selectedSchedule.isNew != undefined && this.selectedSchedule.isNew == true) {
        this.scheduleSelected = false
        this.selectedScheduleIndex = undefined
        this.selectedSchedule = undefined
        this.newSchedule = false
        this.context.schedulersList.pop()
      } else {
        //console.log(this.selectedSchedule)
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: "Delete confirm",
            content: "Would you like to delete this schedule?",
            accept: "Yes",
            reject: "No"
          },
        });
        //console.log(context_data_tmp)
        dialogRef.afterClosed().subscribe(result => {   
          if (result) {              
            this._api.loading = true;    
            var context_data:any = {
              "action": "delete",
              "schedulerName": this.selectedSchedule.name
            }

            this._api.doButtonPostRequest(context_data, "/schedule").subscribe((response) => {      
              if(response) {        
                this._api.printResponse(response);    
                var responseObj = JSON.parse(JSON.stringify(response))
                if (responseObj.isSuccess) {
                  this._snackbarManager.open("Success!", 'ok');   
                  this.getSchedulesList()
                } else {
                  this._snackbarManager.open(responseObj.errorMessage, 'ko');
                }
                this._api.loading = false; 
              }
            }, (error) => {
              if(error) {
                console.log(error);   
                this._api.loading = false
                this._snackbarManager.open("Error!", 'ko');
              }
            });  
            
          }
        });
      }
    }

    changeDays(event:any) {
      //console.log(event)
      if (this.daysMode == "week") {
        this.selectedSchedule.daysMonth = null
        this.selectedSchedule.daysWeek = []
        this.updateDaysWeek()
      } else {
        this.selectedSchedule.daysWeek = null
        this.selectedSchedule.daysMonth = []  
        this.selectDaysMonth()
      }
      //console.log("daysWeek: " + this.selectedSchedule.daysWeek)
      //console.log("daysMonth: " + this.selectedSchedule.daysMonth)
    }

    getFunctionsList(event:any) {
      this.gettingFunctions = true;
      //console.log(this.context)
      this.loadingDetail = true 
      this.selectedSchedule.TEGUID = event.value
      var context_data = {
        "TEGUID": event.value,
      }
      this._api.doButtonPostRequest(context_data, "/schedule").subscribe((response) => {      
        if(response) {        
          this._api.printResponse(response);    
          var responseObj = JSON.parse(JSON.stringify(response))
          if (responseObj.isSuccess) {
            this.context.integrationList = responseObj.integrationList
          } else {
            this._snackbarManager.open(responseObj.errorMessage, 'ko');
          }
          
      //console.log(this.context)
          this.loadingDetail = false
          this.gettingFunctions = false;
        }
      }, (error) => {
        if(error) {
          console.log(error);    
          this.loadingDetail = false          
          this.gettingFunctions = false;
          this._snackbarManager.open("Error!", 'ko');
        }
      });  
    }

    setFAGUID(event:any) {
      this.selectedSchedule["FAGUID"] = event.value
    }

    updateHour(event:any) {
      this.selectedSchedule.hours = Number(event.split(':')[0])
      if (event.includes("PM")) this.selectedSchedule.hours += 12
      if (this.selectedSchedule.hours == 24) this.selectedSchedule.hours = 0
      //console.log(this.selectedSchedule.hour )
    }

    updateDaysWeek() {
      var arr:number[] = []
      var d = 1
      this.daysWeekArray.forEach((day:any) => {
        if (day.checked) arr.push(d)
        d += 1
      })
      this.selectedSchedule.daysWeek = arr
      //console.log(this.selectedSchedule.daysWeek)
    }

    selectDaysMonth() {
      var days:number[] = []
      var d = 1
      //console.log(this.daysMonthArray)
      this.daysMonthArray.forEach((day:boolean) => {
        if (day) days.push(d)
        d += 1
      })
      if (this.lastMonthDayToggle) {
        this.selectedSchedule.daysMonth = [32]    
      } else {        
        this.selectedSchedule.daysMonth = days    
      }  
      //console.log(this.selectedSchedule.daysMonth)
    }

    selectAllDaysMonth() {
      this.lastMonthDayToggle = false
      this.daysMonthArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
      this.selectedSchedule.daysMonth = this.daysMonthArray
    }

    selectNoneDaysMonth() {
      this.lastMonthDayToggle = false
      this.daysMonthArray =  []
      this.selectedSchedule.daysMonth = this.daysMonthArray
    }

    selectLastMonthDay() {
      if (!this.lastMonthDayToggle) {
        this.lastMonthDayToggle = true
        this.selectedSchedule.daysMonth = [32]
      } else {
        this.lastMonthDayToggle = false
        this.selectDaysMonth()
      }
    }

    toggleStatus() {
      this.selectedSchedule.enabled = !this.selectedSchedule.enabled
      //console.log(this.selectedSchedule.enabled)
    }

    isSavingDisabled() {
      if (this.context.schedulersList == undefined || this.selectedSchedule == undefined || 
        (this.daysMode == 'month' && this.selectedSchedule.daysMonth != null && this.selectedSchedule.daysMonth.length == 0) || 
        (this.daysMode == 'week' && this.selectedSchedule.daysWeek != null && this.selectedSchedule.daysWeek.length == 0) || 
        (this.selectedSchedule.isNew != undefined && (this.selectedSchedule.TEGUID == '' || this.selectedSchedule.FAGUID == ''))) {
          return true
      } else {
        return false
      }
    }

    save() {
      //console.log(this.selectedSchedule)
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: this.labels.titleLabel,
          content: this.labels.contentLabel,
          accept: this.labels.okLabel,
          reject: this.labels.koLabel
        },
      });
      //console.log(context_data_tmp)
      dialogRef.afterClosed().subscribe(result => {   
        if (result) {              
          this._api.loading = true;   
          var context_data:any = {
            "action": this.selectedSchedule.isNew ? "create" : "update",
            "scheduler": this.selectedSchedule.name,
            "parameters": this.selectedSchedule
          }

          if (context_data["parameters"]["daysMonth"] != null && context_data["parameters"]["daysMonth"].length == 31) {
            context_data.parameters.daysMonth = [0]
          }
          
          this._api.doButtonPostRequest(context_data, "/schedule").subscribe((response) => {      
            if(response) {        
              this._api.printResponse(response);    
              var responseObj = JSON.parse(JSON.stringify(response))
              if (responseObj.isSuccess) {
                this._snackbarManager.open(this.labels.successLabel, 'ok')
                this.close(true)
                //this.getSchedulesList()   
              } else {
                this._snackbarManager.open(responseObj.errMessage, 'ko');
              }
              this._api.loading = false; 
            }
          }, (error) => {
            if(error) {
              console.log(error);    
              this._snackbarManager.open(this.context.PopupLabels[5], 'ko');
            }
          });  
          
        }
      });
    }

    close(success?:boolean) {
      this.closeFormEvent.emit({
        isSuccess : success,
        name: this.selectedSchedule.name, 
        enabled: this.selectedSchedule.enabled
      });
    }

    //powerbi editor
    /*
    SELECT PRFUCO.TEGUID, PRFUCO.COKEY, PRCOMP.COGEC 
FROM wpdp_main.PRFUCO, wpdp_main.PRCOMP 
WHERE PRFUCO.TEGUID = 'TE0069' and PRFUCO.COGUID = 'CP061' 
and PRCOMP.COGUID = PRFUCO.COGUID AND PRCOMP.COKEY = PRFUCO.COKEY;
    */
  
}

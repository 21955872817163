import { Component, Input, OnInit } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { SnackbarmanagerService } from 'src/app/services/snackbarmanager.service';
import { ApiService } from 'src/app/services/api.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'component-cp020',
  templateUrl: './cp020.component.html',
  styleUrls: ['./cp020.component.scss']
})
export class Cp020Component implements OnInit {

  @Input()
  context:any;
  @Input()
  sections:any;
  @Input()
  position:any;
 
  constructor(public _api:ApiService, public dialog: MatDialog, private  _snackbarManager:SnackbarmanagerService) { }

  ngOnInit(): void {
  }

  openDialog(){
    const dialogRef = this.dialog.open(DialogComponent,{
      data: {
        title: this.context.PopupLabels[0],
        content: this.context.PopupLabels[1],
        accept: this.context.PopupLabels[2],
        reject: this.context.PopupLabels[3]
      },
    });
    dialogRef.afterClosed().subscribe(result => {   
      if(result) {              
        this._api.loading = true;   
        var context_data = {          
          "OUCODA" : this._api.lastOUCODA,
          "Sections" : this._api.lastSections
        }
        
        this._api.doButtonPostRequest(context_data, this.context.ActionAPI).subscribe((response) => {      
          if(response) {        
            this._api.printResponse(response);             
            var responseObj = JSON.parse(JSON.stringify(response))  
            if (responseObj.isSuccess) {
              this._snackbarManager.open(this.context.PopupLabels[4], 'ok');               
              var urlParams = this._api.getUrlParameters();
              if (this.context.Navigation != "") {
                if (this.context.Navigation.includes('/')) {
                  var newUrl = this.context.Navigation.split('/')
                  if (newUrl.length == 5) this._api.navigate(newUrl[1], newUrl[2], newUrl[3], newUrl[4]);
                  else if (newUrl.length == 6) this._api.navigate(newUrl[1], newUrl[2], newUrl[3], newUrl[4], newUrl[5]);
                } else this._api.navigate(urlParams.apguid, urlParams.teguid, urlParams.moguid, this.context.Navigation);
              } //else this._api.navigate(urlParams.apguid, urlParams.teguid, urlParams.moguid, "FA0015");             
            } else {
              this._snackbarManager.open(responseObj.errorMessage, 'ko');
            }
            this._api.loading = false;   
          }
        }, (error) => {
          if(error) {
            console.log(error);    
            this._snackbarManager.open(this.context.PopupLabels[5], 'ko');
            this._api.loading = false; 
          }
        });          
      }
    });
  }

}
